export const CUSTOMERS_PAGE = {
    INTERNAL_REPORT:`דו"ח פנימי`,
    EXTERNAL_REPORT:`דו"ח לקוח`,
    ORDER_NUMBER:`מספר הזמנה / קריאה`,
    ORDER_NUMBER_EXIST: `מספר הזמנה כבר קיים`,
    CUSTOMER_NAME:`שם לקוח`,
    DUE_DATE:`תאריך תיאום`,
    CONTACT_PERSON_NAME:`שם איש קשר`,
    CONTACT_PERSON_PHONE_NUMBER:`מספר טלפון איש קשר`,
    REVIEW: `ביקורת`,
    CREATE_SERVICE_CALL: `ביקור טכנאי`,
    ADD_ACTUAL_SYSTEMS:`הוסף מערכת`,
    OK:`אישור`,
    CANCEL:`ביטול`,
    TECHNICIANS:`שם טכנאי`,
    ADDRESS:`כתובת`,
    CITY:`ישוב`,
    SYSTEM_NAME:`סוג מערכת`,
    ACTUAL_SYSTEM_NAME:`שם מערכת`,
    LOCATION_FLOOR:`קומה`,
    LOCATION_DESCRIPTION:`תיאור מיקום`,
    QUANTITY:`כמות`,
    QUANTITY_MSG: `כמות (יש להכניס כמות אם מוסיפים יותר ממערכת אחת)`,
    ADDITIONAL_NOTE: `הערות פנימיות`,
    PHOTO: `תמונה`,
    REMARKS: `הערות`,
    REASON: `סיבת אי תקינות`,
    STATUS: `תקין/לא תקין`,
    TEST: `תאור בדיקה`,
    SEMI_ANNUAL_AUDIT:`ביקורת חצי שנתית`,
    SYSTEMS:`מערכות`,
    BACK_TO_CUSTOMER_LIST:`חזרה ללקוחות‎`,
    FILES: `קבצים‎`,
    SUBSYSTEM: `תת מערכת`,
    CREATE_ORDER: `הוסף הזמנה`,
    CREATE_PROPOSAL: `הצעה`,
    SYSTEM_NAME_REPORT:`שם המערכת`,
    SIGNATURE: `חֲתִימָה`,
    CREATE_QUOTE: `צור הצעת מחיר חדשה`,
    ADD_SUB_SYSTEM:`הוספת תת מערכת`,
    ACTIONS:`פעולות`,
    SELECT_ORDER_TYPE:`סוג פעולה`,
    DESCRIPTION:`תיאור תקלה`,
    ACTION_REQUIRED:`פעולה נדרשת`,
    REPAIRED_ON_THE_SPOT:`תוקן במקום`,
    // LAYER:'שִׁכבָה',
    LAYER: 'רמה',
    SHOW_STOPPER:`show stopper`,
    START_TIME_AND_END_TIME:`שעת התחלה ושעת סיום`, 
    ORDER_WITH_SELECTED_SYSTEMS:`פעולות`,
    MOVE_SYSTEMS_LAYER:'העבר מערכות',
    DELIVERY_DATE: 'תאריך מסירה',
    ORDER_TYPE: 'סוג הזמנה',
    CATEGORY: 'קטגוריה',
    NUMBER_OF_DEVELOPERS: 'של מפתחים',
    INSPECTOR_NAME: 'שם המפקח',
    MOBILE_INSPECTOR: 'מפקח נייד',
    ARCHITECT_NAME: 'שם האדריכל',
    MOBILE_ARCHITECT: 'אדריכל נייד',
    PROJECT_MANAGER: 'מנהל פרוייקט',
    SALES_REPRESENTATIVE: 'נציג מכירות',
    NUMBER_OF_SHUTTERS: 'מספר תריסים',
    SETTLEMENT: 'הֶסדֵר',
    TRANSACTION_VALUE: 'ערך עסקה',
    ORDER_DETAILS: 'פרטי הזמנה',
    CONTACT_AND_ADDRESS: 'אנשי קשר וכתובת',
    CUSTOMER_DETAILS: 'פרטי לקוח',
    OTHER_DETAILS: 'פרטים נוספים',
    PROPOSAL: 'הצעה',
    CANCEL_RESERVATION: 'ביטול הזמנה',
    PAYMENT_DETAILS: 'פרטי תשלום',
}

export const CHECK_LIST_TRANSLATION = {
    PROPER: `תקין`,
    IMPROPER: 'לא תקין',
    ALL_RIGHT: "הכל תקין",
    IMAGE_MUST_BE_ADDED: `יש להוסיף תמונה‎`,
    INTERNAL_COMMENTS:`הערות פנימיות`,
    IMAGE:`תמונה`, 
    REMARKS: `הערות`,
    CAUSE_OF_IRREGULARITY:`סיבת אי תקינות`,
    PROPER_NOT_PROPER: ` תקין/לא תקין`,
    TEST_DESCRIPTION: `תאור בדיקה`,
    NOT_PROPER:`לא תקין`,
    CRITICAL: 'לא קיים / לא ניתן לבצע בדיקה',
    FIXED_IN_PLACE:`תוקן במקום`,
    ACTION_REQUIRED:`פעולה נדרשת`,
    SELECT:'Select',
    EDIT:'Edit',
    DELETE:'Delete',
    ICON:`Icon`,
    FACTORY:'Factory',
    SHOW_STOPPER: 'Show stopper',
    IS_ADDITIONAL_TEST: 'Conditional test',
    ORDER: 'Order', 
    HALF_YEAR_TEST:'Half year',
    ADD_TO_ADDITIONAL_CHECK_LIST:'Add',
    ADD_TO_LAYER_2_CHECK_LIST: 'Add to check list',
    OPEN:'פתוחות',
    CLOSED:'סגורות',
    IMAGE_UPLOAD_ERROR_MESSAGE: 'התמונה לא הועלתה.',
    IMAGE_SIZE_ERROR_MESSAGE: 'גודל התמונה המקסימלי צריך להיות 10 MB.',
    COMMENTS: 'הערות',
    PROGRESS: 'התקדמות'
}

export const EDIT_POP_UP ={
    ACTUAL_SYSTEM_NAME:'מספר / סימון',
    LOCATION_DESCRIPTION:'תיאור מיקום',
    LOCATION_FLOOR:`קומה`,
    BACK_TO_SYSTEMS:`חזרה למערכות`,
    TASK_DESCRIPTION: 'description',
    DONE: 'done',
    RESOURCE: 'resource',
    CREATE_TASK: 'הוסף משימה'
}

export const CREATE_CUSTOMER_POPUP = {
    CITY: `עיר`,
    BUILDING_MODEL :`יעוד מבנה`,
    TERMS_OF_ENGAGEMENT: `תנאי התקשרות`,
    PROJECT_DESCRIPTION: `תיאור הפרויקט`,
    FLOORS_QUANTITY: `מספר קומות הבניין‎`,
    PARKING_LEVELS_QUANTITY: `מספר מפלסי חניון`,
    IS_THE_PARKING_LOT_SHARED: `האם החניון משותף ?`,
    SHARED_PARKINGS_FOR_A_NUMBER_OF_BUILDINGS: `חניון משותף למס' בניינים ?‎`,
    YEAR_OF_OCCUPANCY: `שנת איכלוס`,
    NAME_ALREADY_EXIST_WARNING: 'Name Already Exist',
    NUMBER_ALREADY_EXIST_WARNING: 'Customer Number Already Exist',
    NAME: "שם לקוח",
    CUSTOMER_NUMBER: "מספר לקוח",
    CREATE_CUSTOMER: "הוסף לקוח",
    EDIT_CUSTOMER: "לערוך לקוח",
    CONTACT_NAME: "שם איש קשר",
    PHONE: "טלפון איש קשר",
    EMAIL: "אימייל",
    ADDRESS: "כתובת",
}

export const CUSTOMER_INFO_TABLE = {
    CUSTOMER_NUMBER: `מספר לקוח`,
    CUSTOMER_NAME: `שם לקוח`,
    CONTACT_NAME: `שם איש קשר`,
    PHONE: `טלפון איש קשר`,
    EMAIL: `אימייל`,
    CITY: `עיר`,
    ADDRESS: `כתובת`,
    DETAILS:'פרטים‎'
  }

export const QUOTATION_HISTORY_TABLE = {
    QUOTE_NUMBER: `מספר הצעה`,
    CUSTOMER_NAME: `שם לקוח`,
    PHONE_NUMBER_MOBILE: `מספר טלפון / נייד`,
    ADDRESS: `כתובת`,
    TOTAL_QUOTE: `סהכ הצעה`,
    STATUS: `סטטוס`,
    ISSUE_DATE: `תאריך הצעה`
}

export const QUOTE = {
    DUE_DATE:`תאריך מסירה`,
    QUOTE_STATUS: `סטטוס`,
    CLIENT_NAME:`שם לקוח`,
    ADDRESS:`כתובת`,
    PHONE_NUMBER:`מספר טלפון`,
    QUOTE_NUMBER:`מספר הצעה`,
    ADD_PRODUCT:`הוסף מוצר`,
    ADD_LOCATION: `הוסף מיקום`,
    ALL_QUOTES: `כל ההצעות`,
    OPEN_QUOTE: `פתיחת הצעה`,
    LOCATION: `מיקום`,
    SAVE: `שמור`,
    PRINT: `הדפס הצעה`,
    TOTAL: `סה׳׳ב מחיר`,
    MODEL_NAME:'שם דגם:',
    TOTAL_COST:'מחיר:',
    AMOUNT_OF_PRODUCT:'כמות:',
    PRICE_PER_UNIT:"מחיר ליח'",
    HEIGHT:`גובה:`,
    WIDTH:'רוחב:',
    HUE:'גוון:',
    ENGINE:'מנוע:',
    OPENING_TYPE:'OPENING_TYPE',
    SHUTTER_TYPE:'SHUTTER_TYPE',
    GLASS: 'זכוכית:',
    NET_TYPE:'סוג רשת:',
    NET_WIDTH:`רוחב רשת:`,
    NET_HEIGHT:`גובה רשת:`,
    SERIES_TYPE:`סגנון וסדרה:`,
    UNKNOWN_FIELD_1: `UNKNOWN_FIELD_1`,
    UNKNOWN_FIELD_2: `UNKNOWN_FIELD_2`,
    PARTITION: `קבועים:`,
    BUFFER:`חציצים:‎`, 
    SHEKEL:'₪',
    PROJECT_TYPE:`סוג פרויקט‎`, 
    BUILDING:`בנייה‎`, 
    RENOVATION:`שיפוץ`,
    ONEOFFER: 'יחת הצעה',
    PEROFFER: 'פר הצעה',
    DISCOUNT: 'הנחת מקדמה',
    DISCOUNT_AMOUNT: 'סה״כ הנחה',
    TOTAL_AFTER_DISCOUNT: 'סה״כ כולל הנחה',
    EXTRAS: 'תוספות',
}

export const QUOTE_STATUS_OPTIONS = {
    DRAFT:`טיוטה`,
    SENT_TO_CLIENT:`נמסר ללקוח`,
    MAKE_AN_ORDER:`הפוך להזמנה`,
    CLOSE:`סגור`
}

export const PRODUCT_FORM = {
    VARIOUS: `שׂונות`,
    CHOOSE_PRODUCT_MODEL:` בחר סוג`,
    BACK_TO_QUOTE:`חזרה להצעת מחיר`,
    SOME_TEXT:`בחר/י את ההגדרות הבאות לחישוב מחיר המוצר`, 
    WIDTH:`רוֹחַב`, 
    HEIGHT:`גוֹבַה`, 
    TITLE_FOR_SIZES: `מידות`,
    STRUCTURE :`מִבְנֶה`, 
    PRODUCT_ADDITIONAL_ATTRIBUTES_TITLE: `מאפיינים נוספים`,
    NET_SIZE_TITLE:` מידות רשת`,
    // -------------------------------
    HANDLE_TYPE:`שם ידית:`, 
    COLOR_TYPE: `סוג צבע`, 
    HUE:'סוג ידית',
    CREATE_PRODUCT:`הוסף מוצר`,
    GLASS_TYPE:`סוג זכוכית`,
    BOX_WIDTH:`רוחב ארגז:`,
    SHUTTER_TYPE: `סוג תריס:`,
    SHUTTER_OPEN:`צורת פתיחה:`,
    NET_TYPE: `סוג רשת`,
    SERIES_TYPE: `סוג פרופיל`,
    ENGINE:`סוג מנוע`, 
    BOX: `סוג ארגז תריס:`,
    HANDLE:`סוג ידית:`,
    QUANTITY: `כַּמוּת`,
    REMARKS: `הערות`,
    PARTITION: `קבועים (כמות)‎`,
    BUFFER:`חציצים (ס"מ)‎`, 
}

export const CUSTOMERS_TABLE_HEAD = {
    CUSTOMER_NUMBER: `מספר לקוח`,
    CUSTOMER_NAME: `שם לקוח`,
    CONTACT_NAME: `שם איש קשר`,
    MOBILE_PHONE: `נייד`,
    EMAIL: `מייל`,
    CITY: `עיר`,
    ADDRESS: `כתובת`,
    EDIT:`ערוך`
  }

  export const QUOTE_PDF = {
      NUMBER:`מספר הצעה:`,
      PROJECT_TYPE: `סוג פרויקט:`,
      CUSTOMER_NAME: `שם הלקוח:`,
      ADDRESS: `כתובת:`,
      PHONE:`מספר נייד:`,
      ID_NUMBER: `מספר ת״ז:`,
      DUE_DATE:`תאריך הפקת הצעה:`,
      EXTRAS:`תוספות`,
      TOTAL_PRICE_BEFORE_VAT:`סה״כ מחיר לפני מע״מ`,
      TOTAL_PAYMENT_INCLUDING_VAT: `סה״כ כולל מע״מ:`,
      OFFER_VALID:`תוקף הצעה`,
      A_LIGHT_SIGNATURE:`חתימת`,
      CUSTOMER_SIGNATURE: `חתימת לקוח `
  }

export const QUOTE_PDF_RULES = [
    `המחירים כוללים מדידה, ייצור, הובלה, הרכבה וזיגוג`,
    `המחיר אינו כולל עלויות פירוק חלונות קיימים, יתכן עדכון של המחירים לאחר מדידה בפועל של הפתחים בפרויקט`,
    `הרכבת המוצרים בגמר עבודות צבע, סיוד, טיח חוץ ופנים`,
    `חשמל, אמצעי הרמה ושטח אחסון, אינם כלולים במחיר ויועמדו לרשותנו על ידי המזמין`,
    `המחירים צמודים לעליית מחירי האלומיניום`,
    `המחירים כוללים משקופים סמויים`
]

export const REPORT_HEADER = {
    DATE:`תאריך`,
    HALF_YEAR_OR_YEARLY:`דוח ממצאים`,
    TECH_NAME:`שם הבודק`,
    TEST_DATE:`תאריך הבדיקה `,
    DESCRIPTION_REASON_REDING:`תיאור / סיבה / קריאה`,
    ACTUAL_HOURS_INVESTED:`שעות עבודה בפועל`,
    ANNUAL:`ביקורת שנתית`,
    SEMI_ANNUAL:`חצי שנתית`,
    IN_ORDER_FOLLOWING_SYSTEMS_WERE_TESTED:(orderNumber)=>`בפרויקט ${orderNumber} נבדקו המערכות הבאות`,
    DETAILS_OF_THE_SYSTEMS_TESTED_IN_THE_PROJECT:`פירוט המערכות שנבדקו בפרויקט`,
    REPORT_TITLE:`נבדקו המערכות הבאות`,
    SYSTEM_IS_DISABLED:`המערכת לא תקינה - מושבתת`,
    GOOD_OR_NOT_GOOD: `תקין/לא תקין‎`

}

export const SYSTEM_TYPES = {
    SYSTEM_TYPE:`שיוך מערכת‎`,
    PARKING:`חניון‎`,
    BUILDING:`בניין`,
    OTHER:`אחר‎`
}

export const QUOTES = 'הצעות מחיר'
export const EDIT = 'ערוך‎'
export const CALENDAR = 'לוח שנה'
export const EMAIL = 'מיילים'
export const CALLS = 'שיחות'
export const FILES = 'קבצים'
export const SYSTEM = 'מערכות'
export const ORDERS = 'הזמנות'
export const TOTAL_CUSTOMERS = 'מספר לקוחות'
export const CUSTOMER_REPORT = 'דו"ח לקוח'
export const INTERNAL_REPORT = 'דו"ח פנימי'
export const SIGNATURE = 'חתימת לקוח'
export const ADD_FILES = 'הוסף קובץ'
export const ADD_SYSTEM = 'הוסף מערכת'
export const DOWNLOAD = 'הורד'
export const UPDATE_ORDER = 'עדכן הזמנה'
export const UPDATE_PROCESS = 'הצג קריאה‎'
export const BACK = 'חזרה'
export const COPY_ORDER = 'שכפל‎'