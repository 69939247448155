import React from 'react'
import { GOOGLE_MAPS_SEARCH_QUERY } from '../../../constants/maps'
import ExploreIcon from '@material-ui/icons/Explore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import "../../Customer/TabletView/DataInformation/DataInformation.scss";

import { styles } from '../styles'

const GoogleMap = ({ process, view }) => {
    const createQuery = () => {
        const { address, city } = process.proccess;
        const fullAddress = city + ' ' + address
        const query = GOOGLE_MAPS_SEARCH_QUERY + fullAddress.split(' ').join('+')
        return query

    }
    return <a href={createQuery()} target='blank'>
        {
            process.icon === 'location' ? <LocationOnIcon className={view === 'desktop' ? 'mainContainer__icon--desktop' : view === 'mobile' ? 'mainContainer__icon--mobile': 'mainContainer__icon'} /> : <ExploreIcon style={styles.styledShowAttachmentsIconMobile} />
        }
    </a>
}

export default GoogleMap