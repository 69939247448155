import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {useStyles} from '../style'

const CustomizedImageSelect = (props) => {
    const classes = useStyles()
    const { value, changeHandler, options } = props
    return (
      <TableCell className={classes.selectTableCell}>
        <Select
          value={value || ''}
          onChange={e => changeHandler(e.target.value)}
          disableUnderline
        >
          {
            options?.map((i, idx) => <MenuItem value={i.icon} key={i.icon}>
              <React.Fragment>
                <img src={i.icon} className = {classes.imageOption} />
                <p>{i.name}</p>
              </React.Fragment>
            </MenuItem>)
          }
        </Select>
      </TableCell>
    )
  }

  export default CustomizedImageSelect