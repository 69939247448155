import React, { useEffect, useState } from "react";
import { useStyles } from '../style'
import { Grid, TextField,Select, MenuItem } from "@material-ui/core"
import {useSelector} from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {FACTORY, NAME, LAYER, HALF_YEAR_TEST, DESIGNATION_OF_STRUCTURE, DESIGNATION_OF_STRUCTURE_VALUES} from '../../../../constants/translations/admin-systems'
import {
    METALPRESS
} from '../../../../constants/factories';
import { deleteSystemChecklist } from "../../../../functions/api/systems";
const SystemDetails = (props) => {
    const classes = useStyles()
    const { _id, 
        layer, 
        description, 
        setLayer, 
        setDescription, 
        factoryId, 
        setFactory, 
        setHalfYearTest, 
        halfYearTest, 
        designationOfStructure, 
        setDesignationOfStructure,
        systemsDesignationOfStructure
        } = props

    const factories = useSelector(state => state.factories.listFactories)   

    return <Grid item className={classes.systemDetailsWrapper}>
        {/* if need first row to be only id => classes.detailsRow */}
        <Grid item className={classes.alignBetween}>
            <TextField
                value={_id || "new"}
                className={classes.input}
                disabled
                variant="outlined"
            />
        <FormControlLabel
                control = {<Checkbox
                    className = {classes.checkBox}
                    checked={halfYearTest}
                    onChange={e => setHalfYearTest(e.target.checked)}
                    />}
                    label = {HALF_YEAR_TEST}
                    labelPlacement = 'start'
                    />
        </Grid>
        <Grid item className = {classes.alignBetween} > 
      
            <Select
                className={classes.input}
                variant="outlined"
                value={factoryId}
                onChange={(e) =>{
                 setFactory(e.target.value)
                 }}
                label={FACTORY}
                >
                {factories.map((element) => {
                    return (
                    <MenuItem key={element._id} value={element._id}>
                        {element.name}
                    </MenuItem>
                    );
                })}
            </Select>
            <Select
                className={classes.input}
                variant="outlined"
                value={designationOfStructure}
                onChange={(e) => setDesignationOfStructure(e.target.value)}
                label={DESIGNATION_OF_STRUCTURE}
                >
                {( factoryId === METALPRESS ? DESIGNATION_OF_STRUCTURE_VALUES :systemsDesignationOfStructure)?.map((element) => {
                    return (
                    <MenuItem key={element} value={element}>
                        {element}
                    </MenuItem>
                    );
                })}
            </Select>
        </Grid>
        <Grid item >
            <TextField
                className={classes.input}
                label={NAME}
                value={description}
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
                className={classes.input}
                label={LAYER}
                variant="outlined"
                value={layer}
                type='number'
                onChange={(e) => setLayer(e.target.value)}
            />
        </Grid>
    </Grid>
}

export default SystemDetails

