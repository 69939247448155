export const FILE_ERRORS ={
    FILE_NAME_REQUIRED: 'שם הקובץ צריך לכלול 4 תווים',
    FILE_TYPE_REQUIRED: 'יש לבחור את סוג הקובץ',
    FILE_ATTACHMENT_REQUIRED: '* יש צורך בקובץ',
}
export const TASK_ERRORS = {
    TASK_NAME_REQUIRED: 'שם המשימה צריך לכלול 4 תווים',
    RESOURCE_NAME_REQUIRED: 'בחר משאב',
    DATE_REQUIRED: 'תאריך נדרש',
    STATUS_REQUIRED: 'בחר סטטוס'
}
export const DATA_INFORMATION_ERRORS={
    ORDERED_REQUIRED:'נדרשת הזמנה',
    REPORTED_REQUIRED: 'דיווח נדרש'
}