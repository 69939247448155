import uuid from 'uuid'

export class CheckListTest{
    constructor(order){
        this._id = uuid()
        this.icon =  ''
        this.order = order
        this.row_title = ''
        this.show_stopper = ''
        this.values = [
          { typeOfInput: '-11', value: '' },
          { typeOfInput: '1', value: '' },
          { typeOfInput: 'read', value: '' },
          { typeOfInput: 'checkbox', value: false },
          { typeOfInput: 'text', value: '' },
          { typeOfInput: 'photo', value: [] },
          { typeOfInput: 'text', value: '' },
        ]
    }
}