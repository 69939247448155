import React from 'react'
import { styles } from '../styles'

const TitleAndValueRow = ({ titleName, value, style, titleStyle, valueStyle }) => {
    return (
        <div style={{ ...styles.TitleAndValueRow, ...style }}>
            <p style={{ ...styles.text, ...titleStyle, wordBreak: 'keep-all'}}>{titleName}</p>
            <p style={{...valueStyle, wordBreak: 'keep-all'}}>{value}</p>
        </div>
    )
}

export default TitleAndValueRow