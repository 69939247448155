import * as types from '../actions/types'
  
  
  const initialState = {
        width: null,
        height: null
  }
  
  export default function (state = initialState, action) {
  
    switch (action.type) {
      case types.SET_WINDOW_SIZE:
        return {
          ...state,
          width: action.payload.width,
          height: action.payload.height
        }
      default:
        return state
    }
  }