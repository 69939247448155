import {CHECK_LIST_TRANSLATION } from '../../../../../constants/translations/customersPage'
const {INTERNAL_COMMENTS, IMAGE, REMARKS, CAUSE_OF_IRREGULARITY, PROPER_NOT_PROPER, ACTION_REQUIRED,FIXED_IN_PLACE} = CHECK_LIST_TRANSLATION

export const LABELS_FOR_CHECK_LIST = [
  // "תקין/לא תקין",
  // " סיבת אי תקינות",
  // " הערות",
  // "תמונה",
  // " הערות פנימיות",
  PROPER_NOT_PROPER,
  CAUSE_OF_IRREGULARITY,
  REMARKS,
  ACTION_REQUIRED,
  FIXED_IN_PLACE,
  IMAGE,
  INTERNAL_COMMENTS
];
