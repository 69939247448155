import React, { useEffect, useState } from "react";
import {generalGetRequest, generalPostRequest } from '../../../../functions/api/general'
import DetailsDialog from '../../DetailsDialog'
import SystemDetails from './SystemDetails'
import { deleteSystemChecklist } from "../../../../functions/api/systems";


const EditSystemPopUp = (props) => {
    const {
      // open && set open pop up
        systemDetailsOpen, setSystemDetailsOpen,
      // selected system for edit
        system, setSelectedSystem,
      // systems list 
        systems, setSystems} = props
    const {_id, layer: propLayer, description: propDescription, factory_id, half_year_test, designation_of_structure, } = system
  
    const [layer, setLayer] = useState(propLayer || '')
    const [description, setDescription] = useState(propDescription || '')
    const [factoryId, setFactory] = useState(factory_id || '')
    const [halfYearTest, setHalfYearTest] = useState(half_year_test || false)
    const [designationOfStructure, setDesignationOfStructure] = useState(designation_of_structure || '')
    const [systemsDesignationOfStructure, setSystemsDesignationOfStructure] = useState([])
    const [isSystemUpdateCalled, setIsSystemCalled] = useState(false);

  async function handleDeleteCheckList() {
    const body = {
      check_list_id: system?.check_list_id
    }
    await deleteSystemChecklist(body)
  }

    const submitHandler = async() => {
      const body = {
        _id, 
        layer, 
        description, 
        factory_id: factoryId, 
        half_year_test: halfYearTest,
        designation_of_structure: designationOfStructure
      }
      const responce = await generalPostRequest(body, '/admin/systems/update-system')
      if (responce.result) updatesystemsAfterEdit(responce.result)
      closePopUpHandler()
      setIsSystemCalled(true)
      handleDeleteCheckList()
    }

    const closePopUpHandler = () => {
      setSelectedSystem('')
      setSystemDetailsOpen(false)
    }
  
  const updatesystemsAfterEdit = (system) => {
    const {_id: systemId } = system
    const systemsCopy = [...systems]
    const systemIndex = systemsCopy.findIndex(i => i._id === systemId)
    const editedSystem = systemsCopy.find(i => i._id === systemId)
    const newSystem = {
      ...editedSystem,
      ...system
    }
    systems.splice(systemIndex, 1, newSystem)
    setSystems(systems )
  }

  async function getSystemsDetails(layer) {
    const layerToGet = layer-1;
    const getSystems = await generalGetRequest(`/admin/systems/get-systems-by-layer?layer=${layerToGet}`)
    const list = getSystems?.result.filter((_system) => {
      if ((_system.layer === layerToGet) && (_system.factory_id === factoryId)) {
        return true;
      }
      return false;
    }).map((ele) => ele.description);
    setSystemsDesignationOfStructure(list);
  }
  
  useEffect(()=>{
    getSystemsDetails(layer);
  }, [layer, factoryId, isSystemUpdateCalled])
  
  
    return <DetailsDialog
              isOpen={systemDetailsOpen}
              onClose={closePopUpHandler}
              submitLabel="Update"
              submitHandler = {submitHandler}
              // form={currentEntity.form}
              title={"Edit system"}
            > 
           
              <SystemDetails
                _id={_id}
                description={description}
                layer={layer}
                setLayer = {setLayer}
                setDescription = {setDescription}
                factoryId = {factoryId}
                setFactory = {setFactory}
                halfYearTest = {halfYearTest} 
                setHalfYearTest={setHalfYearTest}
                designationOfStructure = {designationOfStructure}
                setDesignationOfStructure = {setDesignationOfStructure}
                systemsDesignationOfStructure={systemsDesignationOfStructure}
              />
            </DetailsDialog>
  }

export default EditSystemPopUp