import React from 'react';
import "./TabEmptyLanding.scss";

export default function TabEmptyLanding(props) {
    const {title} = props;
  return (
    <div className='tabEmptyLanding__main'>
        {/* <span>There are no {title} here</span> */}
      <span>אין {title} כאן</span>
    </div>
  )
}
