import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import "./SingleFileDetails.scss";
import AddFilePopUp from '../../../PopUps/AddFilePopUp/AddFilePopUp';
import OpenedFilePopUp from '../../../PopUps/OpenedFilePopUp/OpenedFilePopUp';
// import DeletePopUp from '../../../PopUps/DeletePopUp/DeletePopUp'
import { useDispatch, useSelector } from 'react-redux';
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import { useFiles } from '../../../../../hooks/useFiles';
import DeletePopUp from '../../../TabletView/PopUps/Delete/DeletePopUp';
import { fileActionCreator } from '../../../../../actions/customer/fileActions';

let deleteInfoPopUp = {
    title: POP_UPS_TRANSLATION.DELETE_FILE,
    subtitle: POP_UPS_TRANSLATION.DELETE_FILE_CONFIRMATION,
    info: [
        { label: POP_UPS_TRANSLATION.UPLOADED_BY, value: POP_UPS_TRANSLATION.ZAIN_BATOR },
        { label: POP_UPS_TRANSLATION.UPLOADED_DATE, value: '19/06/2023' },
        { label: POP_UPS_TRANSLATION.FILE_NAME, value: 'Jorem ipsum dolor ' },
        { label: POP_UPS_TRANSLATION.FILE_TYPE, value: 'Jorem ipsum' },
    ]
}

export default function SingleFileDetails({ row, columns, process, updateFilesList }) {
    const [showPopUp, setShowPopUp] = useState(false);
    const [openPopUp, setOpenPopUp] = useState("")
    const mainPopupSlice = useSelector((state) => state.mainPopup);

    const dispatch = useDispatch();
    const deleteFileDetails = useSelector((state) => state.files.singleFile);

    useEffect(() => {
        dispatch(fileActionCreator.selectFileByFileId(row.id))
    }, [row.id])

    function handleShowPopUp() {
        setShowPopUp(!showPopUp)
    }
    function handleMenuOpen(action) {
        if (action === "editFile") {
            setOpenPopUp("editFile")
        } else if (action === "deleteFile") {
            dispatch(fileActionCreator.deleteFileByFileId(row.id))
            setOpenPopUp("deleteFile")
        } else if (action === "openedFile") {
            setOpenPopUp("openedFile")
        }
        handleShowPopUp()
    }

    deleteInfoPopUp = {
        ...deleteInfoPopUp,
        info: [
            { label: POP_UPS_TRANSLATION.UPLOADED_BY, value: deleteFileDetails ? deleteFileDetails.uploadedBy : POP_UPS_TRANSLATION.ZAIN_BATOR },
            { label: POP_UPS_TRANSLATION.UPLOADED_DATE, value: deleteFileDetails ? deleteFileDetails.uploadDate : '18/06/2023' },
            { label: POP_UPS_TRANSLATION.FILE_NAME, value: deleteFileDetails ? deleteFileDetails.fileName : 'Jorem ipsum dolor ' },
            { label: POP_UPS_TRANSLATION.FILE_TYPE, value: deleteFileDetails.fileType ? deleteFileDetails.fileType.placeholder || deleteFileDetails.fileType.name: 'Jorem ipsum' },
        ]
    }
    return (
        <>
            <Grid className=' SingleFileDetails__main'>
                {
                    columns && columns.map((header, index) => {
                        return (
                            header.headerName !== 'Actions' ?
                                <Grid xs={12} className='addFilePopUp__uploadedInfo__container' style={{ height: '34px', background: 'transparent' }}>
                                    <Grid xs={3} className='addFilePopUp__uploadedInfo__label'><span>{header.headerName}</span></Grid>
                                    <Grid xs={9} className='addFilePopUp__uploadedInfo__value'><span>{row[header.field]}</span></Grid>
                                </Grid>
                                :
                                <Grid xs={12} className='addFilePopUp__uploadedInfo__container' style={{ height: '34px', background: 'transparent' }}>
                                    <Grid xs={3} className='addFilePopUp__uploadedInfo__label'><span>{header.headerName}</span></Grid>
                                    <Grid xs={9} className='addFilePopUp__uploadedInfo__value' style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'left' }}>
                                        <span className='SingleFileDetails__iconContainer' onClick={() => handleMenuOpen("editFile")}>
                                            <EditIcon style={{ fontSize: '16px', color: '#F4B506' }} />
                                        </span>
                                        &nbsp;
                                        &nbsp;
                                        <span className='SingleFileDetails__iconContainer' onClick={() => handleMenuOpen("deleteFile")}>
                                            <DeleteIcon style={{ fontSize: '16px', color: '#F67288' }} />
                                        </span>
                                        &nbsp;
                                        &nbsp;
                                        <span className='SingleFileDetails__iconContainer' onClick={() => handleMenuOpen("openedFile")}>
                                            <FolderIcon style={{ fontSize: '16px', color: '#95C353' }} />
                                        </span>
                                    </Grid>
                                </Grid>
                        )
                    })
                }
            </Grid>
            {
                openPopUp === "editFile" ? showPopUp && <AddFilePopUp
                    handleShowPopUp={handleShowPopUp}
                    name={POP_UPS_TRANSLATION.EDIT_FILE}
                    view="mobile"
                    showPopUp={showPopUp}
                    process={process && process}
                    updateFilesList={updateFilesList}
                    mode={'edit'}
                    fileId={row.id}
                /> : openPopUp === "deleteFile" ? showPopUp &&

                    <DeletePopUp
                        handleShowPopUp={handleShowPopUp}
                        data={deleteInfoPopUp}
                        view={'mobile'}
                        id={row.id}
                        popUpFor={'file'}
                    />
                    : openPopUp === "openedFile" ? showPopUp && <OpenedFilePopUp handleShowPopUp={handleShowPopUp} view="mobile"  attechmentFile={row.filePath} /> : null
            }
        </>
    )
}


{/* <DeletePopUp
                    handleShowPopUp={handleShowPopUp}
                    view="mobile"
                    data={deleteInfoPopUp}
                    id={fileId}
                    popUpFor={'file'}
                />  */}