import { GET_CUSTOMER_DETAILS } from "../../constants/customer/customerDetails";
import { customerDetails } from "../../constants/staticeData/customerDetails";


const initailState = {
    customerDetails: customerDetails
}

export default function customerDetailsReducer(state=initailState, action){
    switch (action.type) {
        case GET_CUSTOMER_DETAILS:
            return {...state, customerDetails: state.customerDetails};
            break;
    
        default:
            return state;
    }
}