import React, { useEffect, useState } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { createOrderProcessBackground } from '../../../../../functions/helpers/createOrderProcessBackground';
import { updateClass } from '../../../../../hooks/helper';
import drag_icon from '../../../../../images/icons/drag_x.svg';
import Lock from '../../../../../images/updatepopup/openLock.svg';
import ParallelProcessIcon from '../../../../../images/updatepopup/parallel.svg'



function Completions(props) {
    const { completionArrays, addMoreCompletion, daysOffNumber, initProccessUpdatePopup, user_or_order_type } = props;

    const draggingDestination = useSelector((state) => state.generalReducers.draggingDestination);

    const handleProcessMenu = (data,date) => {
        initProccessUpdatePopup(data,date)
    }
    return (
        <div className="month-view-page__completions" style={{width: `calc( 98% / ${ 7 - daysOffNumber + 1})`}}>
            <header>השלמות</header>
            <Droppable droppableId="completions__droppable">
                {(provided, snapshot)=>{
                    return(
                    <section className="month-view-page__completions__droppable" id='monthly-completion-ix' onScroll={addMoreCompletion} ref={provided.innerRef} {...provided.droppableProps}>
                            {completionArrays.map((data, index)=>{
                                return <Draggable draggableId={data.proccess._id + "x1"} index={index} key={data.proccess._id}>
                                    {(provided, snapshot)=>{
                                        if (snapshot.isDragging && draggingDestination?.droppableId !== 'completions__droppable') {
                                            return <React.Fragment>
                                                <div
                                                    className={data?.proccess?.warnings ? `month-view-page__calendar__table__day__process  month-view-page__calendar__table__day__process_css2 ${updateClass(data.proccess)}` : `month-view-page__calendar__table__day__process month-view-page__calendar__table__day__process_css2 ${updateClass(data.proccess)}`}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        cursor: data.proccess.fraction ? 'pointer' : '',
                                                        backgroundColor: data.proccess.done ? '#E8E9EB' : '',
                                                        background: createOrderProcessBackground(data?.proccess, user_or_order_type),
                                                        borderRadius: '220px',
                                                        maxWidth:'200px',
                                                        maxHeight: '40px',
                                                        marginRight: '5px',
                                                        marginBottom: '5px',
                                                        padding: '0px 5px 0px 20px !important',
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    id={`${data.proccess._id}m-c`}
                                                >

                                                    <div className="drag__icon__container">
                                                        {" "}
                                                        <img className="drag__icon" src={drag_icon} alt="drag icon"></img>
                                                    </div>
                                                    <section style={{ padding: '0px 10px' }}>
                                                        <div>
                                                            <p>
                                                                <span className="month__process__client__name">
                                                                    {data?.proccess?.client_name}
                                                                </span>
                                                                {data?.proccess?.remark ? '-' : ''}
                                                                <span className="month__process__client__name four_characters_length">
                                                                    {data?.proccess?.remark} -
                                                                </span>
                                                                <span className="month__process__number">
                                                                    {data?.proccess?.order_number}
                                                                </span>
                                                                <span className="icones"></span>
                                                            </p>
                                                        </div>
                                                        <div><p>{data?.proccess?.process_name} -<span className="month__proccess__employee">
                                                            resourcesString
                                                         {/* {resourcesString} */}
                                                         </span></p></div>
                                                    </section>
                                                    {
                                                        data?.proccess?.is_detached ?
                                                            <div className="month-view-page__calendar__table__day__process--menu"><img src={Lock} alt="menu" /></div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        !data?.proccess?.is_detached && data?.proccess?.should_occur_parallel ? <img style={data?.proccess?.should_occur_parallel ? { marginBottom: '2px', position: 'absolute', left: '10px' } : {}} className="lock__icon" src={ParallelProcessIcon} alt="lock icon"></img> : null
                                                    }
                                                </div>
                                            </React.Fragment>
                                        }
                                        return ( 
                                        <div id={`${data.proccess._id}m-c`} onClick={()=>{handleProcessMenu(data, null)}} className="month-view-page__completions__draggable"  {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                            <section>
                                                <div>{data.proccess.order_number} - {data.proccess.client_name}</div>
                                                <div>{data.proccess.process_name}</div>
                                                <div>הושלמו {data.proccess.finished} מתוך {data.proccess.quantity}</div>
                                            </section>
                                        </div>
                                         )
                                    }}
                                </Draggable> 
                            })}
                    </section>
                )}}
            </Droppable>
        </div>
    )
}

export default Completions