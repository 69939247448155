export const RESOURCES = 'resources'
export const HOLIDAYS = 'holidays'
export const BACK_TO_TODAY = 'BACK TO TODAY'
export const TIMELINES = 'Timelines'

// new process component's translation keys
export const PROCESSES = {
    PROCESS_NAME: 'שם התהליך',
    CUSTOMER_NAME: 'שם לקוח',
    ORDER_NUMBER: 'מספר הזמנה',
    START_TIME: 'שעת התחלה',
    END_TIME: 'שעת סיום',
    QUANTITY: 'כַּמוּת',
    DURATION: 'מֶשֶׁך',
    STATUS: 'סטָטוּס',
    DONE: 'בוצע'
}