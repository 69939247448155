import * as types from "../actions/types";

const initialState = {
  loading: false,
  listSubDepartments: [],
  subDeptsDetails: null,
  error: null, 
  countSubDepart:0,
  currentPage: 0,
  rowsPerPage: 10,
};

export default function subDepartmentsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SUB_DEPARTMENT_CHANGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }; 
    case types.SUB_DEPARTMENT_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,        
        rowsPerPage: action.payload
      };

    case types.ADMIN_GET_FILTER_SUB_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      }; 
      
    case types.ADMIN_GET_FILTER_SUB_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listSubDepartments: action.payload.result,
        countSubDepart:action.payload.count
      };
    case types.ADMIN_GET_FILTER_SUB_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case types.GET_ALL_SUB_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_SUB_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listSubDepartments: action.payload,
      };
    case types.GET_ALL_SUB_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case types.GET_BY_ID_SUB_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_BY_ID_SUB_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        subDeptsDetails: action.payload,
      };
    case types.GET_BY_ID_SUB_DEPARTMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.SET_SUB_DEPARTMENT:
      return {
        ...state,
        subDeptsDetails: action.payload,
      };

    case types.SUB_DEPARTMENT_GET_BY_FACTORY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.SUB_DEPARTMENT_GET_BY_FACTORY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        listSubDepartments: action.payload,
      };
    case types.SUB_DEPARTMENT_GET_BY_FACTORY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

      case types.SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case types.SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: null,
          listSubDepartments: action.payload,
        };
      case types.SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_ERROR:
        return {
          ...state,
          isLoading: false,
          error: action.payload.error,
        };
    default:
      return state;
  }
}
