import * as React from "react";
import get from "lodash/get";
import {createDateComparator, createNumberComparator, createStringComparator, reverse, sort} from "../utils/sort";

export function useSortedOrders(
    order,
    sortBy,
    orders
) {
    const comparatorsMap = {
        asc: {
            "default" : createDateComparator(),
            "value" : createNumberComparator(order),
            "order_number": createStringComparator(order),
        },
        desc: {
            "default" : reverse(createDateComparator()),
            "value" : createNumberComparator(order),
            "order_number": createStringComparator(order),
        },
    };

    const sortByMapLabel =
        sortBy === "value" ? sortBy : "default";

    const comparator = get(
        comparatorsMap,
        `${order}.${sortByMapLabel}`
    );

    return React.useMemo(
        () =>
            sort(orders,
                    comparator,
                    sortBy,
            ),
            [order, sortBy, orders]
    );
}
