export const DESKTOP_VIEW_TRANSLATION = {
    EDIT: 'לַעֲרוֹך',
    CUSTOMER_NAME_ACTUAL: 'סנט הראל',
    ORDERS: 'הזמנות',
    QUOTES: 'ציטוטים',
    UPLOAD_FROM_EXCEL: 'העלה מאקסל',
    DRAG_AND_DROP_YOUR_FILE_HERE_OR: 'גרור ושחרר את הקובץ שלך כאן או',
    CHOOSE_FILE_TO_UPLOAD: 'בחר קובץ להעלאה',
    QUOTE: 'ציטוט',
    ORDER: 'להזמין',
    CRITICISM: 'ביקורת',
    ACTIONS: 'פעולות',
    SEMI_ANNUAL_AUDIT: 'ביקורת חצי שנתית',
    SEARCH: 'לחפש',
    BACK: 'חזור',
    ADD_AN_ORDER: 'הוסף הזמנה',
    DAILY: 'יום יומי',
    WEEKLY: 'שְׁבוּעִי',
    MONTHLY: 'יַרחוֹן',
    ANNUAL: 'שנתי',
    BACK_TO_TODAY: 'חזרה להיום',
    CHECK_LIST: "צ'ק ליסט"
}