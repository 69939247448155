import React, { Fragment, useEffect, useRef, useState } from 'react'
import Button from '../../Buttons/Button';
import AccordionMain from '../../AccordionRow/AccordionMain';
import AddMeasurements from '../../MobileViews/PopUps/AddMeasurements/AddMeasurements';
import AddSystemPopUp from '../../TabletView/PopUps/AddSystem/AddSystemPopUp';
import { TABLET_VIEW_TRANSLATION } from '../../../../constants/translations/customer/tablet/tabletView';
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import DeletePopUp from '../../TabletView/PopUps/Delete/DeletePopUp';
import '../../TabletView/FileTab/FileTab.scss'
import { CANCEL, SAVE } from '../../../../constants/translations/review-popup';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { systemActionsCreator } from '../../../../actions/customer/systemActions';
import TabEmptyLanding from '../../MobileViews/TabEmptyLanding/TabEmptyLanding';
import EmptyList from '../../TabletView/SystemsTab/EmptyList/EmptyList';
import "../SystemTab/SystemTab.scss";
import SystemsNew from './SystemsNew';
import { updateCustomerFirstLevelSystems } from '../../../../actions/customers_table_actions';
import { SET_FIRST_LAYER_SYSTEMS } from '../../../../actions/types';

const systemTabDesktopBtn = {
    addSystem: {
        name: TABLET_VIEW_TRANSLATION.SYSTEM,
        width: '20%',
        height: '52px',
        color: '#fff',
        backgroundColor: "#186315",
        fontSize: '16px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        border: false,
        isBoxShadow: true,
        buttonName: 'AddIcon'
    },
    uploadExcel: {
        name: DESKTOP_VIEW_TRANSLATION.UPLOAD_FROM_EXCEL,
        width: '30%',
        height: '52px',
        backgroundColor: '#fff',
        color: "#186315",
        fontSize: '16px',
        fontWeight: 800,
        icon: true,
        iconContainer: false,
        border: true,
        isBoxShadow: true,
        buttonName: 'Excel'
    },
}
const systemTabPopUpBtn = {
    addSystem: {
        ...systemTabDesktopBtn.addSystem,
        width: '30%',
    },
    save: {
        name: SAVE,
        width: '40%',
        height: '52px',
        color: '#fff',
        backgroundColor: "#0091FF",
        fontSize: '16px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        borderRadius: '20px',
    },
    cancel: {
        name: CANCEL,
        width: '40%',
        height: '52px',
        color: '#0091FF',
        backgroundColor: "#fff",
        fontSize: '16px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        borderRadius: '20px',
    }
}


export default function SystemTabNew(props) {
    const { view, process, closePopup, customer, systems } = props;
    const [currentPopup, setCurrentPopup] = useState('add');
    const [showPopUp, setShowPopUp] = useState(false);
    const [systemId, setSystemId] = useState(null);
    const orderId = process && process.order_id;
    const [currentLayer, setCurrentLayer] = useState(1);

    const dispatch = useDispatch();
    const accordionDataTree = useSelector((state) => state.customerSystems.systemsForAccordion);
    const { data } = useSelector((state) => state.customerSystems.singleSystem);

    const system_close_ref = useRef({})



    function handleShowPopUp() {
        setShowPopUp(!showPopUp)
    }

    function onClickHandler(label) {
        setShowPopUp(true);
    }
    const popupClickHandlers = {
        'Add System': (systemID, layer) => {
            setCurrentLayer(layer)
            setCurrentPopup('add');
            handleShowPopUp();
        },
        'Edit System': (systemId, layer) => {
            setCurrentPopup('edit');
            setCurrentLayer(layer)
            setSystemId(systemId);
            dispatch(systemActionsCreator.selectedSystemBySystemId(systemId))
            handleShowPopUp();
        },
        'Delete System': (systemId) => {
            setCurrentPopup('delete');
            dispatch(systemActionsCreator.selectedSystemBySystemId(systemId))
            handleShowPopUp();
        },
        'Add Measurements': () => {
            setCurrentPopup('measurement');
            handleShowPopUp();
        },
        'Move Up': (index, layer) => {
            if (index > 0) {
                dispatch(systemActionsCreator.moveSystemRow({ index, layer, direction: 'up' }))
            } else {
                alert("can't move up")
            }
        },
        'Move Down': (index, layer) => {
            dispatch(systemActionsCreator.moveSystemRow({ index, layer, direction: 'down' }))
        },
    }

    useEffect(() => {
        // '646318afdf8e59467ce60344'
        // 63ac4b587fd3fa5e2c7b1e37
        dispatch(systemActionsCreator.getSystemsByOrderId('63ac4b587fd3fa5e2c7b1e37'));
        // dispatch(systemActionsCreator.getSystemsForAccordion());
    }, [dispatch, orderId])

    useEffect(() => {
        dispatch(systemActionsCreator.selectedSystemBySystemId(systemId))
    }, [dispatch, systemId])



    const systemNameToDelete = data && data[0].items[0]?.details;
    const locationDescriptionToDelete = data && data[0].items[1]?.details;
    const floorToDelete = data && data[0].items[2]?.details;

    const deleteInfoPopUp = {
        title: POP_UPS_TRANSLATION.DELETE_SYSTEM,
        subtitle: POP_UPS_TRANSLATION.DELETE_SYSTEM_CONFIRMATION,
        info: [
            { label: POP_UPS_TRANSLATION.SYSTEM_NAME, value: systemNameToDelete && typeof (systemNameToDelete) === 'object' ? systemNameToDelete.name : systemNameToDelete || POP_UPS_TRANSLATION.SYSTEM_NAME_VALUE },
            { label: POP_UPS_TRANSLATION.FLOOR, value: locationDescriptionToDelete && locationDescriptionToDelete || '6' },
            { label: POP_UPS_TRANSLATION.STATUS, value: floorToDelete && floorToDelete || '6' },
        ]
    }

    const setSystemCloseRef = (data) => {
        system_close_ref.current = { ...system_close_ref.current, [data._id]: data.value }
    }

    return (
        <div className='system__container'>

            <div className='systems__list'>
                <SystemsNew
                    systems={systems}
                    updateSiblings={(systems) => dispatch(updateCustomerFirstLevelSystems(systems))}
                    parentSystemId={null}
                    currentLayer={SET_FIRST_LAYER_SYSTEMS}
                    customer={customer}
                    setSystemCloseRef={setSystemCloseRef}
                    system_close_ref={system_close_ref}
                    view={view}
                    popupClickHandlers={popupClickHandlers}
                    onClickHandler={onClickHandler}
                />
            </div>


            <div className={showPopUp ? 'popUp__background' : ""}>
                {
                    showPopUp ?
                        <>
                            <div style={{ visibility: currentPopup && currentPopup === 'delete' ? 'visible' : 'hidden' }}>
                                <DeletePopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    data={deleteInfoPopUp}
                                    view={view === 'desktop-popup' ? 'desktop' : view}
                                    id={systemId}
                                    popUpFor={'system'}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'edit' ? 'visible' : 'hidden' }}>
                                <AddSystemPopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    name={POP_UPS_TRANSLATION.EDIT_SYSTEM}
                                    view={view}
                                    mode={'edit'}
                                    systemId={systemId}
                                    layer={currentLayer}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'add' ? 'visible' : 'hidden' }}>
                                <AddSystemPopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    name={POP_UPS_TRANSLATION.ADD_A_SYSTEM}
                                    layer={currentLayer}
                                    view={view} />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'measurement' ? 'visible' : 'hidden' }}>
                                <AddMeasurements showPopUp={showPopUp} handleShowPopUp={handleShowPopUp} view={view === 'mobile' ? view : 'desktop'} />
                            </div>
                        </> : null
                }
            </div>
            {
                props.view === 'desktop-popup' ? <Grid xs={12} className='fileTab__btnRow'>
                    <Grid xs={6} className='fileTab__btnRow-right'>
                        <Button data={systemTabPopUpBtn.save} />
                        &nbsp;
                        &nbsp;
                        <Button data={systemTabPopUpBtn.cancel} handleClick={() => closePopup()} />
                    </Grid>

                    <Grid xs={6} className='fileTab__btnRow-left'>
                        <Button data={systemTabPopUpBtn.addSystem} handleClick={() => onClickHandler('Add System')} />
                    </Grid>
                </Grid> : <Grid xs={12} className='fileTab__btnRow--desktop'>
                        <Button data={systemTabDesktopBtn.uploadExcel} handleClick={() => handleShowPopUp()} />
                    &nbsp;
                    &nbsp;
                        <Button data={systemTabDesktopBtn.addSystem} handleClick={() => onClickHandler('Add System')} />
                </Grid>
            }
        </div>
    )
}
