import { POP_UPS_TRANSLATION } from "../../../constants/translations/customer/PopUps/PopUps";
import { CREATE_CUSTOMER_POPUP, CUSTOMERS_PAGE, QUOTE } from "../../../constants/translations/customersPage";
import { ADD, CANCEL } from "../../../constants/translations/review-popup";


export const proposalCheckBtn = {
    label: CUSTOMERS_PAGE.PROPOSAL,
    name: 'proposal',
    // name: 'started',
    width: '20%',
    isBoxShadow: true,
    border: true,
    isChecked: false,
}
export const cancelReservationCheckBtn = {
    label: CUSTOMERS_PAGE.CANCEL_RESERVATION,
    name: 'cancelled',
    width: '20%',
    isBoxShadow: true,
    border: true,
    isChecked: false
}
export const add = {
    name: ADD,
    width: '20%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
}
export const cancel = {
    name: CANCEL,
    width: '20%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
}


export const inputs = {
    orderDetails: [
        {
            label: CREATE_CUSTOMER_POPUP.CUSTOMER_NUMBER,
            field: 'Customer Number',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'customerNumber'
        },
        {
            label: POP_UPS_TRANSLATION.ORDER_NUMBER,
            field: 'Order Number',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'text',
            // name: 'orderNumber'
            name: 'order_number'
        },
        {
            label: CUSTOMERS_PAGE.ORDER_TYPE,
            field: 'Order type',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'select',
            name: 'typeOfReservation',
            // name: 'type'
        },
        {
            label: CUSTOMERS_PAGE.CATEGORY,
            field: 'Category',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'select',
            name: 'category',
        },
        {
            label: CUSTOMERS_PAGE.NUMBER_OF_DEVELOPERS,
            field: '# of developers',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'number',
            // name: 'noOfDevelopers'
            name: 'quantity',
        },
        {
            label: CUSTOMERS_PAGE.DELIVERY_DATE,
            field: 'Delivery date',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'date',
            name: 'due_date'
        },
    ],
    contactsAddress: [
        {
            label: CUSTOMERS_PAGE.CUSTOMER_NAME,
            field: 'Customer Name',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'client_name'
        },
        {
            label: CREATE_CUSTOMER_POPUP.CITY,
            field: 'City',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'city',
        },
        {
            label: CREATE_CUSTOMER_POPUP.ADDRESS,
            field: 'Address',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'address'
        },
        {
            label: QUOTE.PHONE_NUMBER,
            field: 'Phone number',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'client_phone'

        },
        {
            label: CREATE_CUSTOMER_POPUP.EMAIL,
            field: 'Email',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'email',
            name: 'client_email'
        },
    ],
    customerDetails: [
        {
            label: CUSTOMERS_PAGE.INSPECTOR_NAME,
            field: 'Inspector name',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'inspectorName'
        },
        {
            label: CUSTOMERS_PAGE.MOBILE_INSPECTOR,
            field: 'Mobile Inspector',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'mobileInspector'
        },
        {
            label: CUSTOMERS_PAGE.ARCHITECT_NAME,
            field: 'Architect name',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'architectName'
        },
        {
            label: CUSTOMERS_PAGE.MOBILE_ARCHITECT,
            field: 'Mobile Architect',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'mobileArchitect'
        },
        {
            label: CUSTOMERS_PAGE.PROJECT_MANAGER,
            field: 'Project manager',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'select',
            // name: 'projectManager'
            name: 'employees'
        },
        {
            label: CUSTOMERS_PAGE.SALES_REPRESENTATIVE,
            field: 'Sales Representative',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'select',
            name: 'salesRepresentative'
        },
    ],
    otherDetails: [
        {
            label: CUSTOMERS_PAGE.NUMBER_OF_SHUTTERS,
            field: 'Number of shutters',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'numberOfShutters'
        },
        {
            label: CUSTOMERS_PAGE.SETTLEMENT,
            field: 'Settlement',
            placeholder: POP_UPS_TRANSLATION.CHOOSE,
            value: '',
            type: 'select',
            name: 'settlement'
        },
        {
            label: CUSTOMERS_PAGE.TRANSACTION_VALUE,
            field: 'Transaction value',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            // name: 'transactionValue'
            name: 'value'
        },

    ],
}