import React, { useMemo } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';

import Check from '@material-ui/icons/Check';

import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import { makeStyles, Paper } from '@material-ui/core';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
   
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        direction: 'ltr',
        "& .MuiPaper-root": {      
            boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.23)',
            // padding: '0px 10px 10px 10px',
            padding: '0px',

        },
        "& .MuiTextField-root": {
           minWidth: '35px!important',      
           maxWidth: '35px',  
          },
        "& .MuiTextField-root": {
            minWidth: '35px!important',      
            maxWidth: '35px',  
           },
        "& .MuiSelect-root": {
            minWidth: '150px',  
        }
      },
      title:{
        "& .MuiToolbar-root":{
            minHeight: '40px',
            '& h6':{
                color: 'rgba(0, 0, 0, 0.87)',
            fontSize:'14px',
            fontWeight:'500',
            }
        }
      },
   
    
  }));

export default function StageTable(props) {
    const classes = useStyles();
    const { processesList, data, onChangeDate} = props;  
    const [state, setState] = React.useState({
    columns: [        
      {
        title: 'Percentage',
        field: 'percentage',
        type: 'numeric' 
      },
      {title:'Reminder',
      field: 'days',
      type: 'numeric' 
      },
      { title: 'Process', field: 'process_id',
      lookup: processesList ? processesList : { } },  
    ],    
    data: data ? data :  [],
  });

  const getLookup  = () => { 
    let  columns = [
        { title: 'Process', field: 'process_id',
        lookup: processesList ? processesList : { } },  
        {
          title:'Reminder',
          field: 'days',
          type: 'numeric' 
        },   
        {
          title: 'Percentage',
          field: 'percentage',
          type: 'numeric' 
        },
      ]    
    setState({ ...state, columns })
  }

  useMemo(() => getLookup(), [processesList]);

  return (
    <MaterialTable
        icons={tableIcons}
        title="Edit Stage"
        columns={state.columns}
        options={{
            search: false,
            paging:false,
            grouping:false,
            sorting:false
        }}
        components={{
            Container: props => (
                <div className={classes.paper}>
                    <Paper {...props} />
                </div>
            ),
            Toolbar: props => (
                <div className={classes.title}>
                    <MTableToolbar {...props} />
                </div>
            )
        }}
    
      data={state.data}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              setState(prevState => {
                const data = [...prevState.data];
                data.push(newData);
                onChangeDate(data)
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                setState(prevState => {
                  const data = [...prevState.data];
                  data[data.indexOf(oldData)] = newData;
                  onChangeDate(data)
                  return { ...prevState, data };
                });
              }
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              setState(prevState => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                onChangeDate(data)
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
  );
}

