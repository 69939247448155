import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFactory,
  getOrderCollectionStage,
  setOrderCollectionStage
} from "../../../actions/actions";
import {
  TextField,
  Grid, 
  makeStyles, 
  FormControl,
  InputLabel, 
  Select,
  MenuItem,
} from "@material-ui/core";
import StageTable from "./StageTable";

import {getAllWithoutProcessesFuncApi } from "../../../functions/api/admin";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
    margin: theme.spacing(1), 
    minWidth: 240
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  firstRow: {
   
  },
}));

export const OrderCollectionStagesDetails = (props) => {
  const dispatch = useDispatch();

  const orderCollectionStage = useSelector(
    (state) => state.orderCollectionStages.orderCollectionStageDetails
  );
  const factoryList = useSelector((state) => state.factories.listFactories);
  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const [processeslist, setProcessesList] = useState([]);
 
  useEffect(() => {
    if (props.id) {
      dispatch(getOrderCollectionStage(props.id));
    }
    return function cleanup() {
      dispatch(setOrderCollectionStage(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await getAllWithoutProcessesFuncApi();
      setProcessesList(res);
    };
    fetchData();
  }, []);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && orderCollectionStage && (
          <OrderCollectionStagesForm
            id={props.id}
            orderCollectionStage={orderCollectionStage}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            processeslist={processeslist}
            
          />
        )}
        {props.id === undefined && (
          <OrderCollectionStagesForm
            id={props.id}
            orderCollectionStage={null}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            filterFactory={filterFactory}
            processeslist={processeslist}            
           
          />
        )}
      </>
    </Grid>
  );
};

function OrderCollectionStagesForm(props) {
  const classes = useStyles();
 
  const { orderCollectionStage, id, factoryList, filterFactory, processeslist } = props;  
  
  const getProcessesList = () => {
    if (values.factory_id) {
      let prosess = [];
      prosess = processeslist
        ? processeslist.filter((element) => {
            return element.factory_id === values.factory_id;
          })
        : [];
        let result = prosess.reduce(function(result, item, index, array) {
            result[item._id] = item.name ; //a, b, c
            return result;
          }, {}) 

      return result;
    } else {
      return {}     
    }
  };

  const [values, setValues] = useState({
    name: orderCollectionStage ? orderCollectionStage.name : "",
    factory_id: orderCollectionStage ? orderCollectionStage.factory_id : '',
    type: orderCollectionStage ? orderCollectionStage.type :  0,
    stages: orderCollectionStage ? orderCollectionStage.stages :  '',
  });

  const getFactoryId  = () => { 
    let res = orderCollectionStage ? orderCollectionStage.factory_id : filterFactory ?filterFactory : null;
    setValues({ ...values, factory_id: res })
  }

  useMemo(() => getFactoryId(), [filterFactory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onChangeStageDate = (value) => {    
    setValues({ ...values, stages: value });
  };


  const onSubmit = (event) => {
    event.preventDefault();
    const {  name, factory_id, type, stages } = values;

    let model;

    if (id) {
      model = {
        _id: id,
        name,
        type,
        factory_id ,
        stages
      };
    } else {
      model = {
        name,
        type,
        factory_id,
        stages
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>   
      <form id="edit-order-collection-stages" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>           
            <TextField
              name="type"
              variant="outlined"
              type="number"
              label="type"
              value={values.type}
              onChange={handleInputChange}
            />
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={ values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
           
          </Grid>
          
          <Grid item>   
          <FormControl variant="outlined" className={classes.formSelect}>
          <StageTable  processesList={getProcessesList()} 
                    data={values.stages}
                    onChangeDate={onChangeStageDate}> </StageTable>   
          </FormControl>      
          
          </Grid>
          </Grid>
      </form>
    </div>
  );
}
