import React, { useState, useEffect } from 'react'
import { useStyles } from '../styles'
import {useIsSelected} from '../../../../../hooks/useIsSelected'

const ProductType = (props) => {
    const classes = useStyles()
    const { type, productType, setProductType } = props
    const selected = useIsSelected(type, productType)

    return (
        <button
            className={`${classes.productType} ${ selected ? classes.activeProductType : ''}`}
            onClick={() => setProductType(type)}
        >
            {type}
        </button>
    )
}

export default ProductType