import React from 'react'
import { styles } from '../styles'
import { QUOTE_PDF, QUOTE, QUOTE_PDF_RULES } from '../../../../constants/translations/customersPage'
import moment from 'moment'
import TitleAndValueRow from './TitleAndValueRow'
import SignatureField from './Signature'
import Line from './Line'
import RemarksPdf from './RemarksPdf'
import { convertIntoCurrency } from '../../../../hooks/helper'
import TotalAmountPdf from '../../Quote/Parts/TotalAmountPdf'

const QuoteAgreementPage = (props) => {
    const { EXTRAS,
        TOTAL_PRICE_BEFORE_VAT,
        TOTAL_PAYMENT_INCLUDING_VAT,
        OFFER_VALID,
        A_LIGHT_SIGNATURE,
        CUSTOMER_SIGNATURE } = QUOTE_PDF

    const { SHEKEL } = QUOTE
    const { totalCost, totalCostWithVAT, remarks, products, productDiscount, extraCharges } = props
    const offerValidDate = (moment().add(2, 'week')).format('DD/MM/YYYY')

    let filterExtraCharges = extraCharges.filter(({amount, remark}) => amount || remark);

    return <div style={{ ...styles.page, ...styles.marginsAndPaddingsForSecondPage}} id='quote-agreement'>
            {
                filterExtraCharges.length > 0 && <>
                    <Line />
                    <p style={{...styles.fontSize16px, ...styles.pageMargin }}>{EXTRAS}</p>
                    <ul style={{...styles.fontSize16px, ...styles.pageMargin}}>
                        {
                            filterExtraCharges.map(({amount, remark}, index)  => {
                                return (<li key={index} style={{...styles.locationRemarks, padding: '10px 0px 0px 0px', display: 'flex'}}>
                                        <div key={index} style={{display: 'flex'}}>
                                            <div style={styles.remarksBulltes}></div>
                                            <div>
                                                <div style={{margin: '0 0px 7px 0'}}>{remark}</div>
                                                <div key={index} style={{margin: '0 0px 7px 0',color: '#0e97ff'}}>{convertIntoCurrency(amount, 'ILS')}</div>
                                            </div>
                                        </div>
                                </li>)
                            })
                        }
                    </ul>
                </>
            }
        <Line />
        {
            remarks.length > 0 && 
            <>
                <div>
                    <p style={{margin: '0px 40px'}}>
                        {'הערות'}
                    </p>
                    <RemarksPdf remarks={remarks} />
                </div>
                <Line />
            </>
        }
        <TotalAmountPdf
            totalCost={totalCost}
            productDiscount={productDiscount}
            extraCharges={extraCharges}
            totalCostWithVAT={totalCostWithVAT}
            titleName={OFFER_VALID}
            value={offerValidDate}
        />
        <div style={{ ...styles.agreementRulesWrapper, ...styles.fontSize12px }}>
            {
                QUOTE_PDF_RULES.map((i, idx) => <p style={{...styles.agreementText}} key={idx}>{`${idx + 1}. ${i}`}</p>)
            }
        </div>
        <div style={{ ...styles.signaturesLine }}>
            <SignatureField label={A_LIGHT_SIGNATURE} />
            <SignatureField label={CUSTOMER_SIGNATURE} />
        </div>
    </div>
}

export default QuoteAgreementPage