import {makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      direction: "rtl"
    },
    container: {
      maxHeight: "calc(100vh - 160px)",
      direction: "ltr"
    },
    pagination: {
      direction: "ltr",
    },
    styledColorDisplay: {
      display: 'block',
      width: '100%',
      height: '35px'
    },
    icons: {
      color: '#0091ff',
      cursor: 'pointer',
      fontSize:'30px'
    },
    imageOption:{
        width: '25px', 
        height: '25px'
    },
    detailsRow:{
        display: 'flex',
        spacing:theme.spacing(1),
        // margin: '0 auto'
    },
    input:{
      width: '229px',
      margin: theme.spacing(1)
    },
    systemDetailsWrapper:{margin:'0 auto'},
    container:{
      width:'100%',
      display:'flex',
      justifyContent:'center',
    },
    addIcon:{
      fontSize:'45px'
    },
    inputNameSelect: {
      width:'50vw',
      minHeight:0
    }, 
    inputsHeaderCell:{
      fontSize: '20px',
      backgroundColor:'#f5f5f5'
    },
    scrollButtons:{
      opacity:'0.9 !important'
    },
    tableCell: {
      cursor:'pointer'
    },
    addIconWrapper:{
      position:'sticky',
      bottom:0,
      backgroundColor:'white',
      display: 'flex',
      // justifyContent: 'center'
      justifyContent: 'space-evenly'
    },
    editInputWrapper: {
      height: '70vh'
    },
    checkBox:{
      color:'#0091ff !important'
    },
    alignBetween:{
      display: 'flex',
      justifyContent: 'space-between',
    },
    // scrollButtons: {
    //   width: 'initial',
  
    //   '&:first-child': {
    //     backgroundColor:
    //       theme.palette.type === 'dark'
    //         ? theme.palette.grey[800]
    //         : theme.palette.grey[50],
    //     paddingRight: theme.spacing(2),
    //     height: '100%',
    //     position: 'absolute',
    //     left: 0,
    //     opacity: 1,
    //     zIndex: 1,
    //   },
  
    //   '&.MuiTabs-scrollButtonsDesktop': {
    //     display: 'flex',
  
    //     '&.Mui-disabled:first-child': {
    //       visibility: 'hidden',
    //     },
    //   },
    // }
    hp_class: {
      "& .MuiInputBase-input": {
        textAlign:'right'
      }
    }
  }));

