import React, {useState, useRef, useCallback, useEffect} from "react";

const json = [
    {
        user_name: 'Andrey',
        color: 'green',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },
    {
        user_name: 'Karl',
        color: 'yellow',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },
    {
        user_name: 'Antony',
        color: 'blue',
        processes: [
            {
                process_name: 'process 1',
                start_time: 11,
                duration: '5'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },
    {
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    }, {
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    }, {
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    }, {
        user_name: 'Mark',
        processes: [
            {
                process_name: 'Order',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'Essay',
                start_time: 15,
                duration: '2'
            },
        ],
    },
    {
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },{
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },{
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },{
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },{
        user_name: 'Mark',
        color: 'red',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 15,
                duration: '2'
            },
        ],
    },{
        user_name: 'Mark',
        processes: [
            {
                process_name: 'process 1',
                start_time: 10,
                duration: '3'
            },
            {
                process_name: 'process 2',
                start_time: 14,
                duration: '2'
            },
        ],
    },
]

const time = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

const startOfDay = 8

const VerticalHeader = () => {
    return <div style={{width: '100vw', height: '100vh',}}>
        <Header time={json}/>
        {
            time.map((i, idx) => {
                return (
                    <div key={idx} style={{width: '100vw', border: '1px gray solid', height: '10vh', display: 'flex'}}>
                        <div style={{width: '13.5vw', margin: '10px', textAlign: 'center'}}
                        >{i}AM
                        </div>
                    </div>
                )
            })
        }
    </div>
}


const Header = (props) => {
    const {jsons} = props
    return <div style={{width: '100vw', height: '15vh', display: 'flex'}}>
        <div style={{
            width: '20vw',
            color: '#0065B1',
            paddingRight: '5px',
            paddingTop: '10px',
            border: '1px  gray'
        }}>Timelines
        </div>
        {
            json.map((obj, idx) => {
                return <div style={{
                    width: '10vh',
                    color: '#455768',
                    height: '15vh',
                    textAlign: 'left',
                    border: '1px solid black'
                }}>
                    <div style={{paddingTop: '5px', fontSize: '12px', left: '-10px', transform: 'rotate(270deg)'}}
                         key={idx}>{obj.user_name}
                    </div>
                    {
                        obj.processes.map((pro, j) => {
                            return (<div>
                                    <div style={{
                                        width: `${(pro.start_time - startOfDay) * 10}vw`,
                                        height: '15vh',
                                        color: 'red',
                                        transform: 'rotate(270deg)'
                                    }}>
                                    </div>
                                    <div>
                                        <div style={{
                                            height: `${pro.duration * 10}vw`,
                                            borderRadius: '50px 50px 50px 50px',
                                            border: '1px black solid',
                                            paddingTop: '1px',
                                            backgroundColor: 'white',
                                            paddingRight: '10px',
                                            boxShadow: ' 0.1rem 0.3rem  #ccc',
                                            width: '17px',
                                        }}>
                                            <div style={{transform: 'rotate(270deg)'}}>
                                                {pro.process_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                    }
                </div>

            })
        }
    </div>
}

export default VerticalHeader
