  export const styles = {
        quotePdfBox:{
          position:'absolute', 
          left:'3000px',
        },
    page: {
    //   width:'793px',
      direction:"rtl",
    //   marginTop: '40px'
    },
    section: {
      padding: '15px 0 0',
      flexGrow: 1,
      borderTop: '2px solid #0091ff1c'
    },
    ////////////////////////////////////////////
    TitleAndValueRow:{
        display:'flex',
        flexDirection:'row',
        marginLeft: '1%'
    },
    generalInfo: {
    //   margin: '40px',
      padding: '15px',
      flexGrow: 1,
      backgroundColor: '#0091ff33',
      display:'flex',
      flexDirection:'column',
      margin: '40px',
      marginTop: '40px !important',
      marginBottom: '25px'
    },
    orderInfoTitle:{
        // width: '70%',
        display:"flex",
        flexDirection: 'row',
        justifyContent:'space-between',
        // marginLeft: '15px',
        alignItems: 'center',
        // fontFamily:'Rubik'
        marginBottom: '1%'
    },
    orderInfo: {
        display:'flex',
        flexDirection: 'row',
        fontSize:'16px',
        // fontFamily:'Rubik'
    },
    orderInfoValue: {
        marginRight:'15px',
    },
    orderInfoProperty:{
        color: `rgb(146 146 142)`,
    },
    location:{
        // marginRight:'20px',
        // fontSize:'16px'
        margin: '15px 0px 15px 0px',
        fontSize: '16px'
    },
    location_from_secondPage:{
        // marginRight:'20px',
        // fontSize:'16px'
        margin: '0px 0px 15px 0px',
        fontSize: '16px'
    },
    locationRemarks: {
        fontSize: '14px',
    },
    remarksBulltes: {
        height: '5px',
        width: '5px',
        backgroundColor: '#574d4d',
        borderRadius: '15px',
        margin: '4px 1px 0px 24px',
    },
    // ----------------------------------------------------------------
    customerInfoRow: {
        fontSize:'10px',
        marginBottom:'5px'
    },
    customerInfoRowTitle:{
        width:'80px'
    },
    customerInfoIdAndDueDate:{
        display:'flex',
        flexDirection:'row',
        // width:'90%',
        justifyContent:'space-between'
    },
    customerInfoDueDateTitle:{
        width: '145px'
    },
    // ----------------------products
    productWrapper:{
        dispaly:'flex',
        flexDirection:'column',
        lineHeight:'15px',
        margin: '40px 40px 15px 40px',
        width: '100%'
    },
    productInfoBox: {
        dispaly:'flex',
        flexDirection:'column',
        lineHeight:'17px',
        marginRight: '3%'
    },
    product:{
        margin: '0 0 45px 0',
        display:'flex',

    },
    productImageAndNumberWrapper:{
        width: '120px',
        height: '150px',
        display:'flex',
        flexDirection:'row'
    },
    productNumber:{
        width:'15px',
        height:'15px',
        border: '1px solid',
        // margin: '0 10px',
        marginLeft: '1%',
        fontSize: '10px',
        textAlign:'center',
        lineHeight: '9px',
        margin: '1px 0 0 5px',
        padding: '2px 5px 5px 10px',
    },
    productImage: {
        width: '100px',
        height:'90px',
        maxWidth: '-webkit-fill-available',
        // objectFit:'contain',
        // height:'auto',
        // maxHeight:'110px',
        // height: '150px',
        // objectFit: 'fill',
        marginLeft:'1%'
    },
    productInfo: {
        display:'flex',
        flexDirection: 'row',
        fontSize:'12px'
    },
    flexRow: {
        display:'flex',
        flexDirection:'row',
        wordBreak: 'break-all'

    },
    productValueStyles:{
        marginLeft: '8px'
    },
    productInfoColumnValue:{
        width: '100px',
    },
    productInfoColumnProperty:{
        width: '60px',
        color: `rgb(146 146 142)`,
    },
    line:{
        // width:'793px',
        borderBottom: `2px`,
        borderColor:'rgb(146 146 142)',
        borderStyle:'dotted',
        margin:'20px 40px 20px 40px',
    },
    fontSize10px:{
        fontSize:'10px'
    },
    fontSize12px:{
        fontSize:'12px'
    },
    fontSize14px:{
        fontSize:'14px'
    },
    fontSize16px:{
        fontSize:'16px'
    },
    fontSize25px:{
        fontSize:'25px'
    },
    agreementPriceAndTermsInfo:{
        width: '240px',
        lineHeight: '20px',
        float: 'right'
    },
     mainColor:{
        color: '#0091ff'
     },
     agreementRulesWrapper:{
        dispaly:'flex',
        flexDirection:'column',
        lineHeight:'15px',
        margin: '20px 40px 20px 40px',
        width: '100%'
     },
     signatureTitle:{
        //  fontSize:'25px'
     },
     signatureLine:{
         width:'150px',
         borderBottom: '1px solid black',
         marginRight:'10px'
     },
     marginsAndPaddingsForSecondPage:{
        // margin:'10px 30px 10px 10px', 
        // padding:'10px 30px 10px 10px'
     },
     remarksPdf:{
        display: 'block',
        // minHeight: '80px',
        padding: '1%',
        // width: '100%',
        backgroundColor: '#0091ff33',
        border: '2px solid #0091ff1c',
        // margin: '10px 0',
        fontSize: '15px'
    },
    // agreeementText:{marginBottom:'7px'},
    agreementText:{lineHeight:'15px'},
    signaturesLine:{
        width: '80%',
        display:"flex",
        flexDirection: 'row',
        justifyContent:'space-between',
        margin: '40px 50px 40px 0px'
    },
    priceLine: {
        width:'150px',
        borderBottom: '1px solid #00000029'
    },
    pageMargin: {
        margin: '0px 40px 0px 40px'
    },
    remarksPdfSection: {
        border: '1px solid #554949',
        borderRadius: '7px',
        padding: '10px 10px 10px 10px',
    },
    remarksPdfBody: {
        margin: '0px 40px 12px 40px',
    }

  }