import React, {useCallback} from "react";
import uuid from 'uuid'
import DetailsDialog from '../../DetailsDialog'
import AddRowButton from '../Components/AddRowButton'
import {useStyles} from './styles'
import { generalPostRequest } from "../../../../functions/api/general";
import {
  REG_EXP_OBJECT_ID, ADD_SUB_SYSTEMS, ADD_INPUT, ADD_ADDITIONAL_TEST, ROW_TITLE, ROW_ORDER, SUB_SYSTEM_ID, TYPE_OF_INPUT, ADDITIONAL_TEST_ID, DESCRIPTION, ADDITIONAL_TEST_EXIST_INPUT_TYPE
} from '../../../../constants/admin-systems'
import GeneralSectionTable from './Parts/GeneralSectionTable'
import {ADD_CONDITIONAL_TEST_ROW, ADD_CHILD_SYSTEMS_ROW, ADD_PLAIN_ROW} from '../../../../constants/translations/admin-systems'
import {ButtonGroup, Button, Box} from '@material-ui/core'
import { deleteTemporaryIdFromObject } from "../../../../utils/deleteTemporaryId";

const EditGeneralSectionPopup = (props) => {
    const { 
// is popup open set pop up open
        isOpen, setEditGeneralSectionPopup,
//  general section  data , 
        generalSection, setGeneralSection, 
// options for rows (add multiple subsystems and change input type)
        inputTypes, subSystems,
// systems list and change systems list handler for update after system update 
    systems, setSystems,
// related to additional test
   additionalTests
   } = props
  
   const classes = useStyles()
    
   const closeHandler = () => {
      setGeneralSection('')
      setEditGeneralSectionPopup(false)
    }

    const deleteRowHandler = (rowId) => {
        const copyGeneralSection = {...generalSection}
        const rowIdx = copyGeneralSection.data.findIndex(i => i._id === rowId)
        copyGeneralSection.data.splice(rowIdx, 1)
        setGeneralSection(copyGeneralSection)
    }

    const createRow = (rowType) => {
        const ROW_ORDER_NUMBER = generalSection.data.length + 1
        const body = {
            _id: uuid(),
            order: ROW_ORDER_NUMBER,
            row_title:'',
            values:[{value: '' }]
        }
        switch(rowType){
            case ADD_INPUT: 
                body.values[0].typeOfInput = '1'
                break;
            case ADD_SUB_SYSTEMS:
                body.values[0].sub_system_id = ''
                body.values[0].typeOfInput = 'number'
                break
            case ADD_ADDITIONAL_TEST:
                body.values[0].additional_test_id = ''
                body.values[0].typeOfInput = ADDITIONAL_TEST_EXIST_INPUT_TYPE
                body.row_title = 'additional test'
        }
        return body
    }
  
    const addRowHandler = (rowType) => {
        const copyGeneralSection = {...generalSection}
        const row = createRow(rowType)
        copyGeneralSection.data.push(row)
        setGeneralSection(copyGeneralSection)
    }

    const editGeneralSection = (rowId, property) => (value) => {
          const copyGeneralSection = {...generalSection}
          const row = copyGeneralSection.data.find(i => i._id === rowId)
          
          switch(property){
              case ROW_TITLE:
                  row.row_title = value
                  break
              case ROW_ORDER:
                  row.order = value
                  break
              case SUB_SYSTEM_ID:
                  row.values[0].sub_system_id = value
                  break
              case TYPE_OF_INPUT:
                  row.values[0].typeOfInput = value
                  break
              case ADDITIONAL_TEST_ID:
                  const additionalTestName = additionalTests?.find?.(i=>i._id === value)?.row_title
                  row.values[0].additional_test_id = value
                  row.row_title = additionalTestName || row.row_title
          }
          setGeneralSection(copyGeneralSection)
      }
      

    const submitHandler = async() => {
        const copyGeneralSection = {...generalSection}
        if(!copyGeneralSection._id.match(REG_EXP_OBJECT_ID)) delete copyGeneralSection._id
        copyGeneralSection.data.forEach(deleteTemporaryIdFromObject)
        const responce = await generalPostRequest(copyGeneralSection, '/admin/systems/edit-general-section')
        if(responce?.result){
            const {_id, system_id} = responce?.result
            const systemsCopy = [...systems]
            const system = systemsCopy.find(i => i._id === system_id)
            system.general_section_id = _id
            setSystems(systems)
        }
        closeHandler()
    }
  
    return (
      <DetailsDialog
        isOpen={isOpen}
        onClose={closeHandler}
        submitLabel="Update"
        maxWidth='lg'
        //   form="edit-user"
        title="Edit general section"
        submitHandler={submitHandler}
        dialogContentStyle = {{padding:0}}
      >
        <div style={{ width: '40vw' }}>
          <GeneralSectionTable
            generalSection={generalSection}
            inputTypes={inputTypes}
            subSystems={subSystems}
            deleteRowHandler = {deleteRowHandler}
            editGeneralSection = {editGeneralSection}
            additionalTests = {additionalTests}
          />
          <Box className = {classes.buttonsGroup}>
            <Button variant = 'outlined' onClick = {() => addRowHandler(ADD_INPUT)}>{ADD_PLAIN_ROW}</Button>
            <Button variant = 'outlined' onClick = {() => addRowHandler(ADD_SUB_SYSTEMS)}> {ADD_CHILD_SYSTEMS_ROW}</Button>
            <Button variant = 'outlined' onClick = {() => addRowHandler(ADD_ADDITIONAL_TEST)}>{ADD_CONDITIONAL_TEST_ROW}</Button>
          </Box>
        </div>
      </DetailsDialog>
    )
  }

export default EditGeneralSectionPopup