import {ADD_FILE_TO_FILES_LIST_DESKTOP, DELETE_FILE_BY_FILE_ID, EDIT_SELECTED_FILE_BY_FILE_ID, GET_FILES_LIST_DESKTOP, GET_FILES_LIST_MOBILE, GET_FILES_LIST_TABLET, SELECT_FILE_BY_FILE_ID, SET_FILES_TO_FILES_LIST_TABLET} from '../../constants/customer/fileConstants';


export const fileActionCreator = {
    getFilesListDesktop: () => ({type: GET_FILES_LIST_DESKTOP}),
     getFilesListMobile: () => ({type: GET_FILES_LIST_MOBILE}),
    getFilesListTablet: () => ({type: GET_FILES_LIST_TABLET}),
    setFileToFilesListTablet: (files) =>{
      return  ({
        type: SET_FILES_TO_FILES_LIST_TABLET, payload : files
    })},
    addFileToFilesListTablet: (file) => ({ type: ADD_FILE_TO_FILES_LIST_DESKTOP, payload: file }),
    selectFileByFileId: (fileId) =>{
        return { type: SELECT_FILE_BY_FILE_ID, payload: fileId }},
    editSelectedFileByFileId: (file) => ({ type: EDIT_SELECTED_FILE_BY_FILE_ID, payload: file }),
    deleteFileByFileId: (fileId) => ({ type: DELETE_FILE_BY_FILE_ID, payload: fileId }),
}