import React from 'react'
import { TableCell, TextField } from "@material-ui/core";

const OrderInput = (props) => {
    const {value, changeHandler} = props
    return (<TableCell style = {{width:'50px'}}>
                <TextField type='number' value={value} onChange = {e => changeHandler(e.target.value)} />
            </TableCell>)
}

export default OrderInput