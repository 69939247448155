export const DELAYED_CHECK_LIST_DATA = 'DELAYED_CHECK_LIST_DATA'
export const CURRENT_ORDER_ID = 'CURRENT_ORDER_ID'
export const CACHED_CHECK_LISTS_DATA = 'CACHED_CHECK_LISTS_DATA' 
export const LOGIN_DATA = 'LOGIN_DATA'
export const ORDER_LIST = 'ORDER_LIST'
export const DEPARTMENTS = 'DEPARTMENTS'
export const CACHED_EDIT_GENERAL_SECTION = 'CACHED_EDIT_GENERAL_SECTION'
export const ELECTRICITY_BOX_LAYER = 2 /*layer number for electricity box system */
export const ACTIVE_TIME_STAMP = 'ACTIVE_TIME_STAMP';
export const REFRESH_TIME = 1800000;
export const REVIEW_POPUP_TIMER = 'REVIEW_POPUP_TIMER'; 
export const UNION_CHECK_LIST_DATA = 'UNION_CHECK_LIST_DATA';
export const TEST_FLOOR_CHECK_LIST_DATA = 'TEST_FLOOR_CHECK_LIST_DATA';
export const URL_HISTORY = 'URL_HISTORY';
export const URL_NAVIGATION_HISTORY = 'URL_NAVIGATION_HISTORY';