import React, {useState, useEffect, useRef} from 'react'

export default function useComponentVisible(initialClick) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialClick);
    const ref = useRef(null);

    function handleOutSideClick(e){
        if(ref.current && !ref.current.contains(e.target)){
          setIsComponentVisible(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleOutSideClick, true)
    
      return () => {
        document.removeEventListener('click', handleOutSideClick, true)
      }
    }, [])
    
  return { ref, isComponentVisible, setIsComponentVisible }
}
