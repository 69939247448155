import { FormControl, Grid, MenuItem, Select, styled, makeStyles, FormHelperText, Checkbox, ListItemText } from '@material-ui/core'
import React, { useState } from 'react'
import "../SelectInput/SelectInput.scss"


const useStyles = makeStyles((theme) => ({
    select: {
        '& .MuiSelect-outlined.MuiSelect-outlined:focus': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.grey[500],
        },
    }
}));

const options = [
    {
        name: "חצר",
        resource_id: "62ce61622872bd2747c0eb08",
        user_for_resource_id: "62ce61622872bd2747c0eb08"
    },
    {
        name: "חצר11",
        resource_id: "62ce61622872bd2747c0eb0811",
        user_for_resource_id: "62ce61622872bd2747c0eb0811"
    },
    {
        name: "חצר22",
        resource_id: "62ce61622872bd2747c0eb0822",
        user_for_resource_id: "62ce61622872bd2747c0eb0822"
    }
]

function isExist (previousSelections, newSelection){
  let isExist = false;
  for (let _selected=0; _selected<previousSelections.length; _selected++){
      if (previousSelections[_selected].resource_id === newSelection.resource_id){
          isExist = true;
      }
  }
  return isExist;
}
export default function MultiSelectInput(props) {
    const classes = useStyles();
    const { label, placeholder, value, type, width, height, border, inputFor, selectOptions, handleChange: onChangeFn, name, error, errorText } = props.data;
    const [selectValue, setSelectValue] = useState([]);
    const { view } = props;
    
    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     console.log("value", value)
    //     let newSelectedValueList = []
    //     if (typeof value === 'object' ) {
    //         if (!isExist(selectValue, value)){
    //             newSelectedValueList = [...newSelectedValueList, value]
    //         }
    //     }
      
    //     setSelectValue((prevValue) =>{ 
    //         onChangeFn(name, [...prevValue, ...newSelectedValueList]);
    //        return [...prevValue, ...newSelectedValueList]
    //     });

    // };

    const handleChange = (event) => {
        const {name, value }= event.target;

        setSelectValue((prevValue) => { 
            return [ ...value]
        });
        onChangeFn(name, [...value]);

    };

    return (
        <Grid item xs={12} className={`${view === 'tablet' && 'select-input-container--tablet'} select-input-container`} style={{ height: height && height }}>
            <FormControl className="select-input-container__form" style={{ width: width && width }}>
                {label ? <label style={{ fontSize: view === 'tablet' && '24px' }}>{label}</label> : null}
                {/* {label ? <label htmlFor="demo-customized-select-native" style={{ fontSize: inputFor === 'dataInforamtionInput' && '24px', marginBottom: view==='desktop' && '5px'}}>{label}</label> : null} */}
                <Select
                    className={`${view === 'tablet' && 'select-input-container__select--tablet'} select-input-container__select ${classes.select}`}
                    value={selectValue}
                    name={name}
                    // label={placeholder}
                    onChange={(e) => handleChange(e)}
                    style={{ width: width, border: border, height: height && height, textAlign: 'right' }}
                    MenuProps={{
                        disablePortal: true,
                    }}
                    multiple
                    error={error}
                    renderValue={(selected) => {
                        if (!selected.length) {
                            return placeholder;
                        }
                        return selected.map((selectedValue, index) => <span key={index}>{index === selected.length - 1 ? <span>{selectedValue.name}</span> : <span>{selectedValue.name}, </span>}</span>)
                    }}
                    variant='outlined'
                // sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                >
                    <MenuItem value={0} disabled style={{ fontSize: view === 'tablet' ? '24px' : '14px', }}>
                        {placeholder}
                    </MenuItem>
                    {
                        selectOptions && selectOptions.length > 0 ?
                            selectOptions.map((selectionOption, index) => {
                                return <MenuItem className='multiSelect__menuItem' key={index} value={selectionOption}>
                                    <Checkbox 
                                    id={index} 
                                    checked={selectValue.find((selected) => selected.resource_id === selectionOption.resource_id)} 
                                    />
                                    <label htmlFor={index}>
                                        {/* {selectionOption.name || selectionOption.full_name} */}
                                    <ListItemText primary={selectionOption.name || selectionOption.full_name} />
                                </label>
                                </MenuItem>
                            })
                            : <>
                                <MenuItem value={''}></MenuItem>
                            </>
                    }

                </Select>
                {
                    error ? <div className='errorContainer'><FormHelperText className='inputError'>{errorText}</FormHelperText></div> : null
                }
            </FormControl>
        </Grid>
    )
}
