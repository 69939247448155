import { getCompletion } from "../api/orders";

export const getProcessDetailsFromApiBySelectedProcessId = async (processId) => {
    if (!processId){
      return;
    }

    let page = 0;
    let limit = 50;
    let current_process = false;
    let selectedDepartment = '';
    let urlOrderNumber = "";
    let employee_id = "";
    let selectedUser = "";

    const res = await getCompletion(limit, page, 'L', selectedDepartment, urlOrderNumber, employee_id, selectedUser, current_process, processId);
    let { data = [], count } = res.result;
    return data[0].proccess;

  }