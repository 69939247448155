import React from 'react'
import {PRODUCT_FORM} from '../../../../../constants/translations/customersPage'
import CustomizedInputProduct from '../../../reused_components/CustomizedInputProduct'
import {useStyles } from '../styles'

const SizeForm = (props) => {
    const classes = useStyles()
    const {WIDTH, HEIGHT } = PRODUCT_FORM
    const {width, height, setWidth, setHeight, title, disabled} = props
    return <div className = {classes.sizeFormWrapper}>
        <p className = {classes.title}>{title}</p>
        <CustomizedInputProduct
            value={ height }
            label={ HEIGHT }
            changeHandler={ setHeight }
            type = 'number'
            disabled = {disabled}
            width = {'160px'}
        />
        <CustomizedInputProduct
            value={ width }
            label={ WIDTH }
            changeHandler={ setWidth }
            type = 'number'
            disabled = {disabled}
            width = {'160px'}
        />
    </div>
}

export default SizeForm