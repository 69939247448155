import { Grid } from '@material-ui/core';
import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import "./ImageButton.scss";
import See_img_icon from '../../assets/See_imgIconSVG.svg';
import Upload_img_icon from '../../assets/Upload_imgIconSVG.svg';



export default function ImageButton(props) {
    const { data, view, isLabel } = props;
    return (
        <Grid container spacing={0} className='img-container'>
            <span className='img__iconContainer'>
                {/* {
                    data === 'Upload' ? <ImageSearchIcon className='img__icon' /> : <ImageIcon className='img__icon' />
            } */}
                {
                    data === 'Upload photo' ? <img src={Upload_img_icon} alt='icon' /> : <img src={See_img_icon} alt='icon' />
                }

            </span>
            &nbsp;
            {isLabel !== false && <label>{data}</label>}
        </Grid>
    )
}
