export const desktopOrdersList = {
    popUpData: [
        {
            label: "Customer report",
            // icon: 'folder'
        },
        {
            label: "Internal report",
            // icon: 'edit'
        },
        {
            label: "Signature",
            // icon: 'delete'
        },
    ],
    columns: [
        {
            field: 'orderNumber',
            headerName: 'Order number',
        },
        {
            field: 'customerName',
            headerName: 'Customer name',
        },
        {
            field: 'coordinationDate',
            headerName: 'Coordination date',
        },
        {
            field: 'contactName',
            headerName: 'Contact name',
        },
        {
            field: 'contactPhoneNumber',
            headerName: 'Contact phone number',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    // rows: [
    //     { id: 1, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     { id: 2, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     { id: 3, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     { id: 4, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     // { id: 5, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     // { id: 6, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     // { id: 7, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     // { id: 8, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    //     // { id: 9, orderNumber: 'PE20001486', customerName: 'Dror Halperin', coordinationDate: '15/06/2023', contactName: 'A. Stasovskiy', contactPhoneNumber: '050 000 00 00' },
    // ],
    rows: []
}