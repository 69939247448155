export const GET_TASKS_LIST_MOBILE = 'GET_TASKS_LIST_MOBILE';
export const GET_TASKS_LIST_TABLET = 'GET_TASKS_LIST_TABLET';
export const ADD_TASK_TO_TASKS_LIST_TABLET = 'ADD_TASK_TO_TASKS_LIST_TABLET';
export const ADD_TASK_TO_TASKS_LIST_MOBILE = 'ADD_TASK_TO_TASKS_LIST_MOBILE';
export const SELECTED_TASK_BY_TASK_ID = 'SELECTED_TASK_BY_TASK_ID';
export const DELETE_TASK_BY_TASK_ID = 'DELETE_TASK_BY_TASK_ID';
export const EDIT_SELECTED_TASK_BY_TASK_ID = 'EDIT_SELECTED_TASK_BY_TASK_ID';
export const SET_TASKS_TO_TASKS_LIST_TABLET = 'SET_TASKS_TO_TASKS_LIST_TABLET';



