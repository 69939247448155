import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    infoSectionWrapper:{
        display:'flex', 
        flexDirection:'column', 
        width:'360px',
        padding:'10px 15px 15px 15px',
    },
    addProductButton:{
        width: '190px',
        border: '2px dashed black',
        height: '150px',
        display: 'flex',
        alignItems: 'center',
        cursor:'pointer'
    },
    addButtonInnerText:{
        margin:'0 auto',
        fontSize: '20px'
    },
    buttonWithIcon: {
        display: 'flex',
        alignItems:'center',
        padding:'10px 0',
        cursor:'pointer'
    },
    buttonWitIconText: {
        marginLeft: '5px',
        marginBottom: '7px',
        letterSpacing: '0.7px'
    },
    navWrapper:{
        display: 'flex',
        justifyContent: 'space-between',
        width:'270px',
    },
    totalAmountFontSize: {fontSize:'25px'},
    buttonsWrapper:{
        width: '340px',
        padding: '15px 0'
    },
    button:{
        // width:'45%'
        width: '155px'
    },
    okButton:{
        background: 'rgb(0, 145, 255);',
        color:'white'
    },
    printButton:{
        color: 'rgb(0, 145, 255);',
        textDecoration:'none'
    },
    buttonPending:{
        color: 'rgb(218, 220, 224)'
    },
    productWrapper:{
        // height: '150px',
        marginBottom:'20px',
        display: 'flex',
        gap: '15px'
    },
    productImage:{
        width: '190px',
        height: '150px',
        objectFit: 'contain',
        background: '#e3e3e3',
        cursor: 'pointer',
        display: 'block'
    },
    productDescriptionWrapper:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        marginLeft: '10px',
        width: '-webkit-fill-available',
    },
    productPropertyAndValue: {
        display: 'flex',
        width: '100%',
        // marginLeft: '30px'
    },
    productDescriptionColumnWrapper: {
        display:'flex',
        flexDirection: 'column',
        marginRight: '15px',
        width: '100%'
    },
    productColumnsWrapper:{
        display: 'flex',
    },
    productCostSectionWrapper :{
        display:'flex',
    },
    productDescriptionProperty:{
        textAlign:'left',
        width:'33%', 
        color: `rgb(187 187 184)`,
        fontSize: '14px',
    },
    perOfferSection: {  
        display: 'block',
        fontSize: '22px',
        fontWeight: 500,
        color: '#6b6d6e',
        padding: '0px 0px 16px 0px',
    },
    editText: {
        fontSize: '20px',
    },
    addButtonLocationWithIcon: {
        display: 'flex',
        alignItems:'center',
        padding:'25px 0',
        cursor:'pointer',
    },
    productDescriptionValue: {
        fontSize: '14px',
        width: '67%',
    },
    quoteNavigationButton: {
        display: 'flex',
        alignItems:'center',
        cursor:'pointer'
    },
    defaultColor: {
        color: '#0091ff',
    },
    amountSection: {
        width: '100%',
        display: 'flex',
        padding: '5px 0px 5px 0',
    },
    totalAmountSection: {
        // width: '70%',
        fontSize: '20px',
        fontFamily: 'Rubik',
    },
    amountLabel :{
        width: '22%'
    },
    amountValue: {
        width: 'inherit'
    },
    amountLine: {
        width: '80%',
        float: 'left',
        margin: '4px 0 4px 0px',
    },
    elevation1: {
        boxShadow: '0px 1px 10px #cdc7c7',
        borderRadius: '4px',
    },
    quoteTableBtn: {
        color: '#0091ff !important',
        borderRadius: '11px !important',
        backgroundColor: 'rgba(0, 145, 255, 0.1) !important',
        padding: '0px !important',
        '& :hover': {
            padding: '0px !important',
            borderRadius: '11px !important',
            backgroundColor: 'rgba(0, 145, 255, 0.205) !important'
        }
    },
    quotePage: {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '100%'
    },
    quoteTableDiv: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: '72vh'
        // height: '66vh',
        // display: 'list-item',
        // overflowY: 'scroll'
    },
    quoteTableHeader: {
        // background: '#f7f7f7',
        // margin: 0,
        // fontSize: '23px',
        // padding: '15px 10px'
        fontSize: '1.5rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.334',
        letterSpacing: '0em'
        // marginBottom: '5px !important'
    },
    quoteHeader: {
        paddingRight: '10px',
        paddingLeft: '10px',
        fontSize: '14px !important',
        color: 'rgba(0, 31, 64, 0.6)'
    },
    quoteTablePagination: {
        direction: 'ltr'
    },
    quoteDropDown: {
        width: '100%',

        '& .MuiSelect-icon' : {
            right: '90%'
        }
    },
    quotesTableRow: {
        textOverflow: 'ellipsis',
        paddingRight: '10px !important',
        paddingLeft: '10px !important',
        fontSize: '12px !important',
        '& .drop__down__header__select': {
            border: 'none !important',
            height: '44px !important',
            fontSize: '14px !important',
            background: 'transparent',
            width: '36%'
        },
        // '& #drop__down__box--active > li': {
        //     fontWeight: 'bold'
        // }
    },
    productModelButtonWitIconText: {
        marginLeft: '5px',
        marginBottom: '7px',
        letterSpacing: '0.7px'
    },
    productModelNameDescription:{
        textAlign:'left',
        color: `rgb(187 187 184)`,
        fontSize: '14px',
        width: '11%',
    },
    extraChargeButtonWithIcon: {
        display: 'flex',
        alignItems:'center',
        padding: '0px 1px 1px 1px',
        cursor:'pointer',
    },
    extraChargeBtnLocation: {
        display: 'flex',
        alignItems:'center',
        padding:'10px 0',
        gap: '10px',
        cursor:'pointer'
    }
}));

export const amountPdfStyle = {
    amountSection: {
        width: '100%',
        display: 'flex',
        padding: '5px 0px 5px 0',
    },
    totalAmountSection: {
        width: '50%',
        margin: '0 40px'
    },
    amountLabel :{
        width: '50%'
    },
    amountLine: {
        width: '80%',
        margin: '4px 0 4px 0px',
        borderBlockStartColor: '#908989',
    }
}

export const activeLink = {
    color: '#f7991e',
}

export const navLinkStyle = {
    textDecoration:'none',
    color:'#0091ff'
}
 export const defaultColor = {
    color: '#0091ff',
 }

 export const disabled = {
    color: 'rgb(187 187 184)',
 }