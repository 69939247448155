import uuid from 'uuid'

export class AdditionalTest{
    constructor(systemId, testOrder){
            this._id = uuid()
            this.isActive = true
            this.icon = ''
            this.row_title = ''
            this.show_stopper = ''
            this.system_id = systemId
            this.order = testOrder
            this.values = [
              { typeOfInput: '-11', value: '' },
              { typeOfInput: '1', value: '' },
              { typeOfInput: 'read', value: '' },
              { typeOfInput: 'checkbox', value: false },
              { typeOfInput: 'text', value: '' },
              { typeOfInput: 'photo', value: [] },
              { typeOfInput: 'text', value: '' },
            ]
        }
}
