import React, { useState } from 'react'
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useStyles } from '../../styles/StylesForTables'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { convertIntoCurrency, getAllExtraCharges, getAmountIncludingExtraCharges, setNavigationUrl } from '../../../../hooks/helper';
import { QUOTE_STATUS_OPTIONS } from '../../../../constants/translations/customersPage';
import QuoteTabePageDropDown from '../../Quote/Parts/QuoteTabePageDropDown';
import { updateQuoteInfo } from '../../../../functions/api/customer-page';

const QuotationHistoryTableRow = ({ quote, customerIdHandler }) => {
  const { DRAFT, SENT_TO_CLIENT, MAKE_AN_ORDER, CLOSE } = QUOTE_STATUS_OPTIONS;
  const factoryName = useSelector((state) => state.login.user.factory_name)
  const history = useHistory()
  const {_id, order_number, value, status, date, customer_id, order_discount, extra_charges , client_name, client_phone, address} = quote
  const classes = useStyles()
  const showQuoteHandler = () => {
    setNavigationUrl(`${history.location.pathname}`);
    history.push(`/${factoryName}/customers-page/create-quote/${customer_id}/${_id}`)
  }

  const changeStatusHandler = (body) => {
    updateQuoteInfo(body).then(
      ({ ok, result }) => {
        ok && customerIdHandler();
      },
    );
  };

  return (
    <TableRow className = {classes.cursorPointer}>
      <TableCell size = 'medium' hover onClick = {showQuoteHandler}  className = {classes.tableBodyCellStyle} >{order_number}</TableCell>
      <TableCell size = 'medium' hover onClick = {showQuoteHandler} className = {classes.tableBodyCellStyle} >{client_name}</TableCell>
      <TableCell size = 'medium' hover onClick = {showQuoteHandler} className = {classes.tableBodyCellStyle} >{client_phone}</TableCell>
      <TableCell size = 'medium' hover onClick = {showQuoteHandler} className = {classes.tableBodyCellStyle} >{address}</TableCell>
      <TableCell size = 'medium' hover onClick = {showQuoteHandler} className = {classes.tableBodyCellStyle} >{convertIntoCurrency(getAmountIncludingExtraCharges(Number(Number(value) + Number(getAllExtraCharges(extra_charges) || 0) || 0), order_discount), 'ILS')}</TableCell>
      <TableCell size = 'medium' className = {classes.tableBodyCellStyle} >
        <QuoteTabePageDropDown
            key={_id}
            validate_all_form={false}
            title={false}
            data={[DRAFT, SENT_TO_CLIENT, MAKE_AN_ORDER, CLOSE]}
            updateForm={changeStatusHandler}
            name={"started"}
            selected_step={status || "בחר"}
            row_data={quote}
            width={'70%'}
          />
        </TableCell>
      <TableCell size = 'medium' hover onClick = {showQuoteHandler} className = {classes.tableBodyCellStyle} >{moment(date).format('DD/MM/YYYY')}</TableCell>
    </TableRow>
  )
}
export default QuotationHistoryTableRow