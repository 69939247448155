export const SET_USER_DATA = "set user data to reducer after api call"
export const SET_USER_TOKEN = "set user token to reducer after api call"
export const SET_ORDERS_AND_WEEKS_DATA = "set orders data and weeks data" 
export const SET_ORDERS_DATA  = "set orders data" 
export const ADD_ORDER_TO_ORDERS_DATA = "add (push) order to the orders data after adding client" 
export const CHANGE_PAGE_NUMBER_FOR_API = "change page number for api req " 
export const SET_ROUTE_LOCATION = "set the current route location"
export const UPDATE_LOADER = "turn off/on loader popup"
export const UPDATE_WARNING_POPUP = "turn off/on warnning popup for order reposition"
export const SAVE_DEPARTMENTS = 'SAVE_DEPARTMENTS'
export const SAVE_SELECTED_DEPARTMENT = 'SAVE_SELECTED_DEPARTMENT'
export const SAVE_SELECTED_USER = 'SAVE_SELECTED_USER'
export const POPUP_ACTIVATION = 'POPUP_ACTIVATION'
export const SET_ADD_ORDER_PAYLOAD = 'SET_ADD_ORDER_PAYLOAD'
export const SAVE_NEW_ORDER_ID = 'SAVE_NEW_ORDER_ID'
export const SET_ORDER_DATA_TO_UPDATE_POPUP = 'SET_ORDER_DATA_TO_UPDATE_POPUP'
export const UPDATE_ORDER_IN_UI = 'UPDATE_ORDER_IN_UI'
export const RESET_POPUP_DATA = 'RESET_POPUP_DATA'
export const UPDATE_ORDER_ANNUAL_VIEW = 'UPDATE_ORDER_ANNUAL_VIEW'
export const ORDER_NUMBER_SEARCH_FOR_ORDER_PROCESS_REPORT = 'ORDER_NUMBER_SEARCH_FOR_ORDER_PROCESS_REPORT'
 
export const CANCLE_BLOCK_RENDER = 'CANCLE_BLOCK_RENDER'
export const SET_CSV_WEEK_HEADERS = 'SET_CSV_WEEK_HEADERS'
export const SET_CSV_WEEK_DATA = 'SET_CSV_WEEK_DATA'
export const SET_CSV_MONTHLY = "SET_CSV_MONTHLY"
export const SET_CSV_CUSTOMER = "SET_CSV_CUSTOMER";

export const UPDATE_SPREAD_POPUP = "UPDATE_SPREAD_POPUP";
export const UPDATE_SPREAD_GREATER_OR_SMALLER =
  "UPDATE_SPREAD_GREATER_OR_SMALLER";

export const UPDATE_REASON_POPUP = "UPDATE_REASON_POPUP";

export const SET_ORDER_ID_TO_UPDATE_POPUP = "SET_ORDER_ID_TO_UPDATE_POPUP";
export const GET_RECIPIENT_REASONE = "GET_RECIPIENT_REASONE";

export const SHOW_POPUP = "SHOW_MAIN_POPUP";
export const UPDATE_PROCESS = "UPDATE_PROCESS";
export const SHOW_ORDER_POPUP = "SHOW_ORDER_POPUP";

export const UPDATE_IS_LOGIN = "UPDATE_IS_LOGIN";
export const SAVE_SELECTED_MANAGER= "SAVE_SELECTED_MANAGER"
export const FIRST_UNCOMPLETE_PROCESS_TOGGLE= "FIRST_UNCOMPLETE_PROCESS_TOGGLE"

export const SET_ALERT_POPUP_MESSAGE= "SET_ALERT_POPUP_MESSAGE"
export const ALERT_POPUP_TOGGLE= "ALERT_POPUP_TOGGLE"
export const ADD_NEW_ORDER_TO_THE_TOP = "ADD_NEW_ORDER_TO_THE_TOP"


export const SHOW_BIDS_TOGGLE = "SHOW_BIDS_TOGGLE"

export const UPDATE_CONSTANT_POPUP = "UPDATE_CONSTANT_POPUP"


export const SET_ORDER_AFTER_EDIT_FROM_RESERVATION_POPUP = "SET_ORDER_AFTER_EDIT_FROM_RESERVATION_POPUP"

export const SET_NEW_DUE_DATE_ORDER_AFTER_EDIT_FROM_RESERVATION_POPUP = "SET_NEW_DUE_DATE_ORDER_AFTER_EDIT_FROM_RESERVATION_POPUP"

export const SAVE_EDDITED_ORDER_ID_FOR_CONST_REPOSITION = 'SAVE_EDDITED_ORDER_ID_FOR_CONST_REPOSITION'
export const SET_ORDER_AFTER_EDIT_FROM_CUSTOMERS_PAGE = 'SET_ORDER_AFTER_EDIT_FROM_CUSTOMERS_PAGE'
export const SET_ORDER_PROCESSES_FOR_CUSTOMER_PAGE = 'SET_ORDER_PROCESSES_FOR_CUSTOMER_PAGE'


// modal

export const SET_MODAL_PROPS = 'SET_MODAL_PROPS'

// factory
export const GET_ALL_FACTORY_REQUEST = "GET_ALL_FACTORY_REQUEST"
export const GET_ALL_FACTORY_SUCCESS = "GET_ALL_FACTORY_SUCCESS"
export const GET_ALL_FACTORY_ERROR = "GET_ALL_FACTORY_ERROR"


export const GET_BY_ID_FACTORY_REQUEST = "GET_BY_ID_FACTORY_REQUEST"
export const GET_BY_ID_FACTORY_SUCCESS = "GET_BY_ID_FACTORY_SUCCESS"
export const GET_BY_ID_FACTORY_ERROR = "GET_BY_ID_FACTORY_ERROR"

export const FACTORY_CREATE_REQUEST = "FACTORY_CREATE_REQUEST"
export const FACTORY_CREATE_SUCCESS = "FACTORY_CREATE_SUCCESS"
export const FACTORY_CREATE_ERROR = "FACTORY_CREATE_ERROR"

export const FACTORY_UPDATE_REQUEST = "FACTORY_UPDATE_REQUEST"
export const FACTORY_UPDATE_SUCCESS = "FACTORY_UPDATE_SUCCESS"
export const FACTORY_UPDATE_ERROR = "FACTORY_UPDATE_ERROR"
export const GANTT_TEMPLATE_TYPES = "GANTT_TEMPLATE_TYPES"
export const UPDATE_ORDER_PROCESSES_REPORT = "ORDER_PROCESSES_REPORT"

export const SET_FFACTORY = "SET_FFACTORY"
export const SET_FACTORY_FILTER_VALUE ="SET_FACTORY_FILTER_VALUE"


//department
export const GET_ALL_DEPARTMENT_REQUEST = "GET_ALL_DEPARTMENT_REQUEST"
export const GET_ALL_DEPARTMENT_SUCCESS = "GET_ALL_DEPARTMENT_SUCCESS"
export const GET_ALL_DEPARTMENT_ERROR = "GET_ALL_DEPARTMENT_ERROR"

export const GET_BY_ID_DEPARTMENT_REQUEST = "GET_BY_ID_DEPARTMENT_REQUEST"
export const GET_BY_ID_DEPARTMENT_SUCCESS = "GET_BY_ID_DEPARTMENT_SUCCESS"
export const GET_BY_ID_DEPARTMENT_ERROR = "GET_BY_ID_DEPARTMENT_ERROR"

export const DEPARTMENT_CREATE_REQUEST = "DEPARTMENT_CREATE_REQUEST"
export const DEPARTMENT_CREATE_SUCCESS = "DEPARTMENT_CREATE_SUCCESS"
export const DEPARTMENT_CREATE_ERROR = "DEPARTMENT_CREATE_ERROR"

export const DEPARTMENT_UPDATE_REQUEST = "DEPARTMENT_UPDATE_REQUEST"
export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS"
export const DEPARTMENT_UPDATE_ERROR = "DEPARTMENT_UPDATE_ERROR"

export const DEPARTMENT_GET_BY_FACTORY_ID_REQUEST = "DEPARTMENT_GET_BY_FACTORY_ID_REQUEST"
export const DEPARTMENT_GET_BY_FACTORY_ID_SUCCESS = "DEPARTMENT_GET_BY_FACTORY_ID_SUCCESS"
export const DEPARTMENT_GET_BY_FACTORY_ID_ERROR = "DEPARTMENT_GET_BY_FACTORY_ID_ERROR"

export const SET_DEPARTMENT = "SET_DEPARTMENT"
export const SET_DEPARTMENT_FILTER_VALUE = "SET_DEPARTMENT_FILTER_VALUE"

export const ADMIN_GET_FILTER_DEPARTMENT_REQUEST =  "ADMIN_GET_FILTER_DEPARTMENT_REQUEST"
export const ADMIN_GET_FILTER_DEPARTMENT_SUCCESS =  "ADMIN_GET_FILTER_DEPARTMENT_SUCCESS"
export const ADMIN_GET_FILTER_DEPARTMENT_ERROR =  "ADMIN_GET_FILTER_DEPARTMENT_ERROR"

export const DEPARTMENT_CHANGE_CURRENT_PAGE =  "DEPARTMENT_CHANGE_CURRENT_PAGE"
export const DEPARTMENT_CHANGE_ROWS_PER_PAGE =  "DEPARTMENT_CHANGE_ROWS_PER_PAGE"

//sud_department
export const GET_ALL_SUB_DEPARTMENT_REQUEST = "GET_ALL_SUB_DEPARTMENT_REQUEST"
export const GET_ALL_SUB_DEPARTMENT_SUCCESS = "GET_ALL_SUB_DEPARTMENT_SUCCESS"
export const GET_ALL_SUB_DEPARTMENT_ERROR = "GET_ALL_SUB_DEPARTMENT_ERROR"

export const GET_BY_ID_SUB_DEPARTMENT_REQUEST = "GET_BY_ID_SUB_DEPARTMENT_REQUEST"
export const GET_BY_ID_SUB_DEPARTMENT_SUCCESS = "GET_BY_ID_SUB_DEPARTMENT_SUCCESS"
export const GET_BY_ID_SUB_DEPARTMENT_ERROR = "GET_BY_ID_SUB_DEPARTMENT_ERROR"

export const SUB_DEPARTMENT_CREATE_REQUEST = "SUB_DEPARTMENT_CREATE_REQUEST"
export const SUB_DEPARTMENT_CREATE_SUCCESS = "SUB_DEPARTMENT_CREATE_SUCCESS"
export const SUB_DEPARTMENT_CREATE_ERROR = "SUB_DEPARTMENT_CREATE_ERROR"

export const SUB_DEPARTMENT_UPDATE_REQUEST = "SUB_DEPARTMENT_UPDATE_REQUEST"
export const SUB_DEPARTMENT_UPDATE_SUCCESS = "SUB_DEPARTMENT_UPDATE_SUCCESS"
export const SUB_DEPARTMENT_UPDATE_ERROR = "SUB_DEPARTMENT_UPDATE_ERROR"

export const SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_REQUEST = "SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_REQUEST"
export const SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_SUCCESS = "SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_SUCCESS"
export const SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_ERROR = "SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_ERROR"

export const SUB_DEPARTMENT_GET_BY_FACTORY_ID_REQUEST = "SUB_DEPARTMENT_GET_BY_FACTORY_ID_REQUEST"
export const SUB_DEPARTMENT_GET_BY_FACTORY_ID_SUCCESS = "SUB_DEPARTMENT_GET_BY_FACTORY_ID_SUCCESS"
export const SUB_DEPARTMENT_GET_BY_FACTORY_ID_ERROR = "SUB_DEPARTMENT_GET_BY_FACTORY_ID_ERROR"

export const SET_SUB_DEPARTMENT = "SET_SUB_DEPARTMENT"

export const ADMIN_GET_FILTER_SUB_DEPARTMENT_REQUEST =  "ADMIN_GET_FILTER_SUB_DEPARTMENT_REQUEST"
export const ADMIN_GET_FILTER_SUB_DEPARTMENT_SUCCESS =  "ADMIN_GET_FILTER_SUB_DEPARTMENT_SUCCESS"
export const ADMIN_GET_FILTER_SUB_DEPARTMENT_ERROR =  "ADMIN_GET_FILTER_SUB_DEPARTMENT_ERROR"

export const SUB_DEPARTMENT_CHANGE_CURRENT_PAGE =  "SUB_DEPARTMENT_CHANGE_CURRENT_PAGE"
export const SUB_DEPARTMENT_CHANGE_ROWS_PER_PAGE =  "SUB_DEPARTMENT_CHANGE_ROWS_PER_PAGE"


//employee
export const GET_ALL_RESOURCES_REQUEST = "GET_ALL_RESOURCES_REQUEST"
export const GET_ALL_RESOURCES_SUCCESS = "GET_ALL_RESOURCES_SUCCESS"
export const GET_ALL_RESOURCES_ERROR = "GET_ALL_RESOURCES_ERROR"

export const GET_BY_ID_RESOURCE_REQUEST = "GET_BY_ID_RESOURCE_REQUEST"
export const GET_BY_ID_RESOURCE_SUCCESS = "GET_BY_ID_RESOURCE_SUCCESS"
export const GET_BY_ID_RESOURCE_ERROR = "GET_BY_ID_RESOURCE_ERROR"

export const RESOURCE_CREATE_REQUEST = "RESOURCE_CREATE_REQUEST"
export const RESOURCE_CREATE_SUCCESS = "RESOURCE_CREATE_SUCCESS"
export const RESOURCE_CREATE_ERROR = "RESOURCE_CREATE_ERROR"

export const RESOURCE_UPDATE_REQUEST = "RESOURCE_UPDATE_REQUEST"
export const RESOURCE_UPDATE_SUCCESS = "RESOURCE_UPDATE_SUCCESS"
export const RESOURCE_UPDATE_ERROR = "RESOURCE_UPDATE_ERROR"

export const RESOURCES_GET_BY_FACTORY_ID_REQUEST = "RESOURCES_GET_BY_FACTORY_ID_REQUEST"
export const RESOURCES_GET_BY_FACTORY_ID_SUCCESS = "RESOURCES_GET_BY_FACTORY_ID_SUCCESS"
export const RESOURCES_GET_BY_FACTORY_ID_ERROR = "RESOURCES_GET_BY_FACTORY_ID_ERROR"
export const SET_RESOURCE = "SET_RESOURCE"

export const ADMIN_GET_FILTER_RESOURCES_REQUEST =  "ADMIN_GET_FILTER_RESOURCES_REQUEST"
export const ADMIN_GET_FILTER_RESOURCES_SUCCESS =  "ADMIN_GET_FILTER_RESOURCES_SUCCESS"
export const ADMIN_GET_FILTER_RESOURCES_ERROR =  "ADMIN_GET_FILTER_RESOURCES_ERROR"

export const RESOURCES_CHANGE_CURRENT_PAGE =  "RESOURCES_CHANGE_CURRENT_PAGE"
export const RESOURCES_CHANGE_ROWS_PER_PAGE =  "RESOURCES_CHANGE_ROWS_PER_PAGE"

export const RESOURCE_DROP_REQUEST = "RESOURCE_DROP_REQUEST"
export const RESOURCE_DROP_SUCCESS = "RESOURCE_DROP_SUCCESS"
export const RESOURCE_DROP_ERROR = "RESOURCE_DROP_ERROR"

export const SET_RESOURCES_LIST = 'SET_RESOURCES_LIST'
export const SET_USER_LIST = 'SET_USER_LIST'




//recipient
export const GET_ALL_RECIPIENTS_REQUEST = "GET_ALL_RECIPIENTS_REQUEST"
export const GET_ALL_RECIPIENTS_SUCCESS = "GET_ALL_RECIPIENTS_SUCCESS"
export const GET_ALL_RECIPIENTS_ERROR = "GET_ALL_RECIPIENTS_ERROR"

export const GET_BY_ID_RECIPIENT_REQUEST = "GET_BY_ID_RECIPIENT_REQUEST"
export const GET_BY_ID_RECIPIENT_SUCCESS = "GET_BY_ID_RECIPIENT_SUCCESS"
export const GET_BY_ID_RECIPIENT_ERROR = "GET_BY_ID_RECIPIENT_ERROR"

export const RECIPIENT_CREATE_REQUEST = "RECIPIENT_CREATE_REQUEST"
export const RECIPIENT_CREATE_SUCCESS = "RECIPIENT_CREATE_SUCCESS"
export const RECIPIENT_CREATE_ERROR = "RECIPIENT_CREATE_ERROR"

export const RECIPIENT_UPDATE_REQUEST = "RECIPIENT_UPDATE_REQUEST"
export const RECIPIENT_UPDATE_SUCCESS = "RECIPIENT_UPDATE_SUCCESS"
export const RECIPIENT_UPDATE_ERROR = "RECIPIENT_UPDATE_ERROR"

export const RECIPIENTS_GET_BY_FACTORY_ID_REQUEST = "RECIPIENTS_GET_BY_FACTORY_ID_REQUEST"
export const RECIPIENTS_GET_BY_FACTORY_ID_SUCCESS = "RECIPIENTS_GET_BY_FACTORY_ID_SUCCESS"
export const RECIPIENTS_GET_BY_FACTORY_ID_ERROR = "RECIPIENTS_GET_BY_FACTORY_ID_ERROR"

export const ADMIN_GET_FILTER_RECIPIENTS_REQUEST =  "ADMIN_GET_FILTER_RECIPIENTS_REQUEST"
export const ADMIN_GET_FILTER_RECIPIENTS_SUCCESS =  "ADMIN_GET_FILTER_RECIPIENTS_SUCCESS"
export const ADMIN_GET_FILTER_RECIPIENTS_ERROR =  "ADMIN_GET_FILTER_RECIPIENTS_ERROR"

export const RECIPIENTS_CHANGE_CURRENT_PAGE =  "RECIPIENTS_CHANGE_CURRENT_PAGE"
export const RECIPIENTS_CHANGE_ROWS_PER_PAGE =  "RECIPIENTS_CHANGE_ROWS_PER_PAGE"

export const SET_RECIPIENT = "SET_RECIPIENT"


//order-type
export const GET_ALL_ORDER_TYPES_REQUEST = "GET_ALL_ORDER_TYPES_REQUEST"
export const GET_ALL_ORDER_TYPES_SUCCESS = "GET_ALL_ORDER_TYPES_SUCCESS"
export const GET_ALL_ORDER_TYPES_ERROR = "GET_ALL_ORDER_TYPES_ERROR"

export const GET_BY_ID_ORDER_TYPE_REQUEST = "GET_BY_ID_ORDER_TYPE_REQUEST"
export const GET_BY_ID_ORDER_TYPE_SUCCESS = "GET_BY_ID_ORDER_TYPE_SUCCESS"
export const GET_BY_ID_ORDER_TYPE_ERROR = "GET_BY_ID_ORDER_TYPE_ERROR"

export const ORDER_TYPE_UPDATE_REQUEST = "ORDER_TYPE_UPDATE_REQUEST"
export const ORDER_TYPE_UPDATE_SUCCESS = "ORDER_TYPE_UPDATE_SUCCESS"
export const ORDER_TYPE_UPDATE_ERROR = "ORDER_TYPE_UPDATE_ERROR"

export const ORDER_TYPE_CREATE_REQUEST = "ORDER_TYPE_CREATE_REQUEST"
export const ORDER_TYPE_CREATE_SUCCESS = "ORDER_TYPE_CREATE_SUCCESS"
export const ORDER_TYPE_CREATE_ERROR = "ORDER_TYPE_CREATE_ERROR"

export const ORDER_TYPES_GET_BY_FACTORY_ID_REQUEST = "ORDER_TYPES_GET_BY_FACTORY_ID_REQUEST"
export const ORDER_TYPES_GET_BY_FACTORY_ID_SUCCESS = "ORDER_TYPES_GET_BY_FACTORY_ID_SUCCESS"
export const ORDER_TYPES_GET_BY_FACTORY_ID_ERROR = "ORDER_TYPES_GET_BY_FACTORY_ID_ERROR"

export const ADMIN_GET_FILTER_ORDER_TYPES_REQUEST =  "ADMIN_GET_FILTER_ORDER_TYPES_REQUEST"
export const ADMIN_GET_FILTER_ORDER_TYPES_SUCCESS =  "ADMIN_GET_FILTER_ORDER_TYPES_SUCCESS"
export const ADMIN_GET_FILTER_ORDER_TYPES_ERROR =  "ADMIN_GET_FILTER_ORDER_TYPES_ERROR"

export const ORDER_TYPES_CHANGE_CURRENT_PAGE =  "ORDER_TYPES_CHANGE_CURRENT_PAGE"
export const ORDER_TYPES_CHANGE_ROWS_PER_PAGE =  "ORDER_TYPES_CHANGE_ROWS_PER_PAGE"

export const SET_ORDER_TYPE = "SET_ORDER_TYPE"

// export const GET_ORDER_REPORTS = "GET_ORDER_REPORTS";
export const SET_ORDER_REPORTS = "SET_ORDER_REPORTS";
export const RESET_ORDER_REPORTS = "RESET_ORDER_REPORTS";
export const ADD_NEW_ORDER_REPORT_RECORDS = "ADD_NEW_ORDER_REPORT_RECORDS";

//processes
export const GET_ALL_PROCESSES_REQUEST = "GET_ALL_PROCESSES_REQUEST"
export const GET_ALL_PROCESSES_SUCCESS = "GET_ALL_PROCESSES_SUCCESS"
export const GET_ALL_PROCESSES_ERROR = "GET_ALL_PROCESSES_ERROR"

export const GET_BY_ID_PROCESS_REQUEST = "GET_BY_ID_PROCESS_REQUEST"
export const GET_BY_ID_PROCESS_SUCCESS = "GET_BY_ID_PROCESS_SUCCESS"
export const GET_BY_ID_PROCESS_TYPE_ERROR = "GET_BY_ID_PROCESS_TYPE_ERROR"

export const PROCESS_UPDATE_REQUEST = "PROCESS_UPDATE_REQUEST"
export const PROCESS_UPDATE_SUCCESS = "PROCESS_UPDATE_SUCCESS"
export const PROCESS_UPDATE_ERROR = "PROCESS_UPDATE_ERROR"

export const PROCESS_CREATE_REQUEST = "PROCESS_CREATE_REQUEST"
export const PROCESS_CREATE_SUCCESS = "PROCESS_CREATE_SUCCESS"
export const PROCESS_CREATE_ERROR = "PROCESS_CREATE_ERROR"

export const PROCESS_GET_BY_FACTORY_ID_REQUEST = "PROCESS_GET_BY_FACTORY_ID_REQUEST"
export const PROCESS_GET_BY_FACTORY_ID_SUCCESS = "PROCESS_GET_BY_FACTORY_ID_SUCCESS"
export const PROCESS_GET_BY_FACTORY_ID_ERROR = "PROCESS_GET_BY_FACTORY_ID_ERROR"

export const PROCESSES_GET_BY_SUB_DEPARTMENT_ID_REQUEST = "PROCESSES_GET_BY_SUB_DEPARTMENT_ID_REQUEST"
export const PROCESSES_GET_BY_SUB_DEPARTMENT_ID_SUCCESS = "PROCESSES_GET_BY_SUB_DEPARTMENT_ID_SUCCESS"
export const PROCESSES_GET_BY_SUB_DEPARTMENT_ID_ERROR = "PROCESSES_GET_BY_SUB_DEPARTMENT_ID_ERROR"

export const SET_PROCESS = "SET_PROCESS"

export const PROCESS_DELETE_REQUEST = "PROCESS_DELETE_REQUEST"
export const PROCESS_DELETE_SUCCESS = "PROCESS_DELETE_SUCCESS"
export const PROCESS_DELETE_ERROR = "PROCESS_DELETE_ERROR"

export const ADMIN_GET_FILTER_PROCESSES_REQUEST =  "ADMIN_GET_FILTER_PROCESSES_REQUEST"
export const ADMIN_GET_FILTER_PROCESSES_SUCCESS =  "ADMIN_GET_FILTER_PROCESSES_SUCCESS"
export const ADMIN_GET_FILTER_PROCESSES_ERROR =  "ADMIN_GET_FILTER_PROCESSES_ERROR"

export const GANTT_TEMPLATE_CHANGE_CURRENT_PAGE =  "GANTT_TEMPLATE_CHANGE_CURRENT_PAGE"
export const GANTT_TEMPLATE_CHANGE_ROWS_PER_PAGE =  "GANTT_TEMPLATE_CHANGE_ROWS_PER_PAGE"


//order
export const GET_ALL_ORDERS_REQUEST = "GET_ALL_ORDERS_REQUEST"
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS"
export const GET_ALL_ORDERS_ERROR = "GET_ALL_ORDERS_ERROR"

export const GET_BY_ID_ORDER_REQUEST = "GET_BY_ID_ORDER_REQUEST"
export const GET_BY_ID_ORDER_SUCCESS = "GET_BY_ID_ORDER_SUCCESS"
export const GET_BY_ID_ORDER_ERROR = "GET_BY_ID_ORDER_ERROR"

export const GET_ONGOING_ORDERS_REQUEST = "GET_ONGOING_ORDERS_REQUEST"
export const GET_ONGOING_ORDERS_SUCCESS = "GET_ONGOING_ORDERS_SUCCESS"
export const GET_ONGOING_ORDERS_ERROR = "GET_ONGOING_ORDERS_ERROR"

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST"
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS"
export const ORDER_UPDATE_ERROR = "ORDER_UPDATE_ERROR"

export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST"
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS"
export const ORDER_CREATE_ERROR = "ORDER_CREATE_ERROR"

export const ORDER_GET_BY_FACTORY_ID_REQUEST = "ORDER_GET_BY_FACTORY_ID_REQUEST"
export const ORDER_GET_BY_FACTORY_ID_SUCCESS = "ORDER_GET_BY_FACTORY_ID_SUCCESS"
export const ORDER_GET_BY_FACTORY_ID_ERROR = "ORDER_GET_BY_FACTORY_ID_ERROR"

export const SET_ORDER = "SET_ORDER"


//order_processes
export const GET_ALL_ORDER_PROCESSES_REQUEST = "GET_ALL_ORDER_PROCESSES_REQUEST"
export const GET_ALL_ORDER_PROCESSES_SUCCESS = "GET_ALL_ORDER_PROCESSES_SUCCESS"
export const GET_ALL_ORDER_PROCESSES_ERROR = "GET_ALL_ORDER_PROCESSES_ERROR"

export const GET_BY_ID_ORDER_PROCESS_REQUEST = "GET_BY_ID_ORDER_PROCESS_REQUEST"
export const GET_BY_ID_ORDER_PROCESS_SUCCESS = "GET_BY_ID_ORDER_PROCESS_SUCCESS"
export const GET_BY_ID_ORDER_PROCESS_ERROR = "GET_BY_ID_ORDER_PROCESS_ERROR"

export const ORDER_PROCESS_UPDATE_REQUEST = "ORDER_PROCESS_UPDATE_REQUEST"
export const ORDER_PROCESS_UPDATE_SUCCESS = "ORDER_PROCESS_UPDATE_SUCCESS"
export const ORDER_PROCESS_UPDATE_ERROR = "ORDER_PROCESS_UPDATE_ERROR"

export const ORDER_PROCESS_CREATE_REQUEST = "ORDER_PROCESS_CREATE_REQUEST"
export const ORDER_PROCESS_CREATE_SUCCESS = "ORDER_PROCESS_CREATE_SUCCESS"
export const ORDER_PROCESS_CREATE_ERROR = "ORDER_PROCESS_CREATE_ERROR"

export const ORDER_PROCESSES_GET_BY_FACTORY_ID_REQUEST = "ORDER_PROCESSES_GET_BY_FACTORY_ID_REQUEST"
export const ORDER_PROCESSES_GET_BY_FACTORY_ID_SUCCESS = "ORDER_PROCESSES_GET_BY_FACTORY_ID_SUCCESS"
export const ORDER_PROCESSES_GET_BY_FACTORY_ID_ERROR = "ORDER_PROCESSES_GET_BY_FACTORY_ID_ERROR"

export const SET_ORDER_PROCESSES = "SET_ORDER_PROCESSES"

export const ADMIN_GET_FILTER_ORDER_PROCESSES_REQUEST =  "ADMIN_GET_FILTER_ORDER_PROCESSES_REQUEST"
export const ADMIN_GET_FILTER_ORDER_PROCESSES_SUCCESS =  "ADMIN_GET_FILTER_ORDER_PROCESSES_SUCCESS"
export const ADMIN_GET_FILTER_ORDER_PROCESSES_ERROR =  "ADMIN_GET_FILTER_ORDER_PROCESSES_ERROR"

export const GANTT_ACTUAL_CHANGE_CURRENT_PAGE =  "GANTT_ACTUAL_CHANGE_CURRENT_PAGE"
export const GANTT_ACTUAL_CHANGE_ROWS_PER_PAGE =  "GANTT_ACTUAL_CHANGE_ROWS_PER_PAGE"
export const UPDATE_DATES_ARRAY_MONTH_RESOURCE =  "UPDATE_DATES_ARRAY_MONTH_RESOURCE"
export const UPDATE_MONTH_RESOURCE_LOADER = "UPDATE_MONTH_RESOURCE_LOADER"
export const UPDATE_MONTH_RESOURCE_SIMPLE_DATE = "UPDATE_MONTH_RESOURCE_SIMPLE_DATE"
export const MONTH_RESOURCE_CALENDER_UPDATE = "MONTH_RESOURCE_CALENDER_UPDATE"
export const MONTH_GLOBAL_STATE_MANAGER = "MONTH_GLOBAL_STATE_MANAGER"
export const MONTH_UPDATE_API = "MONTH_UPDATE_API"
export const CUSTOMER_CHECK_LIST = "CUSTOMER_CHECK_LIST" 

//order-category
export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST"
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS"
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR"

export const GET_BY_ID_CATEGORY_REQUEST = "GET_BY_ID_CATEGORY_REQUEST"
export const GET_BY_ID_CATEGORY_SUCCESS = "GET_BY_ID_CATEGORY_SUCCESS"
export const GET_BY_ID_CATEGORY_ERROR = "GET_BY_ID_CATEGORY_ERROR"

export const ORDER_CATEGORY_UPDATE_REQUEST = "ORDER_CATEGORY_UPDATE_REQUEST"
export const ORDER_CATEGORY_UPDATE_SUCCESS = "ORDER_CATEGORY_UPDATE_SUCCESS"
export const ORDER_CATEGORY_UPDATE_ERROR = "ORDER_CATEGORY_UPDATE_ERROR"

export const ORDER_CATEGORY_CREATE_REQUEST = "ORDER_CATEGORY_CREATE_REQUEST"
export const ORDER_CATEGORY_CREATE_SUCCESS = "ORDER_CATEGORY_CREATE_SUCCESS"
export const ORDER_CATEGORY_CREATE_ERROR = "ORDER_CATEGORY_CREATE_ERROR"

export const ORDER_CATEGORIES_GET_BY_FACTORY_ID_REQUEST = "ORDER_CATEGORIES_GET_BY_FACTORY_ID_REQUEST"
export const ORDER_CATEGORIES_GET_BY_FACTORY_ID_SUCCESS = "ORDER_CATEGORIES_GET_BY_FACTORY_ID_SUCCESS"
export const ORDER_CATEGORIES_GET_BY_FACTORY_ID_ERROR = "ORDER_CATEGORIES_GET_BY_FACTORY_ID_ERROR"

export const ADMIN_GET_FILTER_ORDER_CATEGORIES_REQUEST =  "ADMIN_GET_FILTER_ORDER_CATEGORIES_REQUEST"
export const ADMIN_GET_FILTER_ORDER_CATEGORIES_SUCCESS =  "ADMIN_GET_FILTER_ORDER_CATEGORIES_SUCCESS"
export const ADMIN_GET_FILTER_ORDER_CATEGORIES_ERROR =  "ADMIN_GET_FILTER_ORDER_CATEGORIES_ERROR"

export const ORDER_CATEGORIES_CHANGE_CURRENT_PAGE =  "ORDER_CATEGORIES_CHANGE_CURRENT_PAGE"
export const ORDER_CATEGORIES_CHANGE_ROWS_PER_PAGE =  "ORDER_CATEGORIES_CHANGE_ROWS_PER_PAGE"
export const SET_ORDER_CATEGORY = "SET_ORDER_CATEGORY"

//user
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR"

export const GET_BY_ID_USER_REQUEST = "GET_BY_ID_USER_REQUEST"
export const GET_BY_ID_USER_SUCCESS = "GET_BY_ID_USER_SUCCESS"
export const GET_BY_ID_USER_ERROR = "GET_BY_ID_USER_ERROR"

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR"

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST"
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS"
export const USER_CREATE_ERROR = "USER_CREATE_ERROR"

export const USERS_GET_BY_FACTORY_ID_REQUEST = "USERS_GET_BY_FACTORY_ID_REQUEST"
export const USERS_GET_BY_FACTORY_ID_SUCCESS = "USERS_GET_BY_FACTORY_ID_SUCCESS"
export const USERS_GET_BY_FACTORY_ID_ERROR = "USERS_GET_BY_FACTORY_ID_ERROR"

export const ADMIN_GET_FILTER_USERS_REQUEST =  "ADMIN_GET_FILTER_USERS_REQUEST"
export const ADMIN_GET_FILTER_USERS_SUCCESS =  "ADMIN_GET_FILTER_USERS_SUCCESS"
export const ADMIN_GET_FILTER_USERS_ERROR =  "ADMIN_GET_FILTER_USERS_ERROR"

export const USERS_CHANGE_CURRENT_PAGE =  "USERS_CHANGE_CURRENT_PAGE"
export const USERS_CHANGE_ROWS_PER_PAGE =  "USERS_CHANGE_ROWS_PER_PAGE"
export const SET_USER = "SET_USER"
// export const ADD_SUB_DEPARTMENTS_INTO_USER = 'ADD_SUB_DEPARTMENTS_INTO_USER'


//order-type
export const GET_BY_ID_ORDER_COLLECTION_STAGE_REQUEST = "GET_BY_ID_ORDER_COLLECTION_STAGE_REQUEST"
export const GET_BY_ID_ORDER_COLLECTION_STAGE_SUCCESS = "GET_BY_ID_ORDER_COLLECTION_STAGE_SUCCESS"
export const GET_BY_ID_ORDER_COLLECTION_STAGE_ERROR = "GET_BY_ID_ORDER_COLLECTION_STAGE_ERROR"

export const ORDER_COLLECTION_STAGE_UPDATE_REQUEST = "ORDER_COLLECTION_STAGE_UPDATE_REQUEST"
export const ORDER_COLLECTION_STAGE_UPDATE_SUCCESS = "ORDER_COLLECTION_STAGE_UPDATE_SUCCESS"
export const ORDER_COLLECTION_STAGE_UPDATE_ERROR = "ORDER_COLLECTION_STAGE_UPDATE_ERROR"

export const ORDER_COLLECTION_STAGE_CREATE_REQUEST = "ORDER_COLLECTION_STAGE_CREATE_REQUEST"
export const ORDER_COLLECTION_STAGE_CREATE_SUCCESS = "ORDER_COLLECTION_STAGE_CREATE_SUCCESS"
export const ORDER_COLLECTION_STAGE_CREATE_ERROR = "ORDER_COLLECTION_STAGE_CREATE_ERROR"

export const ADMIN_GET_FILTER_ORDER_COLLECTION_STAGES_REQUEST =  "ADMIN_GET_FILTER_ORDER_COLLECTION_STAGES_REQUEST"
export const ADMIN_GET_FILTER_ORDER_COLLECTION_STAGES_SUCCESS =  "ADMIN_GET_FILTER_ORDER_COLLECTION_STAGES_SUCCESS"
export const ADMIN_GET_FILTER_ORDER_COLLECTION_STAGES_ERROR =  "ADMIN_GET_FILTER_ORDER_COLLECTION_STAGES_ERROR"

export const ORDER_COLLECTION_STAGES_CHANGE_CURRENT_PAGE =  "ORDER_COLLECTION_STAGES_CHANGE_CURRENT_PAGE"
export const ORDER_COLLECTION_STAGES_CHANGE_ROWS_PER_PAGE =  "ORDER_COLLECTION_STAGES_CHANGE_ROWS_PER_PAGE"

export const SET_ORDER_COLLECTION_STAGES = "SET_ORDER_COLLECTION_STAGES"

// customers page

export const GET_CUSTOMERS_INFO_REQUEST = 'GET_CUSTOMERS_INFO_REQUEST'
export const GET_CUSTOMERS_INFO_SUCCESS = 'GET_CUSTOMERS_INFO_SUCCESS'
export const GET_CUSTOMERS_INFO_ERROR = 'GET_CUSTOMERS_INFO_ERROR'

export const CREATE_NEW_CUSTOMER_REQUEST = 'CREATE_NEW_CUSTOMER_REQUEST'
export const CREATE_NEW_CUSTOMER_SUCCESS = 'CREATE_NEW_CUSTOMER_SUCCESS'
export const CREATE_NEW_CUSTOMER_ERROR = 'CREATE_NEW_CUSTOMER_ERROR'

export const GET_CUSTOMER_INFO_BY_ID_REQUEST = 'GET_CUSTOMER_INFO_BY_ID_REQUEST'
export const GET_CUSTOMER_INFO_BY_ID_SUCCESS = 'GET_CUSTOMER_INFO_BY_ID_SUCCESS'
export const GET_CUSTOMER_INFO_BY_ID_ERROR = 'GET_CUSTOMER_INFO_BY_ID_ERROR'

export const ADD_NEW_ACTUAL_SYSTEM_REQUEST = 'ADD_NEW_ACTUAL_SYSTEM_REQUEST'
export const ADD_NEW_ACTUAL_SYSTEM_SUCCESS = 'ADD_NEW_ACTUAL_SYSTEM_SUCCESS'
export const ADD_NEW_ACTUAL_SYSTEM_ERROR = 'ADD_NEW_ACTUAL_SYSTEM_ERROR'

export const EDIT_ACTUAL_SYSTEM_REQUEST = 'EDIT_ACTUAL_SYSTEM_REQUEST'
export const EDIT_ACTUAL_SYSTEM_SUCCESS = 'EDIT_ACTUAL_SYSTEM_SUCCESS'
export const EDIT_ACTUAL_SYSTEM_ERROR = 'EDIT_ACTUAL_SYSTEM_ERROR'

export const DELETE_ACTUAL_SYSTEM_REQUEST = 'DELETE_ACTUAL_SYSTEM_REQUEST'
export const DELETE_ACTUAL_SYSTEM_SUCCESS = 'DELETE_ACTUAL_SYSTEM_SUCCESS'
export const DELETE_ACTUAL_SYSTEM_ERROR = 'DELETE_ACTUAL_SYSTEM_ERROR'

export const CREATE_NEW_ORDER_FROM_CUSTOMER_PAGE_SUCCESS = 'CREATE_NEW_ORDER_FROM_CUSTOMER_PAGE_SUCCESS'

export const RESET_CUSTOMER_DATA = 'RESET_CUSTOMER_DATA'
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA'
export const SET_VALUE_FOR_CUSTOMERS_FILTERING = 'SET_VALUE_FOR_CUSTOMERS_FILTERING'
export const SET_QUERY_FOR_AUTOCOMPLETE = 'SET_QUERY_FOR_AUTOCOMPLETE'
export const SET_OPTIONS_FOR_AUTOCOMPLETE = 'SET_OPTIONS_FOR_AUTOCOMPLETE'

export const UPDATE_CUSTOMER_FIRST_LEVEL_SYSTEMS = 'UPDATE_CUSTOMER_FIRST_LEVEL_SYSTEMS'

//UPDATE STAGES FOR FINANCIAL REPORT

export const ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_REQUEST = 'ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_REQUEST'
export const ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_SUCCESS = 'ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_SUCCESS'
export const ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_ERROR = 'ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_ERROR'
export const ORDER_UPDATE_STAGE_INVOICE_REQUEST = 'ORDER_UPDATE_STAGE_INVOICE_REQUEST'
export const ORDER_UPDATE_STAGE_INVOICE_SUCCESS = 'ORDER_UPDATE_STAGE_INVOICE_SUCCESS'
export const ORDER_UPDATE_STAGE_INVOICE_ERROR = 'ORDER_UPDATE_STAGE_INVOICE_ERROR'

// product
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO'
export const SET_QUOTE_DATA = 'SET_QUOTE_DATA'
export const CLEAR_QUOTE_DATA = 'CLEAR_QUOTE_DATA'
export const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT'
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION'
export const UNSET_ACTIVE_LOCATION = 'UNSET_ACTIVE_LOCATION'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const SET_TOTAL_AMOUNT = 'SET_TOTAL_AMOUNT'
export const SET_PRODUCTS_QUANTITY = 'SET_PRODUCTS_QUANTITY'
export const SET_EDITING_STATUS = 'SET_EDITING_STATUS'
export const SET_PRINT_ACTIVE = 'SET_PRINT_ACTIVE'
export const SET_QUOTE_INFO_WAS_CHANGED = 'SET_QUOTE_INFO_WAS_CHANGED'
export const SET_CURRENT_BIDS_PAGE_NAME = 'SET_CURRENT_BIDS_PAGE_NAME' 

// taks report

export const SET_TASKS_REPORT_VALUE = 'SET_TASKS_REPORT_VALUE'
export const SET_TASKS_REPORT_FILTER_QUERY = 'SET_TASKS_REPORT_FILTER_QUERY'
export const SET_TASKS_REPORT_SEARCH_OPTIONS  = 'SET_TASKS_REPORT_SEARCH_OPTIONS'

// SYSTEM

export const SYSTEM_CREATE_REQUEST = 'SYSTEM_CREATE_REQUEST'
export const SYSTEM_CREATE_SUCCESS = 'SYSTEM_CREATE_SUCCESS'
export const SYSTEM_CREATE_ERROR = 'SYSTEM_CREATE_ERROR'
export const SYSTEM_SUCCESSFULLY_ADDED = 'SYSTEM_SUCCESSFULLY_ADDED'
export const SET_CALLBACK_AND_CREDENTIALS_FOR_ADDING_SYSTEMS_FORM_CUSTOMER_PAGE = 'SET_CALLBACK_AND_CREDENTIALS_FOR_ADDING_SYSTEMS_FORM_CUSTOMER_PAGE'
export const SET_FIRST_LAYER_SYSTEMS = 'SET_FIRST_LAYER_SYSTEMS'
// export const SET_ADDITIONAL_TESTS_POPUP_ACTIVE = 'SET_ADDITIONAL_TESTS_POPUP_ACTIVE'
// review popup
export const SET_EXPANDED_SYSTEM = 'SET_EXPANDED_SYSTEM'
export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE'
export const SET_CUSTOMER_PAGE_ORDER = 'SET_CUSTOMER_PAGE_ORDER'
export const SET_CUSTOMER_PAGE_QUOTES = 'SET_CUSTOMER_PAGE_QUOTES'


export const SET_SHOW_SIDEBAR_CALENDER = 'SET_SHOW_SIDEBAR_CALENDER'
//generalActionsType

export const SET_DRAGGING_DESTINATION = 'SET_DRAGGING_DESTINATION'
