import React from 'react';
import { Button, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '100%',
        height: '100%',
        position: 'fixed'
    },
    content: {
        width: '100%',
        height: '100%'
    },
}));

const FullScreenModal = ({ open, onClose, children }) => {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={onClose} className={classes.modal}>
            <div className={classes.content}>
                {children}
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </div>
        </Modal>
    );
};

export default FullScreenModal;
