import * as types from '../actions/types'
  
  
  const initialState = {
    parentSystem: {
        layer:1,
        parent_system_id: null
    },
    maxLayer: null
  }
  
  export default function (state = initialState, action) {
  
    switch (action.type) {
      case types.SET_EXPANDED_SYSTEM:
        return {
          ...state,
          expandedSystem: action.payload, 
        }
      // case types.SET_MAX_LAYER:
      //   return {
      //     ...state,
      //     maxLayer: action.payload
      //   }
      default:
        return state
    }
  }
  