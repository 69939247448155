import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import "./Footer.scss";
import AddFilePopUp from '../../PopUps/AddFilePopUp/AddFilePopUp';
import AddTaskPopUp from '../../TabletView/PopUps/AddTask/AddTaskPopUp';
import { SAVE } from '../../../../constants/translations/review-popup';
import { MOBILE_LANDING_PAGE } from '../../../../constants/translations/customer/mobile/mobileView';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { useDispatch } from 'react-redux';
import { systemActionsCreator } from '../../../../actions/customer/systemActions';


const save = {
    name: SAVE, //Save
    width: '42%',
    height: '36px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '12px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    borderRadius: '20px'
}
const cancel = {
    name: MOBILE_LANDING_PAGE.CANCEL, //Cancel
    width: '42%',
    height: '36px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '12px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    borderRadius: '20px'

}
export default function Footer(props) {
    const { handleClose, process } = props;
    const { btnName, field } = props.data;
    const [showPopUp, setShowPopUp] = useState(false);

    const dispatch = useDispatch();

    const taskBtn = {
        name: btnName,
        width: '100%',
        height: '36px',
        color: '#fff',
        backgroundColor: "#186315",
        fontSize: '12px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        border: false,
        buttonName: 'AddIcon',
        borderRadius: '20px'
    }

    function handleShowPopUp() {
        dispatch(systemActionsCreator.showSystemsPopup(true))
        setShowPopUp(!showPopUp)
    }
    return (
        <>
            <Grid container spacing={0} className='footer__main'>
                {
                    field === 'details' ? null
                        : field === 'landingPage' ? null
                            : <Grid className='footer__btnContainer' style={{ marginBottom: '10px' }}>
                                <Button data={taskBtn} handleClick={() => handleShowPopUp()} />
                            </Grid>
                }

                <Grid className='footer__btnContainer' style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button data={save} handleClick={() => handleShowPopUp()} />
                    <Button data={cancel} handleClick={() => handleClose()} />
                </Grid>
            </Grid>

            {
               field === 'task' ? showPopUp && <AddTaskPopUp
                        handleShowPopUp={handleShowPopUp}
                        view="mobile"
                        name={POP_UPS_TRANSLATION.ADD_A_TASK}
                        showPopUp={showPopUp}
                        process={process && process}
                    // newTask={newTask}
                    />
                        : field === 'file' ? showPopUp && <AddFilePopUp handleShowPopUp={handleShowPopUp} view="mobile" name={POP_UPS_TRANSLATION.ADD_A_FILE} />
                            : null
            }
        </>
    )
}
