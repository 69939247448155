import React, { useCallback, useEffect, useState } from "react";
import { Table, TableBody, Paper } from "@material-ui/core";
import { generalGetRequest, generalPostRequest } from '../../../../functions/api/general'
import DetailsDialog from '../../DetailsDialog'
import { MULTIPLE_VALUES } from '../../../../constants/customers-page'
import { useStyles } from '../style'
import SystemsCheckListSectionTableHead from './CheckListTableHead'
import { deleteTemporaryIdFromObject } from "../../../../utils";
import { CheckListTest, AdditionalTest } from '../templates'
import TestsList from './TestsList'
import { REG_EXP_OBJECT_ID, LAYER_2, LAYER_4, TEST_WITH_OPEN_DOORS_ID, TEST_WITH_CLOSE_DOORS_ID } from '../../../../constants'
import EditCheckListButtons from './Buttons'

const EditCheckListPopup = (props) => {
  const { checkList, inputTypes, setInputTypes, setCheckList, editCheckListSectionPopup, setEditCheckListSectionPopup, systems, setSystems, showStopperOptions, additionalTests, setAdditionalTests, layer, systemFactoryId, factories, } = props
  const { data: checkListTests, system_id } = checkList
  const [isFactoryServiceByFactoryID, setIsFactoryServiceByFactoryID] = useState(false);
  const classes = useStyles();

  const isFactoryService = () => {
    const factory = factories && factories.find(i => i._id === systemFactoryId);
    if (factory.name === "Metalpress Service") {
      setIsFactoryServiceByFactoryID(true);
    }
  }

  useEffect(() => {
    isFactoryService();
  }, []);
  const changeValuesHandler = useCallback((rowIdx, col) => (value) => {
    const checkListCopy = { ...checkList }
    if (col === 3) {
      checkListCopy.data[rowIdx].values[col].value = value
    } else if (typeof col === 'number') {
      checkListCopy.data[rowIdx].values[col].typeOfInput = value
      if (parseInt(value) >= MULTIPLE_VALUES) checkListCopy.data[rowIdx].values[col].value = []
      else checkListCopy.data[rowIdx].values[col].value = ''
    } else if (col === TEST_WITH_OPEN_DOORS_ID || col === TEST_WITH_CLOSE_DOORS_ID) {
      if (value) checkListCopy.data[rowIdx]['test_per_floor_id'] = col
      else delete checkListCopy.data[rowIdx]['test_per_floor_id']
    } else {
      checkListCopy.data[rowIdx][col] = value
    }
    setCheckList(checkListCopy)
  }, [checkList])

  const deleteTestHandler = useCallback((testId) => {
    const checkListCopy = { ...checkList }
    const testIndex = checkListCopy.data.findIndex(i => i._id === testId)
    checkListCopy.data.splice(testIndex, 1)
    setCheckList(checkListCopy)
  }, [checkList])

  const createTestHandler = () => {
    const checkListCopy = { ...checkList }
    const testOrder = checkListCopy.data.length + 1 /**points on test order among tests */
    const newTest = new CheckListTest(testOrder)
    let newValues = [];

    if (!isFactoryServiceByFactoryID) {
      for (const inputValue of newTest.values) {
        if (inputValue.typeOfInput == "-11" || inputValue.typeOfInput == "text" || inputValue.typeOfInput == "photo"){
          newValues = [...newValues, inputValue]
        } 
      }
      newTest.values = newValues
    }
    checkListCopy.data = [...checkListCopy.data, newTest]

    setCheckList(checkListCopy)
  }

  const submitHandler = async () => {
    const checkListCopy = { ...checkList }
    const additionalTestsCopy = [...additionalTests]
    // delete temporary _id
    checkListCopy.data.forEach(deleteTemporaryIdFromObject)
    additionalTestsCopy.forEach(deleteTemporaryIdFromObject)
    // filter union_test === false from adding to check list tests
    // if(layer = ){
    //   checkListCopy.data = checkListCopy.data.filter(i => i.test_union !== false)
    // }
    filterUnionTestsByLayer(checkListCopy)
    const responce = await generalPostRequest(checkListCopy, '/admin/systems/update-check-list')
    await generalPostRequest(additionalTestsCopy, '/admin/systems/revise-additional-test')
    if (responce.result) {
      const { system_id, _id } = responce.result
      const systemsCopy = [...systems]
      const systemIndex = systemsCopy.findIndex(i => i._id === system_id)
      systemsCopy[systemIndex].check_list_id = _id
      setSystems(systemsCopy)
    }
    closePopUpHandler()
  }

  const filterUnionTestsByLayer = (checkList) => {
    switch (layer) {
      case LAYER_2: {
        checkList.data = checkList.data.filter(i => i.test_union !== false)
        break;
      }
      case LAYER_4: {
        checkList.data = checkList.data.filter(i => i.group_by_parent !== false)
      }
    }
  }

  const closePopUpHandler = () => {
    setCheckList('')
    setEditCheckListSectionPopup(false)
  }
  //--------------------------------------------- conditional / additional test---------------------------------------------
  const createAdditionalTestHandler = () => {
    const testOrder = checkList.data.length + additionalTests.length + 1
    const newTest = new AdditionalTest(system_id, testOrder)
    const newAdditionalTests = [...additionalTests, newTest]
    setAdditionalTests(newAdditionalTests)
  }

  const deleteAdditionalTestHandler = useCallback(async (testId) => {
    const additionalTestsCopy = [...additionalTests]
    const additionalTestIndex = additionalTestsCopy.findIndex(i => i._id === testId)
    if ((testId && !testId.match(REG_EXP_OBJECT_ID))) { /*temporary created TEST */
      additionalTestsCopy.splice(additionalTestIndex, 1)
      setAdditionalTests(additionalTestsCopy)
    } else if (testId.match(REG_EXP_OBJECT_ID)) {
      const responce = await generalGetRequest(`/admin/systems/delete-additional-test?test_id=${testId}`)
      const isDeleted = responce?.result?.is_disabled
      if (isDeleted) {
        additionalTestsCopy.splice(additionalTestIndex, 1)
        setAdditionalTests(additionalTestsCopy)
      }
    }
  }
    , [additionalTests])

  const editAdditionalTestsHandler = useCallback((rowIdx, col) => (value) => {
    const additionalTestsCopy = [...additionalTests]
    if (col === 3) {
      additionalTestsCopy[rowIdx].values[col].value = value
    } else if (typeof col === 'number') {
      additionalTestsCopy[rowIdx].values[col].typeOfInput = value
      if (parseInt(value) >= MULTIPLE_VALUES) additionalTestsCopy[rowIdx].values[col].value = []
      else additionalTestsCopy[rowIdx].values[col].value = ''

    } else {
      additionalTestsCopy[rowIdx][col] = value
    }
    setAdditionalTests(additionalTestsCopy)
  }, [additionalTests])

  return <DetailsDialog
    isOpen={editCheckListSectionPopup}
    onClose={closePopUpHandler}
    submitLabel="Update"
    maxWidth='lg'
    title={<EditCheckListButtons
      createTestHandler={createTestHandler}
      createAdditionalTestHandler={createAdditionalTestHandler}
    />}
    submitHandler={submitHandler}
    dialogContentStyle={{ padding: 0 }}
  >
    <Paper className={classes.root}>
      <Table className={classes.table} stickyHeader>
        {
          !isFactoryServiceByFactoryID ?
            <SystemsCheckListSectionTableHead factoryId={systemFactoryId} layer={layer} /> :
            <SystemsCheckListSectionTableHead layer={layer} />
        }
        <TableBody>
          {
            !isFactoryServiceByFactoryID ?
              <TestsList
                data={checkListTests}
                deleteTestHandler={deleteTestHandler}
                changeValuesHandler={changeValuesHandler}
                showStopperOptions={showStopperOptions}
                inputTypes={inputTypes}
                setInputTypes={setInputTypes}
                isAdditionalTest={false}
                layer={layer}
                isFactoryServiceType={isFactoryServiceByFactoryID}
                factoryId={systemFactoryId}
              />
              : <TestsList
                data={checkListTests} /**.sort((a,b) => +a.order > +b.order ? 1 : +a.order < +b.order ? -1 : 0) */
                deleteTestHandler={deleteTestHandler}
                changeValuesHandler={changeValuesHandler}
                showStopperOptions={showStopperOptions}
                inputTypes={inputTypes}
                setInputTypes={setInputTypes}
                isAdditionalTest={false}
                layer={layer}
                isFactoryServiceType={isFactoryServiceByFactoryID}
              />
          }
          {additionalTests ? <TestsList
            data={additionalTests}
            deleteTestHandler={deleteAdditionalTestHandler}
            changeValuesHandler={editAdditionalTestsHandler}
            showStopperOptions={showStopperOptions}
            inputTypes={inputTypes}
            setInputTypes={setInputTypes}
            isAdditionalTest={true}
            layer={layer}
          /> : null}
        </TableBody>
      </Table>
      {/* <Grid className = {classes.addIconWrapper}  >
        <CustomButton onClick={createTestHandler}>{ADD_TEST}</CustomButton>
        <CustomButton onClick={createAdditionalTestHandler}>{ADD_ADDITIONAL_TEST}</CustomButton>
        </Grid> */}
    </Paper>
  </DetailsDialog>
}

export default EditCheckListPopup