import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { useStyles } from '../styles/StylesForTables'
import QuotationHistoryTableHead from './Components/QuotationHistoryTableHead';
import QuotationHistoryTableRow from './Components/QuotationHistoryTableRow'
import {stableSort, getComparator} from './tools'
import {useCreateUrlWithCustomerId} from '../../../hooks/useCreateUrlWithCustomerId'
import { useEffect } from 'react';
import { generalGetRequest } from '../../../functions/api/general';

const QuotationTable = (props) => {
    const GET_QUOTES_URL = `/system/customer-page/get-quotes`
    const { customerId, customer: customer_data} = props
    const [ customer , setCustomerId] = useState(customer_data)
    const url = useCreateUrlWithCustomerId(GET_QUOTES_URL, customerId)
    const [data , setData] = useState([]) 
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');

    useEffect(() => {
        generalGetRequest(url).then(({ok, result}) => {
            ok && setData(result)
        })
      return () => {
      }
    }, [url , customer])
    

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const customerIdHandler = () => setCustomerId({...customer});;

    return (
       data?.length ? <div className={classes.root}>
            <div className={classes.tableBox}>
                <Table
                    className={classes.table}
                    size={'small'}
                    stickyHeader
                >
                    <QuotationHistoryTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(data, getComparator(order, orderBy))
                            .map((row) => <QuotationHistoryTableRow
                                key={row._id}
                                quote={row}
                                customerIdHandler={customerIdHandler}
                            />)}
                    </TableBody>
                </Table>
            </div>
        </div> :null
    );
}

export default QuotationTable