import React, { useState } from 'react'
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { useStyles } from '../styles/StylesForTables'
import CustomerOrdersTableHead from './components/CustomerOrdersTableHead'
import CustomerOrdersTableBody from './components/CustomerOrdersTableBody'
import ButtonsWrapper from '../reused_components/buttonsWrapper'
import CreatePlainOrderButton from '../Buttons/ServiceCreatePlainOrder'
import CreateSemiAnnualAuditButton from '../Buttons/ServiceCreateSemiAnnualAudit'
import CreateOrderWithSelectedSystemsButton from '../Buttons/ServiceCreateOrderWithSelectedSystems'
import { useDispatch, useSelector } from 'react-redux';
import { SERVICE } from '../../../constants';
import { setCustomerPageOrders } from '../../../actions/customers_table_actions';
import { getCustomerOrders } from '../../../functions/api/customer-page';
import { useEffect } from 'react';

const useCustomerOrders = (customerId, lastCreatedOrderId, customer) => {
  const [ordersList, setOrdersList] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchOrders() {
      const { result } = await getCustomerOrders(customerId)
      if (result) {
        setOrdersList(result)
        dispatch(setCustomerPageOrders(result))
      }
    }
    fetchOrders()
  }, [customerId, lastCreatedOrderId, customer])

  return [ordersList, setOrdersList]
}

const OrdersTable = (props) => {
  const { customer, customerId, lastCreatedOrderId } = props;
  const [orders, setOrders] = useCustomerOrders(customerId, lastCreatedOrderId, customer)
  const classes = useStyles()
  const factoryType = useSelector((state) => state.login.user.type_of_factory);
  const isService = factoryType === SERVICE
  return (<React.Fragment>
    <ButtonsWrapper>
      {isService && <CreatePlainOrderButton customer={customer} isService = {isService}/>}
      {isService && <CreateSemiAnnualAuditButton customer = {customer}/>}
      {isService && <CreateOrderWithSelectedSystemsButton customer = {customer}/>}
    </ButtonsWrapper>
    {orders?.length ? <TableContainer
      component={Paper}
      className={classes.container}
      >
      <Table
        size="small"
        stickyHeader
      >
        <CustomerOrdersTableHead />
        <CustomerOrdersTableBody data={orders} setOrders = {setOrders} />
      </Table>
    </TableContainer> : null}
  </React.Fragment>
  )
}

export default OrdersTable