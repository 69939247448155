import * as types from "./types";
import axios from "axios";
import { api, createHeaders } from "../constants/api-urls";

export const changeCurrentPage = (page) => ({
  type: types.USERS_CHANGE_CURRENT_PAGE,
  payload: page, 
});

export const changeRowsPerPage = (count) => ({
  type: types.USERS_CHANGE_ROWS_PER_PAGE, 
  payload: count,
});

// const getAllUsersRequest = () => ({
//   type: types.GET_ALL_USERS_REQUEST,
// });

// const getAllUsersSuccess = (value) => ({
//   type: types.GET_ALL_USERS_SUCCESS,
//   payload: value,
// });

// const getAllUsersFailure = (error) => ({
//   type: types.GET_ALL_USERS_ERROR,
//   payload: { 
//     error,
//   }, 
// });

// export const getAllUsers = () => {
//   return (dispatch) => {
//     dispatch(getAllUsersRequest());
//     let url = `${api.admin.users.allUsers}`;
//     const headers = createHeaders();
//     axios
//       .get(url, { headers })
//       .then((res) => {
//         const { ok, result } = res.data;
//         dispatch(getAllUsersSuccess(result));
//       })
//       .catch((err) => {
//         dispatch(getAllUsersFailure(err.message));
//       });
//   };
// };



const getByFilterUsersRequest = () => ({
  type: types.ADMIN_GET_FILTER_USERS_REQUEST,
});

const getByFilterUsersSuccess = (value) => ({
  type: types.ADMIN_GET_FILTER_USERS_SUCCESS,
  payload: value,
});

const getByFilterUsersFailure = (error) => ({
  type: types.ADMIN_GET_FILTER_USERS_ERROR,
  payload: {
    error,
  },
});

export const getByFilterUsers = ( 
  limit = 20,
  page = 0,
  sort = -1,
  factory_id 
) => {
  return (dispatch) => {
    dispatch(getByFilterUsersRequest());
    let filter = `limit=${limit}&page=${page}&sort=${sort}${factory_id ? `&factory_id=${factory_id}` : ``}`;

    let url = `${api.admin.users.getByFilter(filter)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result, count } = res.data;
        dispatch(getByFilterUsersSuccess({ result: result, count: count }));
      })
      .catch((err) => {
        dispatch(getByFilterUsersFailure(err.message));
      });
  };
};

const getUserRequest = () => ({
  type: types.GET_BY_ID_USER_REQUEST,
});

const getUserSuccess = (value) => ({
  type: types.GET_BY_ID_USER_SUCCESS,
  payload: value,
});

const getUserFailure = (error) => ({
  type: types.GET_BY_ID_USER_ERROR,
  payload: {
    error,
  },
});



export const getUser = (id) => {
  return (dispatch) => {
    dispatch(getUserRequest());
    let url = `${api.admin.users.getById(id)}`;
    const headers = createHeaders();
    
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getUserSuccess(result));
      })
      .catch((err) => {
        dispatch(getUserFailure(err.message));
      });
  };
};

export const setUserDetais = (value) => {
  return {
    type: types.SET_USER,
    payload: value,
  };
};

export const createUser = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(createUserRequest());
    let url = `${api.admin.users.create}`;
    const headers = createHeaders();
    dispatch(changeCurrentPage(0));
    dispatch(changeRowsPerPage(10));
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(createUserSuccess(ok));
       // dispatch(getUsers(filterFactory));
       dispatch(getByFilterUsers(10,0,-1,filterFactory));  
      })
      .catch((err) => {
        dispatch(createUserFailure(err.message));
      });
  };
};

const createUserRequest = () => ({
  type: types.USER_CREATE_REQUEST,
});

const createUserSuccess = (isCreate) => ({
  type: types.USER_CREATE_SUCCESS,
});

const createUserFailure = (error) => ({
  type: types.USER_CREATE_ERROR,
  payload: {
    error,
  },
});


const changePasswordRequest = () => ({
    type: types.USER_UPDATE_REQUEST,
  });
  
  const changePasswordSuccess = (isCreate) => ({
    type: types.USER_UPDATE_SUCCESS,
  });
  
  const changePasswordFailure = (error) => ({
    type: types.USER_UPDATE_ERROR,
    payload: {
      error,
    },
  });


export const changePassword = (data) => {
    return (dispatch) => {
      dispatch(changePasswordRequest());
      let url = `${api.admin.users.changePassword}`;
      const headers = createHeaders();    
      axios
        .post(url, data, { headers })
        .then((res) => {
          const { ok, result } = res.data;
          dispatch(changePasswordSuccess(ok));          
        })
        .catch((err) => {
          dispatch(changePasswordFailure(err.message));
        });
    };
  };

export const updateUser = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(updateUserRequest());
    let url = `${api.admin.users.update}`;
    const headers = createHeaders();
    dispatch(changeCurrentPage(0));
    dispatch(changeRowsPerPage(10));
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(updateUserSuccess(ok));
        //dispatch(getUsers(filterFactory));
        dispatch(getByFilterUsers(10,0,-1,filterFactory));  
      })
      .catch((err) => {
        dispatch(updateUserFailure(err.message));
      });
  };
};

const updateUserRequest = () => ({
  type: types.USER_UPDATE_REQUEST,
});

const updateUserSuccess = (isUpdate) => ({
  type: types.USER_UPDATE_SUCCESS,
});

const updateUserFailure = (error) => ({
  type: types.USER_UPDATE_ERROR,
  payload: {
    error,
  },
});

const getUserByFactoryRequest = () => ({
  type: types.USERS_GET_BY_FACTORY_ID_REQUEST,
});

const getUserByFactorySuccess = (value) => ({
  type: types.USERS_GET_BY_FACTORY_ID_SUCCESS,
  payload: value,
});

const getUserByFactoryFailure = (error) => ({
  type: types.USERS_GET_BY_FACTORY_ID_ERROR,
  payload: {
    error,
  },
});

export const getUserByFactory = (factory_id) => {
  return (dispatch) => {
    dispatch(getUserByFactoryRequest());
    let url = `${api.admin.users.getByFactoryId(factory_id)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getUserByFactorySuccess(result));
      })
      .catch((err) => {
        dispatch(getUserByFactoryFailure(err.message));
      }); 
  };
};

