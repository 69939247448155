import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useStyles } from '../style'
import CustomizedTextInput from '../Components/CustomizedTextInput'
import CustomizedShowStopperSelect from '../Components/CustomizedShowStopperSelect'

const InputOptions = (props) => {
    const { options, changeInputOptionsHandler, deleteInputOptionHandler, showStoppers } = props
    const classes = useStyles()
    return <TableContainer style={{ height: '40vh' }}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell align = 'center' className = {classes.inputsHeaderCell}>delete</TableCell>
          <TableCell align = 'right' className = {classes.inputsHeaderCell}>option</TableCell>
          <TableCell align = 'right' className = {classes.inputsHeaderCell}>action</TableCell>
          <TableCell align = 'right' className = {classes.inputsHeaderCell}>show stopper</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {options.map(({ option, action, show_stopper }, idx) => <TableRow key={idx}>
          <TableCell align = 'center'>
            <DeleteForeverIcon className={classes.icons} onClick={() => deleteInputOptionHandler(idx)} />
          </TableCell>
          <CustomizedTextInput
            value={option}
            changeHandler={changeInputOptionsHandler(idx, 'option')}
            width={'100%'}
          />
          <CustomizedTextInput
            value={action}
            changeHandler={changeInputOptionsHandler(idx, 'action')}
            width={'100%'}
          />
          <CustomizedShowStopperSelect
            value={show_stopper}
            changeHandler={changeInputOptionsHandler(idx, 'show_stopper')}
            width={'100%'}
            options = {showStoppers}
          />
        </TableRow>)}
      </TableBody>
    </Table>
    </TableContainer>
  }
  
  
  export default InputOptions