import {
  CACHED_CHECK_LISTS_DATA
} from '../../../constants/offline-mode'

export const editCheckListHandler = (layer, parent_system_id, check_list) =>{
  const {actual_system_id: actualSystemId} = check_list
  const systemLayers = JSON.parse(localStorage.getItem(CACHED_CHECK_LISTS_DATA))?.result
  const newSystemLayers = updateCheckList(systemLayers, check_list, actualSystemId)

  const newResponce = {
    ok:true,
    result: newSystemLayers
  }

  localStorage.setItem(CACHED_CHECK_LISTS_DATA, JSON.stringify(newResponce))

  return newSystemLayers

  }


  const updateCheckList = (systems, checkList, actualSystemId) => {
    const systemsCopy = [...systems]
    let selectedSystem = null
    for (let layer of systemsCopy){
      if(selectedSystem) break
      const {systems: systemsRelatedToParentSystem} = layer
      for(let {systems: systemCheckList} of systemsRelatedToParentSystem){
        if(selectedSystem) break
        const actualSystem = systemCheckList.find(system => actualSystemId === system._id)
        if(actualSystem){
          actualSystem.check_list = checkList
          selectedSystem = actualSystem
          return systemsCopy
        }
      }

    }

  }