import React from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {useStyles, defaultColor, disabled} from '../styles'

const AddExtrachargeButton = (props) => {
    const {addLocationHandler ,readOnly, label} = props;
    const classes = useStyles()
    return(
        <div className = {classes.extraChargeButtonWithIcon} onClick = {readOnly ? ()=>{} :addLocationHandler}>
            <AddCircleOutlineIcon style = {readOnly ? disabled : defaultColor}/>
            <p className = {classes.buttonWitIconText}>{label}</p>
        </div>
    )
}

export default AddExtrachargeButton