import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import {useStyles} from '../style'
import EditInputPopUp from '../EditInput/EditInput'


const CustomizedEditableCell = (props) => {
    const classes = useStyles()
    const { value, changeCheckListValueHandler, inputTypes, setInputTypes, showStoppers } = props
    const [editInputPopupActive, setEditInputPopupActive] = useState(false)
  
    return (
      <React.Fragment>
        {editInputPopupActive ? <EditInputPopUp
          inputTypes = {inputTypes}
          setInputTypes = {setInputTypes}
          value = {value}
          changeCheckListValueHandler = {changeCheckListValueHandler}
          setEditInputPopupActive = {setEditInputPopupActive}
          editInputPopupActive = {editInputPopupActive}
          showStoppers = {showStoppers}
        /> : null}
        <TableCell className={classes.tableCell} onClick={() => setEditInputPopupActive(true)}>
          {value}
        </TableCell>
      </React.Fragment>
    )
  }


export default CustomizedEditableCell