import React, { Component } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
//comps
import Clock from './Clock'
import { reportDone } from "../../../../functions/api/orders"
//functions
import { hexToRgba } from '../../../../functions/general/general'
//img
import Lock from '../../../../images/updatepopup/openLock.svg'
import Arrow from '../../../../images/general/white-arrow.svg'
import check from '../../../../images/general/V.svg'
import { connect } from "react-redux";
import * as actions from "../../../../actions/actions";
//icons
import { SvgIcon } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { DAILY_CALENDAR } from '../../../../constants/translations/dailyCalendar'
import ProcessRow from '../../../DailyViewNew/ProcessRow/ProcessRow'
import OrderFormNewPopup from "../../../Header/Parts/ReservationPopup/orderFormNewPopup";
import TabletViewMainScreen from '../../../Customer/TabletView/TabletViewMainScreen/TabletViewMainScreen'
import { generalGetRequest } from '../../../../functions/api/general'
import MobileLandingPage from '../../../Customer/MobileViews/MobileLandingPage/MobileLandingPage'
import ErrorPopup from '../../../NavBar/ErrorPopup/ErrorPopup'
import UpdateProccessPopup from '../../../updatePopups/UpdateProccessPopup'
import { setShowSidebarCalendar } from '../../../../actions/general_actions'
import {store} from '../../../../index';


const { DURATION, START_TIME, END_TIME, REMARK } = DAILY_CALENDAR
let uniqId = require("uniqid");

class DailyCalender extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeFilterDrop: false,
            startTime: 8,
            showMainPopUp: false,
            orderIdFromProcess: null,
            process: {},
            view: this.getInitialView(),
            showErrorPopup: {
                show_error_popup: false,
                error_text: null,
            }
        }
    }


    shouldComponentUpdate(_np, _ns) {
        let _p = this.props; let _s = this.state;
        return (
            _np.processesArray !== _p.processesArray
            || _ns !== _s
        ) ? true : false;
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            activeFilterDrop: !prevState.activeFilterDrop
        }));
    }


    setWrapperRef = node => {
        this.wrapperRef = node;
    }


    getInitialView() {
        // console.log("inner width", window.innerWidth);
        // return window.innerWidth >= 450 ? 'desktop' : 'mobile';

        let view;
        if (window.innerWidth <= 600) {
            view = 'mobile';
        } else if (window.innerWidth >= 601 && window.innerWidth <= 1000) {
            view = 'tablet';
        } else {
            view = 'desktop';
        }
        return view;
    }

    handleResize = () =>  {
        this.setState({
            view: this.getInitialView(),
        });
    }

    componentDidMount() {
        // console.log("paramssssss", this.props.match.params);
        document.addEventListener("mousedown", this.handleClickOutside);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        window.removeEventListener('resize', this.handleResize);
    }

    // componentDidUpdate(prevProps, prevState){
    //   if(prevProps.orderIdFromProcess !== this.state.orderIdFromProcess){
    //      this.getOrderDataFromProcess(this.state.orderIdFromProcess)
    //      this.getAllProcessDataFromOrderId(this.state.orderIdFromProcess)
    //   }
    // }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ activeFilterDrop: false })
        }
    }

    reportDoneProcess = async (id) => {
        const { fetchNewData } = this.props
        let report_result = await reportDone({ _id: id });
        if (report_result.ok) {
            if (typeof report_result.result === "string") {
                this.props.setAlertPopupMessage(report_result.result);
                this.props.alertPopupToggle(true);
            }
            fetchNewData()
        }
    }

    openUpdateMenuOnClick = (e, process) => {
        const { openUpdateMenu } = this.props
        if (e.target.className === "done__button") {
            return
        } else {
            openUpdateMenu(process)
        }

    }

    showEmployeeName = (array) => {
        let filterEmployee = array.map(p => p.name)
        return filterEmployee.join(' / ')
    }

    backToToday = () => {
        this.props.backToToday()
    }

    // calculateTime = (time) => {
    //     const [hours, minutes] = time?.toFixed(2)?.toString()?.split('.')

    //     const calculatedMinutes = ((minutes / 100 ) * 60) + ''

    //     const resultMinutes = (calculatedMinutes && calculatedMinutes.length > 1) ? calculatedMinutes : (calculatedMinutes?.length) ? ('0'+ calculatedMinutes) : '00'

    //     return `${hours}:${resultMinutes}`

    // }

    padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    convertMsToHM = (milliseconds) => {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = seconds >= 30 ? minutes + 1 : minutes;
        minutes = minutes % 60;
        hours = hours % 24;

        return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}`;
    }

    handleShowPopUp = () => {
        if (window.outerWidth >= 801 && window.outerWidth <= 1000) {
            store.dispatch(setShowSidebarCalendar(false));
        }
        this.setState({ showMainPopUp: !this.state.showMainPopUp })
    }

    getAllProcessDataFromOrderId = (orderId) => {
        const process = this.props.processesArray.filter(process => process.order_id === orderId);
        this.setState({ process: process[0] })
    }

    getOrderIdFromProcess = (orderId) => {
        this.setState({ orderIdFromProcess: orderId })
        this.getAllProcessDataFromOrderId(orderId)
    }

    // getOrderDataFromProcess = async(orderId) => {
    //     let res = await generalGetRequest(`/system/order-process/get-last-order-process?order_id=${orderId}`)
    //     console.log("ressss", res)
    // }



    updateErrorPopup = (boolean, error_text, isUpdateCall = false) => {
        this.setState({
            showErrorPopup : { 
            show_error_popup: boolean,
            error_text: error_text,
       }});
    }



    render() {
        const { currentDate, handleDateChange, processesArray, openUpdateMenu, is_loader_on } = this.props
        const { orderIdFromProcess, process } = this.state;
        const { activeFilterDrop } = this.state
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let dynamicView = params.get('view');

        return (
            <>
                {/* {true && <OrderFormNewPopup
                    closePopup={() => {
                        handleShowPopUp(false);
                    }}
                />} */}

                {
                    this.state.showErrorPopup.show_error_popup && (
                        <ErrorPopup
                            afterConfirmationError={this.state.showErrorPopup.error_text}
                            updatePopup={(val) => this.setState({ showErrorPopup : { ...this.state.showErrorPopup, show_error_popup: val }})}
                        />
                    )
                }

                <div className={this.state.showMainPopUp ? 'popUp__background' : ""}>
                    <div className='daily-view-page__update-process-popup'>
                        {
                            this.state.showMainPopUp && this.state.view === 'mobile' ?
                                <MobileLandingPage
                                    process={process && process}
                                    orderId={orderIdFromProcess}
                                    closePopup={this.handleShowPopUp}
                                    view={dynamicView ? dynamicView: 'mobile'}
                                    updateErrorPopup={this.updateErrorPopup}
                                />
                                : null
                        }
                        {
                            this.state.showMainPopUp && this.state.view === 'desktop' ? <TabletViewMainScreen
                                process={process && process}
                                orderId={orderIdFromProcess}
                                closePopup={this.handleShowPopUp}
                                view={dynamicView ? dynamicView : 'tablet'}
                                updateErrorPopup={this.updateErrorPopup}
                            /> : null
                        }
                        {
                            this.state.showMainPopUp && this.state.view === 'tablet' ? <TabletViewMainScreen
                                process={process && process}
                                orderId={orderIdFromProcess}
                                closePopup={this.handleShowPopUp}
                                view={dynamicView ? dynamicView : 'tablet'}
                                updateErrorPopup={this.updateErrorPopup}
                            /> : null
                        }
                    </div>
                </div>
                <div className="daily-view-page__calender">
                    <header className="daily-view-page__calender__header">
                        <section className="daily-view-page__calender__header__right">
                            <div className="daily-view-page__calender__header__right--dates">
                                <figure onClick={() => { handleDateChange('dec') }}><img src={Arrow} alt="arrow" /></figure>
                                <p>{moment(currentDate).format("DD MMMM YYYY")}</p>
                                <figure onClick={() => { handleDateChange('inc') }}><img src={Arrow} alt="arrow" /></figure>
                            </div>
                            <Clock />

                        </section>
                        <button className="daily-view-page__calender__header__left" onClick={() => { this.backToToday() }}>
                            <p>חזור להיום</p>
                        </button>
                        {/* <section className="daily-view-page__calender__header__left">
                        
                    </section> */}
                    </header>

                    <main className="daily-view-page__calender__main" id="daily-view-page__calender__main">
                        {processesArray.length === 0 ?
                            <div className="daily-view-page__calender__main--no-process">
                                <h1>{is_loader_on ? " " : "לא קיימים תהליכים ליום זה"}</h1>
                            </div>

                            :
                            null
                        }


                        {
                            <>
                                <div>
                                    <ProcessRow
                                        getOrderIdFromProcess={this.getOrderIdFromProcess}
                                        handleClick={this.handleShowPopUp}
                                        alertPopupToggle={this.props.alertPopupToggle}
                                        setAlertPopupMessage= {this.props.setAlertPopupMessage}
                                        data={processesArray.map((row) => {
                                            return {
                                                _id: row._id,
                                                orderId: row.order_id,
                                                processId: row.process_id,
                                                processName: row.process_name,
                                                customerName: row.client_name,
                                                orderNumber: row.order_number,
                                                startTime: moment(row.startTime).format("HH:mm"),
                                                endTime: moment(row.endTime).format("HH:mm"),
                                                quantity: `${row.quantity}/${row.finished}`,
                                                duration: row.process_duration,
                                                status: row.done,
                                                done: row.done,
                                                finished: row.finished,
                                                hasChecklist: row?.checklist
                                            };
                                        })} />
                                </div>
                            </>

                        }
                        {/* <div key = {uniqId() + '' +process._id} style={{borderColor: process.warnings.length > 0 ? 'red':null }} className="daily-view-page__calender__main__process" onClick={(e)=>{this.openUpdateMenuOnClick(e, process)}}>
                                 
                                    <main className="daily-view-page__calender__main__content"  >
                                        <div className="daily-view-page__calender__main__content--first">
                                            <h6>{process.client_name} - </h6>
                                            <p>{process.order_number} </p>

                                        </div>
                                        <div className="daily-view-page__calender__main__content--second">
                                            <p>{process.process_name} <span className="daily__employees__name__hyphen">&nbsp;{"-"} &nbsp; </span></p>
                                            <p className="daily__employees__name">{process.resource.length>0 ? this.showEmployeeName(process.resource) :'אין עובד'}</p>
                                        </div>
                                        
                                        
                                        
                                        <div className="daily-view-page__calender__main__content--second">
                                            <p>{DURATION} <span className="daily__employees__name__hyphen">&nbsp;{"-"} &nbsp; </span></p>
                                            <p className="daily__employees__name">{Number(process.actual_duration) ? Number(process.actual_duration) : 0}</p>
                                            {process.remark ? 
                                                <React.Fragment>
                                                    <p className = 'row-second-value'>{REMARK} <span className="daily__employees__name__hyphen">&nbsp;{"-"} &nbsp; </span></p>
                                                    <p className="daily__employees__name four_characters_length">{process.remark}</p>
                                                </React.Fragment> : null
                                            }
                                        </div>
                                        <div className="daily-view-page__calender__main__content--second">
                                            <p>{START_TIME} <span className="daily__employees__name__hyphen">&nbsp;{"-"} &nbsp; </span></p>
                                            <p className="daily__employees__name">{process.startTime ? this.convertMsToHM(process.startTime) : 0}</p>
                                            <p className = 'row-second-value'>{END_TIME} <span className="daily__employees__name__hyphen">&nbsp;{"-"} &nbsp; </span></p>
                                            <p className="daily__employees__name">{process.endTime ? this.convertMsToHM(process.endTime) : 0}</p>
                                        </div>



                                        <div className="daily-view-page__quantity" style={process.done ? {color:'black'} : {color: '#0091ff'}}>
                                          כמות: {process.quantity} / {process.finished}
                                        </div>
                                    </main>
                                  

                                    <div style={{backgroundColor: process.warnings.length  > 0 ? 'red':'transparent' }} className="daily-view-page__warning">  </div>


                                    {process.done ?
                                   <div className="done__icon"><img src={check} alt="check"/>  </div> 
                                  : 
                                   <div className="done__button" onClick={()=>this.reportDoneProcess(process._id)}>סיימתי</div> }
                               </div>  */}
                    </main>
                </div>
            </>
        )
    }
}



function mapStateToProps({ login }) {
    return { login };
}

function mapDispatchToProps({ }) {

}
export default withRouter(connect(mapStateToProps, actions)(DailyCalender))
