import { Grid } from '@material-ui/core'
import React, { useState } from 'react';
import "./CheckButton.scss";

export default function CheckButton(props) {
    const { name, width, border, isBoxShadow, background, label, isChecked, updateFormDataCheckBox } = props.data;
    const boxShadow = `0px 0px 0px 0px #0844721A, 0px 1px 2px 0px #0844721A, 0px 3px 3px 0px #08447217, 0px 6px 4px 0px #0844720D, 0px 11px 5px 0px #08447203, 0px 18px 5px 0px #08447200`;

    const [isCheckedState, setIsCheckedState] = useState(isChecked);
   const view = 'tablet';
   const  handleChange = (event) => {
       updateFormDataCheckBox(event)
       setIsCheckedState(event.target.checked)
   }
    return (
        <Grid container spacing={0} className='checkBtn__main' 
        style={{ 
            width: width, 
            border: border !== 'none' && '1px solid #CACBCC',
            boxShadow: isBoxShadow &&  boxShadow,
            background: background && background
            }}
        >
            <input
                type="checkbox"
                id={`${name}-idx`}
                name={name}
                value={isCheckedState}
                checked={isCheckedState}
                onChange={handleChange}
            />
            {
                name && <span>
                    &nbsp;
                    &nbsp;
                    <label className='checkBtn__label' htmlFor={`${name}-idx`}>{label}</label>
                </span>
            }
        </Grid>
    )
}
