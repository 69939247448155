import React, { Component } from "react"
import "../../../../sass/DropDownBox/DropDownBox.scss"
import { polyfill } from "es6-promise"
polyfill()

class QuoteTabePageDropDown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_step: props.selected_step,
      selected: true,
      is_validate: true
    }
  }

  componentWillReceiveProps(nextProps) {
    let { validate_all_form } = this.props
    if (nextProps.validate_all_form !== validate_all_form) {
      this.validateForm()
    }
  }

  validateForm = () => {
    let { selected_step } = this.state

    if (selected_step === "") {
      this.setState({
        is_validate: false
      })
    }
  }

  hideValidateMessage = () => {
    this.setState({
      is_validate: true
    })
  }

  toggle = (event) => {
    const { selected } = this.state
    this.setState({
      selected: !selected
    })
    this.setStatusDropDownPosition(event)
  }

  closeSelectedBox = () => {
    this.setState({
      selected: true
    })
  }

  selectStep = option => {
    const { updateForm } = this.props
    const {_id, status} = this.props.row_data;
    this.hideValidateMessage()
    this.toggle()
    this.setState({
      selected_step: option
    })
    let body = {
        _id,
        status: option,
        cancelled: 'סגור' === option,
        started: `הפוך להזמנה` === option
    }
    updateForm(body)
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeSelectedBox()
    }
  }

  clickAndScroll = () => {
    //this function  for the main popup
    //if title = false - the component not in the main popup
    const {title} = this.props
    if (title) {
      setTimeout(function() {
        let element = document.querySelector("#drop__down__box--active")
        let scroll_element = document.querySelector(
          ".popup__right__inputs__container"
        )

        if (element !== null) {
          let element_top = element.getBoundingClientRect()

          if (element_top.bottom > 498) {
            scroll_element.scrollTop += scroll_element.offsetTop
          }
        }
      }, 100)
    } else {
      return
    }
  }

  setStatusDropDownPosition = (event) => {
    let xPoint= event?.clientX;
    let yPoint= event?.clientY;
    if(!xPoint || !yPoint) return;
    let height = window.innerHeight - yPoint;
    if(height < 256){
      setTimeout(() => {
        let element = document.getElementById('drop__down__box--active')
        element.style.transform = `scaleY(-1)`;
        let class_element = document.getElementsByClassName('drop__down__option');
        [...class_element].map(item => {
          item.style.transform = `scaleY(-1)`;
        });
      }, 0);
    }else {
      let class_element = document.getElementsByClassName('drop__down__option');
      [...class_element].map(item => {
        if(item.style.transform == `scaleY(-1)`){
          item.style.transform = `unset`;
        }
      });
    }
  }

  render() {
    const { data, step, placeholder, title, validate_message, width } = this.props
    const { selected, selected_step, is_validate } = this.state
    return (
      <div className="input__container">
        {title ? <div>{title}</div> : null}
        <div ref={this.setWrapperRef} className="drop__down__box__container">
          <section className="drop__down__box" onClick={this.clickAndScroll}>
            <header
              onClick={this.toggle}
              className="drop__down__header__select"
              style={{width: width || '55%'}}
            >
              <div className="drop__down__box__icon">
                <div>
                  {selected_step
                    ? selected_step
                    : placeholder
                    ? placeholder
                    : ""}
                </div>
                {selected ? (
                  <div className="drop__down__box__arrow__icon">
                    {" "}
                    <i className="fas fa-sort-down"></i>
                  </div>
                ) : (
                  <div>
                    {" "}
                    <i className="fas fa-sort-up"></i>
                  </div>
                )}
              </div>
            </header>
            <ul
              id={selected ? "drop__down__box--off" : "drop__down__box--active"}
              className="drop__down__list"
            >
              {data.map((o, index) => {
                return (
                  <li
                    className="drop__down__option"
                    onClick={() => this.selectStep(o)}
                    key={index}
                    style={{
                      background: o === selected_step ? '#e4e4e4e0' : 'none'
                    }}
                  >
                    <span className="option__text">{o}</span>
                  </li>
                )
              })}
            </ul>
          </section>
        </div>
        {is_validate ? (
          " "
        ) : (
          <div className="validate__message">{validate_message}</div>
        )}
      </div>
    )
  }
}

export default QuoteTabePageDropDown
