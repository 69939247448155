import * as React from "react";

export const SortOrder = {
    Asc: "asc",
    Desc: "desc",
}

const flip = (sortOrder) =>
    sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;

export const useSorting = (
    initialOrder,
    initialSortBy,
) => {
    const [currentOrder, setCurrentOrder] = React.useState(initialOrder);
    const [currentSortBy, setCurrentSortBy] = React.useState(initialSortBy);

    const toggleSorting = (columnName, baseOrder = SortOrder.Asc) => {
      setCurrentSortBy(columnName);
      setCurrentOrder(columnName === currentSortBy ? flip(currentOrder) : baseOrder);
    }

    return [currentOrder, currentSortBy, toggleSorting];
};
