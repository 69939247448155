import React from "react";
import { useStyles as QuoteStyle } from '../styles'

const QuoteOrderNumber = (props) => {
  const quoteClasses = QuoteStyle();
  const {value, label, disabled} = props;
  let style = {
    padding: '0px 16px 0px 0px',
    color: '#3c3b3b',
  }
  return (
    <div className={quoteClasses.perOfferSection}>
        <span>{label}:</span><span style={style}>{value}</span>
    </div>
  );
};

export default QuoteOrderNumber;