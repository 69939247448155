import uuid from 'uuid'

export class CheckList {
    constructor(systemId, isFactoryServiceType=true){
        this.system_id = systemId
        this.data = isFactoryServiceType ?  [{
          _id: uuid(),
          icon: '',
          row_title: '',
          show_stopper: '',
          order: 1,
          values: [
            { typeOfInput: '-11', value: '' },
            { typeOfInput: '1', value: '' },
            { typeOfInput: 'read', value: '' },
            { typeOfInput: 'checkbox', value: false },
            { typeOfInput: 'text', value: '' },
            { typeOfInput: 'photo', value: [] },
            { typeOfInput: 'text', value: '' },
          ]
        }]: [{
          _id: uuid(),
          icon: '',
          row_title: '',
          show_stopper: '',
          order: 1,
          values: [
            { typeOfInput: '-11', value: '' },
            { typeOfInput: 'text', value: '' },
            { typeOfInput: 'photo', value: [] },
            { typeOfInput: 'text', value: '' },
          ]
        }]
    }
}