import {useState, useEffect} from 'react'
import {generalGetRequest} from '../functions/api/general'

export const useResources = (departmentId) =>{
    const [data, setData] = useState('')
    const getResources = async () => {
        const {ok, result} = await generalGetRequest(`/system/resources/get_by_department_id?department_id=${departmentId}`)
        if(ok && result){
            setData(result)
        }
    }

    useEffect(()=>{
        if(departmentId){
            getResources()
        }
    },[departmentId])

    return data
}