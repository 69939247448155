import { SET_DRAGGING_DESTINATION } from "../actions/types";

const initialState = {
    draggingDestination : null
}


const generalReducers = (state = initialState, action) =>{
    switch (action.type) {
        case SET_DRAGGING_DESTINATION:
            return { ...state, draggingDestination : action.payload}
        default:
            return state;
    }
}

export default generalReducers;