import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField"

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: '#f9f9f9'
  },
  label: {
    color:'black',
    marginBottom:'5px'
  }
}));

function MultipleSelect(props) {
  const classes = useStyles();
  const {label, options, handleChange, value,stateName } = props

  return (
    <Grid item xs={12}>
    <InputLabel className = {classes.label}>{label}</InputLabel>
    <Autocomplete
        multiple = {true}
        options={options}
        getOptionLabel={(option) => option.name}
        onChange={(e, value) => {handleChange(stateName, value)}}
        defaultValue = {value}
        renderInput={(params) => (
            <TextField
                {...params}
                fullWidth
                className={classes.input}
                size = 'small' 
                variant="outlined"
                // value={value}
            />
        )}
    />
</Grid>
  )
}

export const TechniciansMultipleSelectHighlight = (props) => {
  const classes = useStyles();
  const {label, options, handleChange, value,stateName } = props
  let highlightedValue = value?.map(item => item.name) || [];
  return (
    <Grid item xs={12}>
          <InputLabel className = {classes.label}>{label}</InputLabel>
          <Autocomplete
              multiple
              options={options}
              getOptionLabel={(option) => option.name}
              defaultValue = {options.filter(i => highlightedValue.includes(i.name))}
              onChange={(e, value) => {handleChange(stateName, value)}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={classes.input}
                  size = 'small' 
                  variant="outlined"
                  value={value}
                />
              )}
              renderOption={(option) => {
                let isHighlight = highlightedValue.includes(option.name);
                return (
                  <li key={options._id}
                    style={{
                      fontWeight: isHighlight ? 700 : '',
                      width: '100%'
                    }}
                  >
                    {option.name}
                  </li>
                );
              }}
          />
    </Grid>
  )
}

export default MultipleSelect
