import {useState, useEffect } from 'react'
import { generalGetRequest } from '../../functions/api/general'
import moment from 'moment'
import { matchObjectId } from '../../utils'
import { useSelector } from 'react-redux'
import { MONTH_REPORT, YEAR_REPORT } from '../../constants'
import { useLocation } from 'react-router'

export const useChartReport = (reportType, departmentId ) => {
  const [data, setData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState('')

  const increaseStartDateHandler = () => {
    const newStartDate = moment(startDate).add(1, reportType).startOf(reportType).toDate()
    setStartDate(newStartDate)
  }

  const decreaseStartDateHandler = () => {
    const newStartDate = moment(startDate).subtract(1, reportType).startOf(reportType).toDate()
    setStartDate(newStartDate)
  }

  useEffect(()=>{
    const newStartDate = moment().startOf(reportType).toDate()
    setStartDate(newStartDate)
  },[reportType])

  
  useEffect(()=>{
    const getData = async () => {
      setIsLoading(true)
      const depId = matchObjectId(departmentId)
      const res = await generalGetRequest(`/system/reports/chart?type=${reportType}&start_date=${startDate}&department_id=${depId}`)
      if(res.ok && res.result) setData(res.result)
      setIsLoading(false)
    }
    startDate && getData()
  },[departmentId, startDate])

  return {
    charts: data, 
    isLoading, 
    startDate, 
    increaseStartDateHandler, 
    decreaseStartDateHandler
  }
}

export const useDepartmentsOptions = () => {
  const departments = useSelector(state => state.login.departments)
  
  const departmentsOptions = departments.map(i => ({_id: i._id, value: i.name}))
  return departmentsOptions
}

export const useReportType = () => {
  const {pathname} = useLocation()
  const reportType = pathname.includes(MONTH_REPORT) ? MONTH_REPORT : YEAR_REPORT
  return reportType
}