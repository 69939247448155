import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

import "../../sass/header/header.scss";
import "../../sass/adminPanel/_admin.scss";
import { polyfill } from "es6-promise";
import { ControlPanel } from "./ControlPanel";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ListIcon from "@material-ui/icons/List";
import IconButton from "@material-ui/core/IconButton";

import { withStyles, makeStyles } from "@material-ui/core";

polyfill();

const useStyles = makeStyles(() => ({
  smallItemMenu: {
    '& a:visited':{
      color: "rgb(0, 0, 0)"
    },
    '& a:link':{     
        textDecoration: "none",
        color: "rgb(0, 0, 0)",
        padding: "6px",
        fontFamily: "Rubik",
        fontSize: "14px",
        lineHeight: "normal",
        letterSpacing: "normal",
  },
  '& a:hover': {
    color: "#0091ff"
  },
  '& a.header__selected':{   
    color: "#0091ff",
    borderBottom: '2px solid #0091ff'
  }
},
  smallMenu:{

  }
  
}));
const getRoutesMap = () => [
  {
    label: "Factories",
    url: "/admin/factories",
  },
  {
    label: "Users",
    url: "/admin/users",
  }, 
  {
    label: "Departments",
    url: "/admin/department",
  },
  {
    label: "Sub Departments",
    url: "/admin/sub_department",
  },
  // {
  //   label: "Resources",
  //   url: "/admin/resourses",
  // },
  {
    label: "Recipients",
    url: "/admin/recipients",
  },
  {
    label: "Order Types",
    url: "/admin/order_types",
  },
  {
    label: "Gantt Template",
    url: "/admin/gantt_template",
  },
  {
    label: "Gantt Actual",
    url: "/admin/gantt_actual",
  },
  {
    label: "Order Categories",
    url: "/admin/order_categories", 
  },
  {
    label: "Order Collection Stages",
    url: "/admin/order_collection_stages", 
  },
  // {
  //   label: "General section",
  //   url: "/admin/general_section", 
  // },
  {
    label: "Systems",
    url: "/admin/systems", 
  },
  {
    label: "Developers",
    url: "/admin/developers"
  },
];

export default function HeaderAdmin({ history }) {
  const routesMap = getRoutesMap();
  const tabs = useMemo(
    () =>
      routesMap.map((route) => (
        <NavLink
          to={route.url}
          activeClassName="header__selected"
          key={route.label}
        >
          <div>{route.label}</div>
        </NavLink>
      )),
    [routesMap]
  );

  return (
    <div className="admin__panel">
      <div className="header__line">
        <div className="header__tabs normal">
          {tabs}
          </div>
        <div className="header__tabs small">
          <MenuPopup></MenuPopup>
        </div>
        <ControlPanel history={history}></ControlPanel>
      </div>
    </div>
  );
}

export  const CustomIconButton = withStyles({
  root: {
    color: "#0091ff",
    marginRight: "10px",    
    "&:hover": {
      backgroundColor:  "rgba(0, 145, 255, 0.1)"
    },
  },
})(IconButton);


function MenuPopup() {
  const classes = useStyles();
  const routesMap = getRoutesMap();
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <CustomIconButton size="medium" aria-label="list"
            variant="contained"
           
            {...bindTrigger(popupState)}>
            <ListIcon />
          </CustomIconButton>         
          <Menu className={classes.smallMenu} {...bindMenu(popupState)}>
            {routesMap.map((route) => (
              <MenuItem onClick={popupState.close} className={classes.smallItemMenu}   key={route.label} >
                <NavLink
                  to={route.url}
                  activeClassName="header__selected"
                >
                  <div>{route.label}</div>
                </NavLink>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
