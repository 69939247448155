import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core";
import { 
  updateFactory,
} from "../../../actions/actions";
import { FactoryDetails } from "./FactoryDetails";
import DetailsDialog from "../DetailsDialog";

const tableConfig = [
  {
    id: "reason_popup",
    label: "alerts",
    displayOrder: 0,
    type: "bolean",
  },
  {
    id: "type",
    label: "type",
    displayOrder: 1,
    type: "text",
  },
  {
    id: "select_order_process_color_by",
    label: "user / order",
    displayOrder: 2,
    type: "text",
  },
  {
    id: "timezone",
    label: "time zone",
    displayOrder: 3,
    type: "text",
  },
  {
    id: "order_quantity",
    label: "order quantity",
    displayOrder: 4,
    type: "text",
  },
  {
    id: "process_duration",
    label: "process duration",
    displayOrder: 5,
    type: "text",
  },
  {
    id: "mps",
    label: "mps",
    displayOrder: 6,
    type: "text",
  },
  {
    id: "mps_view",
    label: "mps view",
    displayOrder: 7,
    type: "text",
  },
  {
    id: "weekly_labour_cost",
    label: "weekly labour cost",
    displayOrder: 8,
    type: "text",
  },
  {
    id: "start_day",
    label: "start day",
    displayOrder: 9,
    type: "text",
  },
  {
    id: "off_days",
    label: "off days",
    displayOrder: 10,
    type: "array",
  },
  {
    id: "working_hours",
    label: "working hours",
    displayOrder: 11,
    type: "text",
  },
  {
    id: "create_factory_options",
    label: "create factory options",
    displayOrder: 12,
    type: "bolean",
  },
  {
    id: "future_done",
    label: "future done",
    displayOrder: 13,
    type: "bolean",
  },
  {
    id: "name",
    label: "name",
    displayOrder: 14,
    type: "text",
  },
  {
    id: "_id",
    label: "id",
    displayOrder: 15,
    type: "text",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table:{
    direction: "rtl"
  },
  container: {
   // maxHeight: "calc(100vh - 160px)",
    direction: "ltr"
  },
}));

const StyledHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243748",
    color: theme.palette.common.white,
    left: 'unset'
  },
  body: {
    fontSize: 14,
    fontFamily: "Rubik",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TableHeadOrg(props) {
  const classes = useStyles();
  const { tableConfig } = props;
  return (
    <TableHead>
      <TableRow>
        {tableConfig.map((headCell) => (
          <StyledHeadCell
            component="th"
            scope="row"
            key={headCell.id}
            align={headCell.align}
          >
            <div className={classes.headCell}>{headCell.label}</div>
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableFactories(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const { factoryList } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [idCurrent, setIdCurrent] = React.useState(undefined);

  const handleEdit = (id) => {
    setIdCurrent(id);
    setIsOpen(true);
  };

  const handleClose = () => {   
    setIsOpen(false);
  };

  const onSubmit = (id, model) => {
    setIsOpen(false);
    dispatch(updateFactory(model, filterFactory));
  };

  return (
    <div className={classes.root}>
      <DetailsDialog
        isOpen={isOpen}
        onClose={handleClose}
        submitLabel="Update"     
        form="edit-factory"
        title="Edit Factory"
        id={idCurrent}
      >
        <FactoryDetails id={idCurrent} onSubmit={onSubmit} />
      </DetailsDialog>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}  style={{direction: 'ltr', maxHeight: 'calc(100vh - 160px)'}}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHeadOrg tableConfig={tableConfig} />
            <TableBody>
              {factoryList &&
                factoryList.map((element) => {
                  return (
                    <TableRow
                      style={{ height: 33 }}
                      hover
                      tabIndex={-1}
                      key={element._id}
                      onClick={() => handleEdit(element._id)}
                    >
                      {tableConfig.map((col) => {
                        let value = element[col.id];
                        switch (col.id) {
                          case "off_days":
                            value = element[col.id].length
                              ? element[col.id].join(",")
                              : element[col.id];
                            break;
                          case "create_factory_options":
                          case "future_done":
                          case "reason_popup":
                            value =
                              String(element[col.id]) === "undefined"
                                ? ""
                                : String(element[col.id]);
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell component="th" scope="row" key={col.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}


