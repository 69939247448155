import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../sass/adminPanel/_admin.scss";
import {
  getOrderProcesses,
  getByFilterOrderProcesses,
} from "../../../actions/actions";
import TableActualGantt from "./TableActualGantt";

export const ActualGanttList = () => {
  const dispatch = useDispatch();

  const actualGanttList = useSelector(
    (state) => state.ganttActual.listOrderProcesses
  );

  const countActualGantt = useSelector(
    (state) => state.ganttActual.countOrderProcesses
  );

  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const filterDepartment = useSelector(
    (state) => state.departments.filterDepartment
  );
  const page = useSelector((state) => state.ganttActual.currentPage);
  const rowsPerPage = useSelector((state) => state.ganttActual.rowsPerPage);

  useEffect(() => {
    //   dispatch(getOrderProcesses(filterFactory));
    // dispatch(getByFilterOrderProcesses(500,0,-1,filterFactory));
    dispatch(getByFilterOrderProcesses(rowsPerPage, page*rowsPerPage, -1, filterFactory));
  }, [dispatch, page, rowsPerPage, filterFactory, filterDepartment]);

  return (
    <div className="admin_page">
      <TableActualGantt
        actualGanttList={actualGanttList}
        countActualGantt={countActualGantt}
      ></TableActualGantt>
    </div>
  );
};
