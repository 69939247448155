import React, { useEffect, useState } from 'react'
import "../FileTab/FileTab.scss";
import "../../PopUps/AddFilePopUp/AddFilePopUp.scss"
import { Grid } from '@material-ui/core';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import SingleTaskDetails from './SingleTaskDetails/SingleTaskDetails';
import TabEmptyLanding from '../TabEmptyLanding/TabEmptyLanding';
import { TASKS } from '../../../../constants/translations/review-popup';
import { MOBILE_LANDING_PAGE } from '../../../../constants/translations/customer/mobile/mobileView';
import { useSelector, useDispatch } from 'react-redux';
import { useTasks } from '../../../../hooks/useTasks';
import { taskActionsCreator } from '../../../../actions/customer/taskActions';


const footerData = {
    btnName: MOBILE_LANDING_PAGE.TASK,
    field: 'task'
}

export default function TaskTab(props) {
    const dispatch = useDispatch();
    const {handleClose, process, orderProcessId, orderProcess, view } = props;
    const tasksMobileDataList = useSelector((state) => state.tasks.tasksMobile);
    const [tasks, setTasks] = useTasks(orderProcessId, orderProcess);



    useEffect(() => {
        dispatch(taskActionsCreator.setTasksToTasksListTablet(tasks));
    }, [dispatch, tasks]);

    return (
        <Grid container spacing={0} className='FileTab__main'>
            <MobileHeader
                headerName={TASKS}
                processName={process && process.process_name || 'Installation'}
                customerName={process && process.client_name || 'Zain bator'}
                orderNumber={process && process.order_number || ' S02100004B'}
                handleClose={handleClose}
                city={process && process.city}
            />
          
            {
                tasksMobileDataList.rows.length ? <Grid className='FileTab__filesList'>
                    {
                        tasksMobileDataList.rows.map((task, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <SingleTaskDetails row={task} columns={tasksMobileDataList.columns} />
                                </React.Fragment>

                            )   
                        })
                    }

                </Grid>
                    : <TabEmptyLanding title={TASKS} />
            }
            <TabEmptyLanding title={TASKS} />
            {/* {
                tasksMobileDataList ? <Grid className='FileTab__filesList'>
                    {
                        tasksMobileDataList.rows.map((task, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <SingleTaskDetails row={task} columns={tasksMobileDataList.columns} />
                                </React.Fragment>

                            )
                        })
                    }

                </Grid> : <TabEmptyLanding title={TASKS} />
            } */}


            <Footer data={footerData} handleClose={handleClose} process={process && process}/>
        </Grid>

    )
}
