import * as types from "./types";

export const setValueForTasksReportFiltering = (data) => {
    return {
        type: types.SET_TASKS_REPORT_VALUE,
        payload: data
    }
}

export const setQueryForTasksReportAutocomplete = (data) => {
    return {
        type: types.SET_TASKS_REPORT_FILTER_QUERY,
        payload: data
    }
}

export const setAutocompleteOptions = (data) => {
    return {
        type: types.SET_OPTIONS_FOR_AUTOCOMPLETE,
        payload: data
    }
}


