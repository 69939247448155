export const HANDLE_TYPE_OPTIONS = 'HANDLE_TYPE'
export const COLOR_OPTIONS = 'COLOR'
export const NET_OPTIONS = 'NET'
export const HUE_OPTIONS = 'HUE'
export const ENGINE_OPTIONS = 'ENGINE'
export const GLASS_OPTIONS = 'GLASS'
export const SHUTTER_OPTIONS = 'SHUTTER'
export const BOX_OPTIONS = 'BOX'
export const HANDLE_OPTIONS = 'HANDLE'
export const SHUTTER_OPEN_OPTIONS = 'SHUTTER_OPEN'

// THESE TYPES USES FOR CALCULATION COST OF METAL AND GLASS IN PRODUCT
export const TYPE_650 = '650'
export const TYPE_400 = '400'
export const TYPE_500 = '500'
const ADD_FOR_TYPE_500 = 500
const ADD_IF_SQUARE_IS_LITTLE = 1000

// product calculation formulas

export const getCostAluminiumAndGlass = (seriesCost, glassCost, shutterCost, height, width, type) => {
    const square = width * height
    const isLittleSquare = square < 10000
    let summ = 0
    switch (type){
        case TYPE_650:{
            summ = (650 + shutterCost) * height * width / 10000
            break
        }
        case TYPE_400:{
            summ = 400 * height * width / 10000 
            break
    }
        case TYPE_500:
            summ = (seriesCost + glassCost + shutterCost) * height * width / 10000 + ADD_FOR_TYPE_500
            break
        default:
            summ = (seriesCost + glassCost + shutterCost) * height * width / 10000
            break
    }
    // const summ = (seriesCost + glassCost + shutterCost) * height * width / 10000 + ADD_IF_SQUARE_IS_LITTLE
    return isLittleSquare ? summ + ADD_IF_SQUARE_IS_LITTLE : summ

}
export const getCostOfBox = (boxCost, boxWidth)=> boxCost * boxWidth / 100
export const getCostOfEngine = (engineCost) => engineCost
export const getCostOfNet = (netCost, netHeight, netWidth) => netCost * netHeight * netWidth / 10000
export const getCostOfBuffer = (bufferCost) => bufferCost * 150 / 100
export const getPartitonCost = (partitionCost) => partitionCost * 120
export const getCostOfHandle = (handleCost) => handleCost