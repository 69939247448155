import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useStyles } from '../style'

const CustomizedSelect = ({ value, changeHandler, options, property, name='' }) => {
    const classes = useStyles()
    return (
      <TableCell align = 'right' >
        <Select
          // className={classes.selectTableCell}
          value={value || ''}
          onChange={(e) => changeHandler(e.target.value)}
          disableUnderline
        >
          {
            options?.map((i, idx) => <MenuItem value={property && i._id ? i._id : i[property]} key={idx}>{property ? i[property] : i} {(name.trim() && name.trim().length > 0) ? <span>&emsp;{(i[name].trim())}</span> : ''} </MenuItem>)
          }
        </Select>
      </TableCell>
    )
  }
  
  export default CustomizedSelect