//  Metalpress service call

export const SERVICE_CALL_CATEGORY_ID = "5f6e0ee658bf6041b4953c9e"
export const SERVICE_CALL_SUB_CATEGORY_ID = "5e67c7a43a59d884fc6285d2"
// export const serviceCallType = 0
export const SERVICE_CALL_QUANTITY = 1

// types of service call

export const SERVICE_CALL_TYPE_ALL_SYSTEMS = 0
export const SERVICE_CALL_TYPE_SELECTED_SYSTEMS = 2
export const SERVICE_CALL_TYPE_HALF_YEAR = 3

//  TYPES OF SYSTTEMS SYSTEM_LAYER HIERARCHY 
// export const TOP_LEVEL_SYSTEMS = 'TOP_LEVEL_SYSTEMS'
// export const SYSTEMS = 'SYSTEMS'
// export const SUBSYSTEMS = 'SUBSYSTEMS'

//TYPES OF SYSTTEMS LEVEL HIERARCHY  === system levels e.g. system =>subsystem

export const SYSTEM_LAYER_1 = 1
// if input number greater or equal 0 input is multiple else if input number less then 0 input is single
export const MULTIPLE_VALUES = 0
// export const SYSTEM_LAYER_2 = 2
// export const SYSTEM_LAYER_3 = 3
// export const SYSTEM_LAYER_4 = 4

export const MAX_TAB_CONTENT_HEIGHT = 'calc(100vh - 450px)' /* 455 px is height of header & tabs navigation & buttons & short customer info */
export const BLUE_COLOR = "#0091ff"
export const GREY_COLOR = '#6C7389'
export const WHITE_COLOR = '#FFFFFF'
export const CUSTOMERS_PAGE_FONT_SIZE = '14px'

//Messages
export const ORDER_ADDED_SUCCESS = "ההזמנה נוספה בהצלחה"
export const CUSTOMER_TABLE = "customers-table"
export const CUSTOMER_PAGES = "customers-page"
export const CREATE_QUOTES = "create-quote"

