export const paymentDetailsTableData = {
    popUpData: [

        {
            label: "Edit File",
            icon: 'edit'
        },
        {
            label: "Delete File",
            icon: 'delete'
        },
        {
            label: "Move Up",
            icon: 'up'
        },
    ],
    columns: [
        {
            field: 'stepID',
            headerName: 'Step ID',
        },
        {
            field: 'stepName',
            headerName: 'Step Name',
        },
        {
            field: 'percent',
            headerName: 'Percent (%)',
        },
        {
            field: 'sum',
            headerName: 'Sum',
        },
        {
            field: 'reminder',
            headerName: 'Reminder (days)',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    rows: [
        { id: 1, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 2, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 3, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 4, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 5, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        // { id: 6, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '100px', height: '30px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        // { id: 7, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '100px', height: '30px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        // { id: 8, stepID: 'S000111', stepName: 'Advance', percent: '10', sum: '22,236.80', reminder: '7' },
    ],
}
export const step = {
    name: 'Step',
    width: '30%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#186315",
    fontSize: '16px',
    fontWeight: 800,
    icon: true,
    iconContainer: true,
    border: false,
    isBoxShadow: true,
    buttonName: 'AddIcon'
}
export const add = {
    name: 'Add',
    width: '35%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
}
export const cancel = {
    name: 'Cancel',
    width: '35%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
}