import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    styledCell:{
        paddingTop: 0,
        paddingBottom: 0
    },
    colorForIcons:{
        color: '#0091ff',
        cursor: 'pointer'
    },
  });