import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({

    header: {
        color: '#ffffff',
        height: '40px',
        padding: '0 20px',
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Assistant',
        fontWeight: 'bold',
        justifyContent: 'space-between',

        backgroundColor: '#243748'
    },

    content_h1: {
        textAlign: 'center',
        paddingTop: '7px',
        width: '100px',
        float: 'left',
        fontSize: '16px'
    },
    tools: {
        display: "flex",
        marginBottom: '20px',
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: '20px',
        backgroundColor: "#ffffff"
    },
    block1: {
        width: "63%",
        height: "auto",
        marginBottom: '0px',
        background: "#ffffff",
        color: "black",
        textAlign: "left",
        marginRight: '10px',
        paddingRight: '10px',
        paddingLeft: '10px',
        marginLeft: '10px',
        boxShadow: '0px 5px 10px 2px #E7E7E7',
    },
    block2: {
        marginLeft: '10px',
        width: "33%",
        height: "400px",
        background: "#ffffff",
        color: "black",
        textAlign: "left",
    },
    textarea: {
        borderColor: 'grey',
    },

    parafraph: {
        color: '#0B153B',
        display: "flex",
        fontSize: "16px",
        fontStyle: "normal",
        alignItems: "center",
        //fontFamily: "Gilroy",
        fontFamily: 'Rubik',
        fontWeight: "500",
        lineHeight: "17px",
        marginBottom: "5px",
        justifyContent: "space-between",
        flexFirection: "row",
        flexWrap: "nowrap",
        alignContent: "space-around",
        marginRight: '5px'
    },

    btn_save: {
        backgroundColor: '#0091FF',
        borderRadius: '20px',
        width: '28%',
        marginBottom: "20px",
        height: '36px',
        marginLeft: '10px',
        color: 'white',
        border: '#FFFFFF',
        fontFamily: 'Rubik',
    },

    fieldinput: {
        background: "#FFFFFF",
        border: "1px solid #E7E7E7",
        height: '125px',
        boxSizing: "border-box",
        marginBottom: '15px',
        width: '100%',
        borderRadius: "4px",
    },

    contentBlock: {
        height: '400px'
    },

    logoBlock: {
        marginLeft: '10px',
        position: "static",
        height: "200px",
        left: "0px",
        top: "0px",
        width: 'auto',
        flex: "none",
        order: "0",
        flexGrow: "0",
    },

    logoContent: {
        width: "200px",
        height: "90px",
        display: 'flex',
        alignItems: 'flex-start',
        //fontFamily: 'Encode Sans',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        flexDirection: 'column',
        fontWeight: 'normal',
        float: 'right',
        fontSize: '16px',
        lineHeight: '16px',
    },

    audit: {
        marginTop: '30px'
    },

    auditName: {
        //fontFamily: 'Encode Sans',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '30px',
        textAlign: 'center',
        color: '#153F64'
    },

    hr: {
        display: 'block',
        height: '4px',
        width: '30%',
        backgroundColor: '#3E4C6A',
        marginTop: '15px'
    },
    presentation: {
        //fontFamily: 'Encode Sans',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        marginTop: '30px',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '15px',
        textAlign: 'right',
    },
    presentationBold: {
        //fontFamily: 'Encode Sans',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        marginTop: '30px',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '15px',
        textAlign: 'right',

    },

    reviewDetails: {
        marginTop: '30px'
    },
    headerText: {
        //fontFamily: 'Encode Sans, sans-serif',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '10px',
        textAlign: 'right',
        color: '#3E4C6A',
        width: '100%'
    },

    headerLine: {
        height: '2px',
        background: '#3E4C6A',
    },

    descriptionText: {
        //fontFamily: 'Encode Sans',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '15px',
        textAlign: 'right',
        color: '#3E4C6A',
        marginLeft: '7px',
    },

    underLogo: {
        //fontFamily: 'Encode Sans',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '12px',
        color: '#302F2E',
        marginTop: '10px'
    },
    rotate: {
        display: 'block',
        whiteSpace: 'nowrap',
        writingMode: 'vertical-rl',
        textOrientation: 'mixed'
    },
    pdf_section_btn: {
        display: 'flex',
        justifyContent: 'center'
    },
    pdf_Right_Block_Container: {
        height: 'calc(100vh - 126px)',
        overflowY: 'auto',
    },
    verticalText:{
        transform: 'rotate(90deg)'
    }

}))