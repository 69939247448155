import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFactory,
  getAllSubDepartment,
  getProcess,
  setProcessDetais,
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {  getAllProcessesFuncApi, getAllWithoutProcessesFuncApi } from "../../../functions/api/admin";
import ColorPicker from "material-ui-color-picker";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223, 
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  formCheck: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastformCheck: {  
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },  
  fixBottomGridItem: {  
    "& .MuiDivider-middle": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      
    },   
    backgroundColor: "#fff",
    position: "sticky",
    bottom: "-8px",
    zIndex: "2"
  },  
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const GanttTemplateDetails = (props) => {
  const dispatch = useDispatch();
  const procDetails = useSelector(
    (state) => state.ganttTemplate.processesDetails
  );
  const factoryList = useSelector((state) => state.factories.listFactories);

  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const subDepartmentsList = useSelector(
    (state) => state.subDepartments.listSubDepartments
  );

  const [constrProcesseslist, setConstrProcessesList] = useState([]);
  const [futureProcesseslist, setFutureProcessesList] = useState([]);

  useEffect(() => {
    if (props.id) {
      dispatch(getProcess(props.id));
    }
    return function cleanup() {
      dispatch(setProcessDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllSubDepartment());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await getAllWithoutProcessesFuncApi();
      setConstrProcessesList(res);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let res = await getAllWithoutProcessesFuncApi();
      setFutureProcessesList(res);
    };
    fetchData();
  }, []);

  return (
    <Grid container spacing={0}>    
      <>
        {props.id !== undefined && procDetails && (
          <GanttTemplateForm
            id={props.id}
            procDetails={procDetails}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            subDepartmentsList={subDepartmentsList}
            constrProcesseslist={constrProcesseslist}
            futureProcesseslist={futureProcesseslist}
            getIsAddToOrder={props.getIsAddToOrder}
          />
        )}
        {props.id === undefined && (
          <GanttTemplateForm
            id={props.id}
            procDetails={procDetails}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            subDepartmentsList={subDepartmentsList}
            constrProcesseslist={constrProcesseslist}
            filterFactory={filterFactory}
            futureProcesseslist={futureProcesseslist}
            getIsAddToOrder={props.getIsAddToOrder}
          />
        )}
      </>
    </Grid>
  );
};

function GanttTemplateForm(props) {
  const classes = useStyles();

  const {
    constrProcesseslist,
    futureProcesseslist,
    id,
    factoryList,
    subDepartmentsList,
    filterFactory,
    procDetails,
    getIsAddToOrder
  } = props;

  const getSubDepartmentList = () => {
    if (values.factory_id) {
      let depts = [];
      depts = subDepartmentsList.filter((element) => {
        return element.factory_id === values.factory_id;
      });
      return depts;
    } else {
      return []
    }
  };

  const getConstrProcessesList = () => {
    if (values.factory_id) {
      let prosess = [];
      prosess = constrProcesseslist
        ? constrProcesseslist.filter((element) => {
            return element.factory_id === values.factory_id;
          })
        : [];
      return prosess;
    } else {
      return []
      // return constrProcesseslist ? constrProcesseslist : [];
    }
  };

  const getFutureProcessesList = () => {
    if (values.factory_id) {
      let prosess = [];
      prosess = futureProcesseslist
        ? futureProcesseslist.filter((element) => {
            return element.factory_id === values.factory_id;
          })
        : [];
      return prosess;
    } else {
      return []//futureProcesseslist ? futureProcesseslist : [];
    }
  };

  const getConstraintsProcessId = () => {
    let value = [];
    if (procDetails && procDetails.constraints_process_id) {
      procDetails.constraints_process_id.forEach((procDetailsElement) => {
        let res = constrProcesseslist.find((element) => {
          return element._id == procDetailsElement;
        });
        if (res != -1) {
          value.push(res);
        }
      });
    }
   
    let valueProcessesСonstrainedByCurrent = [];
    if (procDetails && procDetails.processesСonstrainedByCurrent) {
      procDetails.processesСonstrainedByCurrent.forEach((procElement) => {
        let res = constrProcesseslist.find((element) => {
          return element._id == procElement._id;
        });
        if (res != -1) {
          valueProcessesСonstrainedByCurrent.push(res);
        }
      });
    }
    setValues(values =>({ ...values, processesСonstrainedByCurrent: valueProcessesСonstrainedByCurrent, constraints_process_id: value  }));
  };

  
  const getFutureProcessId = () => {
    let value = [];
    if (procDetails && procDetails.future_parallel_process_id) {
      procDetails.future_parallel_process_id.forEach((procDetailsElement) => {
        let res = futureProcesseslist.find((element) => {
          return element._id == procDetailsElement;
        });
        if (res != -1) {
          value.push(res);
        }
      });
    }
    setValues(values =>({ ...values, future_parallel_process_id: value }));
  };

  useEffect(() => {
    getConstraintsProcessId();
  }, [constrProcesseslist]);

  useEffect(() => {  
    getFutureProcessId();
  }, [futureProcesseslist]);

  const [values, setValues] = useState({
    name: procDetails ? procDetails.name : "",
    factory_id: procDetails ? procDetails.factory_id : '',
    sub_department_id: procDetails ? procDetails.sub_department_id :undefined,
    constraints_process_id: [], 
    future_parallel_process_id: '', 
    processesСonstrainedByCurrent: [],
    previous_process_id: procDetails ? procDetails.previous_process_id : undefined,
    gap_from_previous: procDetails ? procDetails.gap_from_previous : '',
    planning: procDetails ? procDetails.planning : false,
    mps: procDetails ? procDetails.mps : '',
    duration: procDetails ? procDetails.duration : '',
    constant: procDetails ? procDetails.constant : false,
    is_parallel_process: procDetails ? procDetails.is_parallel_process : false,
    last_process: procDetails ? procDetails.last_process : false,
    type: procDetails ? procDetails.type : '',
    order: procDetails ? procDetails.order : '',
    quantity: procDetails ? procDetails.quantity : '',
    color: procDetails
      ? procDetails.color
        ? procDetails.color
        : "#FFFFFF"
      : "#FFFFFF",
    work_cost_percentage: procDetails?.work_cost_percentage || '',
    should_occur_parallel: procDetails?.should_occur_parallel || false,
  });
  const [isAddToOrder, setIsAddToOrder] = useState(false)

  const getFactoryId = () => {
    let res = procDetails
      ? procDetails.factory_id
      : filterFactory
      ? filterFactory
      : '';
    setValues(values =>({ ...values, factory_id: res }));
  };

  const initFactoryId = useMemo(() => getFactoryId(), [filterFactory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((values =>({ ...values, [name]: value })));
  };

  const handleMultiSelect = (e, name, option) => {
    let value = option.length
      ? option.map((element) => {
          return element;
        })
      : [];

    setValues(values =>({ ...values, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setValues(values =>({ ...values, [name]: checked }));
  };

  const handleAddToOrderChange = (e) => {
    const { checked } = e.target;
    setIsAddToOrder({isAddToOrder: checked });
    if (getIsAddToOrder  ){
      getIsAddToOrder(checked)
    }
  };
  

  const handleColorChange = (value) => {
    setValues(values =>({ ...values, color: value }));
  };

  const onSubmit = (event ) => {
    event.preventDefault();
    const {
      name,
      sub_department_id,
      factory_id,
      constraints_process_id,
      processesСonstrainedByCurrent,
      future_parallel_process_id,
      previous_process_id,
      gap_from_previous,
      planning,
      mps,
      duration,
      constant,
      is_parallel_process,
      should_occur_parallel,
      last_process,
      type,
      order,
      color,
      quantity,
      work_cost_percentage,
    } = values;
    let model;

    if (id) {
      model = {
        _id: id,
        sub_department_id,
        name,
        factory_id,
        constraints_process_id,
        processesСonstrainedByCurrent,
        future_parallel_process_id,
        previous_process_id,
        gap_from_previous,
        planning,
        mps,
        duration,
        constant,
        is_parallel_process,
        should_occur_parallel,
        last_process,
        type,
        order,
        color,
        quantity,
        work_cost_percentage,
      };
    } else {
      model = {
        sub_department_id,
        name,
        factory_id,
        constraints_process_id,
        processesСonstrainedByCurrent,
        future_parallel_process_id,
        previous_process_id,
        gap_from_previous,
        planning,
        mps,
        duration,
        constant,
        is_parallel_process,
        should_occur_parallel,
        last_process,
        type,
        order,
        color,
        quantity,
        work_cost_percentage
      };
    }

    props.onSubmit(id, model, isAddToOrder);
  };

  return (
    <div>
      <form
        id="edit-gantt-template"
        onSubmit={onSubmit}
        className={classes.form}
      >
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <ColorPicker
              name="color"
              name="color"
              variant="outlined"
              label="color"
              defaultValue="color"
              value={values.color}
              onChange={handleColorChange}
            />
          </Grid>
          <Grid item>
            <TextField
              name="order"
              variant="outlined"
              label="order"
              type="number"
              value={values.order}
              onChange={handleInputChange}
            />
            <TextField
              name="type"
              variant="outlined"
              label="type"
              type="number"
              value={values.type}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <TextField
              name="gap_from_previous"
              variant="outlined"
              label="gap from previous"
              value={values.gap_from_previous}
              onChange={handleInputChange}
            />
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="previous_process_label">
                previous process
              </InputLabel>
              <Select
                labelId="previous_process_label"
                id="previous_process_id"
                value={values.previous_process_id}
                onChange={handleInputChange}
                label="previous process"
                name="previous_process_id"
              >
                {getConstrProcessesList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <TextField
              name="mps"
              variant="outlined"
              label="mps"
              value={values.mps}
              type="number"
              onChange={handleInputChange}
            />
            <TextField
              name="duration"
              variant="outlined"
              label="duration"
              value={values.duration}
              type="number"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <TextField
              name="quantity"
              variant="outlined"
              label="quantity"
              value={values.quantity}
              type="number"
              onChange={handleInputChange}
            />
            <TextField
              name="work_cost_percentage"
              variant="outlined"
              label="work cost percentage"
              value={values.work_cost_percentage}
              type="number"
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="sub_department_label">sub department</InputLabel>
              <Select
                labelId="sub_department_label"
                id="sub_department_id"
                value={values.sub_department_id}
                onChange={handleInputChange}
                label="sub department"
                name="sub_department_id"
              >
                {getSubDepartmentList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="constraints_process_id"
              options={getConstrProcessesList()}
              getOptionLabel={(option) => option?.name}
              value={ values.constraints_process_id }
              onChange={(e, option) =>
                handleMultiSelect(e, "constraints_process_id", option)
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="constraints process id"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="future_parallel_process_id"
              options={getFutureProcessesList()}
              getOptionLabel={(option) => option?.name}
              value={
                values.future_parallel_process_id &&
                values.future_parallel_process_id.length
                  ? values.future_parallel_process_id.map((element) => {
                      return element;
                    })
                  : []
              }
              onChange={(e, option) =>
                handleMultiSelect(e, "future_parallel_process_id", option)
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="future parallel process_id"
                />
              )}
            />
          </Grid>

          <Grid item>
            <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="processesСonstrainedByCurrent"
              options={getConstrProcessesList()}
              getOptionLabel={(option) => option?.name}
              value={values.processesСonstrainedByCurrent}
              onChange={(e, option) =>
                handleMultiSelect(e, "processesСonstrainedByCurrent", option)
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="processes constrained by current process"
                />
              )}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="planning"
                  checked={values.planning}
                  onChange={handleCheckboxChange}
                />
              }
              label="planning"
            />
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="constant"
                  checked={values.constant}
                  onChange={handleCheckboxChange}
                />
              }
              label="constant"
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="is_parallel_process"
                  checked={values.is_parallel_process}
                  onChange={handleCheckboxChange}
                />
              }
              label="for forward"
            />
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="last_process"
                  checked={values.last_process}
                  onChange={handleCheckboxChange}
                />
              }
              label="last process"
            />
          </Grid>  
          <Grid item>
            <FormControlLabel
                className={classes.formCheck}
                control={
                  <Checkbox
                    name="should_occur_parallel"
                    checked={values.should_occur_parallel}
                    onChange={handleCheckboxChange}
                  />
              }
              label="parallel process"
            />
          </Grid>
        </Grid>
      </form>
      <Grid  className={classes.fixBottomGridItem} >
      <Grid item>
        <div style={{height: 10}}/>
     </Grid>
      <Grid item>
      <Divider variant="middle" />
     </Grid>
      <Grid item>
            <FormControlLabel
              className={classes.lastformCheck}
              control={
                <Checkbox
                  name="is_add_to_order"
                  checked={isAddToOrder}
                  onChange={handleAddToOrderChange}
                />
              }
              label="choose if you want to add a new process to orders"
            />          
          </Grid>     
          </Grid>          
    </div>
  );
}
