import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core";
import { updateEmployee } from "../../../actions/actions";
import { getModalProps } from "../../modal/modalProps";
import { useModal } from "../../../hooks/useModal";
import { ResourceDetails } from "./ResourceDetails";
import DetailsDialog from "../DetailsDialog";
import { changeCurrentPage, changeRowsPerPage, dropResource } from "../../../actions/resource_actions";
import TablePagination from '@material-ui/core/TablePagination';

const tableConfig = [
  {
    id: "factory_name",
    label: "factory",
  },
  {
    id: "factory_id",
    label: "factory id",
  },
  {
    id: "sub_department_id",
    label: "sub department id",
  },
  {
    id: "sub_department_name",
    label: "sub department",
  },
  {
    id: "email",
    label: "email",
  },
  {
    id: "name",
    label: "name",
  },
  {
    id: "user_full_name",
    label: "user name",
  },
  {
    id: "user_for_resource_id",
    label: "user id",
  },
  {
    id: "priority",
    label: "priority",
  },
  {
    id: "_id",
    label: "id",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table:{
    direction: "rtl"
  },
  container: {
    maxHeight: "calc(100vh - 160px)",
    direction: "ltr"
  },
  pagination: {
    direction: "ltr",
  },
}));

const StyledHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243748",
    color: theme.palette.common.white,
    left: 'unset'
  },
  body: {
    fontSize: 14,
    fontFamily: "Rubik",
  },
}))(TableCell);

function TableHeadOrg(props) {
  const classes = useStyles();
  const { tableConfig } = props;
  return (
    <TableHead>
      <TableRow>
        {tableConfig.map((headCell) => (
          <StyledHeadCell
            component="th"
            scope="row"
            key={headCell.id}
            align={headCell.align}
          >
            <div className={classes.headCell}>{headCell.label}</div>
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableResource(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const { resourcesList, countResource } = props;

  
  const [isOpen, setIsOpen] = React.useState(false);
  const [idCurrent, setIdCurrent] = React.useState(undefined);

  const page = useSelector(state => state.resources.currentPage);
  const rowsPerPage = useSelector(state => state.resources.rowsPerPage);

  const handleEdit = (id) => {
    setIdCurrent(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (id, model) => {
    setIsOpen(false);
    dispatch(updateEmployee(model, filterFactory));
  };

  const onDelete = (_id) =>{
    dispatch(dropResource(_id, filterFactory))
    setIsOpen(false);
  }

  const handleChangePage = (event, newPage) => {
    dispatch(changeCurrentPage(newPage));
};

const handleChangeRowsPerPage = event => {
    dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
};

  return (
    <div className={classes.root}>
      <DetailsDialog
        isOpen={isOpen}
        onClose={handleClose}
        submitLabel="Update"
        deleteLabel="Delete"
        form="edit-resource"
        onDelete={onDelete}
        title="Edit Employee"
        id={idCurrent}
      >
        <ResourceDetails id={idCurrent} onSubmit={onSubmit} />
      </DetailsDialog>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeadOrg tableConfig={tableConfig} />
            <TableBody>
              {resourcesList &&
                resourcesList.map((element) => {
                  return (
                    <TableRow
                      style={{ height: 33 }}
                      hover
                      tabIndex={-1}
                      key={element._id}
                      onClick={() => handleEdit(element._id)}
                    >
                      {tableConfig.map((col) => {
                        let value = element[col.id];
                        return (
                          <TableCell component="th" scope="row" key={col.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          colSpan={3}
          count={countResource}
          // count = {resourcesList?.length}
          rowsPerPage={rowsPerPage}
          page={page} 
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}



  // const handleEdit = useCallback(
  //   (id) => {
  //     const onSubmit = (id, value) => {
  //       closeModal();
  //       dispatch(updateEmployee(value, filterFactory));
  //     };
  //     const modalProps = getModalProps({
  //       isModal: false,
  //       // title: `Edit Employee `,
  //       component: <ResourceDetails id={id} onSubmit={onSubmit} />,
  //       onClose: closeModal,
  //       submitLabel: "Update",
  //       form: "edit-resource",
  //     });
  //     setModalProps(modalProps);
  //   },
  //   [closeModal, dispatch]
  // );
