import * as types from "../actions/types";

const initialState = {
  loading: false,
  listRecipients: [],
  recipientDetails: null,
  error: null,
  countRecipients:0,
  currentPage: 0,
  rowsPerPage: 10,
};

export default function recipientReducer(state = initialState, action) {
  switch (action.type) {
    case types.RECIPIENTS_CHANGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }; 
    case types.RECIPIENTS_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,        
        rowsPerPage: action.payload
      };

      case types.ADMIN_GET_FILTER_RECIPIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }; 
    case types.ADMIN_GET_FILTER_RECIPIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listRecipients: action.payload.result,
        countRecipients:action.payload.count
      };
    case types.ADMIN_GET_FILTER_RECIPIENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.GET_ALL_RECIPIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_RECIPIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listRecipients: action.payload,
      };
    case types.GET_ALL_RECIPIENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    case types.RECIPIENTS_GET_BY_FACTORY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RECIPIENTS_GET_BY_FACTORY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listRecipients: action.payload,
      };
    case types.RECIPIENTS_GET_BY_FACTORY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    case types.GET_BY_ID_RECIPIENT_SUCCESS:
      return { 
        ...state,
        isLoading: false,
        error: null,
        recipientDetails: action.payload,
      };
    case types.SET_RECIPIENT:
      return {
        ...state,
        recipientDetails: action.payload,
      };
    default:
      return state;
  }
}
