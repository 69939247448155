import React, {useState, useEffect} from 'react'
import { amountPdfStyle } from '../styles'
import { QUOTE, QUOTE_PDF } from '../../../../constants/translations/customersPage'
import {useDispatch} from 'react-redux'
import {setTotalProductsQuantity, setTotalAmount} from '../../../../actions/quotation-actions'
import { convertIntoCurrency, getAllExtraCharges, getPercentAmount } from '../../../../hooks/helper'
import CustomizedInputProduct, { CustomizedTotalAmount } from '../../reused_components/CustomizedInputProduct'

const TotalAmountPdf = (props) => {
    const { totalCost, productDiscount, extraCharges , totalCostWithVAT, titleName, value} = props;

    const { TOTAL, EXTRAS, DISCOUNT, DISCOUNT_AMOUNT, TOTAL_AFTER_DISCOUNT,  SHEKEL } = QUOTE;
    const { TOTAL_PAYMENT_INCLUDING_VAT } = QUOTE_PDF

    let discountAmount = 0;
    let remainingAmount = 0

    const calculateAmount = () => {
        let total_ExtraChareges_Amount = getAllExtraCharges(extraCharges)
        discountAmount = getPercentAmount(productDiscount, (Number(totalCost || 0) + Number(total_ExtraChareges_Amount || 0))); 
        remainingAmount =  (Number(totalCost || 0) + Number(total_ExtraChareges_Amount || 0)) -  discountAmount;
    } 

    calculateAmount();

    return(
        <div 
        style = {amountPdfStyle.totalAmountSection}
        >
            <div style={amountPdfStyle.amountSection}>
                <p style ={amountPdfStyle.amountLabel}>{EXTRAS}:</p>
                <p style={amountPdfStyle.amountLabel}>{convertIntoCurrency(getAllExtraCharges(extraCharges), 'ILS')}</p>
            </div>
            <div style={amountPdfStyle.amountSection}>
                <p style ={amountPdfStyle.amountLabel}>{TOTAL}:</p>
                <p style={amountPdfStyle.amountLabel}>{convertIntoCurrency(totalCost, 'ILS')}</p>
            </div>
            <div style={amountPdfStyle.amountSection}>
                <p style = {amountPdfStyle.amountLabel}>{DISCOUNT}:</p>
                <p style={amountPdfStyle.amountLabel}>% {productDiscount}</p>
            </div>
            <div style={amountPdfStyle.amountSection}>
                <p style = {amountPdfStyle.amountLabel}>{DISCOUNT_AMOUNT}:</p>
                <p style={amountPdfStyle.amountLabel}>{convertIntoCurrency(discountAmount, 'ILS')}</p>
            </div>
            <div style={amountPdfStyle.amountSection}>
                <p style = {amountPdfStyle.amountLabel}>{TOTAL_AFTER_DISCOUNT}:</p>
                <p style={amountPdfStyle.amountLabel}>{convertIntoCurrency(remainingAmount, 'ILS')}</p>
            </div>
            <hr style={amountPdfStyle.amountLine}/>
            <div style={amountPdfStyle.amountSection}>
                <p style = {amountPdfStyle.amountLabel}>{TOTAL_PAYMENT_INCLUDING_VAT}</p>
                <p style={{...(amountPdfStyle.amountLabel), color: '#0091ff'}}>{convertIntoCurrency(totalCostWithVAT, 'ILS')}</p>
            </div>
            <div style={amountPdfStyle.amountSection}>
                <p style = {amountPdfStyle.amountLabel}>{titleName}</p>
                <p style={amountPdfStyle.amountLabel}>{value}</p>
            </div>
        </div>
    )
}

export default TotalAmountPdf