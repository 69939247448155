import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFactory, getByFilterUsers } from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";
import TableUser from "./TableUser";


export const UserList = () => {
  const dispatch = useDispatch();

  const userList = useSelector(
    (state) => state.users.listUsers
  );
  const countUsers = useSelector(
    (state) => state.users.countUsers
  ); 
  const page = useSelector((state) => state.users.currentPage);
  const rowsPerPage = useSelector((state) => state.users.rowsPerPage);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]); 
   
  useEffect(() => { 
    dispatch(getByFilterUsers(rowsPerPage, page*rowsPerPage, -1, filterFactory));      
  }, [dispatch,  page, rowsPerPage, filterFactory]);

  return (
    <div className = "admin_page">  
      <TableUser userList = {userList} 
        countUsers={countUsers}></TableUser>
    </div>
  );
};
