import React from "react";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {  TableCell, TableRow} from "@material-ui/core";
import CustomizedTextInput from '../../../Systems/Components/CustomizedTextInput'
import { ROW_TITLE, ROW_ORDER} from '../../../../../constants/admin-systems'
import RowValue from './RowValue'


 const GeneralSectionRow = (props) => {
    const { data, deleteRowHandler, inputTypes, subSystems, editGeneralSection, additionalTests} = props
    const { row_title, order, values, _id } = data
    const valueObj = values[0]

    return (
      <TableRow >
        <TableCell align = 'right'>
        <DeleteOutlinedIcon onClick={()=>deleteRowHandler(_id)}/>
        </TableCell>
        {
          <CustomizedTextInput value = {row_title}  changeHandler = {editGeneralSection(_id, ROW_TITLE)}/> 
        }
        <RowValue 
            value={valueObj}
            inputTypes={inputTypes}
            subSystems={subSystems}
            rowId = {_id}
            editGeneralSection = {editGeneralSection}
            additionalTests = {additionalTests}
        />
        <CustomizedTextInput type='number' value={order} changeHandler = {editGeneralSection(_id, ROW_ORDER)}/>
      </TableRow>
    )
  
  }

export default GeneralSectionRow