import React, {useState, useEffect} from 'react'
import {useStyles } from '../styles'
import { QUOTE } from '../../../../constants/translations/customersPage'
import {useDispatch} from 'react-redux'
import {setTotalProductsQuantity, setTotalAmount} from '../../../../actions/quotation-actions'
import { convertIntoCurrency, getAllExtraCharges, getAmountIncludingExtraCharges, getPercentAmount } from '../../../../hooks/helper'
import CustomizedInputProduct, { CustomeExtraChargeAmount, CustomizedTotalAmount } from '../../reused_components/CustomizedInputProduct'


const TotalAmount = (props) => {
    const { setProductDiscount, productDiscount, extraCharges, setExtraCharges } = props;

    const {locations} = props
    const { TOTAL, EXTRAS, DISCOUNT, DISCOUNT_AMOUNT, TOTAL_AFTER_DISCOUNT, SHEKEL } = QUOTE
    const classes = useStyles()
    const [amountValue, setAmountValue] = useState('')
    const dispatch = useDispatch()
    const [discountAmount, setDiscountAmount] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState();
    const [isNotValid, setIsNotValid] = useState(false)
    const [isExtraNotValid, setIsExtraNotValid] = useState(false);
    const [baseAmountValue, setBaseAmountValue] = useState(0);


    useEffect(()=>{
        const totalSumma = locations?.reduce((summa,i)=> summa + i?.products?.reduce((total,y)=> total + (y.total_cost ? +y.total_cost : 0),0),0)?.toFixed('2')
        const totalQuantity = locations?.reduce((summa,i)=> summa + i?.products?.reduce((total,y)=> total + (y.quantity ? +y.quantity : 0),0),0)
        setAmountValue(totalSumma)
        dispatch(setTotalAmount(totalSumma))
        dispatch(setTotalProductsQuantity(totalQuantity))
        setBaseAmountValue(totalSumma)
    },[locations])

    useEffect(() => {
        let total_ExtraChareges_Amount = getAllExtraCharges(extraCharges);
        let amount = getPercentAmount(productDiscount, (Number(amountValue) + Number(total_ExtraChareges_Amount)));
        setDiscountAmount(prev => amount);
        setRemainingAmount(prev => Number(((Number(amountValue) + Number(total_ExtraChareges_Amount))- amount).toFixed(2) ));
    },[(productDiscount > 0), extraCharges, amountValue]);

    let calculateDiscount = (value) => {
        if(value > 100 || value < 0) {
            setIsNotValid(prev => !prev);
            setTimeout(() => setIsNotValid(prev => !prev), 300);
            return;
        }
        let total_ExtraChareges_Amount = getAllExtraCharges(extraCharges);
        let amount = getPercentAmount(value, (Number(amountValue) + Number(total_ExtraChareges_Amount)))
        setProductDiscount(prev => value)
        setDiscountAmount(prev => amount);
        setRemainingAmount(prev => Number(((Number(amountValue) + Number(total_ExtraChareges_Amount))- amount).toFixed(2) ));
    }

    let calculateExtra = (amount_value, textField, index) => {
        if(amount_value < 0) {
            setIsExtraNotValid(prev => !prev);
            setTimeout(() => setIsExtraNotValid(prev => !prev), 300);
            return;
        }
        let extra_Charge_item = {
            amount: amount_value,
            remark: textField
        };
        let extraCharges_Temp = [...extraCharges];
        extraCharges_Temp[index] = extra_Charge_item;
        let total_ExtraChareges_Amount = getAllExtraCharges(extraCharges_Temp)
        setExtraCharges(extraCharges_Temp);
        let amount = getPercentAmount(productDiscount, (amountValue + total_ExtraChareges_Amount))
        setDiscountAmount(amount);
        setRemainingAmount(prev => Number(((Number(amountValue) + Number(total_ExtraChareges_Amount))- amount).toFixed(2)));
    }

    const locationHandler = () => {
        let temp = JSON.parse(JSON.stringify(extraCharges)) || [];
        temp.push({
            amount: 0,
            remark: ''
        })
        setExtraCharges(temp);
    }

    const deleteHandle = (index) => {
        setExtraCharges(item => item.filter((it,ind) => ind !== index));
    }
    return(
        <div 
            className = {classes.totalAmountSection}
        >
            <div className={classes.amountSection}>
                <CustomeExtraChargeAmount
                    label = {EXTRAS}
                    value={extraCharges}
                    changeAmountHandler={calculateExtra}
                    type = 'number'
                    disabled = {false}
                    width = {'130px'}
                    min={0}
                    max={Infinity}
                    isExtraNotValid={isExtraNotValid}
                    locationHandler={locationHandler}
                    deleteHandler={deleteHandle}
                />
            </div>
            <div className={classes.amountSection}>
                <p className ={classes.amountLabel}>{TOTAL}:</p>
                {Number(amountValue) > 0 && <p className={classes.amountValue}>{convertIntoCurrency(amountValue, 'ILS')}</p>}
            </div>
            <div className={classes.amountSection}>
                <CustomizedTotalAmount
                    label = {DISCOUNT}
                    value={productDiscount}
                    changeHandler={calculateDiscount}
                    type = 'number'
                    disabled = {false}
                    width = {'130px'}
                    min={0}
                    max={100}
                    isNotValid={isNotValid}
                    sign={'%'}
                />
            </div>
            <div className={classes.amountSection}>
                <p className = {classes.amountLabel}>{DISCOUNT_AMOUNT}:</p>
                <p className={classes.amountValue}>{convertIntoCurrency(discountAmount, 'ILS')}</p>
            </div>
            <div className={classes.amountSection}>
                <p className = {classes.amountLabel}>{TOTAL_AFTER_DISCOUNT}:</p>
                <p className={classes.amountValue}>{convertIntoCurrency(remainingAmount, 'ILS')}</p>
            </div>
        </div>
    )
}

export default TotalAmount