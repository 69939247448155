import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CHECK_LIST_TRANSLATION } from '../../../../constants/translations/customersPage'
import { useStyles } from '../style'
import { LAYER_2, LAYER_4 } from '../../../../constants/admin-systems'

const SystemsCheckListSectionTableHead = ({ layer, factoryId }) => {
  const { IS_ADDITIONAL_TEST, INTERNAL_COMMENTS, IMAGE, OPEN, CLOSED, REMARKS, CAUSE_OF_IRREGULARITY, PROPER_NOT_PROPER, TEST_DESCRIPTION, ACTION_REQUIRED, FIXED_IN_PLACE, DELETE, ICON, ORDER, HALF_YEAR_TEST, ADD_TO_ADDITIONAL_CHECK_LIST, ADD_TO_LAYER_2_CHECK_LIST } = CHECK_LIST_TRANSLATION
  const classes = useStyles()
  const layerTwo = layer === LAYER_2
  const layerFour = layer === LAYER_4
  return (
    <TableHead>
      <TableRow>
        {
          !factoryId &&<TableCell className={classes.tableHeaderCellStyle}>
          {OPEN}
        </TableCell>
        }
        {
          !factoryId &&<TableCell className={classes.tableHeaderCellStyle}>
          {CLOSED}
        </TableCell>
        }
        {!factoryId && layerTwo ? <TableCell className={classes.tableHeaderCellStyle}>
          {ADD_TO_LAYER_2_CHECK_LIST}
        </TableCell> : null}
        {!factoryId && layerFour ? <TableCell className={classes.tableHeaderCellStyle}>
          {ADD_TO_ADDITIONAL_CHECK_LIST}
        </TableCell> : null}
        
        {
          !factoryId && <TableCell className={classes.tableHeaderCellStyle}>
            {HALF_YEAR_TEST}
          </TableCell>
        }
        <TableCell className={classes.tableHeaderCellStyle} align="right">
          {DELETE}
        </TableCell>
        {
          factoryId && <TableCell className={classes.tableHeaderCellStyle}>
            Process Name
          </TableCell>
        }
        {
          factoryId && <TableCell className={classes.tableHeaderCellStyle}>
            Upper Layer System
          </TableCell>
        }
        <TableCell className={classes.tableHeaderCellStyle}>
          {ORDER}
        </TableCell>
        
        {
          !factoryId && <TableCell className={classes.tableHeaderCellStyle} align="right">
              {IS_ADDITIONAL_TEST}
            </TableCell>
        }
        <TableCell className={classes.tableHeaderCellStyle} align="right">
          {ICON}
        </TableCell>
        <TableCell className={classes.tableHeaderCellStyle} align="right">
          {TEST_DESCRIPTION}
        </TableCell>
        {/* <TableCell
            className={classes.tableHeaderCellStyle}
            align="center"
          >
            {SHOW_STOPPER}
          </TableCell> */}
        <TableCell
          className={classes.tableHeaderCellStyle}
          align="center"
        >
          {PROPER_NOT_PROPER}
        </TableCell>

        {
          !factoryId && <TableCell
            className={classes.tableHeaderCellStyle}
            align="center"
          >
            {CAUSE_OF_IRREGULARITY}
          </TableCell>
        }
        
        {
          !factoryId && <TableCell
            className={classes.tableHeaderCellStyle}
            align="center"
          >
            {ACTION_REQUIRED}
          </TableCell>
        }
        
        {
          !factoryId && <TableCell
            className={classes.tableHeaderCellStyle}
            align="center"
          >
            {FIXED_IN_PLACE}
          </TableCell>
        }
        <TableCell
          className={classes.tableHeaderCellStyle}
          align="center"
        >
          {REMARKS}
        </TableCell>
        <TableCell
          className={classes.tableHeaderCellStyle}
          align="center"
        >
          {IMAGE}
        </TableCell>
        <TableCell className={classes.tableHeaderCellStyle} align="center">
          {INTERNAL_COMMENTS}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default SystemsCheckListSectionTableHead