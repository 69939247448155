import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFactory,
  getEmployee,
  setEmployeeDetais,
  getAllSubDepartment,
  getUserByFactory
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1), 
    minWidth: 223,
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
}));

export const ResourceDetails = (props) => {
  const dispatch = useDispatch();
  const resource = useSelector((state) => state.resources.resourceDetails);
  const factoryList = useSelector((state) => state.factories.listFactories);
  const subDepartsList = useSelector(
    (state) => state.subDepartments.listSubDepartments
  );

  const filterFactory = useSelector((state) => state.factories.filterFactory);
  // const usersList = useSelector((state) => state.users.listUsers)

  // const [users,setUsers] = useState([])

  useEffect(() => {
    if (props.id) {
      dispatch(getEmployee(props.id));
    }
    return function cleanup() {
      dispatch(setEmployeeDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllSubDepartment());
  }, [dispatch]);

  // useEffect(()=>{
  //   if(resource && resource.factory_id){
  //    dispatch(getUserByFactory(resource.factory_id))
  //   }
  // },[resource, dispatch])

  // useEffect(()=>{
  //   if(usersList && usersList.length){
  //     let users = usersList.map(i=>({_id:i._id,full_name:i.full_name}))
  //     setUsers(users)
  //   }
  // },[usersList])

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && resource && (
          <ResourceForm
            id={props.id}
            resource={resource}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            subDepartsList={subDepartsList}
            // users={users}
          />
        )}
        {props.id === undefined && (
          <ResourceForm
            id={props.id}
            resource={null}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            subDepartsList={subDepartsList}
            filterFactory={filterFactory}
            // users={users}
          />
        )}
      </>
    </Grid>
  );
};

function ResourceForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { resource, id, factoryList, subDepartsList, filterFactory} = props;

  const usersList = useSelector((state) => state.users.listUsers)

  const [users,setUsers] = useState('')
  
  const [values, setValues] = useState({
    name: resource ? resource.name : "",
    email: resource ? resource.email : "",
    factory_id: resource ? resource.factory_id : '',
    sub_department_id: resource ? resource.sub_department_id : '',
    user_for_resource_id:resource && resource.user_for_resource_id ? resource.user_for_resource_id : '',
    user_full_name: resource && resource.user_full_name ? resource.user_full_name : '',
    priority: resource?.priority || 0
  });

  useEffect(()=>{
    if(values.factory_id){
      dispatch(getUserByFactory(values.factory_id))
    }
  },[values.factory_id])

  useEffect(()=>{
    if(usersList && usersList.length > 0){
      let users = usersList.map(i=>({_id:i._id,full_name:i.full_name}))
      setUsers(users)
    }
  },[usersList])


  const getSubDepartmentList = () => {
    if (values.factory_id) {
      let depts = [];
      depts = subDepartsList.filter((element) => {
        return element.factory_id === values.factory_id;
      });
      return depts;
    } else {
      return [];
    }
  };

  const getFactoryId = () => {
    let res = resource
      ? resource.factory_id
      : filterFactory
      ? filterFactory
      : '';
    setValues({ ...values, factory_id: res });
  };

  const initFactoryId = useMemo(() => getFactoryId(), [filterFactory]);

  const handleInputChange = (e) => {   
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleUserChange = (e) =>{
    const {value} = e.target
    let data = users.find(i=>i._id === value)
    setValues({...values,user_full_name:data.full_name,user_for_resource_id:data._id})
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const { name, email, factory_id, sub_department_id, user_for_resource_id, user_full_name, priority} = values;  
    let model;
    if (id) {
      model = {
        _id: id,
        name,
        email,
        factory_id,
        sub_department_id,
        user_for_resource_id,
        user_full_name,
        priority
      };
    } else {
      model = {
        name,
        email,
        factory_id,
        sub_department_id,
        user_for_resource_id,
        user_full_name,
        priority
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>
      <form id="edit-resource" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="email"
              variant="outlined"
              label="email"
              value={values.email}
              onChange={handleInputChange}
            />

            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
          <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="sub_department_label">sub department</InputLabel>
              <Select
                labelId="sub_department_label"
                id="sub_department_id"
                value={values.sub_department_id}
                onChange={handleInputChange}
                label="sub department"
                name="sub_department_id"
              >
                {values.factory_id && getSubDepartmentList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>  
              {/* ----------------------------------------------------------------- */}
         <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="user_id_label">user</InputLabel>
              <Select
                labelId="user_id_label"
                id="user_for_resource_id"
                value={values.user_for_resource_id}
                // defaultValue={values.user_full_name}
                onChange={handleUserChange}
                label="user"
                name="user_for_resource_id"
              >
                {values.factory_id && users && users.length ? users.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.full_name}
                    </MenuItem>
                  );
                }) : null}
              </Select>
            </FormControl>   
          {/* ----------------------------------------------------------------- */}       
          </Grid>
          <Grid item>
            <TextField
              type = 'number'
              name="priority"
              variant="outlined"
              label="priority"
              value={values.priority}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

