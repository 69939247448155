import { ADD_TASK_TO_TASKS_LIST_MOBILE, ADD_TASK_TO_TASKS_LIST_TABLET, DELETE_TASK_BY_TASK_ID, EDIT_SELECTED_TASK_BY_TASK_ID, GET_TASKS_LIST_MOBILE, GET_TASKS_LIST_TABLET, SELECTED_TASK_BY_TASK_ID, SET_TASKS_TO_TASKS_LIST_TABLET } from "../../constants/customer/taskConstants";

export const taskActionsCreator ={
    getTasksListMobile: () => ({type: GET_TASKS_LIST_MOBILE}),
    getTasksListTablet: () => ({ type: GET_TASKS_LIST_TABLET }),
    addTaskToTasksListTablet: (task) => ({ type: ADD_TASK_TO_TASKS_LIST_TABLET, payload: task}),
    addTaskToTasksListMobile: (task) => ({ type: ADD_TASK_TO_TASKS_LIST_MOBILE, payload: task }),
    selectedTaskByTaskId: (taskId) => ({type: SELECTED_TASK_BY_TASK_ID, payload: taskId}),
    deleteTaskByTaskId: (taskId) => ({ type: DELETE_TASK_BY_TASK_ID, payload: taskId }),
    editSelectedTaskByTaskId: (task) => ({ type: EDIT_SELECTED_TASK_BY_TASK_ID, payload: task }),
    setTasksToTasksListTablet: (tasks) => ({type: SET_TASKS_TO_TASKS_LIST_TABLET, payload: tasks})

}