import React from "react";
import { useStyles } from '../styles'
import { Table, TableBody, Paper } from "@material-ui/core";
import GeneralSectionTableHead from './GeneralSectionTableHead'
import GeneralSectionRow from './GeneralSectionRow'
 
  const GeneralSectionTable = (props) => {
    const { generalSection, inputTypes, subSystems, deleteRowHandler, editGeneralSection, additionalTests } = props
    const classes = useStyles()
    return (<Paper className={classes.root}>
      <Table className={classes.table} stickyHeader >
        <GeneralSectionTableHead />
        <TableBody>
          {
            generalSection.data.map(i => <GeneralSectionRow
              key={i._id}
              data={i}
              inputTypes={inputTypes}
              subSystems={subSystems}
              deleteRowHandler = {deleteRowHandler}
              editGeneralSection = {editGeneralSection}
              additionalTests = {additionalTests}
            />)
          }
        </TableBody>
      </Table>
    </Paper>)
  }

  export default GeneralSectionTable