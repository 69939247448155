import * as React from "react";
import {getOngoingOrders} from "../actions/admin_orders_actions";
import {useDispatch, useSelector} from "react-redux";
import {useInfiniteScroll} from "./useInfiniteScroll";
import {uniqueValueFilterById} from "../functions/helpers/uniqueValueFilterById";

export const useOngoingOrders = () => {
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const limit = useSelector(
        (state) => state.adminOrders.ongoingOrdersLimit
    );
    let orders = useSelector(
        (state) => state.adminOrders.ongoingOrders
    );
    const loading = useSelector(
        (state) => state.adminOrders.loading
    );
    const ordersCount = useSelector(
        (state) => state.adminOrders.ongoingOrdersCount
    );

    const loadMore = () => {
        if(!ordersCount || orders.length < ordersCount ){
            dispatch(getOngoingOrders(login.user.factory_id, limit, orders.length ));
            setIsFetching(false)
        }
    }
    const [isFetching, setIsFetching] = useInfiniteScroll(loadMore);
    orders = uniqueValueFilterById(orders, "_id")

    return [orders, loading, ordersCount, isFetching] ;
};