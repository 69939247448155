import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./style";
import MenuItem from "@material-ui/core/MenuItem";
import { InputAdornment } from "@material-ui/core";
import AddExtrachargeButton from "../Quote/Parts/AddExtrachargeButton";
import Location, { ExtraChargeLocation } from "../Quote/Parts/Location";

const CustomizedInputProduct = (props) => {
  const classes = useStyles();
  const {
    label,
    value,
    changeHandler,
    type,
    options,
    disabled,
    isNotValid,
    width,
  } = props;
  return (
    <div className={classes.totalInputWrapper}>
      <h3>{label}</h3>
      <TextField
        error={isNotValid && !value}
        variant='outlined'
        size='small'
        style={{ width: width }}
        value={value}
        onChange={(e) => changeHandler(e.target.value)}
        type={type}
        select={type === "select"}
        disabled={disabled}>
        {options
          ? options.map((option, idx) => (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            ))
          : null}
      </TextField>
    </div>
  );
};

export const CustomizedTotalAmount = (props) => {
  const classes = useStyles();
  const {
    label,
    value,
    changeHandler,
    type,
    options,
    disabled,
    isNotValid,
    width,
    min,
    max,
    sign,
  } = props;

  return (
    <div className={classes.totalInputWrapper}>
      <h3 style={{ width: "22%" }}>{label}</h3>
      <div className={classes.customerExchangeInputContainer}>
        <TextField
          error={isNotValid}
          variant='outlined'
          size='small'
          style={{ width: width }}
          value={value}
          onChange={(e) => changeHandler(e.target.value)}
          type={type}
          inputProps={{ min, max }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                className={classes.totalInputAdornmentSign}
                position='start'>
                {sign}
              </InputAdornment>
            ),
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const CustomeExtraChargeAmount = (props) => {
  const classes = useStyles();
  const {
    label,
    value,
    changeAmountHandler,
    type,
    options,
    disabled,
    isNotValid,
    width,
    min,
    max,
    locationHandler,
    deleteHandler,
  } = props;

  let changeHandler = (_amount = 0, messageField = "", index) => {
    changeAmountHandler(_amount, messageField, index);
  };

  return (
    <div className={classes.totalInputWrapper} style={{ alignItems: "unset" }}>
      {
        value.length > 0 ? <h3 style={{ width: '22%', userSelect: 'auto', padding: "8px 0px" }}>{label}</h3> 
        :
        <h3 
          onClick={locationHandler}
          style={{ width: 'fit-content', userSelect: 'none', padding: "8px 0px" }}
        ><AddExtrachargeButton readOnly={false} label={label} /></h3>
      }
      <div className={classes.customerExchangeInputContainer}>
        {value.map((item, index) => {
          return (
            <div className={classes.extraChargeAndTextField} key={index}>
              <TextField
                error={isNotValid}
                variant='outlined'
                size='small'
                style={{ width: width }}
                value={item.amount}
                onChange={(e) =>
                  changeHandler(e.target.value, item.remark, index)
                }
                type={type}
                inputProps={{ min, max }}
                disabled={disabled}
              />
              <ExtraChargeLocation
                identifier={0}
                changeLocationValueHandler={(e, i) =>
                  changeHandler(item.amount, i, index)
                }
                value={item.remark}
                products={""}
                showAddButton={index === value.length - 1}
                addLocationHandler={locationHandler}
                deleteHandle={() => deleteHandler(index)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomizedInputProduct;
