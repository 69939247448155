import React, {useState, useEffect} from "react";
import TableCell from "@material-ui/core/TableCell";
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useStyles } from '../style'


const CustomizedShowStopperSelect = (props) => {
  const classes = useStyles()
  const { value, changeHandler,  options = [] } = props
  const [background, setBackground] = useState('')

  useEffect(()=>{
    if(value){
      const selectedOption = options.find(i => i.option === value)
      const selectedColor = selectedOption?.color || ''
      setBackground(selectedColor)
    }else{
      setBackground('')
    }
  },[value])

  return (
    <TableCell 
    className={classes.selectTableCell} 
    style={{
      width:'100px',
      backgroundColor: background
      }}>
      <Select
        style ={{width:'100px'}}
        value={value || ''}
        onChange={(e) => changeHandler(e.target.value)}
        disableUnderline
      >
        {
          options?.map((i, idx) => <MenuItem style={{backgroundColor:i.color}} value={i.option} key={idx}>{i.option}</MenuItem>)
        }
      </Select>
    </TableCell>
  )
}

export default CustomizedShowStopperSelect