import React, {  useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {  getByFilterRecipients, getAllFactory } from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";
import TableRecipient from "./TableRecipient";


const useStyles = makeStyles((theme) => ({
  filter: {
    marginLeft: "auto",
  },
}));

export const RecipientList = () => {
  const dispatch = useDispatch();

  const recipientList = useSelector(
    (state) => state.recipients.listRecipients
  );

  const countRecipients = useSelector(
    (state) => state.recipients.countRecipients
  ); 
  const page = useSelector((state) => state.recipients.currentPage);
  const rowsPerPage = useSelector((state) => state.recipients.rowsPerPage);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getRecipients(filterFactory));
  // }, [dispatch, filterFactory]);

  useEffect(() => { 
    dispatch(getByFilterRecipients(rowsPerPage, page*rowsPerPage, -1, filterFactory));      
  }, [dispatch,  page, rowsPerPage, filterFactory]);

  return (
    <div className = "admin_page">  
      <TableRecipient recipientList = {recipientList}
       countRecipients = {countRecipients}
       ></TableRecipient>
    </div>
  );
};
