import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core";
import { updateOrderCollectionStage } from "../../../actions/actions";
import { OrderCollectionStagesDetails } from "./OrderCollectionStagesDetails";
import DetailsDialog from "../DetailsDialog";
import { changeCurrentPage, changeRowsPerPage  } from "../../../actions/order_type_actions";
import TablePagination from '@material-ui/core/TablePagination';

const headTableConfig = [
  {
    id: "factory_id",
    label: "id factory",
  },
  {
    id: "factory_name",
    label: "name factory",
  },
  {
    id: "_id",
    label: "id order collection stages",
  },
  {
    id: "name",
    label: "name",
  },

  {
    id: "type",
    label: "type",
  }
 
];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table:{
    direction: "rtl"
  },
  container: {
    maxHeight: "calc(100vh - 160px)",
    direction: "ltr"
  },
  pagination: {
    direction: "ltr",
  }
}));

const StyledHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243748",
    color: theme.palette.common.white,
    left: 'unset'
  },
  body: {
    fontSize: 14,
    fontFamily: "Rubik",
  },
}))(TableCell);

function TableHeadOrg () {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {headTableConfig.map((headCell) => (
          <StyledHeadCell
            component="th"
            scope="row"
            key={headCell.id}
            align={headCell.align}
          >
            <div className={classes.headCell}>{headCell.label}</div>
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableOrderCollectionStages(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orderCollectionStagesList, countOrderCollectionStages } = props;
  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const [isOpen, setIsOpen] = React.useState(false);
  const [idCurrent, setIdCurrent] = React.useState(undefined);

  
  const page = useSelector(state => state.orderCollectionStages.currentPage);
  const rowsPerPage = useSelector(state => state.orderCollectionStages.rowsPerPage);
  
  const handleEdit = (id) => {
    setIdCurrent(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (id, model) => {
    setIsOpen(false);
    dispatch(updateOrderCollectionStage(model, filterFactory));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(changeCurrentPage(newPage));
  };

  const handleChangeRowsPerPage = event => {  
      dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
  };
  
  return (
    <div className={classes.root}>
      <DetailsDialog
        isOpen={isOpen}
        onClose={handleClose}
        submitLabel="Update"
        form="edit-order-collection-stages"
        title="Edit Collection Stage"
        id={idCurrent}
      >
        <OrderCollectionStagesDetails id={idCurrent} onSubmit={onSubmit} />
      </DetailsDialog>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeadOrg />
            <TableBody>
              {orderCollectionStagesList &&
                orderCollectionStagesList.map((element) => {
                  return (
                    <TableRow
                      style={{ height: 33 }}
                      hover
                      tabIndex={-1}
                      key={element._id}
                      onClick={() => handleEdit(element._id)}
                    >
                      {headTableConfig.map((col) => {
                        let value = element[col.id];
                        return (
                          <TableCell component="th" scope="row" key={col.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          colSpan={3}          
          count={countOrderCollectionStages}
          rowsPerPage={rowsPerPage}
          page={page}

           SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

