import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core";
import { 
  updateSubDepartment,
  dropSubDepartment
} from "../../../actions/actions";
import { SubDepartmentDetails } from "./SubDepartmentDetails";
import DetailsDialog from "../DetailsDialog";
import { changeCurrentPage, changeRowsPerPage } from "../../../actions/sub_department_actions";
import TablePagination from '@material-ui/core/TablePagination';

const headTableConfig = [
  {
    id: "factory_id",
    label: "id factory",
  },
  {
    id: "factory_name",
    label: "name factory",
  },
  {
    id: "department_id",
    label: "id department",
  },
  {
    id: "department_name",
    label: "name department",
  },
  {
    id: "employees",
    label: "employees list",
  },
  {
    id: "_id",
    label: "id subDepartment",
  },
  {
    id: "name",
    label: "name",
  },
  {
    id: "units",
    label: "units",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table:{
    direction: "rtl"
  },
  container: {
    maxHeight: "calc(100vh - 160px)",
    direction: "ltr"
  }, 
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  pagination: {
    direction: "ltr",
  },
}));

const StyledHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243748",
    color: theme.palette.common.white,
    left: 'unset'
  },
  body: {
    fontSize: 14,
    fontFamily: "Rubik",
  },
}))(TableCell);

function TableHeadOrg(props) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headTableConfig.map((headCell) => (
          <StyledHeadCell
            component="th"
            scope="row"
            key={headCell.id}
            align={headCell.align}
          >
            <div className={classes.headCell}>{headCell.label}</div>
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableSubDepartment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { subDeparmentList, countSubDepart } = props;
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const [isOpen, setIsOpen] = React.useState(false);
  const [idCurrent, setIdCurrent] = React.useState(undefined);

  const page = useSelector(state => state.subDepartments.currentPage);
  const rowsPerPage = useSelector(state => state.subDepartments.rowsPerPage);

  const handleEdit = (id) => {
    setIdCurrent(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (id, model) => {
    setIsOpen(false);
    dispatch(updateSubDepartment(model, filterFactory));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(changeCurrentPage(newPage));
};

const handleChangeRowsPerPage = event => {
    dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
};

const onDelete = (id) => {
  dispatch(dropSubDepartment(id, filterFactory))
  setIsOpen(false);
}

  return (
    <div className={classes.root}>
      <DetailsDialog
        isOpen={isOpen}
        onClose={handleClose}
        submitLabel="Update"
        deleteLabel="Delete"
        form="edit-sub-department"
        onDelete={onDelete}
        title="Edit SubDepartment"
        id={idCurrent}
      >
        <SubDepartmentDetails
          id={idCurrent}
          onSubmit={onSubmit}
        />
      </DetailsDialog>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container} component={Paper}>
          <Table 
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeadOrg />
            <TableBody>
              {subDeparmentList &&
                subDeparmentList.map((element) => {
                  return (
                    <TableRow
                      style={{ height: 33 }}
                      hover
                      tabIndex={-1}
                      key={element._id}
                      onClick={() => handleEdit(element._id)}
                    >
                      {headTableConfig.map((col) => {
                        let value = element[col.id];

                        switch (col.id) {
                          case "employees":
                            let res = [""];
                            if (element[col.id].length) {
                              res = element[col.id].map((employee) => {
                                return employee.name;
                              });
                            }
                            value = res.length ? res.join(", ") : res;
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell component="th" scope="row" key={col.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          colSpan={3}
          count={countSubDepart}
          rowsPerPage={rowsPerPage}
          page={page} SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}


