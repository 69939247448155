import { Grid } from '@material-ui/core'
import React from 'react'
import Button from '../../../Buttons/Button';
import "../AddSystem/AddSystemPopUp.scss";
import "./DeletePopUp.scss"
import { CANCEL, DELETE } from '../../../../../constants/translations/review-popup';
import { useDispatch, useSelector } from 'react-redux';
import { taskActionsCreator } from '../../../../../actions/customer/taskActions';
import { fileActionCreator } from '../../../../../actions/customer/fileActions';
import { systemActionsCreator } from '../../../../../actions/customer/systemActions';
import { SET_SYSTEMS_FOR_ACCORDION } from '../../../../../constants/customer/systemConstants';

const deleteBtn = {
    name: DELETE,
    width: '35%',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '24px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}
const cancel = {
    name: CANCEL,
    width: '35%',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}
const deleteBtnMobile = {
    ...deleteBtn,
    fontSize: '12px',
    height: '36px',
    borderRadius: '20px',
    width: '42%',
}
const cancelMobile = {
    ...cancel,
    fontSize: '12px',
    height: '36px',
    borderRadius: '20px',
    width: '42%',
}
const deleteBtnDesktop = {
    ...deleteBtn,
    fontSize: '16px',
    height: '52px',
    borderRadius: '20px',
    width: '35%',
}
const cancelDesktop = {
    ...cancel,
    fontSize: '16px',
    height: '52px',
    borderRadius: '20px',
    width: '35%',
}


export default function DeletePopUp(props) {
    const { handleShowPopUp, view, data, popUpFor, id, layer } = props;
    const dispatch = useDispatch();

    const handelDelete = () => {
        if (popUpFor === 'file') {
            dispatch(fileActionCreator.deleteFileByFileId(id))
        } else if (popUpFor === 'system') {
            dispatch(systemActionsCreator.deleteSystem({ systemId: id, parentSystemId: data?.parentSystemId, layer }))
            dispatch({ type: SET_SYSTEMS_FOR_ACCORDION });
        } else {
            dispatch(taskActionsCreator.deleteTaskByTaskId(id))
        }
        handleShowPopUp()
    }
    return (
        <Grid className='addSystem__main' style={{ left: view === 'desktop' && '15%' }}>
            <Grid item xs={12}>
                <h4 className='addSystem__title'>{data.title}</h4>
            </Grid>
            <Grid item xs={12}>
                <div className='deleteFile__confirmation'>{data.subtitle}</div>
            </Grid>
            <Grid className='addSystem__uploadedInfo delete__uploadInfo--margin'>
                {
                    data && data.info.map((item, index) => {
                        return <Grid item key={index} xs={12} className='addSystem__uploadedInfo-row'>
                            <Grid item xs={6} className='addSystem__uploadedInfo-label'><span>{item.label}</span></Grid>
                            <Grid item xs={6} className='addSystem__uploadedInfo-value'><span>{item.value}</span></Grid>
                        </Grid>
                    })
                }
            </Grid>
            {
                view === 'mobile' ? <Grid sx={12} className='addSystem__btn__container' >
                    <Button data={deleteBtnMobile} handleClick={() => handelDelete()} />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button data={cancelMobile} handleClick={() => handleShowPopUp()} />
                </Grid>
                    : view === 'desktop' ? <Grid sx={12} className='addSystem__btn__container' >
                        <Button data={deleteBtnDesktop} handleClick={() => handelDelete()} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button data={cancelDesktop} handleClick={() => handleShowPopUp()} />
                    </Grid>
                        : <Grid sx={12} className='addSystem__btn__container' >
                            <Button data={deleteBtn} handleClick={() => handelDelete()} />
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button data={cancelDesktop} handleClick={() => handleShowPopUp()} />
                        </Grid>
            }

        </Grid>
    )
}
