import React, {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProcesses, getByFilterProcesses } from "../../../actions/actions";

import "../../../sass/adminPanel/_admin.scss";
import TableGanttTemplate from "./TableGanttTemplate";

export const GanttTemplateList = () => {
  const dispatch = useDispatch();

  const processeslist = useSelector(
    (state) => state.ganttTemplate.listProcesses 
  );

  const countTemplateGantt = useSelector(
    (state) => state.ganttTemplate.countProcesses
  ); 
  
  const page = useSelector((state) => state.ganttTemplate.currentPage);
  const rowsPerPage = useSelector((state) => state.ganttTemplate.rowsPerPage);

  const filterFactory = useSelector((state) => state.factories.filterFactory);

   useEffect(() => {  

    dispatch(getByFilterProcesses(rowsPerPage, page*rowsPerPage, -1, filterFactory));      
  }, [dispatch,  page, rowsPerPage, filterFactory]);

  return (
    <div className = "admin_page">     
      <TableGanttTemplate processeslist = {processeslist} countGantt={countTemplateGantt}></TableGanttTemplate>
    </div>
  );
};
