import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  getAllFactory,
  getUser,
  setUserDetais,
  updateUser,
  getSubDeptGetByFactory,
  resetSubDepartments
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { USER_PRIVILEGES } from "../../../constants/enum";
import DetailsDialog from "../DetailsDialog";
import СhangePassword from "./СhangePassword";
import { CustomButton } from "../../generals/CustomButton";
import ColorPicker from "material-ui-color-picker";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const UserDetails = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.userDetails);
  const factoryList = useSelector((state) => state.factories.listFactories);
  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const listOfSubDepartments = useSelector((state) => state.subDepartments.listSubDepartments)
  

  useEffect(() => {
    if (props.id) {
      dispatch(getUser(props.id));
    }
    return function cleanup() {
      dispatch(setUserDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && user && (
          <UserForm
            id={props.id}
            user={user}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            listOfSubDepartments={listOfSubDepartments}
          />
        )}
        {props.id === undefined && (
          <UserForm
            id={props.id}
            user={user}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            filterFactory={filterFactory}
            listOfSubDepartments={listOfSubDepartments}
          />
        )}
      </>
    </Grid>
  );
};

function UserForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, id, factoryList, filterFactory, listOfSubDepartments} = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    email: user ? user.email : "",
    factory_id: user ? user.factory_id : null,
    full_name: user ? user.full_name : "",
    privileges: user ? user.privileges : "",
    password: "",
    subDepartments:user && user.subDepartments ? user.subDepartments : [],
    color: user?.color || 'rgba(255,255,255,1)',
    inactive: !!user?.inactive
  });

  useEffect(()=>{
    if(values.factory_id){
      dispatch(getSubDeptGetByFactory(values.factory_id))
    }
  }, [values.factory_id,dispatch])

  useEffect(()=>{
    if(!values.factory_id){
      dispatch(resetSubDepartments())
    }
  }, [dispatch])


  const getFactoryId = () => {
    let res = user ? user.factory_id : filterFactory ? filterFactory : null;
    setValues({ ...values, factory_id: res });
  };

  useMemo(() => getFactoryId(), [filterFactory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const getUserPrivelegesIds = () => {
    let res = values.privileges.length
      ? values.privileges.map((element) => {
          return USER_PRIVILEGES.find((value) => {
            return value.code === element;
          });
        })
      : [];
    setValues({ ...values, privileges: res });
  };

  useMemo(() => getUserPrivelegesIds(), [user]);

  const handleMultiSelect = (e, option) => {
    let value = option.length
      ? option.map((element) => {
          return element;
        })
      : [];
    setValues({ ...values, privileges: value });
  };

  const handleSubDepartmentSelect = (e, option) => {
    let value = option.length
      ? option.map((element) => {
          return element;
        })
      : [];
    setValues({ ...values, subDepartments: value });
  };

  const onClickchangePassword = (id) => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { email, factory_id, full_name, password, subDepartments, color, inactive } = values;
    let privileges = values.privileges.map((elem) => {
      return elem.code;
    });

    let model;

    if (id) {
      model = {
        _id: id,
        email,
        full_name,
        factory_id,
        privileges,
        subDepartments,
        color,
        inactive
      };
    } else {
      model = {
        email,
        full_name,
        factory_id,
        privileges,
        password,
        subDepartments,
        color,
        inactive
      };
    }
    props.onSubmit(id, model);
  };

  const onSubmitPassword = (id, model) => {
    setIsOpen(false);
    dispatch(changePassword(model));
  };

  const handleColorChange = (value) =>{
    setValues({ ...values, color: value });
  }

  const handleInactiveChange = (value) =>{
    setValues({ ...values, inactive: value });
  }

  return (
    <div>
      <DetailsDialog
        isOpen={isOpen}
        onClose={handleClose}
        submitLabel="Update"
        form="change_password"
        title="Change Password"
        id={id}
      >
        <СhangePassword id={id} onSubmitPassword={onSubmitPassword} />
      </DetailsDialog>
      <form id="edit-user" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
            <FormControlLabel
              value="start"
              control={<Checkbox 
                          style = {{color:'#0091ff'}}
                          checked={values.inactive}
                          onChange={e => handleInactiveChange(e.target.checked)}
                        />}
              label="inactive"
              labelPlacement="start"
            />
          </Grid>
          <Grid item className={classes.firstRow}>
            <ColorPicker
              name="color"
              variant="outlined"
              label="color"
              style={{
                backgroundColor:`${values.color}`
              }}
              value={values.color}
              onChange={handleColorChange}
            />
            <TextField
              name="email"
              variant="outlined"
              label="login"
              value={values.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <TextField
              name="full_name"
              variant="outlined"
              label="full name"
              value={values.full_name}
              onChange={handleInputChange}
            />
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {/* ----------------------------------------------------------------------- */}
          <Grid item>
            <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="sub_departments"
              options={listOfSubDepartments}
              getOptionLabel={(option) =>`${option ? option.name : ""}`}
              value={
                values.subDepartments.length
                  ? values.subDepartments.map((element) => {
                      return element;
                    })
                  : []
              }
              onChange={(e, option) => handleSubDepartmentSelect(e, option)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Sub departments" />
              )}
            />
          </Grid>
          {/* ----------------------------------------------------------------- */}
          <Grid item>
            <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="tags-outlined"
              options={USER_PRIVILEGES}
              getOptionLabel={(option) =>
                `${option ? option.name : ""} ${
                  option ? " (" + option.code + ")" : ""
                }`
              }
              value={
                values.privileges.length
                  ? values.privileges.map((element) => {
                      return element;
                    })
                  : []
              }
              onChange={(e, option) => handleMultiSelect(e, option)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="privileges" />
              )}
            />
          </Grid>
          { props.id === undefined ? 
          <Grid item>
            <TextField
              name="password"
              variant="outlined"
              label="password"
              value={values.password}
              onChange={handleInputChange}
            />
          </Grid>
          :
          <div className="button-wrapper">
            <CustomButton
              variant="contained"
              color="primary"
              type={"button"}
              onClick={() => onClickchangePassword(id)}
            >
              Change Password
            </CustomButton>
          </div>
        }
        </Grid>
      </form>
    </div>
  );
}
