import React from 'react'
import { useStyles } from '../../styles'

const ProductPropertyAndValue = (props) => {
    const { property, value, style } = props
    const classes = useStyles()
    return (
        <div
            className={classes.productPropertyAndValue}
        >
            <h3 className={`${classes.buttonWitIconText} ${classes.productDescriptionProperty}`}>{property}</h3>
            <h3 className={`${classes.buttonWitIconText} ${classes.productDescriptionValue}`} style={style ? style.color : {}}>{value}</h3>
        </div>
    )
}

export const ProductModelName = (props) => {
    const { property, value, style } = props
    const classes = useStyles()
    return (
        <div
            className={classes.productPropertyAndValue}
        >
            <h3 className={`${classes.productModelButtonWitIconText} ${classes.productModelNameDescription}`}>{property}</h3>
            <h3 className={`${classes.productModelButtonWitIconText} ${classes.productDescriptionValue}`} style={style ? style.color : {}}>{value}</h3>
        </div>
    )
}

export default ProductPropertyAndValue