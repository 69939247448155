import React from "react";
import { useStyles } from '../styles'
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import {DELETE, PROPERTY, VALUE, ORDER} from '../../../../../constants/translations/admin-systems'


const GeneralSectionTableHead = () => {
    const classes = useStyles()
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeaderCellStyle}>
            {DELETE}
          </TableCell>
          <TableCell className={classes.tableHeaderCellStyle}>
            {PROPERTY}
          </TableCell>
          <TableCell className={classes.tableHeaderCellStyle}>
            {VALUE}
          </TableCell>
          <TableCell
            className={classes.tableHeaderCellStyle}
          >
            {ORDER}
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

export default GeneralSectionTableHead