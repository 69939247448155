import { SET_ALERT_POPUP_MESSAGE, ALERT_POPUP_TOGGLE} from "./types";
import { polyfill } from "es6-promise";
polyfill();

export const alertPopupToggle = (boolean) => async dispatch => {
  dispatch({
    type: ALERT_POPUP_TOGGLE, 
    payload: boolean
  });
};

export const setAlertPopupMessage = (text) => async dispatch => {
    dispatch({
      type: SET_ALERT_POPUP_MESSAGE, 
      payload: text
    });
  };


