import React from "react";
import {CustomButton } from '../../../generals/CustomButton'

const EditInputTitle = (props) => {
    const {addHandler, deleteHandler} = props
    return(
      <div style = {{display:'flex', justifyContent:'space-between', width:'100%'}}>
        <CustomButton onClick = {addHandler}>Create</CustomButton>
        <div>Edit</div>
        <CustomButton onClick = {deleteHandler}> delete</CustomButton>
      </div>
    )
  }

export default EditInputTitle