import { api, createHeaders } from "../../constants/api-urls";
import axios from "axios"


export const getAllProcessesFuncApi = async () => {   
    const headers = createHeaders();
    let url = `${api.admin.processes.allProcesses}`;
    let res = []
    await axios
      .get(url, { headers })
      .then((data) => {
        const {  result } = data.data;
        res = result
      })
      .catch((err) => {
        console.log("Error has been occured in GET request", err)
      });
      return res
};

export const getAllWithoutProcessesFuncApi = async () => {   
  const headers = createHeaders();
  let url = `${api.admin.processes.allWithoutProcesses}`;
  let res = []
  await axios
    .get(url, { headers })
    .then((data) => {
      const {  result } = data.data;
      res = result
    })
    .catch((err) => {
      console.log("Error has been occured in GET request", err)
    });
    return res

};

export const uploadLogo = async(body) => new Promise(resolve => {
  const headers = createHeaders();
  
      const url = `${api.admin.factories.uploadLogo}`

      const res = axios({
        method: "post",
        url,
        headers,
        data:body,
      }).then(res => {
      const {
          ok,
          result
      } = res.data
      const output = {
          ok,
          result
      }

      if(ok){
          resolve(output)
      }else{
          resolve(false)
      }
  }).catch(err => {
      resolve({ ok: false })
  })
})

export const updateInput = async (data) => {
    let url = `${api.admin.systems.updateInputs}`;
    const headers = createHeaders();
    const responce = await axios.post(url, data, { headers })
    if(responce.result){
      return responce.result
    }else{
      return null
    }
      
  };

  export const updateCheckList = async (data) => {
    let url = `${api.admin.systems.updateCheckList}`;
    const headers = createHeaders();
    const responce = await axios.post(url, data, { headers })
    if(responce.result){
      return responce.result
    }else{
      return null
    }
      
  };

  export const executeSaturdayScript = async () => {
    let url = `${api.admin.developers.saturdayScriptUrl}`;
    const headers = createHeaders();
    return await axios
    .get(url, { headers })
    .then((data) => {
      const {  result } = data.data;
      return result;
    })
    .catch((err) => {
      console.log("Error has been occured in GET request", err)
      return null;
    });
  };

