import { Grid, Typography } from '@material-ui/core';
import React, {useState} from 'react'
import "./CustomerDetailsNavigation.scss"


export default function DetailsNavigation(props) {
    const { changeTab, data, tabNumber, view } = props;
    const { navigationFor, navigationTab } = data;
    const [value, setValue] = useState(tabNumber);

    const handleChange = (newValue) => {
        setValue(newValue);
        changeTab(newValue);
    };
    const numberOfColTabs = Math.floor(12 / navigationTab.length) 
  return (
    <div>
          <Grid container spacing={0} className={`${navigationFor === 'viewBy' ? 'customerDetails__header-2' : 'customerDetails__header'} ${view === 'tablet' && 'customerDetails__header-2--tablet' }`}>
              {
                  navigationTab.map((tab, index) => {
                      return (
                          <Grid
                              item
                              xs={numberOfColTabs}
                              className={`${(navigationFor === 'viewBy') && value == index ? "selectedTab-2" : ""} ${(navigationFor !== 'viewBy') && value == index ? "selectedTab" : ""}  ${navigationFor === 'viewBy' ? " customerDetails__header__tab-2" : " customerDetails__header__tab"}`}
                              key={index}
                              onClick={(e) =>{ 
                                e.stopPropagation();
                                handleChange(index)
                                }}
                              style={{ borderRadius: (navigationFor === 'viewBy') && index === 0 ? '6px 0px 0px 6px' : (navigationFor === 'viewBy') && index === navigationTab.length - 1 ? '0px 6px 6px 0px' : (navigationFor === 'viewBy') ? '0px' :  '10px' }}
                          >
                              <Typography className={`${navigationFor === 'viewBy' ? 'customerDetails__header__tab_text-2' : 'customerDetails__header__tab_text'} ${view === 'tablet' && 'customerDetails__header__tab_text--tablet'}`}><span>{tab.label}</span></Typography>
                              {
                                  tab.value>-1 &&  navigationFor !== 'viewBy' && <Typography className={`${view === 'tablet' && 'customerDetails__header__tab_value--tablet' } customerDetails__header__tab_value `} ><span style={{ fontSize: view === 'tablet' && '17px' }}>{tab.value}</span></Typography>
                              }
                              
                          </Grid>
                      )
                  })
              }
          </Grid>
    </div>
  )
}
