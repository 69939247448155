import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../../hooks/useModal";
import { getAllFactory, getByFilterCategories, getOrderCategories } from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";
import TableCategory from "./TableCategory";


export const CategoryList = () => {
  const dispatch = useDispatch();

  const orderCategoryList = useSelector(
    (state) => state.orderСategories.listOrderCategories
  );
  const countOrderCategories = useSelector(
    (state) => state.orderСategories.countOrderCategories
  ); 
  const page = useSelector((state) => state.orderСategories.currentPage);
  const rowsPerPage = useSelector((state) => state.orderСategories.rowsPerPage);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]); 

  // useEffect(() => {
  //   dispatch(getOrderCategories(filterFactory));
  // }, [dispatch, filterFactory]);
  
  useEffect(() => { 
    dispatch(getByFilterCategories(rowsPerPage, page*rowsPerPage, -1, filterFactory));      
  }, [dispatch,  page, rowsPerPage, filterFactory]);



  return (
    <div className = "admin_page">
  
      <TableCategory orderCategoryList = {orderCategoryList} 
        countOrderCategories={countOrderCategories}></TableCategory>
    </div>
  );
};
