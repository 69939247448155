import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getRecipient,
  getAllFactory,
  setRecipientDetais,
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {  NOTIFICATION_TYPE } from "../../../constants/enum";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1), 
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const RecipientDetails = (props) => {
  const dispatch = useDispatch();

  const recipient = useSelector(
    (state) => state.recipients.recipientDetails
  );
  const factoryList = useSelector((state) => state.factories.listFactories);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    if (props.id) {
      dispatch(getRecipient(props.id));
    }
    return function cleanup() {
      dispatch(setRecipientDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && recipient && (
          <RecipientForm
            id={props.id}
            recipient={recipient}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
          />
        )}
        {props.id === undefined && (
          <RecipientForm
            id={props.id}
            recipient={null}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            filterFactory={filterFactory}
          />
        )}
      </>
    </Grid>
  );
};

function RecipientForm(props) {
  const classes = useStyles();
 
  const { recipient, id, factoryList, filterFactory } = props;  

  const [values, setValues] = useState({
    full_name: recipient ? recipient.full_name : "",
    email: recipient ? recipient.email : "",
    factory_id: recipient ? recipient.factory_id : null,
    type: recipient ? recipient.type :  [],
  });

  const getFactoryId  = () => { 
    let res = recipient ? recipient.factory_id : filterFactory ?filterFactory : null;
    setValues({ ...values, factory_id: res })
  }

  useMemo(() => getFactoryId(), [filterFactory]);

  const handleMultiSelect = (e, option) => {
    let value = option.length
      ? option.map((element) => {
        return NOTIFICATION_TYPE.find((type) => {
          return type === element;
        }) 
      }) : [];
    setValues({ ...values, type: value });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const {  full_name, factory_id, email, type } = values;

    let model;

    if (id) {
      model = {
        _id: id,
        full_name,
        email,
        type,
        factory_id,
      };
    } else {
      model = {
        full_name,
        email,
        type,
        factory_id,
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>
      <form id="edit-recipient" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="full_name"
              variant="outlined"
              label="name"
              value={values.full_name}
              onChange={handleInputChange}
            />
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>           
            <TextField
              name="email"
              variant="outlined"
              label="email"
              value={values.email}
              onChange={handleInputChange}
            />
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={ values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
          <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="tags-outlined"
              options={NOTIFICATION_TYPE}            
              value={
                values.type.length
                  ? values.type.map((element) => {
                      return NOTIFICATION_TYPE.find((type) => {
                        return type === element;
                      }) 
                    })
                  : []}
              onChange={(e, option) => handleMultiSelect(e, option)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="types" />
              )}
            />        
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
