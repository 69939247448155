import { Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react';
import "./MobileNavigation.scss";
import Details_icon from './../../assets/Details_iconSvg.svg';
import Systems_icon from './../../assets/systems_iconSvg.svg';
import Files_icon from './../../assets/File_iconSvg.svg';
import Tasks_icon from './../../assets/Tasks_iconSvg.svg';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';



export default function MobileNavigation(props) {
    const [value, setValue] = useState(2);

    const { changeTab , data} = props;

    const handleChange = (newValue) => {
        setValue(newValue);
        changeTab(newValue);
    };
    return (
        <Grid container spacing={0} className='mobie-navigation'>
            {
                data.map((nav, index) => {
                    return <Grid item xs={12}
                     className='mobiel-navigation__nav-row'
                    key={index}
                    onClick={() => handleChange(index)}
                    >
                        <Grid item xs={1}> <span className='mobiel-navigation__icon-container'>
                            {
                                nav.field === 'systems' ? <img className='mobiel-navigation__icon-container' src={Systems_icon} alt='icon' />
                                    : nav.field === 'files' ? <img className='mobiel-navigation__icon-container' src={Files_icon} alt='icon' />
                                        : nav.field === 'tasks' ? <img className='mobiel-navigation__icon-container' src={Tasks_icon} alt='icon' />
                                            : nav.field === 'details' ? <img className='mobiel-navigation__icon-container' src={Details_icon} alt='icon' />
                                                : null

                            }

                        </span></Grid>
                        <Grid item xs={10} className='mobiel-navigation__deatils'>
                            <span className='mobiel-navigation__title'>{nav.label}</span>
                            {
                                nav.value && <>
                                    &nbsp;
                                    <span className='mobiel-navigation__icon-container mobiel-navigation__icon-container-value'>{nav.value}</span>
                                </>
                            }

                        </Grid>
                        <Grid item xs={1}> <span className='mobiel-navigation__icon-container'>
                            <ArrowBackIosIcon className='mobiel-navigation__icon' />
                        </span></Grid>
                    </Grid>
                })
            }

        </Grid>
    )
}
