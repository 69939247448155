import React from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { CustomButton } from "../generals/CustomButton";

export default function DetailsDialog(props) {
  const {
    maxWidth,
    isOpen,
    onClose,
    onDelete,
    submitLabel,
    deleteLabel,
    form,
    title,
    id, 
    submitHandler,
    fullScreen,
    dialogContentStyle = {}
  } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete && onDelete(id);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
            onClose(event, reason)
        }
      }}
      // disableBackdropClick={true}
      maxWidth={maxWidth}
      open={isOpen}
      className="modal-material"
      fullScreen = {!!fullScreen}
    >
      <div className={`close`}>
        <i className={`fas fa-times`} onClick={handleCancel} />
      </div>
      {typeof title === 'string' ? <DialogTitle className={`title`}>{title}</DialogTitle> : title ? title : null}
      <DialogContent style = {dialogContentStyle}>
        <div className="modal-container">{props.children}</div>
      </DialogContent>
      <DialogActions>
        {submitLabel && <div className="button-wrapper">
          <CustomButton
            variant="contained"
            color="primary"
            type={form ? "submit" : "button"}
            form={form}
            onClick = {submitHandler ? submitHandler : ()=>({})}
          >
            {submitLabel}
          </CustomButton>
        </div>}
        {deleteLabel && (
          <div className="button-wrapper">
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleDelete}
            >
              {deleteLabel}
            </CustomButton>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
