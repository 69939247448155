import* as types from '../actions/types'

const initialState = {
  error: null,
  loading:false,
  updateStageInvoice:null,
  updatePaymentReceived:null
};

export default function updateStageInFinancialReportReducer(state = initialState, action) {
  switch (action.type) {
    case types.ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_REQUEST:
      return {
        ...state,
        loading:true
      }; 
    case types.ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_SUCCESS:
      return {
        ...state,        
        loading: false,
        error: null,
        updatePaymentReceived: action.payload,
      };

      case types.ORDER_UPDATE_STAGE_PAYMENT_RECEIVED_ERROR:
      return {
        ...state,
        loading: false,
        updatePaymentReceived:null,
        error: action.payload.error,
      }; 
    case types.ORDER_UPDATE_STAGE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        updateStageInvoice: action.payload,
      };
    case types.ORDER_UPDATE_STAGE_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.ORDER_UPDATE_STAGE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    
    default:
      return state;
  }
}


