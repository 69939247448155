import {ADD_FILE_TO_FILES_LIST_DESKTOP, DELETE_FILE_BY_FILE_ID, EDIT_SELECTED_FILE_BY_FILE_ID, GET_FILES_LIST_DESKTOP, GET_FILES_LIST_MOBILE, GET_FILES_LIST_TABLET, SELECT_FILE_BY_FILE_ID, SET_FILES_TO_FILES_LIST_TABLET } from '../../constants/customer/fileConstants';
import { desktopFilesList, mobileFilesList, tabletFilesList } from '../../constants/staticeData/filesList';

const initialState = {
    filesDesktop: desktopFilesList,
    filesMobile: {
        columns: mobileFilesList.columns,
        rows: []
    },
    filesTablet: tabletFilesList,
    singleFile: {}
}
function getUploadDate(fileName) {
    const newFileName = fileName.split('.')[0]
    return newFileName
}
export default function fileReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FILES_LIST_DESKTOP:
            return { ...state, files: state.filesDesktop };
            break;
        case GET_FILES_LIST_MOBILE:
            return { ...state, files: state.filesMobile };
            break;
        case GET_FILES_LIST_TABLET:
            // filterOut filePath to View in Table
          const  newFileRows = state.filesTablet.rows.map((row, index) => {
                if ('filePath' in row) {
                    return {
                        'id': index,
                        'fileName': row.fileName,
                        'uploadedBy': row.uploadedBy,
                        'uploadDate': row.uploadDate,
                        'fileType': row.fileType
                    }
                }
            })

            return { ...state, files: { ...state.tabletFilesList, rows: [...state.filesTablet.rows, ...newFileRows]} };
            break;
        case SET_FILES_TO_FILES_LIST_TABLET:
            console.log("yha bhi", action.payload)
            const newRowsForTablet = action.payload.map((file, index) => {
                return {
                    id: file._id, fileName: getUploadDate(file.original_name), uploadedBy: { placeholder: 'resource' }, uploadDate: '17/07/2023', fileType: { placeholder: file.type }, filePath: file.path
                }
            })
            const newRowsForDesktop = action.payload.map((file, index) => {
                return {
                    id: file._id, fileName: getUploadDate(file.original_name), uploadedBy: 'Stark', uploadDate: '17/07/2023', fileType: { placeholder: file.type}, filePath: file.path
                }
            })
            const newRowsForMobile = action.payload.map((file, index) => {
                return {
                    id: file._id, fileName: getUploadDate(file.original_name), description: "jahsbdfjasbfasl, jhasbks", uploadedBy: 'Stark', uploadDate: '17/07/2023', fileType: file.type, filePath: file.path
                }
            })
            return { ...state, filesTablet: { ...state.filesTablet, rows: [...newRowsForTablet] }, filesDesktop: { ...state.filesDesktop, rows: [...newRowsForDesktop] }, filesMobile: { ...state.filesMobile, rows: [...state.filesMobile.rows, ...newRowsForMobile] } };
        case ADD_FILE_TO_FILES_LIST_DESKTOP:
            console.log("single file from form", action.payload)
            const newRow = {
                id: action.payload.id, fileName: action.payload.fileName, uploadedBy: action.payload.uploadedBy, uploadDate: action.payload.uploadDate, fileType: { label: '', placeholder: action.payload.fileType.name, width: '100px', height: '30px', border: 'none' }, filePath: action.payload.filePath
            }
            const singleInput =  { ...state, filesDesktop: { ...state.filesDesktop, rows: [...state.filesDesktop.rows, newRow] } };
            return singleInput;

        case SELECT_FILE_BY_FILE_ID:
            const selectedRow = state.filesDesktop.rows.filter((row)=> row.id == action.payload);
           // const selectedRowMobile = state.filesMobile.rows.filter((row) => row.id == action.payload);
            return { ...state, singleFile: { ...state.singleFile, ...selectedRow[0] } };

        case EDIT_SELECTED_FILE_BY_FILE_ID:
            const otherRows = state.filesDesktop.rows && state.filesDesktop.rows.filter((row)=> row.id !== action.payload.id);
            const editedRow = {
                id: action.payload.id, fileName: action.payload.fileName, uploadedBy: action.payload.uploadedBy, uploadDate: action.payload.uploadDate, fileType: { label: '', placeholder: action.payload.fileType.name, width: '100px', height: '30px', border: 'none' }, filePath: action.payload.filePath
            }

            const otherRowsMobile = state.filesMobile.rows && state.filesMobile.rows.filter((row) => row.id !== action.payload.id);           
            const editedRowMobile = {
                id: action.payload.id || action.payload._id, fileName: action.payload.original_name || action.payload.fileName, description: "jahsbdfjasbfasl, jhasbks", uploadedBy: 'Stark', uploadDate: action.payload.uploadDate || '17/07/2023', fileType: action.payload.fileType.placeholder || action.payload.fileType.name, filePath: action.payload.filePath
            }
            const data = { ...state, filesDesktop: { ...state.filesDesktop, rows: [...otherRows, editedRow] }, filesMobile: { ...state.filesMobile, rows: [...otherRowsMobile, editedRowMobile] } };
            return data
          
        case DELETE_FILE_BY_FILE_ID:
            const remainingRows = state.filesDesktop.rows && state.filesDesktop.rows.filter((row) => row.id !== action.payload);
            const remainingRowsMobile = state.filesMobile.rows && state.filesMobile.rows.filter((row) => row.id !== action.payload);
            const remainingRowsTablet = state.filesTablet.rows && state.filesTablet.rows.filter((row) => row.id !== action.payload);

            return { ...state, filesDesktop: { ...state.filesDesktop, rows: [...remainingRows] }, filesMobile: { ...state.filesMobile, rows: [...remainingRowsMobile] }, filesTablet: { ...state.filesTablet, rows: [...remainingRowsTablet] } };

        default:
            return state;
    }
}