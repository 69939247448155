import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFactory,
  getOrderType,
  setOrderTypeDetais,  getAllOrderCategories
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles, 
  FormControl,
  InputLabel, 
  Select,
  MenuItem,
} from "@material-ui/core";
import { FORM_TYPE_ORDER } from "../../../constants/enum";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1), 
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const OrderTypeDetails = (props) => {
  const dispatch = useDispatch();

  const orderType = useSelector(
    (state) => state.orderTypes.orderTypeDetails
  );
  const factoryList = useSelector((state) => state.factories.listFactories);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const orderCategoryList = useSelector(
    (state) => state.orderСategories.listOrderCategories
  );

  useEffect(() => {   
    dispatch(getAllOrderCategories());
  }, [dispatch]);

  useEffect(() => {
    if (props.id) {
      dispatch(getOrderType(props.id));
    }
    return function cleanup() {
      dispatch(setOrderTypeDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && orderType && (
          <OrderTypeForm
            id={props.id}
            orderType={orderType}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            orderCategoryList={orderCategoryList}
          />
        )}
        {props.id === undefined && (
          <OrderTypeForm
            id={props.id}
            orderType={null}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            filterFactory={filterFactory}
            orderCategoryList={orderCategoryList}
          />
        )}
      </>
    </Grid>
  );
};

function OrderTypeForm(props) {
  const classes = useStyles();
 
  const { orderType, id, factoryList, filterFactory,orderCategoryList } = props;  
  

  const [values, setValues] = useState({
    name: orderType ? orderType.name : "",
    factory_id: orderType ? orderType.factory_id : '',
    type: orderType ? orderType.type :  0,
    order_category_id: orderType ? orderType.order_category_id :  '',
    formType: orderType ? orderType.formType : '0',
    duration: orderType ? orderType.duration :  0,
  });

  const getFactoryId  = () => { 
    let res = orderType ? orderType.factory_id : filterFactory ?filterFactory : null;
    setValues({ ...values, factory_id: res })
  }

  useMemo(() => getFactoryId(), [filterFactory]);

  const getOrderCategoryList = () => {
    if (values.factory_id) {
      let categories = [];
      categories = orderCategoryList.filter((element) => {
        return element.factory_id === values.factory_id;
      });
      return categories;
    }
    else {
      return [];
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const {  name, factory_id, type, order_category_id, formType, duration } = values;

    let model;

    if (id) {
      model = {
        _id: id,
        name,
        type,
        factory_id ,
        order_category_id,
        formType,
        duration,
      };
    } else {
      model = {
        name,
        type,
        factory_id,
        order_category_id,
        formType,
        duration,
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>   
      <form id="edit-order-type" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>           
            <TextField
              name="type"
              variant="outlined"
              type="number"
              label="type"
              value={values.type}
              onChange={handleInputChange}
            />
             <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="formType_label">form type</InputLabel>
              <Select
                labelId="formType_label"
                id="formType"
                value={values.formType}
                onChange={handleInputChange}
                label="form type"
                name="formType"
              >
                {FORM_TYPE_ORDER.map((element) => {
                  return (
                    <MenuItem key={element.code} value={element.code}>
                      {`${element.name} (${element.code})`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>         
          </Grid>

          <Grid item>           
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={ values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="order_category_id">order category</InputLabel>
              <Select
                labelId="order_category_id"
                id="order_category_id"
                value={ values.order_category_id}
                onChange={handleInputChange}
                label="order category"
                name="order_category_id"
              >
                {getOrderCategoryList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {`${element.name} (${element.name_en})`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
                name="duration"
                variant="outlined"
                label="duration"
                value={values.duration}
                onChange={handleInputChange}
            />
          </Grid>
          </Grid>
      </form>
    </div>
  );
}
