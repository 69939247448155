import React, { useCallback, useEffect } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import TableDepartment from "./TableDepartment";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../../hooks/useModal";
import {
  getByFilterDepartments
} from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";

const useStyles = makeStyles((theme) => ({
  filter: {
    marginLeft: "auto",
  },
}));

export const DepartmentList = () => {
  const dispatch = useDispatch();

  const deparmentList = useSelector(
    (state) => state.departments.listDepartments
  );
  const filterFactory = useSelector((state) => state.factories.filterFactory); 

  
  const countDepartment = useSelector(
    (state) => state.departments.countDepartment
  );
  
  const page = useSelector((state) => state.departments.currentPage);
  const rowsPerPage = useSelector((state) => state.departments.rowsPerPage);


  useEffect(() => {
    //  dispatch(getDepartments(filterFactory));
    dispatch(getByFilterDepartments(rowsPerPage, page*rowsPerPage, -1, filterFactory));
   }, [dispatch, page, rowsPerPage, filterFactory]);

  return ( 
    <div  className = "admin_page">     
      <TableDepartment deparmentList={deparmentList} countDepartment={countDepartment}> </TableDepartment>
    </div>
  );
};
