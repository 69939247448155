import React, { useEffect, useState } from 'react';
import "./DataInformation.scss";
import { Grid } from '@material-ui/core';
import Input from '../../InputFields/Input';
import Button from '../../Buttons/Button';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { MOBILE_LANDING_PAGE } from '../../../../constants/translations/customer/mobile/mobileView';
import GoogleMap from '../../../updatePopups/updateProcessPopup/GoogleMap';
import MultiSelectInput from '../../InputFields/MultiSelectInput/MultiSelectInput';
import { dataInformationInput, dataInformationBtn, dataInformationBtnMobile, dataInformationBtnDesktop } from "./DataInformationStatic";


export default function DataInformation(props) {
    const { view, city, process, inputValue, setInputValue, backlog, setBacklog, isParallelProcess, setIsParallelProcess } = props;
    const {
        ordered,
        orderedError,
        reported,
        reportedError,
        resourceName,
        resourceNameError,
    } = inputValue;

    const selectedProcess = { proccess: { city: city, address: '' }, icon: 'location' };
    // const [inputValue, setInputValue] = useState({ ordered: order, orderedError: false, reported: reported, reportedError: false, resourceName: '', resourceNameError: false, });
    // const [backlog, setBacklog] = useState(process && process.backlog);


    const validation = (name, value) => {
        // task name check
        let isValid = true;
        // ordered check
        if (name === 'ordered' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, orderedError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, orderedError: false })
            }
        }

        // reported check
        if (name === 'reported' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, reportedError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, reportedError: false })
            }
        }

        // resourceName check
        if (name === 'resourceName' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, resourceNameError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, resourceNameError: false })
            }
        }

        return isValid;
    }
    const handleChange = (name, value) => {
        validation(name, value)
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }))
    }
    const handleReportCompletions = () => {
        if(inputValue.reported !== inputValue.ordered){
            handleChange('reported', process.quantity)
        }else{
            handleChange('reported', process.finished)
        }
    } 
    const handelSubmitForCompletion = () => {
        setBacklog(!backlog);
    }

    const handelParallelProcess =() =>{
        setIsParallelProcess(!isParallelProcess);
    }
 
    const dataInformationInputToSend = {
        resourceName: {
            ...dataInformationInput.resourceName,
            value: resourceName && resourceName.map((name) => name.name) || 'Dorem ipsum, Dorem ipsum, Dorem ipsum',
            placeholder: resourceName && resourceName.map((name) => name.name) || 'Dorem ipsum, Dorem ipsum, Dorem ipsum',
            selectOptions: process.resource,
            handleChange,
           // value: inputValue.resourceName || [{ name: 'resource 1' }],
           // placeholder: mode === 'edit' && taskToEdit.resourceName && taskToEdit.resourceName.placeholder,
            // error: inputValue.resourceNameError,
            // errorText: inputValue.resourceNameError && TASK_ERRORS.RESOURCE_NAME_REQUIRED
        },
        reported: {
            ...dataInformationInput.reported,
            value: inputValue && inputValue.reported,
            placeholder: inputValue && inputValue.reported,
            onChange: handleChange,
            // value: mode === 'edit' ? taskToEdit && taskToEdit.fileName : inputValue.taskName,
            // placeholder: mode === 'edit' && reported && taskToEdit.fileName,
            //    error: inputValue.reportedError,
            //    errorText: inputValue.reportedError &&  DATA_INFORMATION_ERRORS.REPORTED_REQUIRED  //'report required'
        },
        ordered: {
            ...dataInformationInput.ordered,
            value: inputValue && inputValue.ordered,
            placeholder: inputValue && inputValue.ordered,
            onChange: handleChange,
            // error: inputValue.reportedError,
            // errorText: inputValue.reportedError && DATA_INFORMATION_ERRORS.REPORTED_REQUIRED //'ordered required'
        }
    }

    const dataInformationInputMobileToSend = {
        resourceName: {
            ...dataInformationInput.resourceName,
            value: resourceName && resourceName.map((name) => name.name) || 'Dorem ipsum, Dorem ipsum, Dorem ipsum',
            placeholder: resourceName && resourceName.map((name) => name.name) || 'Dorem ipsum, Dorem ipsum, Dorem ipsum',
            selectOptions: process.resource,
            handleChange,
        },
        reported: {
            ...dataInformationInput.reported,
            label: MOBILE_LANDING_PAGE.AMOUNT_REPORTED,  
            value: inputValue && inputValue.reported,
            placeholder: inputValue && inputValue.reported,
            onChange: handleChange,
        },
        ordered: {
            ...dataInformationInput.ordered,
            label: MOBILE_LANDING_PAGE.QUANTITY_ORDERED, 
            value: inputValue && inputValue.ordered,
            placeholder: inputValue && inputValue.ordered,
            onChange: handleChange,
        }
    }

    const dataInformationBtnDesktopToSend = {
        ...dataInformationBtnDesktop,
        reportOfCompletion: {
            ...dataInformationBtnDesktop.reportOfCompletion,
            name: inputValue.reported != inputValue.ordered ? MOBILE_LANDING_PAGE.REPORT_OF_COMPLETION : MOBILE_LANDING_PAGE.CANCEL_TERMINATION,
        },
        submitForCompletion:{
            ...dataInformationBtnDesktop.submitForCompletion,
            color: backlog ? '#455768' : "#fff",
            backgroundColor: backlog ? "#fff" : '#455768', 
        },
        isParallelProcess: {
            ...dataInformationBtnDesktop.isParallelProcess,
            backgroundColor: isParallelProcess ? "green" : 'gray',
        }
    }

    const dataInformationBtnMobileToSend = {
        ...dataInformationBtnMobile,
        reportOfCompletion: {
            ...dataInformationBtnMobile.reportOfCompletion,
            name: inputValue.reported != inputValue.ordered ? MOBILE_LANDING_PAGE.REPORT_OF_COMPLETION : MOBILE_LANDING_PAGE.CANCEL_TERMINATION,
        },
        submitForCompletion: {
            ...dataInformationBtnMobile.submitForCompletion,
            color: backlog ? '#455768' : "#fff",
            backgroundColor: backlog ? "#fff" : '#455768',
        },
        isParallelProcess: {
            ...dataInformationBtnMobile.isParallelProcess,
            color: isParallelProcess ? '#ffffff' : "#455768",
            backgroundColor: isParallelProcess ? "green" : 'gray',
        }
    }
    return (
        <Grid item xs={12} container spacing={0} className='dataInfoMainContainer'>
            {
                view === 'tablet' || view === 'desktop' ? <>
                    <Grid item xs={12} md={3} className='mainContainer__input mainContainer__input--multiSelect'>
                        <MultiSelectInput data={{ ...dataInformationInputToSend.resourceName, setInputValue : setInputValue}} view={view} />
                        &nbsp;
                    </Grid>
                    <Grid item xs={6} md={1} className='mainContainer__input mainContainer__input--mobile'>
                        <Input data={dataInformationInputToSend.reported} view={view} />
                    </Grid>
                    <Grid item xs={6} md={1} className='mainContainer__input mainContainer__input--mobile'>
                        <Input data={dataInformationInputToSend.ordered} view={view} />
                    </Grid>
                </>
                    : view === 'mobile' ? <>
                        <Grid item xs={12} md={3} className='mainContainer__input mainContainer__input--mobile-1'>
                            <MultiSelectInput data={dataInformationInputMobileToSend.resourceName} />
                            &nbsp;
                        </Grid>
                        <Grid item xs={6} md={2} className='mainContainer__input mainContainer__input--mobile'>
                            <Input data={dataInformationInputMobileToSend.ordered} />
                        </Grid>
                        <Grid item xs={6} md={2} className='mainContainer__input mainContainer__input--mobile'>
                            <Input data={dataInformationInputMobileToSend.reported} />
                        </Grid>
                    </>
                        : null
            }


            <Grid item xs={6} sm={2} md={2} className={`mainContainer__input mainContainer__input--button ${view === 'desktop' ? 'reportOfCompletion__btn--alignment': ''} `}>
                {
                    view === 'tablet' ? <Button data={dataInformationBtn.reportOfCompletion} handelSubmit={handleReportCompletions} />
                        : view === 'desktop' ? <Button data={dataInformationBtnDesktopToSend.reportOfCompletion} handelSubmit={handleReportCompletions}/>
                            : <Button data={dataInformationBtnMobileToSend.reportOfCompletion} handelSubmit={handleReportCompletions} />
                }
                &nbsp;
                &nbsp;
            </Grid>
            <Grid item xs={6} sm={2} md={2} className={`mainContainer__input mainContainer__input--button ${view === 'desktop' ? 'submitForCompletion__btn--alignment' : ''} `}>
                {
                    view === 'tablet' ? <Button data={dataInformationBtn.submitForCompletion} handelSubmit={handelSubmitForCompletion} />
                        : view === 'desktop' ? <Button data={dataInformationBtnDesktopToSend.submitForCompletion} handelSubmit={handelSubmitForCompletion} />
                            : <Button data={dataInformationBtnMobileToSend.submitForCompletion} handelSubmit={handelSubmitForCompletion} />
                }

            </Grid>
            <Grid item xs={12} sm={2} md={2} className='mainContainer__input mainContainer__input--button'>
                {
                    view === 'tablet' ? <Button data={dataInformationBtn.isParallelProcess} handelSubmit={handelParallelProcess} />
                        : view === 'desktop' ? <Button data={dataInformationBtnDesktopToSend.isParallelProcess} handelSubmit={handelParallelProcess} />
                            : <Button data={dataInformationBtnMobileToSend.isParallelProcess} handelSubmit={handelParallelProcess} />
                }

            </Grid>
            <Grid className='mainContainer__input mainContainer__input--button tabletView mainContainer__locationBtn mainContainer__locationBtn--tablet'>
                &nbsp;
                <span className={view === 'desktop' ? 'mainContainer__iconContainer-xxl mainContainer__iconContainer-xxl--alignment' : 'mainContainer__iconContainer-xxxl'}>
                    <GoogleMap process={selectedProcess} view={view} />
                </span>
            </Grid>

        </Grid>
    )
}
