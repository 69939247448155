import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getOrderCategory,
  getAllFactory,
  setOrderCategoryDetais
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles, 
  FormControl,
  InputLabel, 
  Select,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1), 
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const OrderCategoryDetails = (props) => {
  const dispatch = useDispatch();

  const orderCategory = useSelector(
    (state) => state.orderСategories.orderCategoryDetails
  );
  const factoryList = useSelector((state) => state.factories.listFactories);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    if (props.id) {
      dispatch(getOrderCategory(props.id));
    }
    return function cleanup() {
      dispatch(setOrderCategoryDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  return (
    <Grid container spacing={0}>
      <>
     
        {props.id !== undefined && orderCategory && (            
          <OrderCategoryForm
            id={props.id}
            orderCategory={orderCategory}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
          />
        )}
        {props.id === undefined && (
          <OrderCategoryForm
            id={props.id}
            orderCategory={null}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            filterFactory={filterFactory}
          />
        )}
      </>
    </Grid>
  );
};

function OrderCategoryForm(props) {
  const classes = useStyles();
 
  const { orderCategory, id, factoryList, filterFactory } = props;  

  const [values, setValues] = useState({
    name: orderCategory ? orderCategory.name : "",
    factory_id: orderCategory ? orderCategory.factory_id : null,
    name_en: orderCategory ? orderCategory.name_en :  0,
  });

  const getFactoryId  = () => { 
    let res = orderCategory ? orderCategory.factory_id : filterFactory ?filterFactory : null;
    setValues({ ...values, factory_id: res })
  }

  useMemo(() => getFactoryId(), [filterFactory],);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const {  name, factory_id, name_en } = values;

    let model;

    if (id) {
      model = {
        _id: id,
        name,
        name_en,
        factory_id,
      };
    } else {
      model = {
        name,
        name_en,
        factory_id,
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>   
      <form id="edit-order-category" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>           
            <TextField
              name="name_en"
              variant="outlined"
                label="name_en"
              value={values.name_en}
              onChange={handleInputChange}
            />
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={ values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          </Grid>
      </form>
    </div>
  );
}
