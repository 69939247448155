import React from 'react'
import { useStyles } from '../styles'
import ProductType from './ProductType'

const TypesOfProduct = (props) => {
    const classes = useStyles()
    const { productType, setProductTypeHandler, productTypes } = props

    return (
        <ul
            className={classes.typesWrapper}
        >
            {
                productTypes.map((i, index) => {
                    return <li key={index} className={classes.typesOfProductList}>
                        <ProductType
                            key={i}
                            type={i}
                            productType={productType}
                            setProductType={setProductTypeHandler}
                        />
                    </li>
                })
            }
        </ul>
    )
}

export default TypesOfProduct