import { FormControl, Grid, GridList, InputLabel, MenuItem, NativeSelect, Select, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import "../AddFilePopUp/AddFilePopUp.scss";
import "./DeletePopUp.scss";
import Button from '../../Buttons/Button';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { CANCEL, DELETE } from '../../../../constants/translations/review-popup';

const deleteBtn = {
    name: DELETE,
    width: '42%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    inconContainer: false,
    border: false,
    isBoxShadow: true,
    borderRadius: '20px'
}
const cancel = {
    name: CANCEL,
    width: '42%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    inconContainer: false,
    border: true,
    isBoxShadow: true,
    borderRadius: '20px'
}
export default function DeletePopUp(props) {
    const { showPopUp, handleShowPopUp, view, handleDelete, closePopup } = props;

    const deleteBtnMobile = {
        ...deleteBtn,
        height: '36px',
        fontSize: '12px',
    }
    const cancelBtnMobile = {
        ...cancel,
        height: '36px',
        fontSize: '12px',
    }
    return (
        <Grid className='addFilePopUp__main' style={{height: '400px', width: '580px'}}>
            <Grid xs={12}>
                <h4 className='addFilePopUp__name'>{POP_UPS_TRANSLATION.DELETE_FILE}</h4>
            </Grid>
            <Grid xs={12}>
                <div className='deleteFile__confirmation--desktop'>{POP_UPS_TRANSLATION.DELETE_FILE_CONFIRMATION}</div>
            </Grid>
            <Grid className='addFilePopUp__uploadedInfo__main'>
                <Grid xs={12} spacing={0} className='addFilePopUp__uploadedInfo__container'>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__label'><span>{POP_UPS_TRANSLATION.UPLOADED_BY}</span></Grid>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__value'><span>{POP_UPS_TRANSLATION.ZAIN_BATOR}</span></Grid>
                </Grid>
                <Grid xs={12} className='addFilePopUp__uploadedInfo__container'>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__label'><span>{POP_UPS_TRANSLATION.UPLOADED_DATE}</span></Grid>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__value'><span>19/06/2023</span></Grid>
                </Grid>
                <Grid xs={12} spacing={0} className='addFilePopUp__uploadedInfo__container'>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__label'><span>{POP_UPS_TRANSLATION.FILE_NAME}</span></Grid>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__value'><span>Jorem ipsum dolor</span></Grid>
                </Grid>
                <Grid xs={12} className='addFilePopUp__uploadedInfo__container'>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__label'><span>{POP_UPS_TRANSLATION.FILE_TYPE}</span></Grid>
                    <Grid xs={6} className='addFilePopUp__uploadedInfo__value'><span>Jorem ipsum dolor</span></Grid>
                </Grid>
            </Grid>
            <Grid sx={12} className='deleteFile__emptyBox'>
            </Grid>

            {
                view === 'mobile' ? <Grid sx={12} className='addFilePopUp__btn__container'>
                    <Button data={deleteBtnMobile} handleClick={() => handleShowPopUp()}/>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button data={cancelBtnMobile} handleClick={() => handleShowPopUp()} />
                </Grid> : <Grid sx={12} className='addFilePopUp__btn__container'>
                        <Button data={deleteBtn} handleClick={() => handleDelete()}  />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                        <Button data={cancel} handleClick={() => closePopup()} />
                </Grid>
            }
           
        </Grid>
    )
}
