export const DELETE = 'Delete'
export const PROPERTY = 'Property'
export const VALUE = 'Value'
export const ORDER = 'Order'

export const ADD_PLAIN_ROW = 'Add plain row'
export const ADD_CONDITIONAL_TEST_ROW = 'Add conditonal test row'
export const ADD_CHILD_SYSTEMS_ROW = 'Add child systems row'

export const INPUT_NAME = 'Input name'
export const MULTIPLE = 'Multiple'

export const ADD_ADDITIONAL_TEST = 'Add conditional test'
export const ADD_TEST = 'Add test'

export const LAYER = 'Layer'
export const NAME = 'Name'
export const FACTORY = 'Factory' 
export const HALF_YEAR_TEST = 'Half year test'

export const DESIGNATION_OF_STRUCTURE = 'designation of structure'

export const DESIGNATION_OF_STRUCTURE_VALUES = [
    'מפוחים',
    'מדפי אש/עשן',
    'לוחות חשמל',
    'דיחוס חדרי מדרגות',
    'מערכת שחרור עשן',
    'מערכת אוורור ויניקה',
    'בדיקת מערכת CO',
]