import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import "../../FileTab/SingleFileDetails/SingleFileDetails.scss";
import AddTaskPopUp from '../../../TabletView/PopUps/AddTask/AddTaskPopUp';
import DeletePopUp from '../../../TabletView/PopUps/Delete/DeletePopUp';
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import { ORDER_TASKS_REPORT } from '../../../../../constants/translations/order-tasks-report';
import { useDispatch, useSelector } from 'react-redux';
import { taskActionsCreator } from '../../../../../actions/customer/taskActions';

let deleteInfoPopUp = {
    title: POP_UPS_TRANSLATION.DELETE_TASK, // 'Delete Task'
    subtitle: POP_UPS_TRANSLATION.DELETE_TASK_CONFIRAMTION,    //'Are you sure that you want to delete the task?',
    info: [
        {
            label: POP_UPS_TRANSLATION.TASK_NAME, //'Task name', 
            value: POP_UPS_TRANSLATION.REMOVE //'Removes'
        },
        {
            label: POP_UPS_TRANSLATION.RESOURCE_NAME, //'Resource name', 
            value: POP_UPS_TRANSLATION.ZAIN_BATOR, // 'Zain Bator' 
        },
        {
            label: POP_UPS_TRANSLATION.STATUS, //'Status', 
            value: POP_UPS_TRANSLATION.STATUS_NOT_DONE //'Not Done' 
        },
        {
            label: ORDER_TASKS_REPORT.DUE_DATE,//'Due Date', 
            value: '15/06/2023'
        },

    ]
}

export default function SingleTaskDetails({ row, columns }) {
    const [showPopUp, setShowPopUp] = useState(false);
    const [openPopUp, setOpenPopUp] = useState("");
    const deleteTaskDetails = useSelector((state)=>state.tasks.singleTask);


    const dispatch = useDispatch();

    function handleShowPopUp() {
        setShowPopUp(!showPopUp)
    }
    function handleMenuOpen(action) {
        if (action === "editTask") {
            setOpenPopUp("editTask")
        } else if (action === "deleteTask") {
            setOpenPopUp("deleteTask")
        }
        handleShowPopUp()
    }


    useEffect(() => {
        dispatch(taskActionsCreator.selectedTaskByTaskId(row.id))
    }, [row.id])


    deleteInfoPopUp = {
        ...deleteInfoPopUp,
        info: [
            {
                label: POP_UPS_TRANSLATION.TASK_NAME, value: deleteTaskDetails && deleteTaskDetails.fileName 
            },
            {
                label: POP_UPS_TRANSLATION.RESOURCE_NAME, value: deleteTaskDetails.resourceName && deleteTaskDetails.resourceName.placeholder 
            },
            { label: ORDER_TASKS_REPORT.DUE_DATE, value: deleteTaskDetails && deleteTaskDetails.dueDate  },
            { label: POP_UPS_TRANSLATION.STATUS, value: deleteTaskDetails.fileType && deleteTaskDetails.fileType.placeholder},
        ]
    }
    
    return (
        <>
            <Grid className='SingleFileDetails__main'>
                {
                    columns && columns.map((header, index) => {
                        return (
                            header.headerName !== 'Actions' ?
                                <Grid xs={12} className='addFilePopUp__uploadedInfo__container' style={{ height: '34px', background: 'transparent' }}>
                                    <Grid xs={4} className='addFilePopUp__uploadedInfo__label'><span>{header.headerName}</span></Grid>
                                    <Grid xs={8} className='addFilePopUp__uploadedInfo__value'><span>{row[header.field]}</span></Grid>
                                </Grid>
                                :
                                <Grid xs={12} className='addFilePopUp__uploadedInfo__container' style={{ height: '34px', background: 'transparent' }}>
                                    <Grid xs={3} className='addFilePopUp__uploadedInfo__label'><span>Actions</span></Grid>
                                    <Grid xs={9} className='addFilePopUp__uploadedInfo__value' style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'left' }}>
                                        <span className='SingleFileDetails__iconContainer' onClick={() => handleMenuOpen("editTask")}>
                                            <EditIcon style={{ fontSize: '16px', color: '#F4B506' }} />
                                        </span>
                                        &nbsp;
                                        &nbsp;
                                        <span className='SingleFileDetails__iconContainer' onClick={() => handleMenuOpen("deleteTask")}>
                                            <DeleteIcon style={{ fontSize: '16px', color: '#F67288' }} />
                                        </span>
                                    </Grid>
                                </Grid>
                        )
                    })
                }
            </Grid>
            {
                openPopUp === "editTask" ? showPopUp && <AddTaskPopUp
                    handleShowPopUp={handleShowPopUp}
                    name={POP_UPS_TRANSLATION.EDIT_TASK}
                    view="mobile"
                    showPopUp={showPopUp}
                    process={process && process}
                    taskId={row.id}
                    mode='edit'
                /> : openPopUp === "deleteTask" ? 
                showPopUp && <DeletePopUp 
                id={row.id}
                handleShowPopUp={handleShowPopUp} 
                view="mobile" 
                data={deleteInfoPopUp} /> : null
            }
        </>
    )
}
