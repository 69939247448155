export const customerDetails = [
    {
        label: 'Customer number',
        value: 'PR40478954'
    },
    {
        label: ' Contact name',
        value: 'John Snow'
    },
    {
        label: 'Email',
        value: 'JohnSnow@mail.co.il'
    },
    {
        label: 'City',
        value: 'Tel Aviv Jaffa'
    },
    {
        label: 'Address',
        value: 'Rorem ipsum dolor sit amet'
    },
    {
        label: 'Year of occupancy',
        value: '06/17/2021'
    }
]