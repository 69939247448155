import React from 'react';
import "./Header.scss";
import { Grid } from '@material-ui/core';
import plantika_new_logo from './../../assets/plantika_new_logo.svg';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import Account_logo_Cochav from '../../assets/Account_logo_Cochav.svg';
import Person_icon from '../../assets/Person_icon.svg';
import Chat_icon from '../../assets/Chat_icon.svg';
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';


export default function Header() {
  return (
    <Grid container spacing={0} className='header__main'>
      <Grid xs={4} className='header__col heder__brand'>
        <img src={plantika_new_logo} alt='logo' />
      </Grid>
      <Grid xs={4} className='header__col header__search'>
        <div className='header__iconContainer'>
          <AddIcon style={{ color: '#fff' }} />
        </div>
        &nbsp;
        &nbsp;

        <div className='header__searchContainer'>
          <input placeholder={`...${DESKTOP_VIEW_TRANSLATION.SEARCH}`} />
          <SearchIcon />
        </div>
      </Grid>
      <Grid xs={4} className='header__col header__account'>
        <div className='header__account__icons'>
          <div className='header__account__iconsContainer'>
          <img src={Chat_icon} alt='icon'/>
          </div>
          <div className='header__account__iconsContainer' style={{position: 'relative'}}>
          <span className='header__notification'>5</span>
          <NotificationsIcon style={{
            width: '24px', height: '24px', color: '#1861A3'
          }} /></div>
          <div className='header__account__iconsContainer' style={{ background: "#1861A3"}}>
          <img src={Person_icon} alt='icon'/>
          </div>
        </div>

        <div className='header_verticalLine'></div>

        <div className='header__account__logo'>
        <img src={Account_logo_Cochav} alt='logo'/>
        </div>
      </Grid>
    </Grid>
  )
}
