import {React, useEffect, useState } from "react";
import { generalGetRequest } from "../functions/api/general";
import {SORT_ASC, SORT_DESC} from '../constants/tasks-report'
import moment from 'moment'

export const useTasksReport = (resource, customerId, orderId, searchRequest, sortDirection, deletedTaskId) => {
  const url = '/system/reports/get-tasks-report'
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [isLoading, setLoading] = useState(false)
  
  const sortAsc = (a,b) => a.due_date > b.due_date ? 1 : a.due_date === b.due_date ? 0 : -1
  const sortDesc = (a,b) => a.due_date > b.due_date ? -1 : a.due_date === b.due_date ? 0 : 1
  const compareTaskWithSearchRequest = (query) => (task) => {
    const {order_number, customer_name, process_name, task_description, due_date, resource } = task
    const upperCaseQuery = query.toUpperCase()
    const setToUpperCase = (value) => typeof value === 'string' ? value.toUpperCase() : value
    const formatDate = (date) => moment(date).format('DD/MM/YYYY')
    if(
      setToUpperCase(order_number)     === upperCaseQuery ||
      setToUpperCase(customer_name)    === upperCaseQuery ||
      setToUpperCase(process_name)     === upperCaseQuery ||
      setToUpperCase(task_description) === upperCaseQuery ||
      formatDate(due_date)             === upperCaseQuery ||
      setToUpperCase(resource)         === upperCaseQuery
      ) return task
  }
  
  useEffect(()=>{
      const getTasksData = async(url) =>{
          setLoading(true)
          const {result} = await generalGetRequest(url)
          const sortedResult = result.sort(sortAsc)
          setData(sortedResult)
          setFilteredData(sortedResult)
          setLoading(false)
      }
      getTasksData(url)
  },[])

  useEffect(()=>{
      if(deletedTaskId){
        const copyData = [...data]
        const deletedItemIndex = copyData.findIndex(i => i.task_id === deletedTaskId )
        if(~deletedItemIndex){
          copyData.splice(deletedItemIndex,1)
          setData(copyData)
        }
      }
      
    },[deletedTaskId])

  useEffect(()=>{
    const filterTasks = (data) => {
      // setLoading(true)
      let filteredTasks = [...data]
      if(resource){
        filteredTasks = filteredTasks.filter(i=> i.resource_id === resource)
      }
      if(customerId){
        filteredTasks = filteredTasks.filter(i=> i.customer_id === customerId)
      }
      if(orderId){
        filteredTasks = filteredTasks.filter(i=> i.order_id === orderId)
      }
      if(sortDirection === SORT_ASC){
        filteredTasks = filteredTasks.sort(sortAsc)
      }
      if(sortDirection === SORT_DESC){
        filteredTasks = filteredTasks.sort(sortDesc)
      }
      if(searchRequest){
        filteredTasks = filteredTasks.filter(compareTaskWithSearchRequest(searchRequest))
      }
      setFilteredData(filteredTasks)
      // setLoading(false)
    }
    filterTasks(data)

  },[resource, customerId, orderId, searchRequest, sortDirection, data])

  return [filteredData, isLoading]
};

