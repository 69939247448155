import React, { useState, useEffect } from 'react'
import { styles } from './styles'
import { QUOTE } from '../../../constants/translations/customersPage'
import ProductPdf from './Parts/ProductPdf'
import CustomerAndQuoteInfo from './Parts/CustomerAndQuoteInfo'
import QuoteAgreementPage from './Parts/QuoteAgeementPage'
const VAT_TAX_CALCULATION = 1.17



const QuotePDF = (props) => {
    const { LOCATION } = QUOTE

    const { products, pdfProducts, quoteNumber, clientName, address, phoneNumber, quoteStatus, quoteDate, setReadyForPrint, remarks, projectType , productDiscount, extraCharges} = props
    const [productsFirstPage, setProductsFirstPage] = useState('')
    const [otherPagesProducts, setOtherPagesProducts] = useState('')
    const [totalCost, setTotalCost] = useState('')
    const [totalCostWithVAT, setTotalCostWithVAT] = useState('');
    const {
        firstPage,
        remainingPage
    } = pdfProducts;
    useEffect(() => {
        const createProductsList = () => {
            const allProducts = []
            let productsCost = 0
            products.map( i => i.products.map(i => {
                //  orderNumber is for showing product order in pdf
                const newProduct = {...i, orderNumber: allProducts.length + 1}
                allProducts.push(newProduct)
                productsCost += +i?.total_cost

            }))
            const [firstProduct, secondProduct, thirdProduct, fourthProduct, ...otherSheets] = allProducts

            const productsForFirstPage = [firstProduct, secondProduct, thirdProduct, fourthProduct].filter(i => i)
            setProductsFirstPage(productsForFirstPage)
            if (otherSheets?.length) {
                const MAX_QUANTITY_OF_PRODUCTS_ON_PAGE = 5
                let sheet = []
                let sheets = []
                // creating products for not first page
                for (let i = 0; i < otherSheets.length; i++) {
                    const product = otherSheets[i]
                    sheet.push(product)
                    if (sheet.length === MAX_QUANTITY_OF_PRODUCTS_ON_PAGE) {
                        sheets.push(sheet)
                        sheet = []
                    } else if (i === otherSheets.length - 1 && sheet.length) {
                        sheets.push(sheet)
                        sheet = []
                    }
                }
                setOtherPagesProducts(sheets)

            } else {
                setOtherPagesProducts([])
            }
            setTotalCost(productsCost)
            const priceIncludesTax = (productsCost * VAT_TAX_CALCULATION).toFixed(2)
            setTotalCostWithVAT(priceIncludesTax)
        }

        if (products) {
            createProductsList()
        }
    }, [products])

    useEffect(() => {
        setReadyForPrint(true)
        // if (products && quoteNumber && clientName && quoteStatus && quoteDate && totalCost && totalCostWithVAT && projectType && otherPagesProducts)
            // setReadyForPrint(true)
    }, [productsFirstPage, quoteNumber, clientName, quoteStatus, quoteDate, totalCost, totalCostWithVAT, projectType, otherPagesProducts])

    let index = 0;
    return (productsFirstPage && quoteNumber && clientName && quoteStatus && quoteDate && projectType && otherPagesProducts) ?
    <>
        <div style ={styles.quotePdfBox}>
            <div className="pdf-pages-split-quote" style={{direction: 'rtl'}}>
                <CustomerAndQuoteInfo
                    quoteNumber={quoteNumber}
                    clientName={clientName}
                    address={address}
                    phoneNumber={phoneNumber}
                    quoteStatus={quoteStatus}
                    quoteDate={quoteDate}
                    projectType={projectType}
                />
                {   
                    firstPage.length > 0 && <div style={styles.page} >
                            <div className='quoteProductsPage' style={styles.productWrapper}> 
                                {
                                    firstPage?.map((i, idx) => {
                                        if(i?.productLocation)index = 0;
                                        return( i?.productLocation ?
                                            <p key={idx} style={styles.location}>{i.productLocation}</p>
                                            :
                                            <ProductPdf key={i._id} product={i} idx={++index} />
                                        )
                                    })
                                }
                            </div>
                    </div>
                }
            </div>
            {
                remainingPage.length > 0 && remainingPage?.map((item, idx) => {
                    return <div key={idx} className="pdf-pages-split-quote" style={{direction: 'rtl'}}>
                        <div className='quoteProductsPage' style={styles.productWrapper}> 
                            {
                                item?.map((i, idx) => {
                                    if(i?.productLocation)index = 0;
                                    return( i?.productLocation ? 
                                        <p key={idx} style={styles.location_from_secondPage}>{i.productLocation}</p>
                                        :
                                        <ProductPdf key={i._id} product={i} idx={++index} />
                                    )
                                })
                            }
                        </div>
                    </div>
                })
            }
            <div className="pdf-pages-split-quote" style={{direction: 'rtl'}}>
                <QuoteAgreementPage
                    totalCost={totalCost}
                    totalCostWithVAT={totalCostWithVAT}
                    productDiscount={productDiscount}
                    extraCharges={extraCharges}
                    remarks={remarks}
                    products={products}
                />
            </div>
        </div>
    </>
    : 
    null
}

export default QuotePDF