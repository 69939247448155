import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    typesWrapper:{
        display:'flex',
    },
    productModelsWraper: {
        display:'flex',
    },
    typesOfProductList: {
        marginRight: '9px',
    },
    productType:{
        width: '100%',
        marginBottom: '-1px',
        background: '#f1f3f4',
        border: '1px solid transparent',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',
        padding: '10px',
        fontSize: '16px'
    },
    activeProductType: {
        color: '#0091ff;',
        backgroundColor: '#fff',
        boxShadow: '0 12px 0 #fff, 0 1px 8px #cdc7c7',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderTop: '3px solid #57b7ff',
    },
    productModelType:{
        padding:'10px',
        border: '1px solid rgba(224, 224, 224, 0)',
        marginRight:'10px',
        cursor:'pointer'
    },
    // selectedProductType:{
    //     padding:'10px',
    //     border: '1px solid rgba(224, 224, 224, 1)',
    //     marginRight:'10px',
    //     cursor:'pointer',
    //     color:'#0091ff',
    // },
    selectedProductModelType:{
        padding:'10px',
        // border: '1px solid rgba(224, 224, 224, 1)',
        marginRight:'10px',
        cursor:'pointer',
        color:'#0091ff',
        boxShadow: '0px 1px 7px #cdc7c7',
    },
    productsWrapper:{
        display:'flex',
        flexWrap:'wrap',
        gap: '30px 30px',
        padding: '25px 0px',
    },
    card:{
        // height:'200px',
        cursor:'pointer',
    },
    cardImage:{
        height: '120px',
        objectFit:'contain',
        marginBottom:'4px'
    },
    cardDescription:{
        height:'47px',
        overflow: 'hidden'
    },
    selectedCard:{
        cursor:'pointer',
        background:'#57b7ff',
        padding: '3px',
        borderRadius: '2px',
        boxShadow: '0px 1px 10px #9d9797',
    },
    quantityWrapper:{
        width:'189px'
    }
    
}));