import {useState, useEffect, useRef} from 'react'
import {generalGetRequest} from '../functions/api/general'

export const useTasks = (orderProcessId, tasksFromFormBody) => {
    const [tasks, setTasks] = useState(tasksFromFormBody || [])
    useEffect(()=>{
        const getTasks = async(orderProcessId) => {
            const {result} = await generalGetRequest(`/system/order-process/get-tasks?_id=${orderProcessId}`)
            if (result){
              const doneTasks = result.filter(i=> i.is_done)
              const inProgressTasks = result.filter(i=>!i.is_done).sort((a,b)=> a.due_date > b.due_date ? 1 : a.due_date === b.due_date ? 0 : -1)
              setTasks([...inProgressTasks, ...doneTasks])  
            } 
        }
        if(orderProcessId && !tasksFromFormBody) getTasks(orderProcessId)
        else setTasks(tasksFromFormBody || [])
       
    },[orderProcessId])
    return [tasks, setTasks]
}
