export const DIALOG_BACKGROUND_COLOR = '#EEEEEE'
export const BLUE_COLOR = "#0091ff"
export const TEXT_COLOR = '#455768'
export const BORDER_COLOR = '#CACBCC'
// export const START_TIME_COUNT = 'START_TIME_COUNT'
export const CLOSE_BUTTON_BACKGROUND = '#6C7389'
export const LIGHT_BLUE = '#C4E3FC'
export const WHITE_COLOR = '#FFFFFF'
export const TEXT_FONT_SIZE = '2.5vw'
export const ICON_WIDTH = '5vw'
// export const TIMER_FONT_SIZE = '2vw'
export const NAV_FONT_SIZE = '2.2vw'
export const ICON_SIZE = '4vw'
export const BUTTON_WIDTH = '18.5vw'
// ---------------------------------------
export const TEXT_FONT_SIZE_LARGE = '1.5vw'
export const ICON_WIDTH_SMALL = '1.5vw'
export const ICON_WIDTH_MEDIUM = '1.5rem'
export const ICON_WIDTH_LARGE = '3vw'
// export const TIMER_FONT_SIZE_LARGE = '1vw'
export const NAV_FONT_SIZE_LARGE = '1.7vw'
export const BUTTON_WIDTH_LARGE = '12.5vw'
// ----------------------------------------
export const ORDER_PROCESS_ID = 'ORDER_PROCESS_ID'
export const TIMERS = 'TIMERS'
export const SYSTEMS_TAB = 1 
export const TASKS_TAB = 2
export const FILES_TAB = 3
export const RED = 'red'
export const GREEN = 'green'
export const IMAMGE_LOADING = "IMAMGE_LOADING"
