import React, { useEffect, useState } from 'react'
import "./FileTab.scss";
import "../../PopUps/AddFilePopUp/AddFilePopUp.scss"
import { Grid } from '@material-ui/core';
import SingleFileDetails from './SingleFileDetails/SingleFileDetails';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import TabEmptyLanding from '../TabEmptyLanding/TabEmptyLanding';
import { FILES } from '../../../../constants/translations/review-popup';
import { MOBILE_LANDING_PAGE } from '../../../../constants/translations/customer/mobile/mobileView';
import { useDispatch, useSelector } from 'react-redux';
import { useFiles } from '../../../../hooks/useFiles';
import { fileActionCreator } from '../../../../actions/customer/fileActions';
import Loader from '../../../LoaderNew/Loader';
import EmptyList from '../../TabletView/SystemsTab/EmptyList/EmptyList';


const footerData = {
  btnName: MOBILE_LANDING_PAGE.FILE,
  field: 'file'
}

export default function FileTab(props) {
  const { handleClose, process} = props;

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const filesMobileDataList = useSelector((state) => state.files.filesMobile);
  
  const [filesList, setFilesList] = useFiles(process && process.customer_id);


  useEffect(() => {
    dispatch(fileActionCreator.getFilesListMobile());
  }, [filesList])

  useEffect(() => {
    dispatch(fileActionCreator.setFileToFilesListTablet(filesList))
  }, [filesList, process && process.customer_id])

  setTimeout(() => {
    if (filesMobileDataList.rows.length === 0) {
      setIsLoading(false)
    }
  }, 700);
  console.log("file mobile data", filesMobileDataList.rows)
  return (
    <Grid container spacing={0} className='FileTab__main'>
      <MobileHeader
        headerName={FILES}
        processName={process && process.process_name || 'Installation'}
        customerName={process && process.client_name || 'Zain bator'}
        orderNumber={process && process.order_number || ' S02100004B'}
        handleClose={handleClose}
        city={process && process.city}
        process={process && process}
      />
      {
        filesMobileDataList.rows.length ? <Grid className='FileTab__filesList'>
          {
            filesMobileDataList.rows.length > 0 && filesMobileDataList.rows.map((file, index) => {
              return (
                <SingleFileDetails row={file} columns={filesMobileDataList.columns} process={process} updateFilesList={setFilesList} />
              )
            })
          }

        </Grid>
          : isLoading ? <Loader /> : <TabEmptyLanding title={FILES} />
      }

      {/* {
        filesMobileDataList ? <Grid className='FileTab__filesList'>
          {
            filesMobileDataList.rows.length > 0 && filesMobileDataList.rows.map((file, index) => {
              return (
                <SingleFileDetails row={file} columns={filesMobileDataList.columns} process={process} updateFilesList={setFilesList} />
              )
            })
          }

        </Grid> : <TabEmptyLanding title={FILES} />
      } */}

      <Footer data={footerData} handleClose={handleClose} />
    </Grid>

  )
}
