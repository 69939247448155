import React, {  useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import TableSubDepartment from "./TableSubDepartment";
import { useDispatch, useSelector } from "react-redux";
import {getByFilterSubDepartment } from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";


const useStyles = makeStyles((theme) => ({
  filter: {
    marginLeft: "auto",
  }, 
}));

export const SubDepartmentList = () => {
  const dispatch = useDispatch();

   const subDeparmentList = useSelector(
    (state) => state.subDepartments.listSubDepartments
  );
  
  const countSubDepart = useSelector(
    (state) => state.subDepartments.countSubDepart
  );
  
  const page = useSelector((state) => state.subDepartments.currentPage);
  const rowsPerPage = useSelector((state) => state.subDepartments.rowsPerPage);

  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const filterDepartment = useSelector((state) => state.departments.filterDepartment);
 
  useEffect(() => {
     // dispatch(getSubDepartments(filterFactory));
     dispatch(getByFilterSubDepartment(rowsPerPage, page*rowsPerPage, -1, filterFactory,filterDepartment));
  }, [dispatch, page, rowsPerPage, filterFactory,filterDepartment]);



  return (
    <div className = "admin_page">     
      <TableSubDepartment subDeparmentList = {subDeparmentList} countSubDepart={countSubDepart}></TableSubDepartment>
    </div>
  );
};
