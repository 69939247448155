import React, { useEffect, useState } from 'react'
import MobileHeader from '../Header/MobileHeader'
import DataInformation from '../../TabletView/DataInformation/DataInformation'
import MobileNavigation from '../MobileNavigation/MobileNavigation'
import Footer from '../Footer/Footer'
import { Grid } from '@material-ui/core';
import "./MobileLandingPage.scss";
import Loader from '../../../LoaderNew/Loader'
import DetailsTabMobile from '../DetailsTabMobile/DetailsTabMobile'
import TaskTab from '../TaskTab/TaskTab'
import FileTab from '../FileTab/FileTab'
import Systems from '../Systems/Systems'
import FullScreenModal from './FullPageMobileModal'
import { MOBILE_LANDING_PAGE } from '../../../../constants/translations/customer/mobile/mobileView'
import { FILES, SYSTEMS, SYSTEM_DETAILS, TASKS } from '../../../../constants/translations/review-popup'

const navigationTab = [
  { label: SYSTEMS, value: 12, field: 'systems' },
  { label: FILES, value: 12, field: 'files' },
  { label: TASKS, value: 12, field: 'tasks' },
  { label: SYSTEM_DETAILS, field: 'details' },
]
const footerData = {
  field: 'landingPage'
}

export default function MobileLandingPage(props) {
  const { closePopup, orderId, process, view, updateErrorPopup } = props;
  const [selectedTab, setSelectedTab] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [lockPopup, setLockPopup] = useState(false);

  const [inputValue, setInputValue] = useState(
    {
      ordered: process.quantity,
      orderedError: false,
      reported: process.finished,
      reportedError: false,
      resourceName: process.resource || [],
      resourceNameError: false,
    });
  const [backlog, setBacklog] = useState(process && process.backlog);
  const [isParallelProcess, setIsParallelProcess] = useState(process?.should_occur_parallel ? process?.should_occur_parallel : false);



  function changeSelectionTab(newValue) {
    setLoading(true)
    setSelectedTab(newValue);
    handleOpenModal();
  }

  const handleOpenModal = (component) => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function handleShowLockPopUP() {
    setLockPopup(!lockPopup)
  }


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500);
  }, [selectedTab]);


  return (
    <Grid className='mobileLanding'>
      <MobileHeader
        headerName={MOBILE_LANDING_PAGE.INSTALLATION}
        processName={process && process.process_name || 'Installation'}
        customerName={process && process.client_name || 'Zain bator'}
        orderNumber={process && process.order_number || ' S02100004B'}
        closePopup={closePopup && closePopup}
        handleCloseModal={handleCloseModal}
        headerFor={'landingPage'}
        city={process && process.city}
        process={process && process}
        lockPopup={lockPopup}
        handleShowLockPopUP={handleShowLockPopUP}

      />
      <div className='mobileLanding__dataContainer'>
        <DataInformation
          view={view || 'mobile'}
          // resource={process && process.resource}
          // order={process && process.order} 
          // city={process && process.city}
          process={process && process}
          // reported={0}
          inputValue={inputValue}
          backlog={backlog}
          setBacklog={setBacklog}
          setInputValue={setInputValue}
          isParallelProcess={isParallelProcess}
          setIsParallelProcess={setIsParallelProcess}
        />
        <MobileNavigation changeTab={changeSelectionTab} data={navigationTab} />
      </div>
      <Grid className='mobileLanding__footer'>
        <Footer data={footerData} />
      </Grid>

      {
        lockPopup && <div className="custom__popup__locked-popup" style={{ position: 'absolute', top: '0', left: '0' }}>
          <section className="custom__popup__update__submit-section">
            <div>
              שחרור נעילה הוא הליך בלתי הפיך, האם הינך בטוח שברצונך לשחרר
              נעילה?
            </div>
            <div>
              <button
                onClick={() => {
                  handleShowLockPopUP()
                }}
              >
                ביטול
              </button>
              <button
                onClick={() => {
                  handleShowLockPopUP()
                }}
              >
                אישור
              </button>
            </div>
          </section>
        </div>
      }

      <FullScreenModal open={modalOpen} onClose={handleCloseModal}>
        {
          selectedTab === 1 ? <FileTab 
          orderId={orderId}
          handleClose={handleCloseModal} 
          process={process}
          view={view || 'mobile'}
          />
            : selectedTab === 0 ? <Systems
              view={view} 
              orderId={orderId}
              handleClose={handleCloseModal}
              process={process}
              closePopup={closePopup}
            />
              : selectedTab === 3 ? <DetailsTabMobile
                handleClose={handleCloseModal}
                closePopup={closePopup && closePopup}
                orderId={orderId}
                process={process}
                view={view}
                dataInfoInputValue={inputValue}
                backlog={backlog}
                isParallelProcess={isParallelProcess}
                updateErrorPopup={updateErrorPopup}
              />
                : selectedTab === 2 ? <TaskTab
                  handleClose={handleCloseModal}
                  orderProcessId={process && process._id}
                  process={process}
                />
                  : null
        }
      </FullScreenModal>
    </Grid>
  )
}
