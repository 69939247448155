import React from 'react'
import '../../sass/loader/_loader.scss'

function Loader(props) {
    return (
        <div className="loader-new" style={props.style}>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader

