import { Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react';
import "./MobileHeader.scss";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LockIcon from '@material-ui/icons/Lock';
import person_icon_img_md from "../../assets/person_icon_img_md.svg";
import hash_1_img_icon_md from "../../assets/hash_1_img_icon_md.svg";
import lock_img_icon_md from '../../assets/lock_img_icon_md.svg';
import ClearIcon from '@material-ui/icons/Clear';
import { MOBILE_LANDING_PAGE } from '../../../../constants/translations/customer/mobile/mobileView';
import GoogleMap from '../../../updatePopups/updateProcessPopup/GoogleMap';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useDispatch } from 'react-redux';
import { showMainPopup } from '../../../../actions/mainPopup_actions';


export default function MobileHeader(props) {
    const { headerName, processName, customerName, orderNumber, view, 
        closePopup, headerFor, handleClose, city, process, handleShowLockPopUP, 
        lockPopup, handelOpenLock, handelCloseLock, handleShowAddOrderPopUp, exitFullscreen } = props;
    const selectedProcess = { proccess: { city: city, address: '' }, icon: 'location' };

    const [locked, setLocked] = useState(process && process.is_detached ? false : true);

    const dispatch = useDispatch();
    const handelLock = () => {
        setLocked(!locked)
        handleShowLockPopUP()
    }

    const handleOrderPopup = () => {
        handleShowAddOrderPopUp()
    }
    const handelClosePopup = () => {
        closePopup()
        if (view === 'tablet') exitFullscreen()
    }

    return (
        <Grid container spacing={0} className='mobileHeader__main'>
            <Grid item xs={12} className='mobileHeader__content'>
                {view !== 'mobile' && <div className='mobileHeader__closePopUp'>
                    <span className="mobileHeader__clearIcon-container" onClick={handelClosePopup}>
                        <ClearIcon className="mobileHeader__clearIcon" />
                    </span>
                </div>}
                <Grid className='mobileHeader__head mobileHeader__row'>
                    <Grid item xs={3}>
                        <span className='mobileHeader__iconContainer__lg' onClick={headerFor === 'landingPage' ? closePopup : handleClose} style={{ paddingLeft: '5px' }}>
                            <ArrowBackIosIcon style={{ fontSize: '16px' }} />
                        </span>
                    </Grid>
                    <Grid item xs={8} className='mobileHeader__head__info'>
                        <span className='mobileHeader__name'>{headerName}</span>
                        &nbsp;
                        &nbsp;
                        {
                            headerName === MOBILE_LANDING_PAGE.INSTALLATION ? <span className='mobileHeader__iconContainer__lg-2' onClick={handelLock}> {

                                locked ? <LockIcon className='mobileHeader__icon' style={{ fontSize: '16px', fontWeight: '500' }} /> : <LockOpenIcon style={{ fontSize: '16px', fontWeight: '500' }} />
                            } </span>
                                : <span className='mobileHeader__iconContainer__md' style={{ fontSize: '12px', fontWeight: '500' }}>
                                    3
                                </span>
                        }

                    </Grid>
                </Grid>
                <Grid className='mobileHeader__head'>
                    <Grid item xs={2} className='mobileView'>
                        <span className='mobileHeader__iconContainer__md' style={{ background: '#186315', }}>
                            <GoogleMap process={selectedProcess} view={'mobile'} />
                        </span>
                    </Grid>
                    <Grid item xs={12} md={10} className='mobileHeader__head__info'> 
                        <Typography className='mobileHeader__head__info__data mobileHeader__details__link'
                            onClick={handleOrderPopup}
                        >
                            <span className={view === 'desktop' ? 'mobileHeader__details--desktop' : 'mobileHeader__details'}>{orderNumber || 'S02100004B'}</span>
                            &nbsp;
                            <img src={hash_1_img_icon_md} className={view === 'desktop' ? 'mobileHeader__icon-md' : 'mobileHeader__icon-xl'} alt='icon' />
                        </Typography>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Typography className='mobileHeader__head__info__data'>
                            <span className={view === 'desktop' ? 'mobileHeader__details--desktop' : 'mobileHeader__details'}>{customerName || MOBILE_LANDING_PAGE.ZAIN_BATOR}</span>
                            &nbsp;
                            <img src={person_icon_img_md} className={view === 'desktop' ? 'mobileHeader__icon-md' : 'mobileHeader__icon-xl'} alt='icon' />
                        </Typography>

                        <Typography className='mobileHeader__head__info__data tabletView'>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <span className={view === 'desktop' ? 'tabletHeader__details--desktop tabletHeader__details-wrap' : 'tabletHeader__details tabletHeader__details-wrap'}>{processName}</span>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            {
                                locked ? <img onClick={handelLock} src={lock_img_icon_md}
                                    className={view === 'desktop' ? 'mobileHeader__icon-lg' : 'mobileHeader__icon-xxl'} alt='icon' /> :

                                    <span onClick={handelLock} className={view === 'desktop' ? 'mobileHeader__iconContainer__xl' : 'mobileHeader__iconContainer__xxl'}>
                                        <LockOpenIcon className='mobileHeader__icon' />
                                    </span>
                            }
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

