import React, { useCallback, useEffect  } from "react";
import TableFactories from "./TableFactories";
import { useDispatch, useSelector } from "react-redux";
import {  getFactories } from "../../../actions/actions";


export const FactoriesList = () => {
  const dispatch = useDispatch();
  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const factoryList = useSelector((state) => state.factories.listFactories);
  
  useEffect(() => {
    dispatch(getFactories(filterFactory));
  }, [dispatch, filterFactory]);

  
  return (
    <div>     
     
      <TableFactories factoryList={factoryList} />
    </div>
  );
};
