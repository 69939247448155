import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    sizeFormWrapper:{
        width: '223px',
        margin: '20px 0'
    },
    title:{
        marginBottom:'15px'
    },
    attributesFormsHolder:{
        display:'flex',
        paddingTop: '10px'
    },
    submitButton: {
        background: '#57b7ff',
        color:'white',
        width: '155px',
        borderRadius: '1px'
    }
}));

