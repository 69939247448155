import { POP_UPS_TRANSLATION } from "../../../../../constants/translations/customer/PopUps/PopUps";
import { ADD, CANCEL } from "../../../../../constants/translations/review-popup";

export const add = {
    name: ADD,
    width: '35%',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '24px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}
export const cancel = {
    name: CANCEL,
    width: '35%',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}

export const addSystemInputs = {
    systemName: {
        label: POP_UPS_TRANSLATION.SYSTEM_NAME,
        placeholder: POP_UPS_TRANSLATION.CHOOSE,
        value: 'Choose',
        name: 'systemName'
    },
    locationDescription: {
        label: POP_UPS_TRANSLATION.LOCATION_DESCRIPTION,
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        type: 'text',
        name: 'locationDescription'
    },
    floor: {
        label: POP_UPS_TRANSLATION.FLOOR,
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        type: 'text',
        name: 'floor'
    },

}
export const addMobile = {
    ...add,
    fontSize: '12px',
    height: '36px',
    borderRadius: '20px',
    width: '42%',
};
export const cancelMobile = {
    ...cancel,
    fontSize: '12px',
    height: '36px',
    borderRadius: '20px',
    width: '42%',
};
export const addDesktop = {
    ...add,
    fontSize: '16px',
    height: '52px',
    borderRadius: '20px',
    width: '35%',
};
export const cancelDesktop = {
    ...cancel,
    fontSize: '16px',
    height: '52px',
    borderRadius: '20px',
    width: '35%',
};