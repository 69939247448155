import { useEffect, useState } from 'react'

export const useOnline = () => {
    const [onlineStatus, setOnlineStatus] = useState(navigator.onLine)

    useEffect(() => {
        window.addEventListener("offline", () => {
          setOnlineStatus(false);
        });

        window.addEventListener("online", () => {
            setOnlineStatus(true);
          });

        return () => {
          window.removeEventListener("offline", () => {
            setOnlineStatus(false);
          });
          window.removeEventListener("online", () => {
            setOnlineStatus(false);
          });
        };
      }, []);

    return onlineStatus
}

