import React from "react";
import { polyfill } from "es6-promise";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { DepartmentList } from "./department/DepartmentList";
import { SubDepartmentList } from "./subDepartment/SubDepartmentList";
import { FactoriesList } from "./factoryies/FactoriesList";
import "../../sass/adminPanel/_admin.scss";
import { RecipientList } from "./recipient/RecipientList";
import { ResourceList } from "./resource/ResourceList";
import { OrderTypeList } from "./orderType/OrderTypeList";
import { GanttTemplateList } from "./ganttTemplate/GanttTemplateList";
import { OrderList } from "./order/OrderList";
import { ActualGanttList } from "./actualGantt/ActualGanttList";
import { CategoryList } from "./categories/CategoryList";
import { UserList } from "./users/UserList";
import { OrderCollectionStagesList } from "./orderCollectionStages/OrderCollectionStagesList";
import Systems from './Systems/Systems'
import DeveloperTools from "./developerTools/DeveloverPage";

polyfill();

function AdminPanel() {
  return (
    
      <Switch>
        <Route exact path={"/admin/factories"} component={FactoriesList} />
        <Route
          exact
          path={"/admin/users"}
          component={UserList}
        />
        <Route exact path={"/admin/department"} component={DepartmentList} />
        <Route
          exact
          path={"/admin/sub_department"}
          component={SubDepartmentList}
        />
        {/* <Route exact path={"/admin/resourses"} component={ResourceList} /> */}
        <Route exact path={"/admin/recipients"} component={RecipientList} />
        <Route exact path={"/admin/order_types"} component={OrderTypeList} />
        <Route
          exact
          path={"/admin/gantt_template"}
          component={GanttTemplateList}
        />
         <Route
          exact
          path={"/admin/orders"}
          component={OrderList}
        />
         <Route
          exact
          path={"/admin/gantt_actual"}
          component={ActualGanttList}
        />
         <Route
          exact
          path={"/admin/order_categories"}
          component={CategoryList}
        />   
          <Route
          exact
          path={"/admin/order_collection_stages"}
          component={OrderCollectionStagesList}
        /> 
         <Route
          exact
          path={"/admin/systems"}
          component={Systems}
        />
         <Route
          exact
          path={"/admin/developers"}
          component={DeveloperTools}
        />      
         {/* <Route
          exact
          path={"/admin/general_section"}
          component={GeneralSection}
        />                  */}
        
        <Redirect from="/" to={"/admin/factories"} />
      </Switch>
  );
}

export default AdminPanel;
