import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import "./ProcessRow.scss";
import CircularWithValueLabel from '../../Customer/Progress/CircularProgress/CircularProgress';
import Button from '../../Customer/Buttons/Button';
import Checked_img_icon from '../../Customer/assets/checked_icon.svg';
import { PROCESSES } from '../../../constants/translations/daily-page';
import { getOrderSystemsList, reportDone } from '../../../functions/api/orders';


const statusDone = {
    name: PROCESSES.DONE,
    width: '100%',
    height: '42px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '30px',
    icon: false,
    iconContainer: false,
    border: false,
    buttonName: 'Done'
}
const statusDoneMobile = {
    ...statusDone,
    width: '80px',
    height: '36px',
    fontSize: '12px',
    borderRadius: '20px',
}
const processesData = {
    columns: [
        {
            field: 'processName',
            headerName: PROCESSES.PROCESS_NAME,
        },
        {
            field: 'customerName',
            headerName: PROCESSES.CUSTOMER_NAME,
        },
        {
            field: 'orderNumber',
            headerName: PROCESSES.ORDER_NUMBER,
        },
        {
            field: 'startTime',
            headerName: PROCESSES.START_TIME,
        },
        {
            field: 'endTime',
            headerName: PROCESSES.END_TIME,
        },
        {
            field: 'quantity',
            headerName: PROCESSES.QUANTITY,
        },
        {
            field: 'duration',
            headerName: PROCESSES.DURATION,
        },
        {
            field: 'status',
            headerName: PROCESSES.STATUS,
        },
    ],
    rows: [
        {
            processName: 'Glazing - Glazing',
            customerName: 'Mintos Tami',
            orderNumber: 'PR20002420',
            startTime: '00 30:10',
            endTime: '00 30:10',
            quantity: '13/0',
            duration: '11.25',
            status: 'check',
            finished: 13,
        },
        {
            processName: 'Glazing - Glazing',
            customerName: 'Mintos Tami',
            orderNumber: 'PR20002420',
            startTime: '00 30:10',
            endTime: '00 30:10',
            quantity: '13/0',
            duration: '11.25',
            status: 'done',
            finished: 2,
        },
        {
            processName: 'Glazing - Glazing',
            customerName: 'Mintos Tami',
            orderNumber: 'PR20002420',
            startTime: '00 30:10',
            endTime: '00 30:10',
            quantity: '13/0',
            duration: '11.25',
            status: 'progress',
            finished: 13,
        },
        {
            processName: 'Glazing - Glazing',
            customerName: 'Mintos Tami',
            orderNumber: 'PR20002420',
            startTime: '00 30:10',
            endTime: '00 30:10',
            quantity: '13/0',
            duration: '11.25',
            status: 'progress',
            finished: 1,
        },

    ]
}

let progressInPercentage;
function calculateProgress(finished, quantity) {
    let newQuantity = +(quantity.split('/')[0])
    progressInPercentage = (finished / newQuantity) * 100;
    // progressInPercentage = 50
}


export default function ProcessRow(props) {
    const { data, handleClick, getOrderIdFromProcess, alertPopupToggle, setAlertPopupMessage } = props;
    const [process, setProcess] = useState([]);

    const popUpHandler = (orderId) => {
        getOrderIdFromProcess(orderId)
        handleClick();
    }



    const handleProcessDone = async (e, id, action) => {
        e.stopPropagation();

        if (action === 'done'){
            let report_result = await reportDone({ _id: id });
            if (report_result.ok) {
                if (typeof report_result.result === "string") {
                    setAlertPopupMessage(report_result.result);
                    alertPopupToggle(true);
                } else {
                    setProcess(process.map((_process) => _process._id === id ? ({ ..._process, done: true }) : (_process)));
                }
            }
        }else{
            setProcess(process.map((_process) => _process._id === id ? ({ ..._process, done: false }) : (_process)));
        }
       
    }

    const  getStatusOfOrderProcessInDailyView = (row, view='desktop') => {
        //  check checklist
        if (row?.hasChecklist) {
            return <span className={view !== 'mobile' ? 'processRow__progressBar-alignment' : ''}><CircularWithValueLabel data={progressInPercentage} /> </span>;
        } else {
        //  check done or not done
            if (row.done) {
                return <span 
                className={view !== 'mobile' ? 'processRow__check-alignment' : ''}
                onClick={(e) => handleProcessDone(e, row._id, 'undone')}
                ><img src={Checked_img_icon} alt='icon' /></span>
            }
            return <span
                className={view !== 'mobile' ? 'processRow__btn-alignment' : ''}
                onClick={(e) => handleProcessDone(e, row._id, 'done')}
            >            
                <Button data={view === 'mobile' ? statusDoneMobile : statusDone} />
            </span>
        }
    }

    useEffect(() => {
        setProcess(data)
    }, [data])

    return (
        <>
            {
                process.map((row, rowIdx) => {
                    calculateProgress(row.finished, row.quantity);
                    return <Grid
                        onClick={() => popUpHandler(row.orderId)}
                        xs={12}
                        className={`${!row.done && 'backgroundColor-F9F9F9 borderColor-CACBCC'} processRow__main`}
                        key={row._id}
                        item
                        >
                        <Grid className='processRow__col'>
                            {
                                processesData.columns.map((col, colIdx) => {
                                    if (col.field === 'processName') {
                                        return <Grid className='processRow__col-3' key={col.field}>
                                            <Typography className='processRow__col--heading'>{col.headerName}</Typography>
                                            <Typography className='processRow__col--value color-455768'>{row[col.field]}</Typography>
                                        </Grid>
                                    } else if (col.field === 'status') {
                                        return <Grid className='processRow__col-0' key={col.field}>
                                            <Typography className='processRow__col--heading processRow__status--mobile process__heading--mobile'>{col.headerName}</Typography>
                                            <div className='processRow__col--value processRow__status--mobile'>
                                                {
                                                    getStatusOfOrderProcessInDailyView(row, 'mobile')
                                                }

                                            </div>
                                            <div className='processRow__col--value processRow__status'>
                                                {
                                                    getStatusOfOrderProcessInDailyView(row, 'desktop')
                                                }
                                            </div>


                                        </Grid>
                                    } else if (col.field === 'orderNumber' || col.field === 'customerName') {
                                        return <Grid className='processRow__col-2' key={col.field}>
                                            <Typography className='processRow__col--heading'>{col.headerName}</Typography>
                                            <Typography className='processRow__col--value color-0091FF'>{row[col.field]}</Typography>
                                        </Grid>
                                    }
                                    return <Grid className='processRow__col-1' key={col.field}>
                                        <Typography className='processRow__col--heading'>{col.headerName}</Typography>
                                        <Typography className='processRow__col--value color-455768'>{row[col.field]}</Typography>
                                    </Grid>
                                })
                            }

                        </Grid>
                    </Grid>
                })
            }


        </>
    )
}


