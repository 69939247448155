import axios from "axios"
import { API } from "../../tools/keys/keys"
import Cookies from 'js-cookie'
import moment from "moment"
import { CACHED_EDIT_GENERAL_SECTION } from '../../constants/offline-mode'

export const getCookie = (name) => Cookies.get(name)



export const generalGetRequest = async (urlParam, caller = {name:'',_id:''}) => {
    const token_from_cookie = getCookie("login_cookie")
    const headers = {
      "access-token": token_from_cookie
    }

    const url = `${API + urlParam}`

    try {
        const res = await axios({
          method: "get",
          url,
          headers
        })
        if(caller.name === 'GENERAL_SECTION'){
          return detailChecker(caller._id, res.data)
        }
        return res.data
    } catch (err) {
        console.log("Error has been occured in GET request", err)
        if(caller.name === 'GENERAL_SECTION'){
          return detailChecker(caller._id)
        }
        if (err) return undefined
    }
}

const detailChecker = (actual_system_id , data) =>{
  const storage_data = new Map(JSON.parse(localStorage.getItem(CACHED_EDIT_GENERAL_SECTION)))
  if(storage_data.has(actual_system_id)) return {ok: true, result: storage_data.get(actual_system_id)}
  return data
}

export const generalPostRequest = async (body, urlParam) => {
    const token_from_cookie = getCookie("login_cookie")
    const headers = {
      "access-token": token_from_cookie
    }

    const url = `${API + urlParam}`

    try {
        const res = await axios({
          method: "post",
          url,
          headers,
          data: body
        })
        return res.data
    } catch (err) {
        console.log("Error has been occured in POST request", err)
        if (err) return undefined
    }
}

