import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>({
    buttonsWrapper: { 
        width: '90%', 
        margin: '0 auto', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center' 
    }
}))