import { FormControl, Grid, GridList, InputLabel, MenuItem, NativeSelect, Select, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import "./ReviewPopUp.scss";
import Button from '../../Buttons/Button';
import Input from '../../InputFields/Input';
import SelectInput from '../../InputFields/SelectInput/SelectInput';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { CUSTOMERS_PAGE, CUSTOMER_INFO_TABLE } from '../../../../constants/translations/customersPage';
import { CANCEL, SAVE } from '../../../../constants/translations/review-popup';
import { useResources } from '../../../../hooks/useResources';
import { METALPRESS_TECHNICIANS } from '../../../../constants/sub-departments';
import MultiSelectInput from '../../InputFields/MultiSelectInput/MultiSelectInput';
import { israelCities } from '../../../Header/Parts/ReservationPopup/parts/cities';
import { CUSTOM_FIELDS_CONTACT_NAME, CUSTOM_FIELDS_PHONE } from '../../../../constants/custom-fields';
import { SERVICE_CALL_TYPE_ALL_SYSTEMS, SERVICE_CALL_TYPE_HALF_YEAR } from '../../../../constants/customers-page';
import { checkOrderNumberFree, createServiceCall } from '../../../../functions/api/customer-page';
import { createNewOrderFromCustomersPageSuccess } from '../../../../actions/customers_table_actions';
import { useDispatch } from 'react-redux';


const reviewFormData = {
    orderNumber: {
        label: POP_UPS_TRANSLATION.ORDER_NUMBER,//'Order Number',
        type: 'number',
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        name: 'orderNumber'
    },
     nameOfTechnician: {
        label: POP_UPS_TRANSLATION.NUMBER_OF_TECHNICIAN, // Number of Technician
        type: 'select',
        placeholder: POP_UPS_TRANSLATION.CHOOSE, //'Choose',
        value: 'Choose',
        name: 'nameOfTechnician'
    },
    customerName: {
        label: POP_UPS_TRANSLATION.CUSTOMER_NAME,//'Customer name',
        type: 'text',
        placeholder: POP_UPS_TRANSLATION.DROR_HALPERIN, //'Dror Halperin',
        value: 'Dror Halperin',
        name: 'customerName'
    },
    coordinationDate: {
        label: POP_UPS_TRANSLATION.COORDINATION_DATE,//'Coordination date',
        type: 'date',
        placeholder: '08/03/2021',
        value: '08/03/2021',
        name: 'dueDate'
    },
    settlement: {
        label: POP_UPS_TRANSLATION.SETTLEMENT,//'Settlement',
        type: 'select',
        placeholder: POP_UPS_TRANSLATION.INFO, //'Info',
        value: 'Info',
        name: 'city',
    },
    address: {
        label: CUSTOMERS_PAGE.ADDRESS ,//'Address',
        type: 'text',
        placeholder: POP_UPS_TRANSLATION.STREET, // 'Street',
        value: 'Street',
        name: 'address'
    },
    contactName: {
        label: CUSTOMER_INFO_TABLE.CONTACT_NAME, //'Contact name',
        type: 'text',
        placeholder: POP_UPS_TRANSLATION.DROR_HALPERIN, //'Dror Halperin',
        value: 'Dror Halperin',
        name: 'contactName'
    },
    contactPhoneNumber: {
        label: POP_UPS_TRANSLATION.CONTACT_PHONE_NUMBER, //'Contact phone number',
        type: 'number',
        placeholder: '050 000 00 00',
        value: '050 000 00 00',
        name: 'contactPhoneNumber'
    },

}
const changePassword = {
    name: POP_UPS_TRANSLATION.CHANGE_PASSWORD, //'Change Password',
    width: '35%',
    height: '52px',
    color: '#fff',
    backgroundColor: '#186315',
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    buttonName: 'AddIcon'
}
const save = {
    name: SAVE, //'Save',
    width: '28%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    buttonName: 'AddIcon'
}
const cancel = {
    name: CANCEL, // 'Cancel',
    width: '28%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    buttonName: 'AddIcon'

}

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function ReviewPopUp(props) {
    const { showPopUp, handleShowPopUp, view, popUpName, customer, isSpecialCall, setIsOrderCreatedMessage, closeServiceCallPopUpHandler, setDefectionMessage } =props;
    const { address: customerAddress, city: customerCity, contact_name, email, name, phone, _id, systems, terms_of_engagement } = customer


    const [inputValues, setInputValues] = useState({ 
        orderNumber: null, 
        orderNumberError: false,
        nameOfTechnicians: [], 
        nameOfTechniciansError: false,
        customerName: name, 
        customerNameError: false,
        dueDate: null,
        city: customerCity,
        address: customerAddress,
        contactName: contact_name,
        contactPhoneNumber: phone
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isOrderNumberFree, setIsOrderNumberFree] = useState(true);

   
    const dispatch = useDispatch()

    const resources = useResources(METALPRESS_TECHNICIANS)


    const handleChange = (name, value) => {
        // validation(name, value)
        if(name === 'orderNumber'){
            checkOrderNumberFree(value).then(res => setIsOrderNumberFree(!res));
            setInputValues((prev) => ({
                ...prev,
                orderNumber : value,
            }))
        }else{
            setInputValues((prev) => ({
                ...prev,
                [name]: value,
            }))
        }
       
    }


    const cleanInputs = () => {
       
        setInputValues({
            orderNumber: null,
            orderNumberError: false,
            nameOfTechnicians: [],
            nameOfTechniciansError: false,
            customerName: name,
            customerNameError: false,
            dueDate: null,
            city: customerCity,
            address: customerAddress,
            contactName: contact_name,
            contactPhoneNumber: phone
        })
    }

    const handleSubmit = async() => {
        console.log("this is submit call", inputValues)
        const { contactName, contactPhone, address, customerName, dueDate, nameOfTechnicians, orderNumber, city } =  inputValues;
        const body = {
            type: isSpecialCall ? SERVICE_CALL_TYPE_HALF_YEAR : SERVICE_CALL_TYPE_ALL_SYSTEMS,
            [CUSTOM_FIELDS_CONTACT_NAME]: contactName,
            [CUSTOM_FIELDS_PHONE]: contactPhone,
            address,
            city : typeof(city) === 'object' ? city.name : city,
            client_name: customerName,
            customer_id: _id,
            due_date: dueDate,
            employees: [],
            order_number: orderNumber,
            // systems: isSpecialCall ? []: systems,
            technician_resource: nameOfTechnicians,
            agreement_terms: terms_of_engagement
        }


        if (!isOrderNumberFree) return;

        setIsLoading(true)
        // set_Loading(prev => !prev)
        let { ok, result } = await createServiceCall(body)
        // set_Loading(prev => !prev)

        if (result && ok) {
            cleanInputs()
            dispatch(createNewOrderFromCustomersPageSuccess(result))
            setIsOrderCreatedMessage(true)
            // closeServiceCallPopUpHandler();
            setDefectionMessage('');
            handleShowPopUp();

        }
        if (!ok && typeof result === 'string') {
            setDefectionMessage(result);
            setIsOrderCreatedMessage(true)
            handleShowPopUp();
            // closeServiceCallPopUpHandler();
        }
    }
 

    const classes = useStyles();
    return (
        <Grid container spacing={0} className='reviewPopUp__main'>
            <Grid xs={12}> 
                <h4 className='reviewPopUp__name'>{ popUpName || POP_UPS_TRANSLATION.REVIEW}</h4>
            </Grid>
            <Grid xs={12} style={{display: 'flex', flexDirection: 'row'}}>
                <Grid xs={6} style={{marginLeft: '10px'}}>
                   <Input
                        view={view}
                        data={{
                            ...reviewFormData.orderNumber,
                            value: inputValues.orderNumber,
                            onChange: handleChange
                        }}
                   />
                </Grid>
                <Grid xs={6} style={{ marginRight: '10px' }}>
                    <MultiSelectInput
                        data={{
                            ...reviewFormData.nameOfTechnician,
                            selectOptions: resources.length ? resources.map((_resource) => ({ ..._resource, resource_id: _resource._id })) : [],
                            value: inputValues.nameOfTechnicians,
                            handleChange: handleChange
                        }}
                        view={view}
                    />
                 </Grid>
            </Grid>
            <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                <Grid xs={6} style={{ marginLeft: '10px' }}>
                    <Input
                        data={{
                            ...reviewFormData.customerName,
                            value: inputValues.customerName,
                            onChange: handleChange
                        }}
                        view={view}
                    />
                </Grid>
                <Grid xs={6} style={{ marginRight: '10px' }}> 
                    <Input
                        data={{
                            ...reviewFormData.coordinationDate,
                            value: inputValues.dueDate,
                            onChange: handleChange
                        }}
                        view={view}
                    />
                </Grid>
            </Grid>
            <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                <Grid xs={6} style={{ marginLeft: '10px' }}>
                    <SelectInput
                        data={{
                            ...reviewFormData.settlement,
                            value: inputValues.city,
                            selectOptions: israelCities?.map((_city, index)=>({name:_city, _id: _city + '-' + index})),
                            handleChange: handleChange
                        }}
                        view={view}
                    />
                </Grid>
                <Grid xs={6} style={{ marginRight: '10px' }}>
                    <Input
                        data={{
                            ...reviewFormData.address,
                            value: inputValues.address,
                            onChange: handleChange
                        }}
                        view={view}
                    /> 
                </Grid>
            </Grid>
            <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                <Grid xs={6} style={{ marginLeft: '10px' }}>
                    <Input
                        data={{
                            ...reviewFormData.contactName,
                            value: inputValues.contactName,
                            onChange: handleChange
                        }}
                        view={view}
                    />
                </Grid>
                <Grid xs={6} style={{ marginRight: '10px' }}> 
                    <Input
                        data={{
                            ...reviewFormData.contactPhoneNumber,
                            value: inputValues.contactPhoneNumber,
                            onChange: handleChange
                        }}
                        view={view}
                    />
                </Grid>
            </Grid>
           
            <Grid sx={12} className='reviewPopUp__btn__contianer'>
                <Button data={save} handleClick={() => handleSubmit()} isLoading={isLoading} />
                <Button data={cancel} handleClick={() => handleShowPopUp()} />
                <Button data={changePassword}/>
            </Grid>
        </Grid>
    )
}
