import { GET_ORDERS_LIST } from "../../constants/customer/orderConstants";
import { desktopOrdersList } from '../../constants/staticeData/ordersList';

const initialState = {
    orders: desktopOrdersList,
}

export default function orderReducer(state = initialState, action){
    switch (action.type) {
        case GET_ORDERS_LIST:
            
            return{...state, orders: state.orders};
            break;
        default:
            return state;
    }
}