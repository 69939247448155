import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from '@material-ui/core/Checkbox';
import {useStyles} from '../style'


const CustomizedCheckBox = (props) => {
    const { value, changeHandler, disabled } = props
    const classes = useStyles()
    return <TableCell className={classes.selectTableCell}>
      <Checkbox
        className = {classes.checkBox}
        checked={value}
        onChange={e => changeHandler(e.target.checked)}
        disabled= {disabled}
      />
    </TableCell>
  }

export default CustomizedCheckBox