import React, { useState, useEffect, useMemo, useRef } from "react";
import { useStyles, jss, theme } from "../styles/CustomersTable.styles";
import { StylesProvider, ThemeProvider} from "@material-ui/styles";
import InfoSection from "./Parts/InfoSection";
import Line from "../reused_components/Line";
import AddLocationButton from "../Quote/Parts/AddLocationButton";
import QuoteNavigation from "./Parts/HeaderNavigation";
import TotalAmount from "./Parts/TotalAmount";
import QuoteButtons from "./Parts/ButtonsSection";
import LocationAndAddProductButton from "./Parts/LocationAndAddProductSection";
import { useSelector, useDispatch } from "react-redux";
import { createQuote } from "../../../functions/api/customer-page";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useQuote } from "../../../hooks/useQuote";
import QuotePDF from "../QuotePDFTemplate/QuotePdf";
import {
  setPrintActive,
  setQuoteInfoData,
} from "../../../actions/quotation-actions";
import {
  QUOTE,
  QUOTE_STATUS_OPTIONS
} from "../../../constants/translations/customersPage";
import RemarksField from './Parts/Remarks'
import html2PDF from 'jspdf-html2canvas';
import Loader from "../../LoaderNew/Loader";
import Loader1 from '../../PopupLoader/PopupLoader';
import FiltersAndAdditionalActionsWrapper from '../../reused-components/filtersAndAdditionalActionsWrapper'
import Stripe from '../../reused-components/Stripe'
import RedirectBackToCustomersButton from '../reused_components/redirectBackToCustomersButton'
import { currentAndPrevUrlSnapShot } from "../../../hooks/helper";
import { useStyles as QuoteStyle } from './styles'
import { TestPDF } from "./TestPDF";
import BackNavigation from "../../reused-components/BackNavigation";
import { CUSTOMER_PAGE, CUSTOMER_PAGES } from "../../../constants/customers-page";
import DeleteQuoteCnfPopup from "./Parts/DeleteQuoteCnfPopup";
import { deleteQuote } from "../../../functions/api/orders";

const CreateQuote = (props) => {
  const goBackRef = useRef(null);
  const preventPathRef = useRef({
    path: null,
    canBack: false
  });
  const { PEROFFER } = QUOTE
  const NOT_VALID_FIELDS = 'SOME OF FIELDS ARE EMPTY'
  const dispatch = useDispatch();
  const history = useHistory();
  const products = useSelector((state) => state.quotationManagement.products);
  const totalAmount = useSelector(
    (state) => state.quotationManagement.totalAmount
  );
  const quantityOfProducts = useSelector(
    (state) => state.quotationManagement.quantityOfProducts
  );
  const printActive = useSelector(
    (state) => state.quotationManagement.printActive
  );
  const factoryName = useSelector((state) => state.login.user.factory_name);
  const { customerId, quoteId } = useParams();
  const classes = useStyles();
  const [readyForPrint, setReadyForPrint] = useState(false);
  const { SENT_TO_CLIENT, MAKE_AN_ORDER, CLOSE } = QUOTE_STATUS_OPTIONS;
  const [notValidInfoSection, setNotValidInfoSection] = useState(false)
  const [loader , setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      preventPathRef.current = {
        path: null,
        canBack: false
      }
      goBackRef.current = null;
    }
  }, [])
  
  useEffect(() => {
    const printDocument = async () => {
      let pdf_elements = document.getElementsByClassName('pdf-pages-split-quote');
      

      try {
        await html2PDF([...pdf_elements],
          {
            jsPDF: {
              format: 'a4',
              orientation: 'portrait'
            },
            html2canvas: {
              scale: 5,
              useCORS: true
            },
            imageType: 'image/jpeg',
            output: 'quote.pdf',
            pagebreak: { 
              mode: ['avoid-all', 'css', 'legacy'] 
            },
            margin: {
              top: 30,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        );
      } catch (error) {
        console.error(error)
      }
    }

    const showPdf = () => {
      let pdf_elements = document.getElementsByClassName('pdf-pages-split-quote');
      [...pdf_elements].map((res) => {
        document.getElementById('test-pdf').innerHTML +=  res.innerHTML
      })
    }

    const printDocumentAndRedirectToCustomerPage = async () => {
      await printDocument();
      // showPdf()
      setReadyForPrint(false);
      dispatch(setPrintActive(false));
    };

    if (readyForPrint) {
      printDocumentAndRedirectToCustomerPage();
    }
  }, [readyForPrint]);

  const {
    locations,
    setLocations,
    quoteNumber,
    setQuoteNumber,
    clientName,
    setClientName,
    address,
    setAddress,
    phoneNumber,
    setPhoneNumber,
    quoteStatus,
    setQuoteStatus,
    quoteDate,
    setQuoteDate,
    readOnly,
    remarks,
    setRemarks,
    projectType,
    setProjectType,
    productDiscount,
    setProductDiscount,
    extraCharges,
    setExtraCharges
  } = useQuote(quoteId);

  const isDataChanged = useQuote(quoteId)

  useEffect(() => {
    dispatch(
      setQuoteInfoData({
        _id: quoteId,
        order_number: quoteNumber,
        due_date: quoteDate,
        status: quoteStatus,
        remarks,
        project_type: projectType,
      })
    );
  }, [quoteNumber, quoteDate, quoteStatus, remarks, projectType]);

  const addLocationHandler = () => {
    const newItem = {
      location: "",
      products: [],
    };
    const newLocations = [...locations, newItem];
    setLocations(newLocations);
  };

  const changeLocationValueHandler = (index, value) => {
    const newLocations = [...locations];
    newLocations[index]["location"] = value;
    setLocations(newLocations);
  };

  const submitForm = async () => {
    setLoader(true);
    const body = {
      quote_info: {
        _id: quoteId,
        order_number: quoteNumber,
        client_name: clientName,
        due_date: quoteDate,
        started: quoteStatus === MAKE_AN_ORDER,
        cancelled: quoteStatus === CLOSE,
        address: address,
        status: quoteStatus,
        quantity: quantityOfProducts,
        value: totalAmount,
        customer_id: customerId,
        client_phone: phoneNumber,
        remarks,
        project_type: projectType,
        order_category_id: "5f6e0ee658bf6041b4953c99",
        sub_category_id: "5e3a77fd5ee4ad2e1dff7bf0",
        type: 0,
        order_discount: productDiscount,
        extra_charges: extraCharges
      },
      products,
    };
    if(quoteNumber && quoteDate && quoteStatus && projectType){
      preventPathRef.current.canBack = true;
      setNotValidInfoSection(false)
      const result = await createQuote(body);
      setLoader(false);
    }else{
      setNotValidInfoSection(true)
      setLoader(false);
      return NOT_VALID_FIELDS
    }
  };

  let preparePdfData = () => {
    let newLocation = JSON.parse(JSON.stringify(locations));
    let tempLocation = [];
    newLocation.map(item => {
      tempLocation.push({productLocation: item.location});
      tempLocation = [...tempLocation,...item.products]
    });
    let indexNumber = 0;
    tempLocationLoop: for(let i=0; i < tempLocation.length; i++){
      if(Object.keys(tempLocation[i]).length !== 1){
        indexNumber++;
      }if(indexNumber >= 4){
        indexNumber = i;
        break tempLocationLoop;
      }
    }
    let remainingPage = indexNumber <= 3 ? [] : tempLocation.splice(indexNumber);
    return {
      firstPage: tempLocation,
      remainingPage: preparedSecondPageData({input: remainingPage, pageCollection: []}),
    }
  }

  let preparedSecondPageData = (objData) => {
    if(objData.input.length <= 4){ objData.input.length > 0 && objData.pageCollection.push(objData.input);return objData.pageCollection}
    let indexNumber = 0;
    for(let i=0; i < objData.input.length; i++){
      if(!objData.input[i]?.productLocation){
        indexNumber++;
      }if(indexNumber >= 4){
        indexNumber = i;
        break;
      }
    }
    let temp_input = objData.input.splice(indexNumber+1);
    objData.input.length > 0 && objData.pageCollection.push(objData.input)
    preparedSecondPageData({input:temp_input, pageCollection: objData.pageCollection});
    return objData.pageCollection;
  }

  const clickBackHandler = (funRef) => {
    const [ { location = '', products = [] } = {} ] = locations;
    if(products.length === 0){
      goBackRef.current = funRef;
      setIsOpen(true);
    }else {
      funRef && typeof funRef === 'function' && funRef(history)
    }
  }

  const callGoBack = () => {
    if(preventPathRef.current.path){
      let path = preventPathRef.current.path;
      preventPathRef.current.canBack = true;
      history.push(path); return;
    }
    let fun = goBackRef.current;
    preventPathRef.current.canBack = true;
    fun && typeof fun === 'function' && fun(history);
    goBackRef.current = null;
  }

  const deleteEmptyQuote = (order_id) => {
    deleteQuote(order_id).then(response => {}).catch(err => console.error(err))
  }

  return <React.Fragment>
  <Prompt
    when={(locations.length === 0 || locations[0].products.length === 0)}
    message={(location, action) => {
      preventPathRef.current.path = location.pathname;
      clickBackHandler(null);
      return !!location.pathname.includes('customers-page/create-product') || !!locations[0]?.products?.length || !!preventPathRef.current.canBack;
    }}
  />
  {printActive ? (
    <QuotePDF
      products={locations}
      pdfProducts={preparePdfData()}
      quoteNumber={quoteNumber}
      clientName={clientName}
      address={address}
      phoneNumber={phoneNumber}
      quoteStatus={quoteStatus}
      quoteDate={quoteDate}
      setReadyForPrint={setReadyForPrint}
      remarks={remarks}
      projectType={projectType}
      productDiscount={productDiscount}
      extraCharges={extraCharges}
      />
  ) : null }
  {printActive ? <Loader /> : null}
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <Stripe>
          <BackNavigation to={`${CUSTOMER_PAGES}/${customerId}`} onClickCB={clickBackHandler}/>
        </Stripe>
        <div
          className={`${classes.customersPageWrapper} ${classes.overflowAuto} ${classes.formHeight}`}
          >
          {/* <QuoteNavigation quoteId={quoteId} /> */}
          <InfoSection
            quoteNumber={quoteNumber}
            setQuoteNumber={setQuoteNumber}
            quoteStatus={quoteStatus}
            setQuoteStatus={setQuoteStatus}
            quoteDate={quoteDate}
            setQuoteDate={setQuoteDate}
            projectType={projectType}
            setProjectType={setProjectType}
            isNotValid = {notValidInfoSection}
            />
          <Line />
          
          {locations.map((i, idx) => {
            return (
              <LocationAndAddProductButton
              key={idx}
              index={idx}
              data={i}
              changeLocationValueHandler={changeLocationValueHandler}
              locations={locations}
              status={quoteStatus}
              readOnly={readOnly}
              />
              );
            })}
          <AddLocationButton
            addLocationHandler={addLocationHandler}
            readOnly={readOnly}
            />
          <TotalAmount 
            locations={locations} 
            setProductDiscount={setProductDiscount} 
            productDiscount={productDiscount}
            setExtraCharges={setExtraCharges}
            extraCharges={extraCharges}
          />
          <RemarksField
            value={remarks}
            changeHandler={setRemarks}
            disabled={quoteStatus === SENT_TO_CLIENT}
            />
          <QuoteButtons submitForm={submitForm} locations={locations} NOT_VALID_FIELDS = {NOT_VALID_FIELDS}/>
          {/* <TestPDF /> */}
        </div>
      </ThemeProvider>
      {
        isOpen && <DeleteQuoteCnfPopup
          okCallback={() => {
            setIsOpen(prev => false)
            if(!quoteNumber || !quoteDate || !quoteStatus || !projectType){
              setNotValidInfoSection(true)
            }else{
              submitForm();
              callGoBack();
            }
          }}
          cancelCallback={() => {
            // deleteEmptyQuote(quoteId)
            setIsOpen(prev => false)
            callGoBack();
          }}
          isOpen={isOpen}
        />
      }
    </StylesProvider>
</React.Fragment>
};

export default CreateQuote;
