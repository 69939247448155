import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    tableHeaderCellStyle: {
        textAlign: 'right',
        fontSize:'18px'
    },
    buttonsGroup: {
      width:'100%', 
      backgroundColor:'white', 
      display:'flex',
      justifyContent:'center', 
      position:'sticky', 
      bottom:0
    },
    
}))