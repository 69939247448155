import { TabContext } from "@material-ui/lab";
import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  listResource: [],
  resourceDetails: null,
  error: null,
  countResource:0,
  currentPage: 0,
  rowsPerPage: 10,
  resourcesList:[],
  userList:[]
};

export default function resourcesReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESOURCES_CHANGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }; 
    case types.RESOURCES_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,        
        rowsPerPage: action.payload
      };

    case types.ADMIN_GET_FILTER_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true,
      }; 

    case types.ADMIN_GET_FILTER_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listResource: action.payload.result,
        countResource:action.payload.count
      };
      
    case types.ADMIN_GET_FILTER_RESOURCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case types.GET_ALL_RESOURCES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ALL_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        listResource: action.payload,
      };
    case types.GET_ALL_RESOURCES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case types.GET_BY_ID_RESOURCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_BY_ID_RESOURCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        resourceDetails: action.payload,
      };
    case types.GET_BY_ID_RESOURCE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.SET_RESOURCE:
      return {
        ...state,
        resourceDetails: action.payload,
      };

    case types.RESOURCES_GET_BY_FACTORY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.RESOURCES_GET_BY_FACTORY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        listResource: action.payload,
      };
    case types.RESOURCES_GET_BY_FACTORY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
      case types.SET_RESOURCES_LIST:
        return {
          ...state,
          resourcesList: action.payload
        }
      case types.SET_USER_LIST:
      return {
        ...state,
        userList: action.payload
      }

    default:
      return state;
  }
}
