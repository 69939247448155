import React, {useState, useEffect} from 'react'
import {generalGetRequest} from '../functions/api/general'
import {SHUTTER_OPEN_OPTIONS, HANDLE_OPTIONS, HANDLE_TYPE_OPTIONS, BOX_OPTIONS, SHUTTER_OPTIONS, GLASS_OPTIONS, ENGINE_OPTIONS, HUE_OPTIONS, NET_OPTIONS, COLOR_OPTIONS } from '../constants/product-parts'
import { SET_ORDER_COLLECTION_STAGES } from '../actions/types'
// '/product/get-additional-options'
export const useProductComponents = (productId, color, handle, shutter) => {
    const emptyInputs = {
        [SHUTTER_OPEN_OPTIONS]:[], 
        [HANDLE_OPTIONS]:[], 
        [HANDLE_TYPE_OPTIONS]:[], 
        [BOX_OPTIONS]:[],
        [SHUTTER_OPTIONS]:[], 
        [GLASS_OPTIONS]:[], 
        [ENGINE_OPTIONS]:[], 
        [HUE_OPTIONS]:[], 
        [NET_OPTIONS]:[], 
        [COLOR_OPTIONS]:[] 
    }
    const [inputs, setInputs] = useState(emptyInputs)
    const [isColorUploaded, setColorUploaded] = useState(false)
    const [isHandleUploaded, setHandleUploaded] = useState(false)
    const [isShutterUploaded, setShutterUploaded] = useState(false)

    useEffect(()=>{
        if(inputs[HUE_OPTIONS].length && !isColorUploaded) setColorUploaded(true)
        if(inputs[HANDLE_TYPE_OPTIONS].length && !isHandleUploaded) setHandleUploaded(true)
        if(inputs[SHUTTER_OPEN_OPTIONS].length && !isShutterUploaded) setShutterUploaded(true)


    },[inputs])


    const setInputsHandler = (data) =>{
        setInputs(i=>({...i, ...data}))
    }
   
    const getConstantOptions = async() => {
        const {result} = await generalGetRequest(`/system/product/get-general-options`)
        if (result){ 
            setInputsHandler(result)
        }       
    }

    const getOptionsAccordingToSelected = async(id) => {
        const {result} = await generalGetRequest(`/system//product/get-additional-options?id=${id}`)
        if(result){
            setInputsHandler(result)

        }
    }
    useEffect(()=>{
        getConstantOptions()
    },[])

    useEffect(()=>{
        if(color){
            getOptionsAccordingToSelected(color)
            // setColorUploaded(true)
        }else{
            setInputsHandler({[HUE_OPTIONS]: []}) 
        }
    },[color])

    useEffect(()=>{
        if(handle){
            getOptionsAccordingToSelected(handle)
            // setHandleUploaded(true)
        }else{
            setInputsHandler({[HANDLE_TYPE_OPTIONS]: []}) 
        }
    },[handle])

    useEffect(()=>{
        if(shutter){
            getOptionsAccordingToSelected(shutter)
            // setShutterUploaded(true)
        }else{
            setInputsHandler({[SHUTTER_OPEN_OPTIONS]: [], [BOX_OPTIONS]: []}) 
        }
    },[shutter])


    return productId ? [inputs, isColorUploaded,isShutterUploaded,isHandleUploaded ]: [emptyInputs, isColorUploaded,isShutterUploaded,isHandleUploaded ]
    // return emptyInputs
    
}