import { FormControl, Grid, MenuItem, Select, styled, makeStyles, FormHelperText } from '@material-ui/core'
import React, { useState } from 'react'
import "../SelectInput/SelectInput.scss"
import "./SelectInputForTable.scss"


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SelectInputForTable(props) {
    const { label, placeholder, value, type, width, height, border, inputFor, selectOptions, handleChange: onChangeFn, name, error, errorText, selectionOptions } = props.data;

    const classes = useStyles();
    const [select, setSelect] = useState(props.data.value);

    const handleChange = (event) => {
        setSelect(event.target.value);
    };

    return (
        <div className='selectInputContainer__table'>
            <FormControl className={classes.formControl}>
                <Select
                    value={select}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    disableUnderline={true}
                    renderValue={(selected) => {
                        if (!selected) {
                            return placeholder;
                        }
                        if (typeof selected === 'object') {
                            return selected.name ? selected.name : selected.full_name;
                        } else {
                            return selected
                        }
                    }}
                >
                    <MenuItem value="" disabled>
                        {placeholder ? placeholder :  5}
                    </MenuItem>
                    {
                        selectionOptions ?  selectionOptions.map((selectionOption, index) => {
                           return <MenuItem value={selectionOption} key={selectionOption+index}>{selectionOption}</MenuItem>
                        })
                        : <MenuItem value={''}></MenuItem>
                    }
                </Select>
            </FormControl>
        </div>
    )
}
