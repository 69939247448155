import * as types from "./types";
import axios from "axios";
import { api, createHeaders } from "../constants/api-urls";

const getAllOrdersRequest = () => ({
  type: types.GET_ALL_ORDERS_REQUEST,
});

const getAllOrdersSuccess = (value) => ({
  type: types.GET_ALL_ORDERS_SUCCESS,
  payload: value,
});

const getAllOrdersFailure = (error) => ({
  type: types.GET_ALL_ORDERS_ERROR,
  payload: {
    error,
  },
});

export const getAllOrders = () => {
  return (dispatch) => {
    dispatch(getAllOrdersRequest());
    let url = `${api.admin.orders.allOrders}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getAllOrdersSuccess(result));
      })
      .catch((err) => {
        dispatch(getAllOrdersFailure(err.message));
      });
  };
};

const getOngoingOrdersRequest = () => ({
  type: types.GET_ONGOING_ORDERS_REQUEST,
});

const getOngoingOrdersSuccess = (value) => ({
  type: types.GET_ONGOING_ORDERS_SUCCESS,
  payload: value,
});

const getOngoingOrdersFailure = (error) => ({
  type: types.GET_ONGOING_ORDERS_ERROR,
  payload: {
    error,
  },
});

export const getOngoingOrders = (factory_id, limit, skip) => {
  return (dispatch) => {
    dispatch(getOngoingOrdersRequest(factory_id, limit));
    let url = `${api.admin.orders.getOngoingOrders(factory_id, limit, skip)}`;
    const headers = createHeaders();
    axios
        .get(url, { headers })
        .then((res) => {
          const { result } = res.data;
          dispatch(getOngoingOrdersSuccess(result));
        })
        .catch((err) => {
          dispatch(getOngoingOrdersFailure(err.message));
        });
  };
};

const getOrderRequest = () => ({
  type: types.GET_BY_ID_ORDER_REQUEST,
});

const getOrderSuccess = (value) => ({
  type: types.GET_BY_ID_ORDER_SUCCESS,
  payload: value,
});

const getOrderFailure = (error) => ({
  type: types.GET_BY_ID_ORDER_ERROR,
  payload: {
    error,
  },
});

export const getOrder = (id) => {
  return (dispatch) => {
    dispatch(getOrderRequest());
    let url = `${api.admin.orders.getById(id)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getOrderSuccess(result));
      })
      .catch((err) => {
        dispatch(getOrderFailure(err.message));
      });
  };
};

export const setOrderDetais = (value) => {
  return {
    type: types.SET_ORDER,
    payload: value,
  };
};

export const createOrder = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(createOrderRequest());
    let url = `${api.admin.orders.create}`;
    const headers = createHeaders();
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(createOrderSuccess(ok));
        dispatch(getOrders(filterFactory));
      })
      .catch((err) => {
        dispatch(createOrderFailure(err.message));
      });
  };
};

const createOrderRequest = () => ({
  type: types.ORDER_CREATE_REQUEST,
});

const createOrderSuccess = (isCreate) => ({
  type: types.ORDER_CREATE_SUCCESS,
});

const createOrderFailure = (error) => ({
  type: types.ORDER_CREATE_ERROR,
  payload: {
    error,
  },
});

export const updateOrder = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(updateOrderRequest());
    let url = `${api.admin.orders.update}`;
    const headers = createHeaders();
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(updateOrderSuccess(ok));
        dispatch(getOrders(filterFactory));
      })
      .catch((err) => {
        dispatch(updateOrderFailure(err.message));
      });
  };
};

const updateOrderRequest = () => ({
  type: types.ORDER_UPDATE_REQUEST,
});

const updateOrderSuccess = (isUpdate) => ({
  type: types.ORDER_UPDATE_SUCCESS,
});

const updateOrderFailure = (error) => ({
  type: types.ORDER_UPDATE_ERROR,
  payload: {
    error,
  },
});

const getOrdersByFactoryRequest = () => ({
  type: types.ORDER_GET_BY_FACTORY_ID_REQUEST,
});

const getOrdersByFactorySuccess = (value) => ({
  type: types.ORDER_GET_BY_FACTORY_ID_SUCCESS,
  payload: value,
});

const getOrdersByFactoryFailure = (error) => ({
  type: types.ORDER_GET_BY_FACTORY_ID_ERROR,
  payload: {
    error,
  },
});

export const getOrdersByFactory = (factory_id) => {
  return (dispatch) => {
    dispatch(getOrdersByFactoryRequest());
    let url = `${api.admin.orders.getByFactoryId(factory_id)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { result } = res.data;
        dispatch(getOrdersByFactorySuccess(result));
      })
      .catch((err) => {
        dispatch(getOrdersByFactoryFailure(err.message));
      });
  };
};

export const getOrders = (
  idFactoryId = null
) => {
  return (dispatch) => {   
      if (idFactoryId) {
      dispatch(getOrdersByFactory(idFactoryId));
    } else {
      dispatch(getAllOrders());
    }
  };
};
