export const desktopFilesList = {
    popUpData: [
        {
            label: "Open File",
            icon: 'folder'
        },
        {
            label: "Edit File",
            icon: 'edit'
        },
        {
            label: "Delete File",
            icon: 'delete'
        },
    ],
    columns: [
        {
            field: 'fileName',
            headerName: 'File Name',
        },
        // {
        //     field: 'description',
        //     headerName: 'Description',
        // },
        {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
        },
        {
            field: 'uploadDate',
            headerName: 'Upload Date',
        },
        {
            field: 'fileType',
            headerName: 'File Type',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    // rows: [
    //     { id: 1, fileName: 'A Corem ipsum dolor sit amet, consectetur adipiscing, jahsbdfjasbfasl, jhasbks', uploadedBy: 'Jon', uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
    //     { id: 2, fileName: 'B Corem ipsum dolor sit amet, consectetur adipiscing', uploadedBy: 'Cersei', uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
    //     // { id: 3, fileName: 'C Corem ipsum dolor sit amet, consectetur adipiscing',  uploadedBy: 'Jaime', uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
    //     // { id: 4, fileName: 'D Corem ipsum dolor sit amet, consectetur adipiscing', uploadedBy: 'Arya', uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
    //     // { id: 5, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing', description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Daenerys', uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
    //     // { id: 6, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Roger Geidt', uploadDate: '15/06/2023', fileType: 'Not Done' },
    //     // { id: 7, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Ferrara', uploadDate: '15/06/2023', fileType: 'Not Done' },
    //     // { id: 8, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Rossini', uploadDate: '15/06/2023', fileType: 'Not Done' },
    //     // { id: 9, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Harvey', uploadDate: '15/06/2023', fileType: 'Not Done' },
    // ],
    rows: []
}


export const mobileFilesList = {
    popUpData: [
        {
            label: "Open File",
            icon: 'folder'
        },
        {
            label: "Edit File",
            icon: 'edit'
        },
        {
            label: "Delete File",
            icon: 'delete'
        },
    ],
    columns: [
        {
            field: 'fileName',
            headerName: 'File name',
        },
        {
            field: 'description',
            headerName: 'Description',
        },
        {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
        },
        {
            field: 'uploadDate',
            headerName: 'Upload Date',
        },
        {
            field: 'fileType',
            headerName: 'File Type',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    rows: [
        { id: 1, fileName: 'A Corem ipsum dolor sit amet, consectetur adipiscing, jahsbdfjasbfasl, jhasbks', description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Jon', uploadDate: '15/06/2023', fileType: '1 Not Done' },
        { id: 2, fileName: 'B Corem ipsum dolor sit amet, consectetur adipiscing', description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Cersei', uploadDate: '15/06/2023', fileType: '2 Not Done' },
        { id: 3, fileName: 'C Corem ipsum dolor sit amet, consectetur adipiscing', description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Jaime', uploadDate: '15/06/2023', fileType: '5 Not Done' },
        { id: 4, fileName: 'D Corem ipsum dolor sit amet, consectetur adipiscing', description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Arya', uploadDate: '15/06/2023', fileType: '4 Not Done' },
        { id: 5, fileName: 'E Corem ipsum dolor sit amet, consectetur adipiscing', description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Daenerys', uploadDate: '15/06/2023', fileType: 'Not Done' },
        // { id: 6, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Roger Geidt', uploadDate: '15/06/2023', fileType: 'Not Done' },
        // { id: 7, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Ferrara', uploadDate: '15/06/2023', fileType: 'Not Done' },
        // { id: 8, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Rossini', uploadDate: '15/06/2023', fileType: 'Not Done' },
        // { id: 9, fileName: 'Corem ipsum dolor sit amet, consectetur adipiscing',  description: 'Forem ipsum dolor sit amet, sit amet', uploadedBy: 'Harvey', uploadDate: '15/06/2023', fileType: 'Not Done' },
    ],
}

export const tabletFilesList = {
    view: 'tablet',
    popUpData: [
        {
            label: "Open File",
            icon: 'folder'
        },
        {
            label: "Edit File",
            icon: 'edit'
        },
        {
            label: "Delete File",
            icon: 'delete'
        },
    ],
    columns: [
        {
            field: 'fileName',
            headerName: 'File Name',
        },
        {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
        },
        {
            field: 'uploadDate',
            headerName: 'Upload Date',
        },
        {
            field: 'fileType',
            headerName: 'File Type',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    rows: [
        { 
            id: 1, 
            fileName: 'Removes', 
            uploadedBy: {placeholder: 'Jakob Baptista'}, 
            uploadDate: '15/06/2023', 
            fileType: {placeholder: 'Not Done'} },
        { id: 2, fileName: 'Removes', uploadedBy: { placeholder: 'Jakob Baptista' }, uploadDate: '15/06/2023', fileType: { placeholder: 'Not Done' } },
        // { id: 3, fileName: 'Removes', uploadedBy: { label: '', placeholder: 'Jakob Baptista', width: '200px', height: '30px', border: 'none' }, uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
        // { id: 4, fileName: 'Removes', uploadedBy: { label: '', placeholder: 'Jakob Baptista', width: '200px', height: '30px', border: 'none' }, uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
        // { id: 5, fileName: 'Removes', uploadedBy: { label: '', placeholder: 'Jakob Baptista', width: '200px', height: '30px', border: 'none' }, uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },

    ],
}