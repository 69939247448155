import * as types from "./types";
import axios from "axios";
import {api, createHeaders} from "../constants/api-urls"


export const changeCurrentPage = (page) => ({
  type: types.RESOURCES_CHANGE_CURRENT_PAGE,
  payload: page,
});

export const changeRowsPerPage = (count) => ({
  type: types.RESOURCES_CHANGE_ROWS_PER_PAGE, 
  payload: count,
});

const getByFilterResourcesRequest = () => ({
  type: types.ADMIN_GET_FILTER_RESOURCES_REQUEST,
});

const getByFilterResourcesSuccess = (value) => ({
  type: types.ADMIN_GET_FILTER_RESOURCES_SUCCESS,
  payload: value,
});

const getByFilterResourcesFailure = (error) => ({
  type: types.ADMIN_GET_FILTER_RESOURCES_ERROR,
  payload: {
    error,
  },
});

export const getByFilterResources = (
  limit = 20,
  page = 0,
  sort = -1,
  factory_id,
  sub_department_id
) => {
  return (dispatch) => {
    dispatch(getByFilterResourcesRequest());
    let filter = `limit=${limit}&page=${page}&sort=${sort}
    ${factory_id ? `&factory_id=${factory_id}` : ``}
    ${sub_department_id ? `&sub_department_id=${sub_department_id}` : ``}`;

    let url = `${api.admin.resources.getByFilter(filter)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result, count } = res.data;
        dispatch(getByFilterResourcesSuccess({ result: result, count: count }));
      })
      .catch((err) => {
        dispatch(getByFilterResourcesFailure(err.message));
      });
  };
};

const getAllEmployeeRequest = () => ({
  type: types.GET_ALL_RESOURCES_REQUEST,
});

const getAllEmployeeSuccess = (factories) => ({
  type: types.GET_ALL_RESOURCES_SUCCESS,
  payload: factories
});

const getAllEmployeeFailure = (error) => ({
  type: types.GET_ALL_RESOURCES_ERROR,
  payload: {
    error,
  }, 
});  

export const getAllEmployee = () => {
  return (dispatch) => {
    dispatch(getAllEmployeeRequest());
    let url = `${api.admin.resources.allResources}`
    const headers = createHeaders()
    axios
      .get(url, {headers}) 
      .then((res) => {
        const { ok, result} = res.data
        dispatch(getAllEmployeeSuccess(result));
      })
      .catch((err) => {
        dispatch(getAllEmployeeFailure(err.message));
      });
  };
};

const getEmployeeRequest = () => ({
  type: types.GET_BY_ID_RESOURCE_REQUEST,
});

const getEmployeeSuccess = (value) => ({
  type: types.GET_BY_ID_RESOURCE_SUCCESS,
  payload: value
});

const getEmployeeFailure = (error) => ({
  type: types.GET_BY_ID_RESOURCE_ERROR,
  payload: {
    error,
  },
});

export const getEmployee = (id) => {
  return (dispatch) => {
    dispatch(getEmployeeRequest());
    let url = `${api.admin.resources.getById(id)}`
    const headers = createHeaders()
    axios
      .get(url, {headers})  
      .then((res) => {
        const { ok, result} = res.data
        dispatch(getEmployeeSuccess(result));
      })
      .catch((err) => {
        dispatch(getEmployeeFailure(err.message));
      });
  };
};

export const setEmployeeDetais = employee => {
  return {
    type: types.SET_RESOURCE,
    payload: employee
  };
};

export const createEmployee = (data, filterFactory) => {
  return (dispatch) => {
    dispatch(createEmployeeRequest());
    let url = `${api.admin.resources.create}`
    const headers = createHeaders()
    axios
      .post(url, data, {headers}) 
      .then((res) => {
        const { ok, result} = res.data
        dispatch(createEmployeeSuccess(ok));
        dispatch(getEmployees(filterFactory));
      })
      .catch((err) => {
        dispatch(createEmployeeFailure(err.message));
      });
  };
};

const createEmployeeRequest = () => ({
  type: types.RESOURCE_CREATE_REQUEST,
});

const createEmployeeSuccess = (isCreate) => ({
  type: types.RESOURCE_CREATE_SUCCESS,
});

const createEmployeeFailure = (error) => ({
  type: types.RESOURCE_CREATE_ERROR,
  payload: {
    error,
  },
});

export const updateEmployee = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(updateEmployeeRequest());
    let url = `${api.admin.resources.update}`
    const headers = createHeaders()
    axios
      .post(url, data, {headers}) 
      .then((res) => {
        const { ok, result} = res.data
        dispatch(updateEmployeeSuccess(ok));
        dispatch(getEmployees(filterFactory));
      })
      .catch((err) => {
        dispatch(updateEmployeeFailure(err.message));
      });
  };
};

const updateEmployeeRequest = () => ({
  type: types.RESOURCE_UPDATE_REQUEST,
});

const updateEmployeeSuccess = (isUpdate) => ({
  type: types.RESOURCE_UPDATE_SUCCESS,
});

const updateEmployeeFailure = (error) => ({
  type: types.RESOURCE_UPDATE_ERROR,
  payload: {
    error,
  },
});

const getEmployeesGetByFactoryRequest = () => ({
    type: types.RESOURCES_GET_BY_FACTORY_ID_REQUEST,
  });
  
  const getEmployeesGetByFactorySuccess = (value) => ({
    type: types.RESOURCES_GET_BY_FACTORY_ID_SUCCESS,
    payload: value
  });
  
  const getEmployeesGetByFactoryFailure = (error) => ({
    type: types.RESOURCES_GET_BY_FACTORY_ID_ERROR,
    payload: {
      error,
    },
  });
  
  export const getEmployeesGetByFactory = (factory_id) => {
    return (dispatch) => {
      dispatch(getEmployeesGetByFactoryRequest());
      let url = `${api.admin.resources.getByFactoryId(factory_id)}`
      const headers = createHeaders()
      axios
        .get(url, {headers})  
        .then((res) => {
          const { ok, result} = res.data
          dispatch(getEmployeesGetByFactorySuccess(result));
        })
        .catch((err) => {
          dispatch(getEmployeesGetByFactoryFailure(err.message));
        });
    };
  };
  

  export const getEmployees = (idFactoryId = null) => {
    return (dispatch) => {
      if (idFactoryId) {
        dispatch(getEmployeesGetByFactory(idFactoryId));
      } else {
        dispatch(getAllEmployee());
      }
    };
  };

// drop resource

export const dropResource = (id, filterFactory = null) => {
  return (dispatch) => {
    dispatch(dropResourceRequest());
    let url = `${api.admin.resources.drop(id)}`
    const headers = createHeaders()
    axios
      .get(url,{headers}) 
      .then((res) => {
        const { ok, result} = res.data
        dispatch(dropResourceSuccess(ok));
        dispatch(getEmployees(filterFactory));
      })
      .catch((err) => {
        dispatch(dropResourceFailure(err.message));
      });
  };
};

const dropResourceRequest = () => ({
  type: types.RESOURCE_DROP_REQUEST,
});

const dropResourceSuccess = (isDeleted) => ({
  type: types.RESOURCE_DROP_SUCCESS,
});

const dropResourceFailure = (error) => ({
  type: types.RESOURCE_DROP_ERROR,
  payload: {
    error,
  },
});

export const setResources = (data) =>({
  type: types.SET_RESOURCES_LIST,
  payload:data
})

export const setUserList = (data) =>({
  type: types.SET_USER_LIST,
  payload:data
})

  


  
  
