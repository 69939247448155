import {
    UPDATE_PROCESS,FIRST_UNCOMPLETE_PROCESS_TOGGLE, SHOW_BIDS_TOGGLE

  } from '../actions/types'
  
  
  const initialState = {
    _id: "",
    finished: "",
    is_detached: "",
    show_first_uncomplete_process: false,
    show_bids: false 
  }
  
  export default function (state = initialState, action) {
  
    switch (action.type) {
      case UPDATE_PROCESS:
        return {
          ...state,
          _id: action.payload._id,
          finished: action.payload.finished,
          is_detached: action.payload.is_detached
        }

        case FIRST_UNCOMPLETE_PROCESS_TOGGLE:
         
        return {
          ...state,
          show_first_uncomplete_process: !state.show_first_uncomplete_process
        }

        case SHOW_BIDS_TOGGLE:
         
        return {
          ...state,
          show_bids: !state.show_bids
        }
       
      default:
        return state
    }
  }
  