import { POP_UPS_TRANSLATION } from "../../../../../constants/translations/customer/PopUps/PopUps"
import { ORDER_TASKS_REPORT } from "../../../../../constants/translations/order-tasks-report"
import { ADD, CANCEL } from "../../../../../constants/translations/review-popup"


export const add = {
    name: ADD,
    width: '35%',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '24px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}
export const cancel = {
    name: CANCEL, 
    width: '35%',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '24px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}

export const addBtnDesktop = {
    ...add,
    fontSize: '16px',
    borderRadius: '20px',
    height: '52px',
    width: '42%',
}
export const cancelBtnDesktop = {
    ...cancel,
    fontSize: '16px',
    borderRadius: '20px',
    height: '52px',
    width: '42%',
}

export const addBtnMobile = {
    ...add,
    fontSize: '12px',
    borderRadius: '20px',
    height: '36px',
    width: '42%',
}
export const cancelBtnMobile = {
    ...cancel,
    fontSize: '12px',
    borderRadius: '20px',
    height: '36px',
    width: '42%',
}

export let addTaskInputs = {
    taskName: {
        label: POP_UPS_TRANSLATION.TASK_NAME,
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        name: 'taskName',
        type: 'text',
    },
    resourceName: {
        label: POP_UPS_TRANSLATION.RESOURCE_NAME,
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        name: 'resourceName',
        type: 'select',
    },
    dueDate: {
        label: ORDER_TASKS_REPORT.DUE_DATE,
        placeholder: '2023/08/19', //POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        name: 'dueDate',
        type: 'date',
        id: 'date',
    },
    status: {
        label: POP_UPS_TRANSLATION.STATUS,
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        name: 'status',
        type: 'select',
    },

}