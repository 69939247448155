import React, { Component } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { onScroll } from '../../../../hooks/helper';

class WeeklyCompletions extends Component {

    openUpdateMenu = (process) => {
        this.props.openUpdateMenu(process)
    }
    
    render() {
        const {weeklyOrders, daysOffNumber, addMoreCompletion} = this.props;

        return (
            <div className="month-view-page__completions week-view-page__completions" style={{width: `calc( 100% / ${ 7 - daysOffNumber +2})`}}>
            <header>השלמות</header>
            <Droppable droppableId="completions__droppable">
                {(provided)=>(
                    <section className="month-view-page__completions__droppable" id='weekly-completion-ix' onScroll={addMoreCompletion} ref={provided.innerRef} {...provided.droppableProps}>
                            {weeklyOrders.map((data, index)=>{
                                return <Draggable draggableId={data._id} index={index} key={data._id} isDragDisabled={data.fraction}>
                                {(provided)=>( 
                                    <div id={`${data._id}w-c`} onClick={()=>{this.openUpdateMenu(data)}} className="month-view-page__completions__draggable"  {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                        <section>
                                            <div>{data.order_number} - {data.client_name}</div>
                                            <div>{data.process_name}</div>
                                            <div>הושלמו {data.finished} מתוך {data.quantity}</div>
                                        </section>
                                    </div>
                                )}
                                </Draggable> 
                            })}
                    </section>
                )}
            </Droppable>
        </div>
        )
    }
}

export default WeeklyCompletions
