import React, { Component } from "react";
import Loader from "../LoaderNew/Loader";
import { removeCookie, getCookie } from "../../tools/cookies/cookie";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { withRouter } from "react-router-dom";

class Logout extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false
    };
  }
 
  startRedirect = () => {
    removeCookie("login_cookie");
    setTimeout(() => {
      this.props.updateLogin(false);
      this.props.history.push("/");
      this.setState({
        redirect: true
      });
    }, 2000);
  };
 
  render() {
    this.startRedirect();
    return <div className="logout__loader">{this.state.redirect  ? null : <Loader />}</div>;
  }
}

function mapStateToProps({ router, login }) {
  return { router, login };
}
export default withRouter(connect(mapStateToProps, actions)(Logout));
