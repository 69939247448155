import React, { useCallback, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee, getAllFactory,  getEmployees, getByFilterResources } from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";
import TableResource from "./TableResource";


export const ResourceList = () => { 
  const dispatch = useDispatch();
  const resourcesList = useSelector((state) => state.resources.listResource);
  
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const countResource = useSelector(
    (state) => state.resources.countResource
  );
  
  const page = useSelector((state) => state.resources.currentPage);
  const rowsPerPage = useSelector((state) => state.resources.rowsPerPage);

  
  useEffect(() => {
    //dispatch(getEmployees(filterFactory));
    dispatch(getByFilterResources(rowsPerPage, page*rowsPerPage, -1, filterFactory));
  }, [dispatch,  page, rowsPerPage, filterFactory]);


  return (
    <div>     
      
      <TableResource resourcesList ={resourcesList} countResource={countResource}/>
    </div>
  );
};
