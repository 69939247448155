import React from "react";
import SideBar from "../../SideBar/SideBar";
import "../../../sass/quotes/_quotes_table_offer.scss";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "./styles";
import { useState } from "react";
import { useEffect } from "react";
import { getBids, getQuotePrice } from "../../../functions/api/orders";
import moment from "moment";
import {
  convertIntoCurrency,
  getAmountIncludingExtraCharges,
  setNavigationUrl,
  currentAndPrevUrlSnapShot,
  getAllExtraCharges,
} from "../../../hooks/helper";
import DropDownBox from "../../Header/Parts/Popup/Parts/DropDownBox/DropDownBox";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Loader from "../../LoaderNew/Loader";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  QUOTE,
  QUOTE_STATUS_OPTIONS,
} from "../../../constants/translations/customersPage";
import CustomizedInputWithLabel from "../reused_components/CustomizedInputWithLabel";
import QuoteTabePageDropDown from "./Parts/QuoteTabePageDropDown";
import { updateQuoteOrder } from "../../../functions/api/popup";

const ColorByUser = {
  TableBackgroundColor: "",
  tHBorder: "",
  tDBorder: "",
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: ColorByUser.TableBackgroundColor,
    textAlign: "right",
    fontSize: 16,
    fontFamily: "Rubik",
  },
  body: {
    fontSize: 16,
    fontFamily: "Rubik",
    border: "none",
    textAlign: "right",
    padding: "3px 10px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      background: "#f2f2f2",
    },
  },
}))(TableRow);

const QuotesTablePage = () => {
  const { DRAFT, SENT_TO_CLIENT, MAKE_AN_ORDER, CLOSE } = QUOTE_STATUS_OPTIONS;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const factoryName = useSelector((state) => state.login.user.factory_name);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [quoteData, setQuoteData] = useState([]);
  const [nameTs, setNameTs] = useState("");
  const [sortTable, setSortTable] = useState({
    order_number: 1,
    client_name: -1,
    status: -1,
    due_date: -1,
  });
  const [orderBy, setOrderBy] = useState({
    order: -1,
    sort: "order_number",
  });
  const [totalCount, setTotalCount] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    setLoading((prev) => true);
    const { order, sort } = orderBy;
    getBids(page * 10, rowsPerPage, order, sort)
      .then((response) => {
        const { ok, result , count} = response;
        if (ok) {
          setQuoteData((prev) => result);
          setTotalCount(count)
        }
        setLoading((prev) => false);
      })
      .catch((error) => {
        console.error("Quote pagination error");
        setLoading((prev) => false);
      });
  }, [page, rowsPerPage, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectChange = (event) => {
    setNameTs(event.target.value);
  };

  const changeStatusHandler = (body) => {
    updateQuoteOrder({ quote_info: body, page: page * 10, limit: rowsPerPage }).then(
      ({ ok, result }) => {
        ok && setQuoteData((prev) => result);
      },
    );
  };

  const saveSelectedOrder = (selectedBid) => {
    const { customer_id, _id } = selectedBid;
    if (customer_id && _id) {
      setNavigationUrl(`${history.location.pathname}`);
      history.push(
        `/${factoryName}/customers-page/create-quote/${customer_id}/${_id}`,
      );
    }
  };

  const createSortHandler = (order, sort) => {
    setOrderBy({ order, sort });
    setSortTable({ ...sortTable, [sort]: order === 1 ? -1 : 1 });
  };

  return (
    <div className='quote__bids__table'>
      <div className={classes.quotePage}>
        <div className={classes.quoteTableDiv}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.quoteHeader}>
                  <TableSortLabel
                    direction={sortTable.order_number === -1 ? "asc" : "desc"}
                    onClick={(e) =>
                      createSortHandler(sortTable.order_number, "order_number")
                    }>
                    {"מספר ציטוט"}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.quoteHeader}>
                  <TableSortLabel
                    direction={sortTable.client_name === -1 ? "asc" : "desc"}
                    onClick={(e) =>
                      createSortHandler(sortTable.client_name, "client_name")
                    }>
                    {"שם לקוח"}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.quoteHeader}>
                  {"מספר טלפון / נייד"}
                </StyledTableCell>
                <StyledTableCell className={classes.quoteHeader}>
                  {"כתובת"}
                </StyledTableCell>
                <StyledTableCell className={classes.quoteHeader}>
                  {"ציטוט עלות/הצעה כוללת"}
                </StyledTableCell>
                <StyledTableCell className={classes.quoteHeader}>
                  <TableSortLabel
                    direction={sortTable.status === -1 ? "asc" : "desc"}
                    onClick={(e) =>
                      createSortHandler(sortTable.status, "status")
                    }>
                    {"מצב ציטוטים"}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.quoteHeader}>
                  <TableSortLabel
                    direction={sortTable.due_date === -1 ? "asc" : "desc"}
                    onClick={(e) =>
                      createSortHandler(sortTable.due_date, "due_date")
                    }>
                    {"תאריך פתיחת הצעת מחיר"}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.quoteHeader}>
                  {"להראות ציטוט"}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {quoteData.length > 0 && (
              <TableBody>
                {quoteData.map((row) => (
                  <StyledTableRow hover key={row._id}>
                    <StyledTableCell className={classes.quotesTableRow}>
                      {row.order_number}
                    </StyledTableCell>
                    <StyledTableCell className={classes.quotesTableRow}>
                      {row.client_name}
                    </StyledTableCell>
                    <StyledTableCell className={classes.quotesTableRow}>
                      {row.client_phone}
                    </StyledTableCell>
                    <StyledTableCell className={classes.quotesTableRow}>
                      {row.address}
                    </StyledTableCell>
                    <StyledTableCell className={classes.quotesTableRow}>
                      {convertIntoCurrency(
                        getAmountIncludingExtraCharges(
                          Number(
                            Number(row.value) +
                              getAllExtraCharges(row.extra_charges || []) || 0,
                          ),
                          row.order_discount,
                        ),
                        "ILS",
                      )}
                    </StyledTableCell>
                    <StyledTableCell className={classes.quotesTableRow}>
                      <QuoteTabePageDropDown
                        key={row._id}
                        validate_all_form={false}
                        title={false}
                        data={[DRAFT, SENT_TO_CLIENT, MAKE_AN_ORDER, CLOSE]}
                        updateForm={changeStatusHandler}
                        name={"started"}
                        selected_step={row.status || "טיוטה"}
                        row_data={row}
                      />
                    </StyledTableCell>
                    <StyledTableCell className={classes.quotesTableRow}>
                      {moment(row.due_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell className={classes.quotesTableRow}>
                      <Button
                        className={classes.quoteTableBtn}
                        variant='text'
                        onClick={() => {
                          saveSelectedOrder(row);
                        }}>
                        {`להראות`}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {loading ? (
            <div className='quote_price_loader'>
              <Loader />
            </div>
          ) : (
            !loading &&
            quoteData.length === 0 && (
              <div className='no__quotes'>אין הצעות מחיר</div>
            )
          )}
          {quoteData.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component='div'
              colSpan={3}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.quoteTablePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotesTablePage;
