export const TABLET_VIEW_TRANSLATION = {
    FILE: 'קוֹבֶץ',
    START_TIME_COUNT: 'ספירת זמני התחלה',
    SYSTEM: 'מערכת',
    STARTED_AT: 'התחיל ב9 בבוקר',
    TASK: 'מְשִׁימָה',
    START_DATE: 'תאריך התחלה',
    END_DATE: 'תאריך סיום',
    TOTAL_HOURS: 'סה"כ שעות',
    SIGN: 'סִימָן',
    CUSTOMER_SIGNATURE: 'חתימת לקוח',
    NOT_SIGNED: 'לא רשום',
    NOTES:'הערות',
    DETAILS: 'פרטים',
    CHECK_LIST: "צ'ק ליסט",
    SYSTEMS_LIST: 'רשימת מערכות',
    ELECTRICAL_SYSTEM_BUILDING_SYSTEM: 'מערכת בניית מערכת חשמל',
    EMPTY_SYSTEMS_LIST: 'אין רשימת בדיקה. צריך לבחור מערכת כדי לראות את זה.',
    REPORTED:  'דווח',
    ORDERED: 'הוזמן',
}