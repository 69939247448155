import React from 'react'
import ProcessRow from '../ProcessRow/ProcessRow';
import "./DailyView.scss";
import Header from '../../Customer/Header/TopHeader.jsx/Header';
import TopStrap from '../../Customer/Header/Strap/TopStrap';
import BlackStrap from '../../Customer/Header/BlackStrap/BlackStrap';

const processesData = {
  columns: [
    {
      field: 'processName',
      headerName: 'Process Name',
    },
    {
      field: 'customerName',
      headerName: 'Customer name',
    },
    {
      field: 'orderNumber',
      headerName: 'Order number',
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
    },
    {
      field: 'endTime',
      headerName: 'End Time',
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
    },
    {
      field: 'duration',
      headerName: 'Duration',
    },
    {
      field: 'status',
      headerName: 'status',
    },
  ],
  rows: [
    {
      processName: 'Glazing - Glazing',
      customerName: 'Mintos Tami',
      orderNumber: 'PR20002420',
      startTime: '00 30:10',
      endTime: '00 30:10',
      quantity: '13/0',
      duration: '11.25',
      status: 'check',
      done: false,
      finished: 13,	
    },
    {
      processName: 'Glazing - Glazing',
      customerName: 'Mintos Tami',
      orderNumber: 'PR20002420',
      startTime: '00 30:10',
      endTime: '00 30:10',
      quantity: '13/0',
      duration: '11.25',
      status: 'done',
      done: true,
      finished: 13,	
    },
    {
      processName: 'Glazing - Glazing',
      customerName: 'Mintos Tami',
      orderNumber: 'PR20002420',
      startTime: '00 30:10',
      endTime: '00 30:10',
      quantity: '13/0',
      duration: '11.25',
      status: 'progress',
      done: false,
      finished: 13,
    },
    {
      processName: 'Glazing - Glazing',
      customerName: 'Mintos Tami',
      orderNumber: 'PR20002420',
      startTime: '00 30:10',
      endTime: '00 30:10',
      quantity: '13/0',
      duration: '11.25',
      status: 'progress',
      done: false,
      finished: 1,
    },

  ]
}

export default function DailyView() {
  return (
    <div className='DailyView__processesContainer'>
      <Header />
      <TopStrap data={true} />
      <BlackStrap data={true} />
      <div className='dailyView__processRow'>
        <ProcessRow data={processesData.rows.map((row) => {
          return {
           ...row
          }
        })}/>
      </div>
     
    </div>
  )
}
