import React, {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getAllFactory, getByFilterOrderCollectionStages } from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";
import TableOrderCollectionStages from "./TableOrderCollectionStages";


export const OrderCollectionStagesList = () => {
  const dispatch = useDispatch();

  const orderCollectionStagesList = useSelector(
    (state) => state.orderCollectionStages.listCollectionStages
  );
  const countOrderCollectionStages = useSelector(
    (state) => state.orderCollectionStages.countOrderCollectionStages
  ); 
  const page = useSelector((state) => state.orderCollectionStages.currentPage);
  const rowsPerPage = useSelector((state) => state.orderCollectionStages.rowsPerPage);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  useEffect(() => { 
    dispatch(getByFilterOrderCollectionStages(rowsPerPage, page*rowsPerPage, -1, filterFactory));    
  }, [dispatch,  page, rowsPerPage, filterFactory]);

  return (
    <div className = "admin_page">
  
      <TableOrderCollectionStages orderCollectionStagesList = {orderCollectionStagesList} 
        countOrderCollectionStages={countOrderCollectionStages}></TableOrderCollectionStages>
    </div>
  );
};
