import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartment,
  setDepartmentDetais,
  getAllFactory,
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { DEEPARTMENT_MPS_VIEW } from "../../../constants/enum";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1), 
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const DepartmentDetails = (props) => {
  const dispatch = useDispatch();

  const department = useSelector(
    (state) => state.departments.departmentDetails
  );
  const factoryList = useSelector((state) => state.factories.listFactories);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    if (props.id) {
      dispatch(getDepartment(props.id));
    }
    return function cleanup() {
      dispatch(setDepartmentDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && department && (
          <DepartmentForm
            id={props.id}
            department={department}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
          />
        )}
        {props.id === undefined && (
          <DepartmentForm
            id={props.id}
            department={null}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            filterFactory={filterFactory}
          />
        )}
      </>
    </Grid>
  );
};

function DepartmentForm(props) {
  const classes = useStyles();
 
  const { department, id, factoryList, filterFactory } = props;
  

  const [values, setValues] = useState({
    name: department ? department.name : "",
    view: department ? department.view : "",
    factory_id: department ? department.factory_id : '',
  });

  const getFactoryId  = () => { 
    let res = department ? department.factory_id : filterFactory ?filterFactory : '';
    setValues({ ...values, factory_id: res })
  }

  const initFactoryId = useMemo(() => getFactoryId(), [filterFactory],);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { name, view, factory_id } = values;

    let model;

    if (id) {
      model = {
        _id: id,
        name,
        view,
        factory_id,
      };
    } else {
      model = {
        name,
        view,
        factory_id,
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>
      <form id="edit-department" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="view_label">mps view</InputLabel>
              <Select
                labelId="view_label"
                id="view"
                value={values.view}
                onChange={handleInputChange}
                label="view"
                name="view"
              >
                {DEEPARTMENT_MPS_VIEW.map((name) => {
                  return (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={ values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
