export const SHOW_STOPPER = 'show_stopper'
export const ADD_SUB_SYSTEMS = 'ADD_SUB_SYSTEMS'
export const ADD_INPUT = 'ADD_INPUT'
export const ADD_ADDITIONAL_TEST = 'ADD_ADDITIONAL_TEST'
export const ROW_TITLE = 'row_title'
export const ROW_ORDER = 'order'
export const SUB_SYSTEM_ID = 'sub_system_id'
export const TYPE_OF_INPUT = 'typeOfInput'
export const ADDITIONAL_TEST_ID = 'additional_test_id'
export const DESCRIPTION = 'description'
// export const REG_EXP_OBJECT_ID = 24
export const REG_EXP_OBJECT_ID = /^[0-9a-fA-F]{24}$/
export const NEW_INPUT_NUMBER = 'new input'
export const INPUT_NAME_PROPERTY = 'name'
export const INPUT_NUMBER = 'input_number'
export const ADDITIONAL_TEST_EXIST_INPUT_TYPE = "-19"
export const MAX_SINGLE_INPUT = 0
export const LAYER_2 = 2
export const LAYER_4 = 4
export const TEST_WITH_OPEN_DOORS_ID = '61af455faaeab38a47caa931'
export const TEST_WITH_CLOSE_DOORS_ID = '61af455faaeab38a47caa932' 