import * as types from "./types";
import axios from "axios";
import { api, createHeaders } from "../constants/api-urls";


export const changeCurrentPage = (page) => ({
  type: types.SUB_DEPARTMENT_CHANGE_CURRENT_PAGE,
  payload: page,
});

export const changeRowsPerPage = (count) => ({
  type: types.SUB_DEPARTMENT_CHANGE_ROWS_PER_PAGE, 
  payload: count,
});

const getByFilterSubDepartmentRequest = () => ({
  type: types.ADMIN_GET_FILTER_SUB_DEPARTMENT_REQUEST,
});

const getByFilterSubDepartmentSuccess = (value) => ({
  type: types.ADMIN_GET_FILTER_SUB_DEPARTMENT_SUCCESS,
  payload: value,
});

const getByFilterSubDepartmentFailure = (error) => ({
  type: types.ADMIN_GET_FILTER_SUB_DEPARTMENT_ERROR,
  payload: {
    error,
  },
});

export const getByFilterSubDepartment = (
  limit = 20,
  page = 0,
  sort = -1,
  factory_id,
  department_id
) => {
  return (dispatch) => {
    dispatch(getByFilterSubDepartmentRequest());
    let filter = `limit=${limit}&page=${page}&sort=${sort}${factory_id ? `&factory_id=${factory_id}` : ``}${department_id ? `&department_id=${department_id}` : ``}`;

    let url = `${api.admin.subDepartments.getByFilter(filter)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result, count } = res.data;
        dispatch(getByFilterSubDepartmentSuccess({ result: result, count: count }));
      })
      .catch((err) => {
        dispatch(getByFilterSubDepartmentFailure(err.message));
      });
  };
};


const getAllSubDepartmentRequest = () => ({
  type: types.GET_ALL_SUB_DEPARTMENT_REQUEST,
});

const getAllSubDepartmentSuccess = (subDepartment) => ({
  type: types.GET_ALL_SUB_DEPARTMENT_SUCCESS,
  payload: subDepartment,
});

const getAllSubDepartmentFailure = (error) => ({
  type: types.GET_ALL_SUB_DEPARTMENT_ERROR,
  payload: {
    error,
  },
});

export const getAllSubDepartment = () => {
  return (dispatch) => {
    dispatch(getAllSubDepartmentRequest());
    let url = `${api.admin.subDepartments.allSubDepartments}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getAllSubDepartmentSuccess(result));
      })
      .catch((err) => {
        dispatch(getAllSubDepartmentFailure(err.message));
      });
  };
};

const getSubDepartmentRequest = () => ({
  type: types.GET_BY_ID_SUB_DEPARTMENT_REQUEST,
});

const getSubDepartmentSuccess = (subDepartment) => ({
  type: types.GET_BY_ID_SUB_DEPARTMENT_SUCCESS,
  payload: subDepartment,
});

const getSubDepartmentFailure = (error) => ({
  type: types.GET_BY_ID_SUB_DEPARTMENT_ERROR,
  payload: {
    error,
  },
});

export const getSubDepartment = (id) => {
  return (dispatch) => {
    dispatch(getSubDepartmentRequest());
    let url = `${api.admin.subDepartments.getById(id)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getSubDepartmentSuccess(result));
      })
      .catch((err) => {
        dispatch(getSubDepartmentFailure(err.message));
      });
  };
};

export const setSubDepartmentDetais = (subDepartment) => {
  return {
    type: types.SET_SUB_DEPARTMENT,
    payload: subDepartment,
  };
};

export const createSubDepartment = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(createSubDepartmentRequest());
    let url = `${api.admin.subDepartments.create}`;
    const headers = createHeaders();
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(createSubDepartmentSuccess(ok));
        dispatch(getSubDepartments(filterFactory));
      })
      .catch((err) => {
        dispatch(createSubDepartmentFailure(err.message));
      });
  };
};

const createSubDepartmentRequest = () => ({
  type: types.SUB_DEPARTMENT_CREATE_REQUEST,
});

const createSubDepartmentSuccess = (isCreate) => ({
  type: types.SUB_DEPARTMENT_CREATE_SUCCESS,
});

const createSubDepartmentFailure = (error) => ({
  type: types.SUB_DEPARTMENT_CREATE_ERROR,
  payload: {
    error,
  },
});

export const updateSubDepartment = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(updateSubDepartmentRequest());
    let url = `${api.admin.subDepartments.update}`;
    const headers = createHeaders();
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(updateSubDepartmentSuccess(ok));
        dispatch(getSubDepartments(filterFactory));
      })
      .catch((err) => {
        dispatch(updateSubDepartmentFailure(err.message));
      });
  };
};

const updateSubDepartmentRequest = () => ({
  type: types.SUB_DEPARTMENT_UPDATE_REQUEST,
});

const updateSubDepartmentSuccess = (isUpdate) => ({
  type: types.SUB_DEPARTMENT_UPDATE_SUCCESS,
});

const updateSubDepartmentFailure = (error) => ({
  type: types.SUB_DEPARTMENT_UPDATE_ERROR,
  payload: {
    error,
  },
});

const getSubDeptGetByDeptRequest = () => ({
  type: types.SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_REQUEST,
});

const getSubDeptGetByDeptSuccess = (subDepartments) => ({
  type: types.SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_SUCCESS,
  payload: subDepartments,
});

const getSubDeptGetByDeptFailure = (error) => ({
  type: types.SUB_DEPARTMENT_GET_BY_DEPARTMENT_ID_ERROR,
  payload: {
    error,
  },
});

export const getSubDeptGetByDept = (department_id) => {
  return (dispatch) => {
    
    dispatch(getSubDeptGetByDeptRequest());
    
    let url = `${api.admin.subDepartments.getByDepartmentId(department_id)}`;
    
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        
        const { ok, result } = res.data;
        dispatch(getSubDeptGetByDeptSuccess(result));
      })
      .catch((err) => {
        
        dispatch(getSubDeptGetByDeptFailure(err.message));
      });
  };
};

const getSubDeptGetByFactoryRequest = () => ({
  type: types.SUB_DEPARTMENT_GET_BY_FACTORY_ID_REQUEST,
});

const getSubDeptGetByFactorySuccess = (subDepartments) => ({
  type: types.SUB_DEPARTMENT_GET_BY_FACTORY_ID_SUCCESS,
  payload: subDepartments,
});

const getSubDeptGetByFactoryFailure = (error) => ({
  type: types.SUB_DEPARTMENT_GET_BY_FACTORY_ID_ERROR,
  payload: {
    error,
  },
});

export const getSubDeptGetByFactory = (factory_id) => {
  return (dispatch) => {
    dispatch(getSubDeptGetByFactoryRequest());
    let url = `${api.admin.subDepartments.getByFactoryId(factory_id)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getSubDeptGetByFactorySuccess(result));
      })
      .catch((err) => {
        dispatch(getSubDeptGetByFactoryFailure(err.message));
      });
  };
};

export const resetSubDepartments = () => {
  return (dispatch) => {
    dispatch(getSubDeptGetByFactorySuccess([]))
  }
}

export const getSubDepartments = (
  idFactoryId = null,
  idDepartment = null
  //idSubDept = null
) => {
  return (dispatch) => {
    // if (idFactoryId) {
    //   dispatch(getSubDeptGetByFactory(idFactoryId));
    // } else {
    //   dispatch(getAllSubDepartment());
    // }
    // if ((idFactoryId, idDepartment)) {
      if (idDepartment) {
        dispatch( getSubDeptGetByDept(idDepartment))
    } else if (idFactoryId) {
      dispatch(getSubDeptGetByFactory(idFactoryId));
    } else {
      dispatch(getAllSubDepartment());
    }
  };
};



export const dropSubDepartment = (id, selectedFactoryId) => {
  return (dispatch) => {
    let url = `${api.admin.subDepartments.drop(id)}`
    const headers = createHeaders()
    axios
      .get(url,{headers}) 
      .then((res) => {
        const { ok, result} = res.data
        dispatch(getSubDepartments(selectedFactoryId));
      })
  };
};
