import React, { useState } from "react";
import {useSelector} from 'react-redux'
import SystemDetails from './SystemDetails'


const SystemDetailsWrapper = (props) => {
    const { onSubmit } = props
    const [layer, setLayer] = useState('')
    const [description, setDescription] = useState('')
    const [factoryId, setFactory] = useState('')
    const [halfYearTest,setHalfYearTest] = useState(false)
    const [designationOfStructure, setDesignationOfStructure] = useState('')
    const selectedFactory = useSelector(state => state.factories.filterFactory)

    const submitForm = (e) => {
        e.preventDefault()
        const body = {
            layer, 
            description, 
            factory_id: factoryId, 
            desigantion_of_structure: designationOfStructure,
        }

        onSubmit(undefined, body, selectedFactory)
    }

    return <form id="edit-system" onSubmit={(e)=>submitForm(e)} >
        <SystemDetails
            description={description}
            layer={layer}
            setLayer={setLayer}
            setDescription={setDescription}
            factoryId = {factoryId}
            setFactory = {setFactory}
            halfYearTest = {halfYearTest} 
            setHalfYearTest={setHalfYearTest}
            designationOfStructure = {designationOfStructure}
            setDesignationOfStructure = {setDesignationOfStructure}
        />
    </form>
}

export default SystemDetailsWrapper