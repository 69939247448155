import * as types from "./types";
import axios from "axios";

import {api, createHeaders} from "../constants/api-urls"

export const createSystem = (data) => {
    return (dispatch) => {
      dispatch(createSystemRequest());
      let url = `${api.admin.systems.create}`
      const headers = createHeaders()
      axios
        .post(url, data, {headers}) 
        .then((res) => {
          const { ok, result} = res.data 
          dispatch(createSystemSuccess(result || null));
          // dispatch(getAllFactory());
        })
        .catch((err) => {
          dispatch(createSystemFailure(err.message));
        });
    };
  };
  
  const createSystemRequest = () => ({
    type: types.SYSTEM_CREATE_REQUEST,
  });
  
  const createSystemSuccess = (system) => ({
    type: types.SYSTEM_CREATE_SUCCESS,
    payload: system
  });
  
  const createSystemFailure = (error) => ({
    type: types.SYSTEM_CREATE_ERROR,
    payload: {
      error,
    },
  });

  export const systemSuccessfullyAdded = () =>({
    type: types.SYSTEM_SUCCESSFULLY_ADDED
  })

  // export const setAdditionalTestsPopUpActive = (isActive) => ({
  //   type: types.SET_ADDITIONAL_TESTS_POPUP_ACTIVE,
  //   payload: isActive
  // })