import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import "./ChangeSystemPopUp.scss";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FolderIcon from '@material-ui/icons/Folder';
import OpenedFilePopUp from '../OpenedFilePopUp/OpenedFilePopUp';
import AddFilePopUp from '../AddFilePopUp/AddFilePopUp';
import DeletePopUp from '../DeletePopUp/DeletePopUp';
import VisibilityIcon from '@material-ui/icons/Visibility';

const renderBtnIcon = (iconName) => {
    if (iconName === 'add') {
        return <AddIcon className='icon' style={{ fontSize: '15px' }} />
    } else if (iconName === 'edit') {
        return <EditIcon className='icon' style={{ fontSize: '15px' }} />
    } else if (iconName === 'delete') {
        return <DeleteIcon className='icon' style={{ fontSize: '15px' }} />
    } else if (iconName === 'up') {
        return <ArrowUpwardIcon className='icon' style={{ fontSize: '15px' }} />
    } else if (iconName === 'down') {
        return <ArrowDownwardIcon className='icon' style={{ fontSize: '15px' }} />
    } else if (iconName === 'folder') {
        return <FolderIcon className='icon' style={{ fontSize: '15px' }} />
    } else if (iconName === 'vision') {
        return <VisibilityIcon className='icon' style={{ fontSize: '15px' }} />
    } else {
        return null;
    }
}


export default function ChangeSystemPopUp(props) {
    const { data, id, handleViewAllDetails, view, onClickHandler, clickHandlers, rowIndex, layer } = props;
    const [showPopUpByLabel, setShowPopUpByLabel] = useState(null);
    const [showPopUp, setShowPopUp] = useState(false);
   

    const handleOpenPopUp = (label) => {
        if (label) {
            setShowPopUpByLabel(label)
            setShowPopUp(true)
        }
        if (label === "Show Details") {
            handleViewAllDetails()
        }
        // if (label === "Add System") {
        //     handleViewAllDetails()
        // }
        // if (label === "open File") {
        //     handleViewAllDetails()
        // }
    }

    const handleClick=(label)=>{
        if (label === "Show Details") {
            handleViewAllDetails()
        }
        // onClickHandler(label)
    }
  
    return (
        <>
            <Grid className='changeSystemPopUp__main'  >
                {data.map((item, index) => {

                    return <> <Grid key={item.label + id} className='changeSystemPopUp__list__item' style={{ position: 'relative', justifyContent: `${item.icon === undefined ? 'right' : 'left'}` }} 
                    onClick={(e) => {
                    //    e.stopPropagation();
                        if (item.label in clickHandlers) {
                            if (item.label === 'Move Up' || item.label === 'Move Down'){
                                clickHandlers[item.label](rowIndex, layer);
                            }else{
                                clickHandlers[item.label](id, layer);
                            }
                        }
                        if (item.label === 'Show Details'){
                            handleViewAllDetails()
                        }
                        
                    }}  > 
                        <span className='changeSystemPopUp__list__item__icon' >
                            {renderBtnIcon(item.icon)}
                        </span>
                        &nbsp;
                        &nbsp;
                        <span className='changeSystemPopUp__list__item__text'>{item.label}</span>

                    </Grid>
                        {/* {
                            item.label === 'Open File' ? showPopUp && <OpenedFilePopUp view="tablet"  handleClick={clickHandlers['openFile']}/>
                                : item.label === 'Edit File' ? showPopUp && <AddFilePopUp />
                                    : item.label === 'Open File' ? showPopUp && <DeletePopUp />
                                        : null
                        } */}

                    </>
                })}
            </Grid>
        </>
    )
}

// showPopUp && <OpenedFilePopUp /> : item.label === 'Edit File' ? showPopUp && <AddFilePopUp /> : item.label === 'Open File' ? showPopUp && <DeletePopUp /> 