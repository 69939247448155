import React, {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getAllFactory, getOrderTypes, getByFilterOrderTypes } from "../../../actions/actions";
import "../../../sass/adminPanel/_admin.scss";
import TableOrderType from "./TableOrderType";




export const OrderTypeList = () => {
  const dispatch = useDispatch();

  const orderTypeList = useSelector(
    (state) => state.orderTypes.listOrderTypes
  );
  const countOrderTypes = useSelector(
    (state) => state.orderTypes.countOrderTypes
  ); 
  const page = useSelector((state) => state.orderTypes.currentPage);
  const rowsPerPage = useSelector((state) => state.orderTypes.rowsPerPage);
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  useEffect(() => { 
    dispatch(getByFilterOrderTypes(rowsPerPage, page*rowsPerPage, -1, filterFactory));      
  }, [dispatch,  page, rowsPerPage, filterFactory]);

  return (
    <div className = "admin_page">
  
      <TableOrderType orderTypeList = {orderTypeList} countOrderTypes={countOrderTypes}></TableOrderType>
    </div>
  );
};
