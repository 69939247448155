import React, { useEffect, useState } from 'react';
import Table from '../../Table/Table2';
import Button from '../../Buttons/Button';
import { Grid } from '@material-ui/core';
import "./FileTab.scss";
import OpenedFilePopUp from '../../PopUps/OpenedFilePopUp/OpenedFilePopUp';
import DeletePopUp from '../PopUps/Delete/DeletePopUp';
import { useDispatch, useSelector } from 'react-redux';
import { fileActionCreator } from '../../../../actions/customer/fileActions';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { CANCEL, FILES, SAVE } from '../../../../constants/translations/review-popup';
import { TABLET_VIEW_TRANSLATION } from '../../../../constants/translations/customer/tablet/tabletView';
import { useFiles } from '../../../../hooks/useFiles';
import AddFilePopUp from '../../PopUps/AddFilePopUp/AddFilePopUp';
import EmptyList from '../SystemsTab/EmptyList/EmptyList';
import Loader from '../../../LoaderNew/Loader';
import { fileTabBtns, fileTabBtnsDesktop, fileTabBtnsTablet, deleteInfoPopUp } from "./FileTabStatic";


export default function FileTab(props) {
    const { showPopUp, handleShowPopUp, closePopup, view, process } = props;
    const [filesList, setFilesList] = useFiles(process && process.customer_id);

   
    const dispatch = useDispatch();
    const filesListDesktop = useSelector((state) => state.files.filesDesktop);
    const filesListTablet = useSelector((state) => state.files.filesTablet);
    const fileTableData = view === "desktop" ? filesListDesktop : filesListTablet;

    const deleteFileDetails = useSelector((state) => state.files.singleFile);
    const attachmentFile = useSelector((state) => state.files.singleFile.filePath);
    const [currentPopup, setCurrentPopup] = useState('add');
    const [selectedId, setSelectedId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (view === 'desktop') {
            dispatch(fileActionCreator.getFilesListDesktop());
        }
        dispatch(fileActionCreator.getFilesListTablet());
    }, [dispatch, view])
    useEffect(() => {
        dispatch(fileActionCreator.setFileToFilesListTablet(filesList))
    }, [dispatch, filesList])


    const deleteInfoPopUpToSend = {
        ...deleteInfoPopUp,
        info: [
            { label: POP_UPS_TRANSLATION.UPLOADED_BY, value: deleteFileDetails ? deleteFileDetails.uploadedBy : POP_UPS_TRANSLATION.ZAIN_BATOR },
            { label: POP_UPS_TRANSLATION.UPLOADED_DATE, value: deleteFileDetails ? deleteFileDetails.uploadDate : '18/06/2023' },
            { label: POP_UPS_TRANSLATION.FILE_NAME, value: deleteFileDetails ? deleteFileDetails.fileName : 'Jorem ipsum dolor ' },
            { label: POP_UPS_TRANSLATION.FILE_TYPE, value: deleteFileDetails.fileType ? deleteFileDetails.fileType.placeholder : 'Jorem ipsum' },
        ]
    }

    function onClickHandler(label) {
        setCurrentPopup(label);
        handleShowPopUp();
    }

    const popupClickHandlers = {
        'Open File': (fileId) => {
            setCurrentPopup('open');
            dispatch(fileActionCreator.selectFileByFileId(fileId))
            handleShowPopUp();
        },
        'Edit File': (fileId) => {
            setCurrentPopup('edit');
            dispatch(fileActionCreator.selectFileByFileId(fileId))
            handleShowPopUp();
        },
        'Delete File': (fileId) => {
            setCurrentPopup('delete');
            setSelectedId(fileId)
            dispatch(fileActionCreator.selectFileByFileId(fileId))
            handleShowPopUp();
        },
    }

    useEffect(() => {
        dispatch(fileActionCreator.selectFileByFileId(selectedId))
    }, [selectedId, filesList, dispatch])

    setTimeout(() => {
        if (fileTableData.rows.length === 0) {
            setIsLoading(false)
        }
    }, 700);
    return (
        <div>
        <div className='files__list'>
                {
                    fileTableData.rows.length ? <Table data={fileTableData} view={view} popupClickHandlers={popupClickHandlers} />
                        : isLoading ? <Loader /> : <EmptyList name={FILES} />
                }
        </div>
           
            <div className={showPopUp ? 'popUp__background' : ""}>
                {showPopUp ?
                    <>
                        <div style={{ visibility: currentPopup && currentPopup === 'delete' ? 'visible' : 'hidden' }}>
                            <DeletePopUp
                                handleShowPopUp={handleShowPopUp}
                                data={deleteInfoPopUpToSend}
                                view={view}
                                id={selectedId}
                                popUpFor={'file'}
                            />
                        </div>
                        <div style={{ visibility: currentPopup && currentPopup === 'open' ? 'visible' : 'hidden' }}>
                            <OpenedFilePopUp
                                showPopUp={showPopUp}
                                handleShowPopUp={handleShowPopUp}
                                view={view}
                                attachmentFile={attachmentFile && attachmentFile}
                            />
                        </div>
                        <div style={{ visibility: currentPopup && currentPopup === 'add' ? 'visible' : 'hidden' }}>
                            <AddFilePopUp
                                showPopUp={showPopUp}
                                handleShowPopUp={handleShowPopUp}
                                name={POP_UPS_TRANSLATION.ADD_A_FILE}
                                process={process && process}
                                customerId={process.customer_id}
                                updateFilesList={setFilesList}
                                view={view}
                            />
                        </div>
                        <div style={{ visibility: currentPopup && currentPopup === 'edit' ? 'visible' : 'hidden' }}>
                            <AddFilePopUp
                                showPopUp={showPopUp}
                                handleShowPopUp={handleShowPopUp}
                                name={POP_UPS_TRANSLATION.EDIT_FILE}
                                process={process && process}
                                updateFilesList={setFilesList}
                                mode={'edit'}
                                view={view}
                            />
                        </div>
                    </> : null}
            </div>
            {
                view === 'desktop' ? <Grid item xs={12} className='fileTab__btnRow'>
                    <Grid item xs={6} className='fileTab__btnRow-right'>
                        <Button data={fileTabBtnsDesktop.save} />
                        &nbsp;
                        &nbsp;
                        <Button data={fileTabBtnsDesktop.cancel} handleClick={() => closePopup()} />
                    </Grid>
                    <Grid item xs={6} className='fileTab__btnRow-left'>
                        <Button
                            data={fileTabBtnsDesktop.file}
                            handleClick={() => {
                                setCurrentPopup('add');
                                handleShowPopUp()
                            }}
                        />
                    </Grid>
                </Grid>
                    : <Grid item xs={12} className='fileTab__btnRow'>
                        <Grid item xs={6} className='fileTab__btnRow-right'>
                            <Button data={fileTabBtnsTablet.save} />
                            &nbsp;
                            &nbsp;
                            <Button data={fileTabBtnsTablet.cancel} handleClick={() => closePopup()}/>
                        </Grid>
                        <Grid item xs={6} className='fileTab__btnRow-left'>

                            <Button data={fileTabBtnsTablet.startTimeCount} />
                            &nbsp;
                            &nbsp;
                            <Button
                                data={fileTabBtnsTablet.file}
                                handleClick={() => {
                                    setCurrentPopup('add');
                                    handleShowPopUp()
                                }}
                            />
                        </Grid>
                    </Grid>

            }
        </div>
    )
}
