import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFactory, setFactoryDetais } from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fab
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {
  daysEnglish,
  FACTORY_MPS_VIEW,
  FACTORY_DEFAULT,
} from "../../../constants/enum";
import { API } from '../../../tools/keys/keys'
import Autocomplete from "@material-ui/lab/Autocomplete";
import {uploadLogo} from '../../../functions/api/admin'
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {PRODUCTION, SERVICE} from '../../../constants/factories'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  formCheck: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const FactoryDetails = (props) => {
  const dispatch = useDispatch();
  const factory = useSelector((state) => state.factories.factoryDetails);

  useEffect(() => {
    if (props.id) {
      dispatch(getFactory(props.id));
    }
    return function cleanup() {
      dispatch(setFactoryDetais(null));
    };
  }, [props.id, dispatch]);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && factory && (
          <FactoryForm
            id={props.id}
            factory={factory}
            onSubmit={props.onSubmit}
          />
        )}
        {props.id === undefined && <FactoryForm id={props.id} factory={null} onSubmit={props.onSubmit}/>}
      </>
    </Grid>
  );
};
 
function FactoryForm(props) {
  const classes = useStyles();

  const { factory, id } = props;
  const [values, setValues] = useState({
    name: factory ? factory.name : "",
    timezone: factory ? factory.timezone : FACTORY_DEFAULT.timezone,
    working_hours: factory ? factory.working_hours : 0,
    order_quantity: factory
      ? factory.order_quantity
      : FACTORY_DEFAULT.order_quantity,
    process_duration: factory
      ? factory.process_duration
      : FACTORY_DEFAULT.process_duration,
    mps: factory ? factory.mps : FACTORY_DEFAULT.mps,
    mps_view: factory ? factory.mps_view : "",
    start_day: factory ? factory.start_day : 0,
    off_days: factory ? factory.off_days : [],
    create_factory_options: factory ? factory.create_factory_options : false,
    future_done: factory ? factory.future_done : false,
    factory_logo: factory ? factory.factory_logo : '',
    select_order_process_color_by: factory?.select_order_process_color_by || 'order',
    weekly_labour_cost: factory?.weekly_labour_cost || '',
    type: factory?.type || '',
    reason_popup: factory?.reason_popup || false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleDeleteLogo = () =>{
    setValues({ ...values, factory_logo:'' });
  }

  const uploadLogoHandler = async(element) =>{
    const formData = new FormData()
    formData.append('photo', element.files[0])
    const { result: factory_logo } = await uploadLogo(formData)
    setValues({...values, factory_logo })
  }

  
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });
  };

  const handleMultiSelect = (e, option) => {
    let value = option.length
      ? option.map((element) => {
          let index = daysEnglish.indexOf(element);
          if (index !== -1) {
            return index;
          }
          return index;
        })
      : [];
    setValues({ ...values, off_days: value });
  };

  const changeRadioButtonHandler = (e) => {
    setValues({...values, select_order_process_color_by: e.target.value})
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const {
      name,
      timezone,
      working_hours,
      order_quantity,
      process_duration,
      mps,
      mps_view,
      start_day,
      off_days,
      create_factory_options,
      future_done,
      factory_logo,
      select_order_process_color_by,
      weekly_labour_cost,
      type,
      reason_popup
    } = values;

    let model;

    if (id) {
      model = {
        _id: id,
        name,
        timezone,
        working_hours,
        order_quantity,
        process_duration,
        mps,
        mps_view,
        start_day,
        off_days,
        create_factory_options,
        future_done,
        factory_logo,
        select_order_process_color_by,
        weekly_labour_cost,
        type,
        reason_popup
      };
    } else{
      model = {      
        name,
        timezone,
        working_hours,
        order_quantity,
        process_duration,
        mps,
        mps_view,
        start_day,
        off_days,
        create_factory_options,
        future_done,
        factory_logo,
        select_order_process_color_by,
        weekly_labour_cost,
        type,
        reason_popup
      };
    }
    props.onSubmit(id, model);
  };


  return (
    <div>
      <form id="edit-factory" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
           
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
             {/* //TO DO  */}
             <div className="logo"></div>
          </Grid>
          <Grid item>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <TextField
              name="timezone"
              variant="outlined"
              label="timezone"
              value={values.timezone}
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="working_hours"
              variant="outlined"
              type="number"
              label="working hours"
              value={values.working_hours}
              onChange={handleInputChange}
            />
            <TextField
              name="order_quantity"
              variant="outlined"
              label="order quantity"
              value={values.order_quantity}
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="process_duration"
              variant="outlined"
              label="process duration"
              value={values.process_duration}
              InputProps={{
                perUser: true,
              }}
            />
            <TextField
              name="mps"
              variant="outlined"
              label="mps"
              value={values.mps}
              InputProps={{
                perUser: true,
              }}
            />
          </Grid> 
          <Grid item>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="mps_view_label">mps view</InputLabel>
              <Select
                labelId="mps_view_label"
                id="mps_view"
                value={values.mps_view}
                onChange={handleInputChange}
                label="mps view"
                name="mps_view"
              >
                {FACTORY_MPS_VIEW.map((name) => {
                  return (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="start_day_label">start day</InputLabel>
              <Select
                labelId="start_day_label"
                id="start_day"
                value={values.start_day}
                onChange={handleInputChange}
                label="start day"
                name="start_day"
              >
                {daysEnglish.map((name, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="tags-outlined"
              options={daysEnglish}            
              value={
                values.off_days.length
                  ? values.off_days.map((element) => {
                      return daysEnglish[element];
                    })
                  : []}
              onChange={(e, option) => handleMultiSelect(e, option)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="off days" />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              name="weekly_labour_cost"
              variant="outlined"
              label="weekly labour cost"
              type = 'number'
              value={values.weekly_labour_cost}
              onChange={handleInputChange}
            />
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="type">type</InputLabel>
              <Select
                labelId="type"
                // id="mps_view"
                value={values.type}
                onChange={handleInputChange}
                name="type"
              >
                    <MenuItem value={SERVICE}>{SERVICE}</MenuItem>
                    <MenuItem value={PRODUCTION}>{PRODUCTION}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid 
              container
              direction="row"
              justify="space-evenly"
              alignItems="center" >
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue={values.select_order_process_color_by}>
                  <FormControlLabel
                    value="order"
                    control={<Radio style ={{color:'rgb(0, 145, 255)'}} />}
                    label="order"
                    labelPlacement="start"
                    onChange = {changeRadioButtonHandler}
                  />
                  <FormControlLabel
                    value="user"
                    control={<Radio style ={{color:'rgb(0, 145, 255)'}} />}
                    label="user"
                    labelPlacement="start"
                    onChange = {changeRadioButtonHandler}
                  />
                </RadioGroup>
            </FormControl>
            <label>
              <TextField
                style={{ display: "none" }}
                type="file"
                onChange={(e) => uploadLogoHandler(e.target)}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                style={{
                  backgroundColor: '#0091ff',
                  color: 'white',
                  width: 30,
                  height: 30,
                  minHeight: 30,
                }}
              >
                <AddIcon 
                className={classes.colorForButtons}
                style={{ fontSize: 30 }} />
              </Fab>
            </label>
            {values.factory_logo && <img src = {`${API}${values.factory_logo}`}
                alt='logo'
                style ={{
                  'maxWidth': '140px',
                  width:'100%',
                  'maxHeight': '45px',
                  objectFit:'cover'
                }}/>}
            {values.factory_logo && <DeleteForeverOutlinedIcon  
                      onClick = {handleDeleteLogo}
                      style={{
                        color: '#0091ff',
                        width: 30,
                        height: 30,
                        minHeight: 30,
                      }}
                  />}
            </Grid>
          <Grid item>
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="future_done"
                  checked={values.future_done}
                  onChange={handleCheckboxChange}
                />
              }
              label="future done"
            />

            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="create_factory_options"
                  checked={values.create_factory_options}
                  onChange={handleCheckboxChange}
                />
              }
              label="create factory options"
            />

            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="reason_popup"
                  checked={values.reason_popup}
                  onChange={handleCheckboxChange}
                />
              }
              label="alerts"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
