export const POP_UPS_TRANSLATION ={
    ADD_A_SYSTEM: 'הוסף מערכת',
    ADD_A_TASK: 'הוסף משימה',
    ADD_A_FILE: 'הוסף קובץ',
    CUSTOMER_NAME: 'שם לקוח',
    ORDER_NUMBER: 'מספר הזמנה',
    ZAIN_BATOR: 'זאין בטור',
    SYSTEM_NAME: 'שם המערכת',
    LOCATION_DESCRIPTION: 'תיאור מיקום',
    FLOOR: 'קוֹמָה',
    CHOOSE: 'בחר',
    ENTER: 'להיכנס',
    UPLOADED_BY: 'הועלה על ידי',
    UPLOADED_DATE: 'תאריך העלאה',
    FILE_NAME: 'שם קובץ',
    FILE_TYPE: 'סוג קובץ',
    UPLOAD_FILE: 'העלה קובץ',
    UPLOADED_FILE: 'קובץ שהועלה',
    RESOURCE_NAME: 'שם המשאב',
    TASK_NAME: 'שם המטלה',
    STATUS: 'סטָטוּס',
    EDIT_TASK: 'ערוך משימה',
    DELETE_TASK: 'מחק את המשימה',
    DELETE_TASK_CONFIRMATION: 'האם אתה בטוח שברצונך למחוק את המשימה?',
    REMOVE: 'לְהַסִיר',
    STATUS_NOT_DONE: 'לא מוכן',
    EDIT_FILE: 'ערוך קובץ',
    DELETE_FILE: 'מחק קובץ',
    DELETE_FILE_CONFIRMATION: 'האם אתה בטוח שברצונך למחוק את הקובץ?',
    OPENED_FILE: 'קובץ נפתח',
    DELETE_SYSTEM: 'למחוק מערכת',
    DELETE_SYSTEM_CONFIRMATION: 'האם אתה בטוח שברצונך למחוק את המערכת?',
    SYSTEM_NAME_VALUE: 'בניית מערכת חשמל',
    REMOVES: 'מסיר',
    TASK_NAME_VALUE: 'יעקב בפטיסטה',
    EDIT_SYSTEM: 'עריכת מערכת',
    ADD_SIGN: 'הוסף סימן',
    DOWNLOAD: 'הורד',
    CLEAR_OUT: 'לנקות את',
    SIGNATURE: 'חֲתִימָה',
    REVIEW: 'סקירה',
    NUMBER_OF_TECHNICIAN: 'שם הטכנאי',
    COORDINATION_DATE: 'תאריך תיאום',
    SETTLEMENT: 'הֶסדֵר',
    INFO: 'מידע',
    DROR_HALPERIN: 'דרור הלפרין',
    STREET: 'רְחוֹב',
    CONTACT_PHONE_NUMBER: 'מספר טלפון ליצירת קשר',
    CHANGE_PASSWORD: 'שנה סיסמא',
    ADD_MEASUREMENTS: 'הוסף מידות',
    CM: 'ס"מ',
    PLEASE_ENTER: 'בבקשה היכנס',
    ADD_ORDER: 'הוסף הזמנה',
}