import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {useStyles} from '../style'

const SystemsTableRow = (props) => {
    const { system, setEditSystemDetailsActive, openEditCheckListHandler,
      openEditGeneralSectionHandler, setSystemFactoryId } = props
    const { _id, description, general_section_id, check_list_id, layer, factory_id } = system
    const classes = useStyles()

    return (<TableRow
      style={{ height: 33 }}
      hover
      tabIndex={-1}
      key={_id}
    >
      <TableCell component="th" scope="row" className = {classes.tableCell} onClick={() => setEditSystemDetailsActive(system)}>
        {_id}
      </TableCell>
      <TableCell component="th" scope="row" className = {classes.tableCell} onClick={() => setEditSystemDetailsActive(system)}>
        {description}
      </TableCell>
      <TableCell component="th" scope="row" className = {classes.tableCell} onClick={() => setEditSystemDetailsActive(system)}>
        {layer}
      </TableCell>
      <TableCell component="th" scope="row" className = {classes.tableCell} onClick={() => setEditSystemDetailsActive(system)}>
        {factory_id}
      </TableCell>
      <TableCell component="th" scope="row" className = {classes.tableCell} 
      onClick={(e) =>{ 
        openEditCheckListHandler(e, system)
        setSystemFactoryId(factory_id)
        }
        }>
        {check_list_id}
      </TableCell>
      <TableCell component="th" scope="row" className = {classes.tableCell} onClick={(e) => openEditGeneralSectionHandler(e, system)}>
        {general_section_id}
      </TableCell>
    </TableRow>
    );
  }

  export default SystemsTableRow