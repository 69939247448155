import React from "react";
import TableBody from "@material-ui/core/TableBody";
import SystemsTableRow from './SystemsTableRow'


const SystemsTableBody = (props) => {
    const {
      systems,
      openEditCheckListHandler,
      openEditGeneralSectionHandler,
      setEditSystemDetailsActive,
      setSystemFactoryId
    } = props
  
    return (<TableBody>
      {systems.map((system) => {
        return (<SystemsTableRow
          key={system._id}
          openEditCheckListHandler={openEditCheckListHandler}
          openEditGeneralSectionHandler={openEditGeneralSectionHandler}
          system={system}
          setEditSystemDetailsActive={setEditSystemDetailsActive}
          setSystemFactoryId={setSystemFactoryId}
        />);
      })}
    </TableBody>)
  }

  export default SystemsTableBody