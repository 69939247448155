import * as types from "./types";
import { polyfill } from "es6-promise";
polyfill();

export const setExpandedSystem = (data) => ({
    type: types.SET_EXPANDED_SYSTEM, 
    payload: data
  });

// export const  = (maxLayer) => ({
//   type: types.SET_MAX_LAYER,
//   payload: maxLayer
// })

