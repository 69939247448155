import React, { useEffect, useState } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField'
import {INPUT_NAME, MULTIPLE} from '../../../../constants/translations/admin-systems'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { REG_EXP_OBJECT_ID } from "../../../../constants/admin-systems";

const EditInputName = (props) => {
    const {input, changeNameHandler, setMultiple, multiple} = props
    const {name, _id} = input
    const [disabled, setDisabled] = useState(true)
  
    useEffect(() => {
      if(_id?.match(REG_EXP_OBJECT_ID)) setDisabled(true)
      else setDisabled(false)
    },[_id])
  
    return<div style ={{width:'100%', display:'flex', justifyContent:'space-between', margin:'15px 0'}}>
      <TextField
        InputProps={{ disableUnderline: true }}
        variant = 'outlined'
        label = {INPUT_NAME}
        value = {name || ''} 
        onChange = {e => changeNameHandler(e.target.value)}
      />
      <FormControlLabel
        control = {<Checkbox
            checked={multiple}
            onChange={e => setMultiple(e.target.checked)}
            disabled = {disabled}
        />}
          label = {MULTIPLE}
          labelPlacement = 'start'
        />
  
    </div>
  
  }

export default EditInputName