import * as React from "react";

export const SmallLoader = ({ color, left }) => (
    <div className="bids-loader" style={{ left: left && left }}>
        <div style={{ background: color && color }} />
        <div style={{ background: color && color }} />
        <div style={{ background: color && color }} />
        <div style={{ background: color && color }} />
    </div>
)
