import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import MobileHeader from '../Header/MobileHeader'
import Footer from '../Footer/Footer'
import DetailsTab from '../../TabletView/DetailsTab/DetailsTab';
import "./DetailsTabMobile.scss";
import { SYSTEM_DETAILS } from '../../../../constants/translations/review-popup';

const footerData = {
  field: 'details',

}
export default function DetailsTabMobile(props) {
  const { handleClose, orderId, process, view, dataInfoInputValue, backlog, isParallelProcess, closePopup, updateErrorPopup } = props;

  const [showPopUp, setShowPopUp] = useState(false);
  
  function handleShowPopUp() {
    setShowPopUp(!showPopUp)
  }


  console.log('process -------------------- ', process);


  return (
    <Grid spacing={0} className='DetailMobile__main'>
      <MobileHeader
        headerName={SYSTEM_DETAILS} 
        processName={process && process.process_name || 'Installation'}
        customerName={process && process.client_name || 'Zain bator'}
        orderNumber={process && process.order_number || ' S02100004B'}
        handleClose={handleClose}
        city={process && process.city}
      />
      <DetailsTab
        showPopUp={showPopUp}
        handleShowPopUp={handleShowPopUp}
        view={view}
        startTime={process && process.startTime}
        endTime={process && process.endTime}
        startDate={process && process.process_date}
        endDate={process && process.process_endDate}
        orderId={orderId && orderId}
        processDuration={process && process.process_duration}
        closePopup={closePopup && closePopup}
        handleSubmit={() => { }}
        dataInfoInputValue={dataInfoInputValue}
        backlog={backlog}
        isParallelProcess={isParallelProcess}
        process={process && process}
        updateErrorPopupProcess={updateErrorPopup}
      />
      {/* <Footer data={footerData}  handleClose={handleClose}/> */}
    </Grid>
  )
}
