import { SET_ROUTE_LOCATION } from "./types";
import { polyfill } from "es6-promise";
polyfill();

export const setRouteLocation = route => async dispatch => {
  dispatch({
    type: SET_ROUTE_LOCATION,
    payload: route
  });
};




