import { TABLET_VIEW_TRANSLATION } from "../../../../constants/translations/customer/tablet/tabletView";
import { DAILY_CALENDAR } from "../../../../constants/translations/dailyCalendar";
import { CANCEL, SAVE } from "../../../../constants/translations/review-popup";

export const detailsTabInput = {
    startDate: {
        label: TABLET_VIEW_TRANSLATION.START_DATE,
        placeholder: '14/06/2023',
        value: '14/06/2023',
        type: 'date',
        name: 'startDate'
    },
    endDate: {
        label: TABLET_VIEW_TRANSLATION.END_DATE,
        placeholder: '14/06/2023',
        value: '14/06/2023',
        type: 'date',
        name: 'endDate'

    },
    startTime: {
        label: DAILY_CALENDAR.START_TIME,
        placeholder: '00 30:10',
        value: '00 30:10',
        type: 'time',
        width: '100%',
        name: 'startTime'

    },
    endTime: {
        label: DAILY_CALENDAR.END_TIME,
        placeholder: '00 30:10',
        value: '00 30:10',
        type: 'time',
        width: '100%',
        name: 'endTime'

    },
    totalHours: {
        label: TABLET_VIEW_TRANSLATION.TOTAL_HOURS,
        placeholder: 0,
        value: 0,
        type: 'number',
        width: '80%',
        name: 'totalHours'
    },
};

export const detailsTabBtns = {
    sign: {
        name: TABLET_VIEW_TRANSLATION.SIGN,
        width: '100%',
        height: '70px',
        color: '#186315',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: true,
        iconContainer: false,
        iconContainerColor: '#fff',
        iconContainerWidth: '22px',
        border: true,
        borderRadius: '90px',
        buttonName: 'sign'
    },
    startTimeCount: {
        name: TABLET_VIEW_TRANSLATION.START_TIME_COUNT,
        width: '40%',
        height: '70px',
        color: '#455768',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        iconContainerWidth: '32px',
        iconContainerColor: '#F67288',
        border: true,
        borderColor: '#F67288',
        borderRadius: '90px',
        buttonName: 'Stop'
    },
    save: {
        name: SAVE, //'Save',
        width: '30%',
        height: '70px',
        color: '#fff',
        backgroundColor: "#0091FF",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        borderRadius: '90px',
        isBoxShadow: true,
    },
    cancel: {
        name: CANCEL, //'Cancel',
        width: '30%',
        height: '70px',
        color: '#0091FF',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        borderRadius: '90px',
        isBoxShadow: true,

    }


}


export const detailsTabBtnsMobile = {
    sign: {
        ...detailsTabBtns.sign,
        height: '42px',
        fontSize: '12px',
        iconContainerWidth: '18px',
        borderRadius: '12px',
        padding: '0px 24px',
    },
    save: {
        ...detailsTabBtns.save,
        width: '42%',
        height: '36px',
        fontSize: '12px',
        borderRadius: '20px',
        padding: '14px 16px',
    },
    cancel: {
        ...detailsTabBtns.cancel,
        width: '42%',
        height: '36px',
        fontSize: '12px',
        borderRadius: '20px',
        padding: '14px 16px',
    },
}
export const detailsTabBtnsDesktop = {
    save: {
        ...detailsTabBtns.save,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        padding: '14px 16px',
    },
    cancel: {
        ...detailsTabBtns.cancel,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        padding: '14px 16px',
    },
}
export const detailsTabBtnsTablet = {
    startTimeCount: {
        ...detailsTabBtns.startTimeCount,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        // padding: '14px 16px',
        iconContainerWidht: '26px',
        width: '50%',
    },
    save: {
        ...detailsTabBtnsDesktop.save,
        with: '35%'
    },
    cancel: {
        ...detailsTabBtnsDesktop.cancel,
        with: '35%'
    },
}
