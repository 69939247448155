import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import React, { Component } from 'react'
import GeneralDropdown from '../../generals/generalDropdown/GeneralDropdown'
import { connect } from "react-redux"
import * as actions from "../../../actions/actions"
import ReportsTable from './parts/ReportTable'
import SideBar from "../../SideBar/SideBar";
import { Grid } from "@material-ui/core";

class OrderProcessesReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ganttTemplatesOptions: [],
            ganttTemplateTypeSelection: "all",
            reportsDataByGanttTemplateType: null,
        }
    }

    componentDidMount() {
        this.props.getGanttTemplatesByFactoryId();
    }

    updatefilterOptions = (e, selection) => {
      if (selection.name === 'all') {
        this.setState({
          ganttTemplateTypeSelection:  'all'
        })
      } else {
        this.setState({
          ganttTemplateTypeSelection:  selection._id.toString(),
      });
      }
    }

    render() {
        return (
          <React.Fragment>
            <div className="backlogs-reports">
              <Grid container>
                <Grid
                  //xs={2}
                  style={{ width: "150px" }}
                >
                  <SideBar />
                </Grid>
                <Grid
                  // xs={10}
                  style={{ width: "calc(100% - 150px)" }}
                >
                  <section className="backlogs-reports__main">
                    <header>
                      <span>
                        {/* <p>דו״ח השלמות</p> */}
                        <p style={{marginLeft: '10px'}}>תבנ ית גאנט</p>
                        <GeneralDropdown
                          options={[{ _id: -1, name: 'all'} , ...this.props.factories.ganttTemplateTypes]}
                          dynamicValueForMapping={"name"}
                          updateFormData={this.updatefilterOptions}
                          value={
                            this.state.ganttTemplateTypeSelection
                          }
                        />
                      </span>
                      <p style={{float:'right'}}>סך ההזמנות - {this.props.orders?.orderProcessesReport?.report?.result?.rows.length} </p>
                    </header>
                    {
                      <ReportsTable
                        ganttTemplateType={
                          this.state.ganttTemplateTypeSelection
                        }
                        orderId={
                          this.props.orders.orderNumberForOrderProcessReport
                        }
                      ></ReportsTable>
                    }
                  </section>

                  {/* TABLE RENDER */}
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        );
    }
}

function mapStateToProps({login, process, orders, factories}) {
    return { login, process, orders, factories};
}

const mapDispatchToProps = {};


export default withRouter(connect(mapStateToProps, actions)(OrderProcessesReport));