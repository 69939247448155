import axios from "axios";
import { API } from "../../tools/keys/keys";
import { getCookie } from "../../tools/cookies/cookie";
import moment from "moment";
import { polyfill } from "es6-promise";
import { updateOfflineTestFloorCheckList } from "./orders";

polyfill();

export const deleteActualSystem = async (actual_system_id, customer_id, current_order_id) =>  new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
  
    const headers = {
        'access-token': token_from_cookie
        }
  
    axios.get(`${API}/system/order-systems/delete-actual-system?order_id=${current_order_id}&customer_id=${customer_id}&actual_system_id=${actual_system_id}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
  
        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })

  export const editActualSystem = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/order-systems/edit-actual-system`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };

//   export const deleteSubSystem = async (actual_sub_system_id , order_id, actual_system_id) =>  new Promise(resolve => {
//     const token_from_cookie = getCookie("login_cookie")
  
  
//     const headers = {
//         'access-token': token_from_cookie
//         }
  
//     axios.get(`${API}/system/order-systems/delete-sub-system?actual_sub_system_id=${actual_sub_system_id}&order_id=${order_id}&actual_system_id=${actual_system_id}`, {headers}).then(res => {
//         const {
//             ok,
//             result
//         } = res.data
//         const output = {
//             ok,
//             result
//         }
  
//         if(ok){
//             resolve(output)
//         }else{
//             resolve(false)
//         }
//     }).catch(err => {
//         resolve({ ok: false })
//     })
//   })

  // export const editSubSystem = async (body) => {
  //   const token_from_cookie = getCookie("login_cookie");
  //   const headers = {
  //     "access-token": token_from_cookie,
  //   };
  //   const url = `${API}/system/order-systems/edit-sub-system`;
  //   try {
  //     const res = await axios({
  //       method: "post",
  //       url,
  //       headers,
  //       data: body,
  //     });
  //     return res.data;
  //   } catch (err) {
  //     console.log("Error has been occured in POST request", err);
  //     if (err) return undefined;
  //   }
  // };


  // --mobile---------------------------------------------------

  export const dropSystem = async (actual_system_id, customer_id, current_order_id) =>  new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
  
    const headers = {
        'access-token': token_from_cookie
        }
  
    axios.get(`${API}/system/order-systems/delete-system-from-popup?order_id=${current_order_id}&customer_id=${customer_id}&actual_system_id=${actual_system_id}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
  
        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })

  export const editSystem = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/order-systems/edit-system-from-popup`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return {ok: true,
                       is_checked: 'pending',
                       result: body
                      };
    }
  };

  // export const deleteActualSubSystemMobile = async (_id, order_id, actualSystemId) =>  new Promise(resolve => {
  //   const token_from_cookie = getCookie("login_cookie")
  
  
  //   const headers = {
  //       'access-token': token_from_cookie
  //       }
  
  //   axios.get(`${API}/system/order-systems/delete-actual-sub-system-mobile?_id=${_id}&order_id=${order_id}&actual_system_id=${actualSystemId}`, {headers}).then(res => {
  //       const {
  //           ok,
  //           result
  //       } = res.data
  //       const output = {
  //           ok,
  //           result
  //       }
  
  //       if(ok){
  //           resolve(output)
  //       }else{
  //           resolve(false)
  //       }
  //   }).catch(err => {
  //       resolve({ ok: false })
  //   })
  // })


  export const getBadShowStoppers = async (order_id) =>  new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
    const headers = {
        'access-token': token_from_cookie
        }
  
    axios.get(`${API}/system/order-systems/get-bad-show-stoppers?order_id=${order_id}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
  
        if(ok){
            resolve(result)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })


  export const updateTasksPerFloor = (tasks, offline=false) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
    const { template_id, test_id , order_id, actual_system_id} = tasks;
  
    const headers = {
        'access-token': token_from_cookie
        }
    
    const url = `${API}/system/order-systems/update-tasks-per-floor`
  
    axios({
        method: "post",
        url,
        headers,
        data:tasks,
      }).then(res => {
        const {
            ok,
            result
        } = res.data
          
        if(ok){
            resolve(result)
        }else{
            resolve(false)
        }
        !offline && updateOfflineTestFloorCheckList(null, template_id, test_id, order_id, actual_system_id, tasks,false);
    }).catch(err => {
        !offline && updateOfflineTestFloorCheckList(null, template_id, test_id, order_id, actual_system_id, tasks,true);
        resolve({ ok: false })
    })
  })

  

export const deleteSystemChecklist = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/admin/systems/delete-check-list`;
  try {
    const res = await axios({
      method: "delete",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in DELETE request", err);
    if (err) return {
      ok: true,
      is_checked: 'pending',
      result: body
    };
  }
};
  
  