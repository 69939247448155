import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TextField from '@material-ui/core/TextField'
import {useStyles} from '../style'

const CustomizedTextInput = (props) => {
    const { value, changeHandler, width , type } = props
    const classes = useStyles()
    let isHp = (typeof value === 'string') && (value.includes('HP') && (value.includes('WK') || value.includes('KW')));
    const hp_style = {
      direction: 'ltr',
      width: width
    }
    return (
      <TableCell className={classes.selectTableCell}>
        <TextField
          style={ isHp ? hp_style : { width }}
          className={isHp ? classes.hp_class : ''}
          InputProps={{ disableUnderline: true }}
          value={value}
          onChange={(e) => changeHandler(e.target.value)}
          type = {type}
        />
      </TableCell>
    )
  }

export default CustomizedTextInput