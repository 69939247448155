import * as types from "./types";
import axios from "axios";
import { api, createHeaders } from "../constants/api-urls";


export const changeCurrentPage = (page) => ({
  type: types.RECIPIENTS_CHANGE_CURRENT_PAGE,
  payload: page, 
});

export const changeRowsPerPage = (count) => ({
  type: types.RECIPIENTS_CHANGE_ROWS_PER_PAGE, 
  payload: count,
});

const getByFilterRecipientsRequest = () => ({
  type: types.ADMIN_GET_FILTER_RECIPIENTS_REQUEST,
});

const getByFilterRecipientsSuccess = (value) => ({
  type: types.ADMIN_GET_FILTER_RECIPIENTS_SUCCESS,
  payload: value,
});

const getByFilterRecipientsFailure = (error) => ({
  type: types.ADMIN_GET_FILTER_RECIPIENTS_ERROR,
  payload: {
    error,
  },
});

export const getByFilterRecipients = ( 
  limit = 20,
  page = 0,
  sort = -1,
  factory_id 
) => {
  return (dispatch) => {
    dispatch(getByFilterRecipientsRequest());
    let filter = `limit=${limit}&page=${page}&sort=${sort}${factory_id ? `&factory_id=${factory_id}` : ``}`;

    let url = `${api.admin.recipients.getByFilter(filter)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result, count } = res.data;
        dispatch(getByFilterRecipientsSuccess({ result: result, count: count }));
      })
      .catch((err) => {
        dispatch(getByFilterRecipientsFailure(err.message));
      });
  };
};
const getAllRecipientRequest = () => ({
  type: types.GET_ALL_RECIPIENTS_REQUEST,
});

const getAllRecipientSuccess = (factories) => ({
  type: types.GET_ALL_RECIPIENTS_SUCCESS,
  payload: factories,
});

const getAllRecipientFailure = (error) => ({
  type: types.GET_ALL_RECIPIENTS_ERROR,
  payload: { 
    error,
  },
});

export const getAllRecipient = () => {
  return (dispatch) => {
    dispatch(getAllRecipientRequest());
    let url = `${api.admin.recipients.allRecipients}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getAllRecipientSuccess(result));
      })
      .catch((err) => {
        dispatch(getAllRecipientFailure(err.message));
      });
  };
};

const getRecipientRequest = () => ({
  type: types.GET_BY_ID_RECIPIENT_REQUEST,
});

const getRecipientSuccess = (department) => ({
  type: types.GET_BY_ID_RECIPIENT_SUCCESS,
  payload: department,
});

const getRecipientFailure = (error) => ({
  type: types.GET_BY_ID_RECIPIENT_ERROR,
  payload: {
    error,
  },
});

export const getRecipient = (id) => {
  return (dispatch) => {
    dispatch(getRecipientRequest());
    let url = `${api.admin.recipients.getById(id)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getRecipientSuccess(result));
      })
      .catch((err) => {
        dispatch(getRecipientFailure(err.message));
      });
  };
};

export const setRecipientDetais = (factory) => {
  return {
    type: types.SET_RECIPIENT,
    payload: factory,
  };
};

export const createRecipient = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(createRecipientRequest());
    let url = `${api.admin.recipients.create}`;
    const headers = createHeaders();
    dispatch(changeCurrentPage(0));
    dispatch(changeRowsPerPage(10));
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(createRecipientSuccess(ok));
        dispatch(getByFilterRecipients(10,0,-1,filterFactory)); 
        //dispatch(getRecipients(filterFactory));
      })
      .catch((err) => {
        dispatch(createRecipientFailure(err.message));
      });
  };
};

const createRecipientRequest = () => ({
  type: types.RECIPIENT_CREATE_REQUEST,
});

const createRecipientSuccess = (isCreate) => ({
  type: types.RECIPIENT_CREATE_SUCCESS,
});

const createRecipientFailure = (error) => ({
  type: types.RECIPIENT_CREATE_ERROR,
  payload: {
    error,
  },
});

export const updateRecipient = (data, filterFactory = null) => {
  return (dispatch) => {
    dispatch(updateRecipientRequest());
    let url = `${api.admin.recipients.update}`;
    const headers = createHeaders();
    dispatch(changeCurrentPage(0));
    dispatch(changeRowsPerPage(10));
    axios
      .post(url, data, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(updateRecipientSuccess(ok));
       //dispatch(getRecipients(filterFactory));
       dispatch(getByFilterRecipients(10,0,-1,filterFactory)); 
      })
      .catch((err) => {
        dispatch(updateRecipientFailure(err.message));
      });
  };
};

const updateRecipientRequest = () => ({
  type: types.RECIPIENT_UPDATE_REQUEST,
});

const updateRecipientSuccess = (isUpdate) => ({
  type: types.RECIPIENT_UPDATE_SUCCESS,
});

const updateRecipientFailure = (error) => ({
  type: types.RECIPIENT_UPDATE_ERROR,
  payload: {
    error,
  },
});

const getRecipientByFactoryRequest = () => ({
  type: types.RECIPIENTS_GET_BY_FACTORY_ID_REQUEST,
});

const getRecipientByFactorySuccess = (subDepartments) => ({
  type: types.RECIPIENTS_GET_BY_FACTORY_ID_SUCCESS,
  payload: subDepartments,
});

const getRecipientByFactoryFailure = (error) => ({
  type: types.RECIPIENTS_GET_BY_FACTORY_ID_ERROR,
  payload: {
    error,
  },
});

export const getRecipientByFactory = (factory_id) => {
  return (dispatch) => {
    dispatch(getRecipientByFactoryRequest());
    let url = `${api.admin.recipients.getByFactoryId(factory_id)}`;
    const headers = createHeaders();
    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        dispatch(getRecipientByFactorySuccess(result));
      })
      .catch((err) => {
        dispatch(getRecipientByFactoryFailure(err.message));
      }); 
  };
};

export const getRecipients = (idFactoryId = null) => {
  return (dispatch) => {
    if (idFactoryId) {
      dispatch(getRecipientByFactory(idFactoryId));
    } else {
      dispatch(getAllRecipient());
    }
  };
};
