import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core";
import { updateProcess } from "../../../actions/actions";
import { useModal } from "../../../hooks/useModal";
import { getModalProps } from "../../modal/modalProps";

const headTableConfig = [
  {
    id: "factory_id",
    label: "id factory",
    type: "string",
  },
  {
    id: "factory_name",
    label: "name factory",
    type: "string",
  },

  {
    id: "employees",
    label: "employees",
    type: "array",
    funcHandler: () => {},
  },
  {
    id: "employees_name",
    label: "employees name",
    type: "array",
    funcHandler: () => {},
  },

  {
    id: "collection_stages",
    label: "collection_stages",
    type: "array",
    funcHandler: () => {},
  },

  {
    id: "user_id",
    label: "user id",
    type: "string",
  },

  {
    id: "user_name",
    label: "user name",
    type: "string",
  },

  {
    id: "active",
    label: "active",
    type: "boolean",
  },
  {
    id: "address",
    label: "address",
    type: "string",
  },

  {
    id: "city",
    label: "city",
    type: "string",
  },

  {
    id: "cancelled",
    label: "cancelled",
    type: "boolean",
  },
  {
    id: "client_name",
    label: "client_name",
    type: "string",
  },
  {
    id: "client_phone",
    label: "client_phone",
    type: "string",
  },

  {
    id: "type",
    label: "type",
    type: "string",
  },
  {
    id: "color",
    label: "color",
    type: "color",
  },

  {
    id: "due_date",
    label: "due date",
    type: "date",
  },
  {
    id: "order_number",
    label: "order number",
    type: "string",
  },
  {
    id: "quantity",
    label: "quantity",
    type: "number",
  },
  {
    id: "second_phone",
    label: "second phone",
    type: "string",
  },
  {
    id: "value",
    label: "value",
    type: "number",
  },

  {
    id: "_id",
    label: "id",
    type: "id",
  },
  {
    id: "name",
    label: "name",
    type: "string",
  },
  {
    id: "process_dates",
    label: "process dates",
    type: "string",
  },
  {
    id: "custom_fields",
    label: "custom fields",
    type: "string",
  },
  {
    id: "custom_inputs",
    label: "custom inputs",
    type: "string",
  },
  {
    id: "custom_process",
    label: "custom process",
    type: "string",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const StyledHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243748",
    color: theme.palette.common.white,
    left: 'unset'
  },
  body: {
    fontSize: 14,
    fontFamily: "Rubik",
  },
}))(TableCell);

function TableHeadOrg(props) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headTableConfig.map((headCell) => (
          <StyledHeadCell
            component="th"
            scope="row"
            key={headCell.id}
            align={headCell.align}
          >
            <div className={classes.headCell}>{headCell.label}</div>
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableOrder(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orderlist } = props;
  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const { closeModal, setModalProps } = useModal();

    const handleEdit = useCallback(
    
    );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeadOrg />
            <TableBody>
              {orderlist &&
                orderlist.map((element) => {
                  return (
                    <TableRow
                      style={{ height: 33 }}
                      hover
                      tabIndex={-1}
                      key={element._id}
                      onClick={() => handleEdit(element._id)}
                    >
                      {headTableConfig.map((col) => {
                        let value = element[col.id];

                        switch (col.id) {
                          case "process_dates":
                          case "employees":
                          case "collection_stages":
                          case "custom_process":
                          case "custom_inputs":
                          case "custom_fields":
                          case "process_dates":
                            if (element[col.id])
                              value = element[col.id].length
                                ? element[col.id].join(",")
                                : element[col.id];
                            break;
                          case "active":
                          case "cancelled":
                          case "started":
                            value =
                              String(element[col.id]) === "undefined"
                                ? ""
                                : String(element[col.id]);
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell component="th" scope="row" key={col.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

