import { useEffect, useState } from "react";
import { generalGetRequest } from '../functions/api/general'
import { useSelector, useDispatch } from 'react-redux'
import {systemSuccessfullyAdded} from '../actions/systems-actions'

export const useSystems = (factoryId) => {
  const [systems, setSystems] = useState([])
  const queryTemplate = {
    page: 0,
    rowsPerPage: 10,
    factoryId: ''
  }
  const [query, setQuery] = useState(queryTemplate)
  const [systemsQuantity, setSystemsQuantity] = useState(0) /* quantity of all items  */

  const newSystem = useSelector(state => state.adminSystems.newSystem)
  const dispatch = useDispatch()

  useEffect(() => {
    const getSystems = async () => {
      const {page, rowsPerPage, factoryId} = query
      const responce = await generalGetRequest(`/admin/systems/get-all?$skip=${page * rowsPerPage}&$limit=${rowsPerPage}&factory_id=${factoryId || ''}`)
      if (responce?.result){
        const [systems, quantity] = responce?.result
        setSystems(systems)
        setSystemsQuantity(quantity)
      } 
    }
    getSystems()
  }, [query])

  useEffect(() => {
    const newQuery = {...queryTemplate, factoryId: factoryId ? factoryId : ''}
    const oldFactoryId = queryTemplate.factoryId
    const newFactoryId = newQuery.factoryId
    
    if(oldFactoryId !== newFactoryId) setQuery(newQuery)

  },[factoryId])

  useEffect(() => {
    const addSystem = (system) => {
      const newSystems = [...systems, system]
      setSystems(newSystems)
      dispatch(systemSuccessfullyAdded())
    }
    newSystem && addSystem(newSystem)
  }, [newSystem])

  const setPage = (page) => {
    const newQuery = {...query, page}
    setQuery(newQuery)
  }

  const setRowsPerPage = (rowsPerPage) => {
    const newQuery = {...query, rowsPerPage}
    setQuery(newQuery)
  }


return ({
  systems, setSystems, setPage, setRowsPerPage, systemsQuantity, ...query
})

}
