import * as types from '../actions/types'
  
  
  const initialState = {
    searchQuery: "",
    filterTasksQuery:'',
    tasksAutocompleteOptions:''
  }
  
  export default function (state = initialState, action) {
  
    switch (action.type) {
      
      case types.SET_TASKS_REPORT_VALUE:
        return {
          ...state,
          filterTasksQuery: action.payload
        } 
      case types.SET_TASKS_REPORT_FILTER_QUERY:
        return {
          ...state,
          searchQuery: action.payload
        }  
      case types.SET_TASKS_REPORT_SEARCH_OPTIONS:
        return  {
          ...state,
          tasksAutocompleteOptions: action.payload
        }  
      default:
        return state
    }
  }