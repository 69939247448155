import React, { useState } from "react";
// import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useStyles } from '../style'
// import TabList from "@material-ui/lab/TabList"
// import TabContext from "@material-ui/lab/TabContext";

const InputsSelect = (props) => {
  const { inputTypes, setInputType, defaultValue } = props
  const classes = useStyles();
  // const [value, setValue] = useState(defaultValue);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   setInputType(inputTypes[newValue])
  // };


  const handleChange = (newValue) => {
    const {input_number: inputNumber} = newValue
    // setValue(inputNumber);
    setInputType(inputNumber)
  };

  return (
    <div style ={{width:'100%', height:'80px', overflow:'auto', display:'flex', alignItems:'center'}}>
        {inputTypes.map((i, idx) => (
          <div style={{
                        height:'40px',
                        border:'1px solid black',
                        padding:'10px',
                        margin:'0 10px', 
                        // width: '100px',
                        cursor:'pointer',
                        backgroundColor: i.input_number === defaultValue ? '#0091ff' : ''
                      }} 
                        key={idx} 
                        onClick={()=>handleChange(i)}>
                  <span
                    style = {{
                      display: 'flex',
                      width: 'max-content'
                    }}
                  >{i.name || i.input_number}</span>
          </div>
                ))}
    </div>
    // <AppBar position="static" color="default" style ={{minHeight:0}}>
    //   <Tabs
    //     value={value}
    //     onChange={handleChange}
    //     indicatorColor="primary"
    //     textColor="primary"
    //     variant="scrollable"
    //     scrollButtons = 'auto'
    //     // allowScrollButtonsMobile
    //     classes = {{root: classes.inputNameSelect, scrollButtons: classes.scrollButtons}}
    //   >
    //     {inputTypes.map((i) => (
    //       <Tab label={i} />
    //     ))}
    //   </Tabs>
    // </AppBar>
    // <div>
    // <TabContext>
    //   <AppBar position="static" color="default" style={{ minHeight: 0 }}>
    //     <TabList
    //       value={value}
    //       onChange={handleChange}
    //       indicatorColor="primary"
    //       textColor="primary"
    //       variant="scrollable"
    //       scrollButtons='auto'
    //       // allowScrollButtonsMobile
    //       classes={{ root: classes.inputNameSelect, scrollButtons: classes.scrollButtons }}
    //     >
    //       {inputTypes.map((i) => (
    //         <Tab label={i} />
    //       ))}
    //     </TabList>
    //   </AppBar>
    // </TabContext>
    // </div>
  );
}


export default InputsSelect