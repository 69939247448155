import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import Button from '../../../Buttons/Button';
import Input from '../../../InputFields/Input';
import SelectInput from '../../../InputFields/SelectInput/SelectInput';
import "../AddSystem/AddSystemPopUp.scss";
import "./AddFilePopUp.scss"
import UploadFileButton from '../../../Buttons/UploadFileButton/UploadFileButton';
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import { ADD, CANCEL } from '../../../../../constants/translations/review-popup';
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import moment from 'moment';


const add = {
    name: ADD,
    width: '35%',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '24px',
    fontWeight: 800,
    icon: false,
    inconContainer: false,
    border: false,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}
const cancel = {
    name: CANCEL,
    width: '35%',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    inconContainer: false,
    border: true,
    isBoxShadow: true,
    height: '70px',
    borderRadius: '90px',
}

const addBtnDesktop = {
    ...add,
    fontSize: '16px',
    borderRadius: '20px',
    height: '52px',
    width: '42%',
}
const cancelBtnDesktop = {
    ...cancel,
    fontSize: '16px',
    borderRadius: '20px',
    height: '52px',
    width: '42%',
}

const addFileInputs = {
    fileName: {
        label: POP_UPS_TRANSLATION.FILE_NAME,
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        type: 'number',
    },
    fileType: {
        label: POP_UPS_TRANSLATION.FILE_TYPE,
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        type: 'select',
    },

}
export default function AddFilePopUp(props) {
    const { showPopUp, handleShowPopUp, name, view, process } = props;
    const [file, setFile] = useState(null);

    const handleFileUpload = (e) => {
        setFile(e.name)
    }
    const extractFileName = (fileName) => {
        let newFileName = fileName.split(" ")[0]
        return newFileName;
    }
    const addBtnTablet = {
        ...add,
        backgroundColor: file ? '#0091FF' : '#CACBCC'
    }
    return (
        <Grid className='addSystem__main'>
            <Grid xs={12}>
                <h4 className='addSystem__title'>{name}</h4>
            </Grid>
            <Grid className='addSystem__uploadedInfo'>
                <Grid xs={12} spacing={0} className='addSystem__uploadedInfo-row'>
                    <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.UPLOADED_BY}</span></Grid>
                    <Grid xs={6} className='addSystem__uploadedInfo-value'><span>{process && process.client_name || POP_UPS_TRANSLATION.ZAIN_BATOR}</span></Grid>
                </Grid>
                <Grid xs={12} className='addSystem__uploadedInfo-row'>
                    <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.UPLOADED_DATE}</span></Grid>
                    <Grid xs={6} className='addSystem__uploadedInfo-value'><span>{moment(process && process.process_date).format("DD/MM/YYYY") || '17/08/23'}</span></Grid>
                </Grid>
            </Grid>
            <Grid sx={12} className='addSystem__inputsFields'>
                <Grid>
                    <Input data={addFileInputs.fileName} />

                </Grid>
                <Grid className='addSystem__inputsRow addFile__inputRow--margin'>
                    <SelectInput data={addFileInputs.fileType} />
                </Grid>
            </Grid>
            <Grid xs={12} className='addfile__uploadFile'>
                {
                    file ? <Grid xs={12} className='addFile__uploaded-contianer'>
                        <label>{POP_UPS_TRANSLATION.UPLOADED_FILE}</label>
                        <Grid xs={12} className='addFile__uploaded-infoContainer'>
                            <Grid xs={6} className='addFile__titleType'>
                                <span className='addFile__icon-container'> <DescriptionIcon className="addFile__uploaded-icon" /></span>
                                <label>{extractFileName(file)}</label>
                            </Grid>
                            <Grid xs={6} className='addFile__remove'>
                                <span className='addFile__icon-container' onClick={() => setFile(null)} > <ClearIcon className="addFile__uploaded-icon" /></span>
                            </Grid>

                        </Grid>
                    </Grid>
                        : <Grid sx={12} className='addSystem__inputsFields'>
                            <UploadFileButton handleFileUpload={handleFileUpload} view="tablet" />
                        </Grid>
                }
            </Grid>
            <Grid sx={12} className='addSystem__btn__container '>
                <Button data={addBtnTablet} handleClick={() => handleShowPopUp()} />
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                <Button data={cancelBtnDesktop} handleClick={() => handleShowPopUp()} />
            </Grid>
        </Grid>
    )
}
