import axios from "axios";
import { API } from "../../tools/keys/keys";
import { getCookie } from "../../tools/cookies/cookie";
import { polyfill } from "es6-promise";

polyfill();

export const deleteTask = (orderProcessId, taskId) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
  
    const headers = {
        'access-token': token_from_cookie
        }
  
    axios.get(`${API}/system/order-process/delete-task?order_process_id=${orderProcessId}&task_id=${taskId}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        
        if(ok){
            resolve(result)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })

  export const setTaskDone = (orderProcessId, taskId, value) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
  
    const headers = {
        'access-token': token_from_cookie
        }
  
    axios.get(`${API}/system/order-process/set-task-done?order_process_id=${orderProcessId}&task_id=${taskId}&value=${value}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
  
        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })

  export const uploadFiles = async(body, customerId) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
    // axios(`${API}/system/order-systems/upload-file`, {headers} )
    const headers = {
        'access-token': token_from_cookie
        }
  
        const url = `${API}/system/customer-page/upload-multiple-files?customer_id=${customerId}`    
  
        const res = axios({
          method: "post",
          url,
          headers,
          data:body,
        }).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
  
        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })


  export const saveTask = (orderProcessId, task) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
  
    const headers = {
        'access-token': token_from_cookie
        }
    
    const url = `${API}/system/order-process/save-task?order_process_id=${orderProcessId}`
  
    axios({
        method: "post",
        url,
        headers,
        data:task,
      }).then(res => {
        const {
            ok,
            result
        } = res.data
          
        if(ok){
            resolve(result)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })


  export const updateActualDuration = (data) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
  
    const headers = {
        'access-token': token_from_cookie
        }
    
    const url = `${API}/system/order-process/update-actual-duration`
  
    axios({
        method: "post",
        url,
        headers,
        data,
      }).then(res => {
        const {
            ok,
            result
        } = res.data
          
        if(ok){
            resolve(result)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })


  export const setProcessStartTime = (orderProcessId, startTime) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
    const data = {
        _id: orderProcessId,
        start_time: startTime
    }
  
    const headers = {
        'access-token': token_from_cookie
        }
    
    const url = `${API}/system/order-process/set-start-time`
  
    axios({
        method: "post",
        url,
        headers,
        data,
      }).then(res => {
        const {
            ok,
            result
        } = res.data
          
        if(ok){
            resolve(result)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })
