import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from '@material-ui/core/TablePagination';
import { generalGetRequest } from '../../../functions/api/general'
import EditCheckListPopup from './EditCheckList/EditCheckList'
import { useStyles } from './style'
import EditSystemPopUp from './SystemDetails/EditSystemPopUp'
import SystemsTableBody from './SystemsTable/SystemsTableBody'
import TableHeadOrg from './SystemsTable/SystemsTableHead'
import { useSystems } from '../../../hooks/useSystems'
import uuid from 'uuid'
import { SHOW_STOPPER } from '../../../constants'
import { Table, Paper } from "@material-ui/core";
import EditGeneralSectionPopup from './EditGeneralSection/EditGeneralSection'
import { useSelector} from 'react-redux'
import { CheckList} from './templates'


const Systems = () => {
  const classes = useStyles();
  const currentFactory = useSelector(state => state.factories.filterFactory);
  const factoriesList = useSelector(state => state.factories.listFactories);
  const [editGeneralSectionPopup, setEditGeneralSectionPopup] = useState(false)
  const [editCheckListSectionPopup, setEditCheckListSectionPopup] = useState(false)
  const [checkList, setCheckList] = useState('')
  const [inputTypes, setInputTypes] = useState('');
  const [systemFactoryId, setSystemFactoryId] = useState(null);

  const [systemDetailsOpen, setSystemDetailsOpen] = useState(false)
  const [selectedSystem, setSelectedSystem] = useState('')
  const [showStopperOptions, setShowStopperOptions] = useState('')


  const [generalSection, setGeneralSection] = useState('')
  const [subSystems, setSubSystems] = useState('')
  const [additionalTests, setAdditionalTests] = useState('')
  const [additionalTestsForSystem, setAdditionalTestsForSystem] = useState('')
  const setEditSystemDetailsActive = async (system) => {
    setSelectedSystem(system)
    setSystemDetailsOpen(true)
  }

  // const [systems, setSystems] = useSystems()
  const {systems, setSystems, page, setPage, rowsPerPage, setRowsPerPage, systemsQuantity} = useSystems(currentFactory)

  const openEditGeneralSectionHandler = async (e, system) => {
    e.stopPropagation()
    setSelectedSystem(system)
    const { general_section_id, layer, factory_id } = system
    const [generalSection, childSystems, inputs, additionalTests] = await Promise.all([
      general_section_id ? generalGetRequest(`/admin/systems/get-general-section?general_section_id=${general_section_id}`) : null,
      generalGetRequest(`/admin/systems/get-systems-by-layer?layer=${layer + 1}`),
      generalGetRequest(`/admin/systems/get-system-inputs`),
      // generalGetRequest(`/admin/systems/get-additional-tests?factory_id=${factory_id}`)
      generalGetRequest(`/admin/systems/get-additional-tests-for-system?system_id=${system._id}`),
    ])

    if (generalSection?.result) setGeneralSection(generalSection?.result)
    else {
      const generalSectionTemplate = {
        _id: uuid(),
        system_id: system._id,
        data: []
      }
      setGeneralSection(generalSectionTemplate)
    }
    setSubSystems(childSystems?.result || '')
    setInputTypes(inputs?.result || '')
    setAdditionalTests(additionalTests?.result || '')

    setEditGeneralSectionPopup(true)
  }

  const openEditCheckListHandler = async (e, system) => {
    setSelectedSystem(system)
    const { check_list_id: checkListId, _id: systemId, factory_id: factoryId } = system
    e.stopPropagation()
    setEditCheckListSectionPopup(true)
    const [checkListTemplate, inputs, showStopper, additionalTests, factoryDetails] = await Promise.all([
      checkListId ? generalGetRequest(`/admin/systems/get-check-list?check_list_id=${checkListId}`) : null,
      generalGetRequest(`/admin/systems/get-system-inputs`),
      generalGetRequest(`/admin/systems/find-input-by-number?number=${SHOW_STOPPER}`),
      generalGetRequest(`/admin/systems/get-additional-tests-for-system?system_id=${systemId}`),
      generalGetRequest(`/admin/factory/get-document?_id=${factoryId}`)
    ]);

    if (checkListTemplate?.result) setCheckList(checkListTemplate.result)
    else {
      const newCheckList = new CheckList(systemId, factoryDetails && factoryDetails.result.name !== "Metalpress Service" ? false : true)

      setCheckList(newCheckList)
    }
    setInputTypes(inputs?.result)
    setShowStopperOptions(showStopper?.result?.options)
    setAdditionalTestsForSystem(additionalTests?.result)
  }

  return <div className={classes.root}>
    {checkList && inputTypes && showStopperOptions ?
      <EditCheckListPopup
        checkList={checkList} 
        inputTypes={inputTypes}
        setInputTypes = {setInputTypes}
        setCheckList={setCheckList}
        editCheckListSectionPopup={editCheckListSectionPopup}
        setEditCheckListSectionPopup={setEditCheckListSectionPopup}
        systems={systems}
        setSystems={setSystems}
        showStopperOptions={showStopperOptions}
        additionalTests = {additionalTestsForSystem}
        setAdditionalTests = {setAdditionalTestsForSystem}
        layer = {selectedSystem?.layer}
        systemFactoryId={systemFactoryId}
        factories={factoriesList}
      /> : null}
    {
      generalSection && editGeneralSectionPopup ?
        <EditGeneralSectionPopup
          isOpen={editGeneralSectionPopup}
          setGeneralSection={setGeneralSection}
          generalSection={generalSection}
          setEditGeneralSectionPopup={setEditGeneralSectionPopup}
          inputTypes={inputTypes}
          subSystems={subSystems}
          systems={systems}
          setSystems={setSystems}
          additionalTests={additionalTests}
        /> : null
    }
    {selectedSystem ? <EditSystemPopUp
      systemDetailsOpen={systemDetailsOpen}
      setSystemDetailsOpen={setSystemDetailsOpen}
      system={selectedSystem}
      setSelectedSystem={setSelectedSystem}
      systems={systems}
      setSystems={setSystems}
    /> : null}
    <Paper className={classes.paper}>

      <TableContainer component={Paper} className={classes.container}  style={{direction: 'ltr', maxHeight: 'calc(100vh - 160px)'}}>
        <Table
          stickyHeader
          className={classes.table}
        >
          <TableHeadOrg />
          <SystemsTableBody
            systems={systems}
            openEditGeneralSectionHandler={openEditGeneralSectionHandler}
            openEditCheckListHandler={openEditCheckListHandler}
            setEditSystemDetailsActive={setEditSystemDetailsActive}
            setSystemFactoryId={setSystemFactoryId}
          />
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        colSpan={3}          
        count={systemsQuantity}
        rowsPerPage={rowsPerPage}
        page={page}

        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: false,
        }}
        onChangePage={(event, newPage) => setPage(newPage)}
        onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
      />
    </Paper>
  </div>
}

export default Systems

