const moment = require('moment');

// V1 implementation
// export function getWeeksBetweenDates(startDate, endDate) {
//     if (startDate && endDate) {
//         const startMoment = moment(startDate);
//         const endMoment = moment(endDate);

//         const startYear = startMoment.year();
//         const endYear = endMoment.year();

//         const startWeek = startMoment.isoWeek();
//         const endWeek = endMoment.isoWeek();

//         let weekSpan = 0;

//         if (endYear === startYear) {
//             weekSpan = endWeek - startWeek + 1;
//         } else {
//             weekSpan = (moment({ year: startYear, week: startWeek }).endOf('year').isoWeek() - startWeek + 1) +
//                         (endWeek);
//         }

//         return weekSpan;
//     }

//     return 0;
// }



const weeksGenerator = function (startDate, endDate) {
    let start = new Date(startDate)
    let end = new Date(endDate)
    end = moment(end).add(7, 'days').toDate()
    // end = new Date(end)

    let arr = new Array(),
        dt = new Date(start)
    while (dt <= end) {

        let start_day = moment(dt).startOf('week')
        let end_day = moment(dt).endOf('week')

        let year = moment(dt).year()
        start_day = moment(start_day._d).format()
        let start_day_formated = moment(start_day).format('DD/MM/YYYY')

        let final_start_day = start_day_formated.slice(0, -5)

        end_day = moment(end_day._d).format()
        let end_day_formated = moment(end_day).format('DD/MM/YYYY')
        let final_end_day = end_day_formated.slice(0, -5)
        let week_dates = final_start_day + '-' + final_end_day

        arr.push({
            week: moment(dt).format('w'),
            holiday: false,
            week_dates: week_dates,
            start_week: start_day,
            end_week: end_day,
            year,
            start_day_formated,
        }) //need to add holidays
        dt.setDate(dt.getDate() + 7);
    }
    return arr;
}

export function getWeeksBetweenDates(startDate, endDate) {
    const weeks_array = weeksGenerator(startDate, endDate);
    let weekCount = 0;
    let spanning = false;
    for (let i = 0; i < weeks_array.length; i++) {
        const {
            start_week,
            end_week,
        } = weeks_array[i];
        if (moment(startDate).isSameOrAfter(start_week)) {
            spanning = true;
            weekCount += 1;
        }

        if (spanning) {
            if (moment(endDate).isSameOrBefore(end_week)) {
                spanning = false;
                break;
            }
            weekCount += 1;
        }
    }

    return weekCount;
}
