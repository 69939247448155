import axios from 'axios'
import {API} from "../../tools/keys/keys"
import {getCookie} from "../../tools/cookies/cookie"
import { polyfill } from 'es6-promise';
polyfill()



export const getSalesAndProjectManager = () => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")


    const headers = {
        'access-token': token_from_cookie
        }

    axios.get(`${API}/system/order/get-departments-for-order-form`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok){
            resolve(output.result)
        }else{
            resolve(false)
        }
      
      
    }).catch(err => {
        resolve({ ok: false })
    })
})

export const getResources = (factory_id) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")


    const headers = {
        'access-token': token_from_cookie
        }

    axios.get(`${API}/system/resources/get_by_factory_id?factory_id=${factory_id}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok){
            resolve(output.result)
        }else{
            resolve(false)
        }
      
      
    }).catch(err => {
        resolve({ ok: false })
    })
})

export const getUsersByFactoryId = (factory_id) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")


    const headers = {
        'access-token': token_from_cookie
        }

    axios.get(`${API}/system/users/get_by_factory_id?factory_id=${factory_id}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok){
            resolve(output.result)
        }else{
            resolve(false)
        }
      
      
    }).catch(err => {
        resolve({ ok: false })
    })
})

export const getProcessSteps = (type = false) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")


    const headers = {
        'access-token': token_from_cookie
    }

    let url
    if(type) {
        url = `${API}/system/process/get?type=1`
    } else {
        url = `${API}/system/process/get?type=0`
    }

    axios.get(url, {headers} ).then(res => {
    
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok && !res.code ){
            resolve(output.result)
        }else{
            resolve(false)
        }
      
      
    }).catch(err => {
        resolve({ ok: false })
    })
})

export const getFavoriteProcessSteps = () => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")


    const headers = {
        'access-token': token_from_cookie
        }

    axios.get(`${API}/system/favorite-collection-stages/get`, {headers} ).then(res => {
    
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok){
            resolve(output.result)
        }else{
            resolve(false)
        }
      
      
    }).catch(err => {
        resolve({ ok: false })
    })
})

export const getOrderInputForPopup = () => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
    const headers = {
        'access-token': token_from_cookie
        }

    axios.get(`${API}/system/order-input/get`, {headers} ).then(res => {
    
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }

        if(ok && !res.code){
            resolve(output.result)
        }else{
            resolve(false)
        }   
    }).catch(err => {
        resolve({ ok: false })
    })
})


export const addClient = (body) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")

    const headers = {
        'access-token': token_from_cookie
    }

    let url = `${API}/system/order/create`

    axios.post(url, body, {headers} ).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok || typeof result ===  'string'){
            resolve(output)

        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
})

export const updateClient = (body) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")

    const headers = {
        'access-token': token_from_cookie
    }

    let url = `${API}/system/order/update`

    axios.post(url, body, {headers} ).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }

        if(ok){
            resolve(output)

        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
})


export const getClientDateForPopup = (id) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")


    const headers = {
        'access-token': token_from_cookie
        }

    axios.get(`${API}/system/order/get-document?_id=${id}`, {headers} ).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }

        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
})



export const getReportProcessDataPopup = (order_id, process_id) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
    const headers = {
        'access-token': token_from_cookie
        }
    axios.get(`${API}/system/order/report-process?order_id=${order_id}&process_id=${process_id}`, {headers} ).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
})

export const updateQuoteOrder = (body) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")

    const headers = {
        'access-token': token_from_cookie
    }

    const url = `${API}/system/customer-page/update-quote-order`;

    axios.post(url, body, {headers} ).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        resolve(output)
    }).catch(err => {
        resolve({ ok: false, rsult: 'something wrong' })
    })
})
