import React, { useEffect, useState } from "react";
import CustomizedSelect from '../../Components/CustomizedSelect'
import {
  INPUT_NUMBER, ADD_SUB_SYSTEMS, ADD_INPUT, ADD_ADDITIONAL_TEST, ROW_TITLE, ROW_ORDER, SUB_SYSTEM_ID, TYPE_OF_INPUT, ADDITIONAL_TEST_ID, DESCRIPTION, ADDITIONAL_TEST_EXIST_INPUT_TYPE, INPUT_NAME_PROPERTY
} from '../../../../../constants/admin-systems'


const RowValue = (props) => {
    const { value, inputTypes, subSystems, rowId, editGeneralSection, additionalTests } = props
    const {typeOfInput, sub_system_id, additional_test_id} = value
    const [valueType, setValueType] = useState('')
     
    useEffect(() => {
      if (SUB_SYSTEM_ID in value) setValueType(ADD_SUB_SYSTEMS)
      else if (ADDITIONAL_TEST_ID in value) setValueType(ADD_ADDITIONAL_TEST)
      else setValueType(ADD_INPUT)
    }, [value])
    
    return (valueType === ADD_INPUT ? 
                                        <CustomizedSelect 
                                            options = {inputTypes} 
                                            value = {typeOfInput}
                                            property = {INPUT_NUMBER}
                                            name={INPUT_NAME_PROPERTY}
                                            changeHandler = {editGeneralSection(rowId, TYPE_OF_INPUT)}  /> :
            valueType === ADD_SUB_SYSTEMS ?
                                        <CustomizedSelect 
                                            options = {subSystems} 
                                            value = {sub_system_id}
                                            property = {DESCRIPTION}
                                            changeHandler = {editGeneralSection(rowId, SUB_SYSTEM_ID)}
                                        />
                                        :
                                        <CustomizedSelect 
                                            options = {additionalTests} 
                                            value = {additional_test_id}
                                            property = {ROW_TITLE}
                                            changeHandler = {editGeneralSection(rowId, ADDITIONAL_TEST_ID)}
                                        />
  
    )
  }

export default RowValue