import uuid from 'uuid'
// import {NEW_INPUT_NUMBER} from '../../../../constants/admin-systems'

export class InputType {
    constructor(inputNumber){
        this._id = uuid()
        this.name = ''
        this.input_number = inputNumber
        this.options = []
    }
}