import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core";
import { useStyles } from '../style'

const headTableConfig = [
    {
      id: "_id",
      label: "ID",
    },
    {
      id: "description",
      label: "name",
    },
    {
      id: 'layer',
      label: 'Layer'
    },
    {
      id: 'factory_id',
      label: 'Factory id'
    },
    {
      id: 'check_list_id',
      label: 'check list'
    },
    {
      id: 'general_section_id',
      label: 'general section'
    }
  ];
  
  
const StyledHeadCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#243748",
      color: theme.palette.common.white,
      left: 'unset'
    },
    body: {
      fontSize: 14,
      fontFamily: "Rubik",
    }
  }))(TableCell);
  



  function TableHeadOrg() {
    const classes = useStyles();
    return (
      <TableHead>
        <TableRow>
          {headTableConfig.map((headCell) => (
            <StyledHeadCell
              component="th"
              scope="row"
              key={headCell.id}
              align={headCell.align}
            >
              <div className={classes.headCell}>{headCell.label}</div>
            </StyledHeadCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

export default TableHeadOrg