import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import "./AddOrderPopUp.scss";
import DetailsNavigation from '../../Customer/CustomerDetails/DetailsNavigation/DetailsNavigation';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import Systems from '../Systems/Systems';
import OrderDetails from '../OrderDetails/OrderDetails';
import Loader from '../../LoaderNew/Loader';
import { POP_UPS_TRANSLATION } from '../../../constants/translations/customer/PopUps/PopUps';
import { CUSTOMERS_PAGE } from '../../../constants/translations/customersPage';
import ClearIcon from '@material-ui/icons/Clear';


const navigationData = {
    navigationFor: 'normal',
    navigationTab: [
        {
            label: CUSTOMERS_PAGE.SYSTEMS,
            value: 12
        },
        {
            label: CUSTOMERS_PAGE.PAYMENT_DETAILS,
            value: 12
        },
        {
            label: CUSTOMERS_PAGE.ORDER_DETAILS,
            value: 12
        },
    ]
}

export default function AddOrderPopUp(props) {
    const { handleShowPopUp, showPopUp } = props;
    const [selectedTab, setSelectedTab] = useState(2);
    const [loading, setLoading] = useState(false)

    function changeSelectionTab(newValue) {
        setLoading(true);
        setSelectedTab(newValue);
    }
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500)
    })
    const view = "desktop";
    return (

        <Grid container spacing={0} className='addOrderPopUp__main'>
            <Grid xs={12} className="addOrderPopUp__headerRow">
                <h4 className='addOrderPopUp__name'>{POP_UPS_TRANSLATION.ADD_ORDER}</h4>
                <span
                    onClick={() => handleShowPopUp()}
                ><ClearIcon style={{color: '#ffffff'}}/></span>
            </Grid>
            <Grid xs={12} className='addOrderPopUp__navigation'>
                <DetailsNavigation changeTab={changeSelectionTab} data={navigationData} />
            </Grid>
            {
                loading && <Loader />
            }
            {/* <>
                {
                    selectedTab === 2 ? <OrderDetails showPopUp={showPopUp} handleShowPopUp={handleShowPopUp} view={view} />
                        : selectedTab === 1 ? <PaymentDetails showPopUp={showPopUp} handleShowPopUp={handleShowPopUp} />
                            : selectedTab === 0 ? <Systems showPopUp={showPopUp} handleShowPopUp={handleShowPopUp} />
                                : null
                }
            </> */}

        </Grid>

    )
}
