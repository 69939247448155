import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubDepartment,
  getAllFactory,
  getAllDepartment,
  setSubDepartmentDetais,
  getAllEmployee,
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { SUB_DEPARTMENT_DEFAULT } from "../../../constants/enum";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  formCheck: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const SubDepartmentDetails = (props) => {
  const dispatch = useDispatch();
  const subDepts = useSelector((state) => state.subDepartments.subDeptsDetails);
  const factoryList = useSelector((state) => state.factories.listFactories);
  const employeesList = useSelector((state) => state.resources.listResource);

  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const filterDepartment = useSelector(
    (state) => state.departments.filterDepartment
  );

  const departmentList = useSelector(
    (state) => state.departments.listDepartments
  );

  useEffect(() => {
    if (props.id) {
      dispatch(getSubDepartment(props.id));
    }
    return function cleanup() {
      dispatch(setSubDepartmentDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllDepartment());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllEmployee());
  }, [dispatch]);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && subDepts && (
          <SubDepartmentForm
            id={props.id}
            subDepts={subDepts}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            departmentList={departmentList}
            employeesList={employeesList}
          />
        )}
        {props.id === undefined && (
          <SubDepartmentForm
            id={props.id}
            subDepts={subDepts}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            departmentList={departmentList}
            employeesList={employeesList}
            filterFactory={filterFactory}
            filterDepartment={filterDepartment}
          />
        )}
      </>
    </Grid>
  );
};

function SubDepartmentForm(props) {
  const classes = useStyles();

  const {
    subDepts,
    id,
    factoryList,
    departmentList,
    employeesList,
    filterFactory,
  } = props;
  
  const [values, setValues] = useState({
    name: subDepts ? subDepts.name : "",
    factory_id: subDepts ? subDepts.factory_id : '',
    department_id: subDepts ? subDepts.department_id : '',
    employees: subDepts ? subDepts.employees : [],
    units: subDepts ? subDepts.units : SUB_DEPARTMENT_DEFAULT.units,
    project_manager: !!subDepts?.project_manager,
    sales: !!subDepts?.sales
  });

  const getFactoryId = () => {
    let res = subDepts
      ? subDepts.factory_id
      : filterFactory
      ? filterFactory
      : null;
    setValues({ ...values, factory_id: res });
  };

 useMemo(() => getFactoryId(), [filterFactory]);

  const getEmployeesList = () => {
    if (values.factory_id) {
      let employees = [];
      employees = employeesList.filter((element) => {
        return element.factory_id === values.factory_id;
      });
      return employees;
    } else {
      return []
    }
  };

  const getDepartmentList = () => {
    if (values.factory_id) {
      let depts = [];
      depts = departmentList.filter((element) => {
        return element.factory_id === values.factory_id;
      });
      return depts;
    }
    else {
      return [];
    }
  };
 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleMultiSelect = (e, option) => {
    let value = option.length
      ? option.map((element) => {
          return element;
        })
      : [];

    setValues({ ...values, employees: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { name, department_id, factory_id, employees, units, project_manager, sales } = values;
    let model;

    if (id) {
      model = {
        _id: id,
        units,
        name,
        department_id,
        factory_id,
        employees,
        project_manager,
        sales
      };
    } else {
      model = {
        name,
        units,
        department_id,
        factory_id,
        employees,
        project_manager,
        sales
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>
      <form
        id="edit-sub-department"
        onSubmit={onSubmit}
        className={classes.form}
      >
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
            <div>
              <FormControlLabel style={{marginLeft: '7px'}}
                value="start"
                control={<Checkbox 
                            style = {{color:'#0091ff', paddingRight: '3px'}}
                            checked={values.project_manager}
                            onChange={(e) => {
                              setValues({ ...values, project_manager : e.target.checked });
                            }}
                          />}
                label="Project Manager"
                labelPlacement="start"
              />
              <FormControlLabel style={{marginLeft: '0px'}}
                value="start"
                control={<Checkbox 
                            style = {{color:'#0091ff', paddingRight: '3px'}}
                            checked={values.sales}
                            onChange={(e) => {
                              setValues({ ...values, sales : e.target.checked });
                            }}
                          />}
                label="Sales"
                labelPlacement="start"
              />
            </div>
          </Grid>
          <Grid item>
            <TextField
              name="name"
              variant="outlined"
              label="name"
              value={values.name}
              onChange={handleInputChange}
            />
            <TextField
              name="units"
              variant="outlined"
              label="units"
              value={values.units}
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="department_label">department</InputLabel>
              <Select
                labelId="department_label"
                id="department_id"
                value={values.department_id}
                onChange={handleInputChange}
                label="department"
                name="department_id"
              >             
                {getDepartmentList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Autocomplete
              className={classes.formMultiSelect}
              multiple
              id="tags-outlined"
              options={getEmployeesList()}
              getOptionLabel={(option) => option.name}
              value={
                values.employees.length
                  ? values.employees.map((element) => {
                      return element;
                    })
                  : []
              }
              onChange={(e, option) => handleMultiSelect(e, option)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="employees" />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
