import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  makeStyles,
  withStyles  
} from "@material-ui/core";
import { updateOrderProcess } from "../../../actions/actions";
import {
  changeCurrentPage,
  changeRowsPerPage,
} from "../../../actions/gantt_actual_actions";
import { ActualGanttDetails } from "./ActualGanttDetails";
import DetailsDialog from "../DetailsDialog";
import TablePagination from '@material-ui/core/TablePagination';

const headTableConfig = [
  {
    id: "factory_id",
    label: "id factory",
    type: "string",
  },
  {
    id: "factory_name",
    label: "name factory",
    type: "string",
  },
  {
    id: "sub_department_id",
    label: "id sub department",
    type: "string",
  },
  {
    id: "sub_department_name",
    label: "sub department name",
    type: "string",
  },
  {
    id: "order_id",
    label: "order id",
    type: "string",
  },
  {
    id: "order_number",
    label: "order number",
    type: "string",
  },
  {
    id: "process_id",
    label: "process id",
    type: "string",
  },
  {
    id: "process_name",
    label: " process name",
    type: "string",
  },

  {
    id: "backlog",
    label: "backlog",
    type: "boolean",
  },
  {
    id: "cancelled",
    label: "cancelled",
    type: "boolean",
  },
  {
    id: "constant",
    label: "constant",
    type: "boolean",
  },

  {
    id: "done",
    label: "done",
    type: "boolean",
  },

  {
    id: "finished",
    label: "finished",
    type: "numeric",
  },

  {
    id: "fraction",
    label: "fraction",
    type: "boolean",
  },
  {
    id: "is_detached",
    label: "is_detached",
    type: "boolean",
  },

  {
    id: "order_index",
    label: "order index",
    type: "numeric",
  },
  {
    id: "original_process_date",
    label: "original process date",
    type: "date",
  },
  {
    id: "process_date",
    label: "process date",
    type: "date",
  },

  {
    id: "sent_to_backlogs",
    label: "sent to backlogs",
    type: "date",
  },

  {
    id: "quantity",
    label: "quantity",
    type: "numeric",
  },
  {
    id: "labour_cost",
    label:'labour cost',
    type: 'numeric'
  },
  {
    id: "_id",
    label: "id",
    type: "id",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table:{
    direction: "rtl"
  },
  container: {
    maxHeight: "calc(100vh - 160px)",
    direction: "ltr"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  pagination: {
    direction: "ltr",
  },
}));

const StyledHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243748",
    color: theme.palette.common.white,
    left: 'unset'
  },
  body: {
    fontSize: 14,
    fontFamily: "Rubik",
  },
}))(TableCell);

function TableHeadOrg(props) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headTableConfig.map((headCell) => (
          <StyledHeadCell
            component="th"
            scope="row"
            key={headCell.id}
            align={headCell.align}
          >
            <div className={classes.headCell}>{headCell.label}</div>
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableActualGantt(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actualGanttList, countActualGantt } = props;
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const [isOpen, setIsOpen] = React.useState(false);
  const [idCurrent, setIdCurrent] = React.useState(undefined);

  const page = useSelector((state) => state.ganttActual.currentPage);
  const rowsPerPage = useSelector((state) => state.ganttActual.rowsPerPage);

  const handleEdit = (id) => {
    setIdCurrent(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (id, model) => {
    setIsOpen(false);
    dispatch(updateOrderProcess(model, filterFactory,page,rowsPerPage));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(changeCurrentPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
  };

  return (
    <div className={classes.root}>
      <DetailsDialog
        isOpen={isOpen}
        onClose={handleClose}
        submitLabel="Update"
        form="edit-gantt-actual"
        title="Edit Gantt Actual"
        id={idCurrent}
      >
        <ActualGanttDetails id={idCurrent} onSubmit={onSubmit} />
      </DetailsDialog>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeadOrg />
            <TableBody>
              {actualGanttList &&
                actualGanttList.map((element) => {
                  return (
                    <TableRow
                      style={{ height: 33 }}
                      hover
                      tabIndex={-1}
                      key={element._id}
                      onClick={() => handleEdit(element._id)}
                    >
                      {headTableConfig.map((col) => {
                        let value = element[col.id];
                        switch (col.id) {
                          case "done":
                          case "is_detached":
                          case "fraction":
                          case "constant":
                          case "cancelled":
                          case "backlog":
                            value =
                              String(element[col.id]) === "undefined"
                                ? ""
                                : String(element[col.id]);
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell component="th" scope="row" key={col.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>         
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          colSpan={3}
          count={countActualGantt}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}


