import React, { useState } from "react";
import {  Grid, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    form: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1), 
        minWidth: 200,
      },
    },
    firstRow: {
      display: "flex",
      justifyContent: "space-between",
      "& .MuiTextField-root": {
        minWidth: 223,
      },
    },
  }));
  
export default function СhangePassword(props) {
    const classes = useStyles();
    const {  
        id
    } = props;

  const [values, setValues] = useState({
    confirmPassword: undefined,
    password: undefined,
  });

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const {  password, confirmPassword } = values;
    if ( password !== confirmPassword ){
        return
    }
  
    let model;

    if (id) {
      model = {
        _id: id,        
        password        
      };
    } else {
      model = {      
        password        
      };
    }
    props.onSubmitPassword(id, model);
  };


  return (
    <form id="change_password" onSubmit={onSubmit} className={classes.form}>
    <Grid container direction="column">
      <Grid item className={classes.firstRow}>
      <TextField 
              name="password"            
              variant="outlined"
              label="password"
              value={values.password}       
              onChange={handleInputChange}              
            />
       <TextField 
              name="confirmPassword"            
              variant="outlined"
              label="confirm password"
              value={values.confirmPassword}       
              onChange={handleInputChange}              
            />
      </Grid>
      </Grid>
      </form>
  );
}
