import { DESKTOP_VIEW_TRANSLATION } from "../../../../constants/translations/customer/desktop/desktopView"
import { TABLET_VIEW_TRANSLATION } from "../../../../constants/translations/customer/tablet/tabletView"
import { CANCEL, SAVE } from "../../../../constants/translations/review-popup"

export const systemTabDesktopBtn = {
    addSystem: {
        name: TABLET_VIEW_TRANSLATION.SYSTEM,
        width: '20%',
        height: '52px',
        color: '#fff',
        backgroundColor: "#186315",
        fontSize: '16px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        border: false,
        isBoxShadow: true,
        buttonName: 'AddIcon'
    },
    uploadExcel: {
        name: DESKTOP_VIEW_TRANSLATION.UPLOAD_FROM_EXCEL,
        width: '30%',
        height: '52px',
        backgroundColor: '#fff',
        color: "#186315",
        fontSize: '16px',
        fontWeight: 800,
        icon: true,
        iconContainer: false,
        border: true,
        isBoxShadow: true,
        buttonName: 'Excel'
    },
}
export const systemTabPopUpBtn = {
    addSystem: {
        ...systemTabDesktopBtn.addSystem,
        width: '30%',
    },
    save: {
        name: SAVE,
        width: '40%',
        height: '52px',
        color: '#fff',
        backgroundColor: "#0091FF",
        fontSize: '16px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        borderRadius: '20px',
    },
    cancel: {
        name: CANCEL,
        width: '40%',
        height: '52px',
        color: '#0091FF',
        backgroundColor: "#fff",
        fontSize: '16px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        borderRadius: '20px',
    }
}
