import React, { useState } from 'react';
import "./AddMeasurements.scss";
import Button from '../../../Buttons/Button';
import "../../../TabletView/PopUps/AddSystem/AddSystemPopUp.scss";
import { Grid } from '@material-ui/core';
import Input from '../../../InputFields/Input';
import MeasurementInput from '../../../InputFields/MeasurementInput/MeasurmentInput';
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import { PRODUCT_FORM } from '../../../../../constants/translations/customersPage';
import { ADD, CANCEL } from '../../../../../constants/translations/review-popup';
import { useDispatch, useSelector } from 'react-redux';
import { systemActionsCreator } from '../../../../../actions/customer/systemActions';


const add = {
    name: ADD,
    width: '42%',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '12px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    height: '36px',
    borderRadius: '20px',
}
const cancel = {
    name: CANCEL,
    width: '42%',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '12px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    height: '36px',
    borderRadius: '20px',
}
const AddDesktop = {
    ...add,
    fontSize: '16px',
    height: '52px',
};
const cancelDesktop = {
    ...cancel,
    fontSize: '16px',
    height: '52px',
}
const addTablet = {
    ...add,
    fontSize: '24px',
    height: '70px',
    borderRadius: '90px'
};
const cancelTablet = {
    ...cancel,
    fontSize: '24px',
    height: '70px',
    borderRadius: '90px'
}

const inputData = {
   width : {
        label: '',
        placeholder: '_ _',
        value: 'Enter',
        type: 'number',
        width: '60%',
        name: 'width'
   },
   height : {
       label: '',
       placeholder: '_ _',
       value: 'Enter',
       type: 'number',
       width: '60%',
       name: 'height'
   }
};
const inputDataDesktop = {
    width: {
        label: 'Width',
        placeholder: '6',
        value: 'Enter',
        type: 'number',
        width: '100%',
        height: '52px',
        name: 'width'
    },
    height: {
        label: 'Height',
        placeholder: '6',
        value: 'Enter',
        type: 'number',
        width: '100%',
        height: '52px',
        name: 'height'
    }
};


export default function AddMeasurements(props) {
    const {handleShowPopUp, view } = props;

    const [measurementInputs, setMeasurementInputs] = useState({
        width: null, 
        height: null
    })
    const [measurementInputsErrors, setMeasurementInputsErrors] = useState({
        width: '',
        height: ''
    });

    const dispatch = useDispatch();

    const selectedSystem = useSelector((state) => state.customerSystems.singleSystem);

    const { system_name, location_description, location_floor, system_id } = selectedSystem;

    const handleValidations = (name, value) => {
       
        let error={};
        let isValid = true;
        if (name === 'width') {
            if (value !== '' && value !== null && typeof(value) !== 'number') {
               error[name] = ''
            } else {
                error[name] = 'Invalid' + ' '+ name;
                isValid = false;
            }
        }

        if (name === 'height') {
            if (value !== '' && value !== null && typeof(value) !== 'number') {
               error[name] = ''
            } else {
                error[name] = 'Invalid' + ' ' + name;
                isValid = false;
            }
        }
        setMeasurementInputsErrors(() => ({
            ...measurementInputsErrors,
            ...error,
        }))

        return isValid;
    }

    
    const handelChangeMeasurement = (name, value) => {
        handleValidations(name, value)
        setMeasurementInputs((preState) => {
          return { 
            ...preState,
            [name]: value
        }
        })
    }

   

    const handelAddMeasurement = () => {
        if (!handleValidations('width', measurementInputs.width) || !handleValidations('height', measurementInputs.height)){
            return;
        }
        const systemMeasurementObject = {...selectedSystem, measurements: measurementInputs }
        dispatch(systemActionsCreator.addSystemMeasurement(systemMeasurementObject))
        handleShowPopUp()
    }

    return (
        <Grid className='addSystem__main' style={{ left: view === 'desktop' && '15%' }}>
            <Grid>
                <h4 className='addSystem__title'>{POP_UPS_TRANSLATION.ADD_MEASUREMENTS}</h4>
            </Grid>
            <Grid className='addSystem__uploadedInfo'>
                <Grid item xs={12} className='addSystem__uploadedInfo-row'>
                    <Grid item xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.SYSTEM_NAME}</span></Grid>
                    <Grid item xs={6} className='addSystem__uploadedInfo-value'><span>{system_name || ''}</span></Grid>
                </Grid>
                <Grid item xs={12} className='addSystem__uploadedInfo-row'>
                    <Grid item xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.FLOOR}</span></Grid>
                    <Grid item xs={6} className='addSystem__uploadedInfo-value'><span>{location_floor || ''}</span></Grid>
                </Grid>
                <Grid item xs={12} className='addSystem__uploadedInfo-row'>
                    <Grid item xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.LOCATION_DESCRIPTION}</span></Grid>
                    <Grid item xs={6} className='addSystem__uploadedInfo-value'><span>{location_description || ''}</span></Grid>
                </Grid>
            </Grid>

            {
                view === 'desktop' ? <Grid item xs={12} className='addSystemMeasurement__inputRow'>
                    <Grid item xs={6}>
                        <Input
                            data={{
                                ...inputDataDesktop.width,
                                value: measurementInputs.width,
                                onChange: handelChangeMeasurement,
                       
                                error: !!(measurementInputsErrors['width'] && measurementInputsErrors['width'].length),
                                errorText: measurementInputsErrors['width'] ? measurementInputsErrors['width'] : '',
                            }}
                            view={view}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            data={{
                                ...inputDataDesktop.height,
                                value: measurementInputs.height,
                                onChange: handelChangeMeasurement,
                                error: !!(measurementInputsErrors['height'] && measurementInputsErrors['height'].length),
                                errorText: measurementInputsErrors['height'] ? measurementInputsErrors['height'] : '',
                            }}
                            view={view}
                        />
                    </Grid>

                </Grid> : <>
                        <Grid item xs={12} className='addSystemMeasurement__measurement'>
                        <label>{POP_UPS_TRANSLATION.PLEASE_ENTER}{PRODUCT_FORM.WIDTH}, {POP_UPS_TRANSLATION.CM}</label>
                            <Grid className='addSystemMeasurement__inputs'>
                                <Grid className='addSystemMeasurement__rightInput'>
                                    <MeasurementInput
                                        data={{
                                            ...inputData.width,
                                            value: measurementInputs.width,
                                            onChange: handelChangeMeasurement,
                                            error: !!(measurementInputsErrors['width'] && measurementInputsErrors['width'].length),
                                            errorText: measurementInputsErrors['width'] ? measurementInputsErrors['width'] : '',
                                        }}
                                        view={view}
                                    />
                                </Grid>
                                <div className='addSystemMeasurement__dot'>&#8226;</div>
                                <Grid className='addSystemMeasurement__leftInput'>
                                    <MeasurementInput
                                        data={inputData.width}
                                        view={view}
                                    />
                                </Grid>
                            </Grid>
                    </Grid>
                        <Grid item xs={12} className='addSystemMeasurement__measurement'>
                            <label>{POP_UPS_TRANSLATION.PLEASE_ENTER}{PRODUCT_FORM.HEIGHT}, {POP_UPS_TRANSLATION.CM}</label>
                            <Grid className='addSystemMeasurement__inputs'>
                                <Grid className='addSystemMeasurement__rightInput'>
                                    <MeasurementInput 
                                        data={{
                                            ...inputData.height,
                                            value: measurementInputs.height,
                                            onChange: handelChangeMeasurement,
                                            error: !!(measurementInputsErrors['height'] && measurementInputsErrors['height'].length),
                                            errorText: measurementInputsErrors['height'] ? measurementInputsErrors['height'] : '',
                                        }}
                                    view={view} />
                                    </Grid>
                                <div className='addSystemMeasurement__dot'>&#8226;</div>
                                <Grid className='addSystemMeasurement__leftInput'>
                                    <MeasurementInput data={inputData.height} view={view} />
                                </Grid>
                            </Grid>
                    </Grid>
                </>
            }

            {
                view === 'desktop' ? <Grid sx={12} className='addSystem__btn__container' style={{ position: view === 'desktop' && 'static' }}>
                    <Button data={AddDesktop} handleClick={() => handelAddMeasurement()} />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button data={cancelDesktop} handleClick={() => handleShowPopUp()} />
                </Grid> : 
                    <Grid sx={12} className='addSystem__btn__container'>
                        <Button data={add} handleClick={() => handelAddMeasurement()} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button data={cancel} handleClick={() => handleShowPopUp()} />
                    </Grid>
            }
        </Grid>
    )
}
