import moment from "moment";

// set time out try:

export const columnWeekGenerator = async function(weeks_array, ordersData) {

  let ordersDataCopy = JSON.parse(JSON.stringify(ordersData));
  let number_of_weeks = weeks_array.length + 1;

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  async function start(i) {
    if (i < ordersData.length) {
      // console.log("setTimeout");
      // console.log(ordersDataCopy[i]);
      // debugger

      ordersDataCopy[i].columnOrder = [];
      ordersDataCopy[i].columns = {};

      let processes_week_nums = ordersDataCopy[i].processes.map(p => ({
        week: moment(new Date(p.process_date)).isoWeek(),
        date: p.process_date,
        id: p.process_id,
        name: p.process_name
      }));

      // loop num 2 :

      async function loop2(num) {
        if (num < number_of_weeks) {
          let column_num = "column-" + num;
          let week_number = "week-" + num;
          let column_number = "column-" + num;
          let week_index = num - 1;

          let week_num_obj = weeks_array[week_index];
          ordersDataCopy[i].columns[column_number] = {
            id: column_number,
            title: week_number,
            processId: []
          };

          // loop num 3 :

          async function loop3(pro) {
            if (pro < processes_week_nums.length) {
              //loop over the processes dates and push the process id to the correct week.
              let process_date = processes_week_nums[pro].date;
              let week_start = moment(week_num_obj.start_week).format();
              let week_end = moment(week_num_obj.end_week).format();

              if (week_start < process_date && process_date < week_end) {
                ordersDataCopy[i].columns[column_number].processId.push(
                  processes_week_nums[pro].id
                );
              }

              pro++;
              await loop3(pro);
              await delay(2);
            }
          }

          await loop3(0);
          // after end of loop 3--->

          ordersDataCopy[i].columnOrder.push(column_num);

          num++;
          await loop2(num);

          await delay(1);
          // console.log("done!");
        }
      }

      await loop2(1);
      // after end of loop 2--->

      i++;
      await start(i);
      await delay(1);
      // after all loops!
    }
  }

  // debugger
  await start(0); // start the big loop
  return ordersDataCopy;
};

// ********* save copy of columnWeekGenerator loops before change - the original with 3 loops **********/

// export const columnWeekGenerator = function(weeks_array, ordersData) {
//   let orders_data_copy = JSON.parse(JSON.stringify(ordersData));

//   let number_of_weeks = weeks_array.length + 1;

//   for (let i = 0; i < orders_data_copy.length; i++) {

//     orders_data_copy[i].columnOrder = [];
//     orders_data_copy[i].columns = {};

//     let processes_week_nums = orders_data_copy[i].processes.map(p => ({
//       week: moment(new Date(p.process_date)).isoWeek(),
//       date: p.process_date,
//       id: p.process_id,
//       name: p.process_name
//     }));

//     for (let num = 1; num < number_of_weeks; num++) {
//       let column_num = "column-" + num;
//       let week_number = "week-" + num;
//       let column_number = "column-" + num;
//       let week_index = num - 1;

//       let week_num_obj = weeks_array[week_index];
//       orders_data_copy[i].columns[column_number] = {
//         id: column_number,
//         title: week_number,
//         processId: []
//       };

//       for (let pro = 0; pro < processes_week_nums.length; pro++) {
//         //loop over the processes dates and push the process id to the correct week.
//         let process_date = processes_week_nums[pro].date;
//         let week_start = moment(week_num_obj.start_week).format();
//         let week_end = moment(week_num_obj.end_week).format();

//         if (week_start < process_date && process_date < week_end) {
//           orders_data_copy[i].columns[column_number].processId.push(
//             processes_week_nums[pro].id
//           );
//         }
//         console.log("done!");
//       }

//       orders_data_copy[i].columnOrder.push(column_num);
//     }
//   }

//   return orders_data_copy;
// };




export const addColumnWeeksGenerator = function(number, ordersData, off_days_number = 0, mps_view = 'week') {

    let newNumber
    if(mps_view === 'week'){
        newNumber = number
    } else if(mps_view === 'day'){
        newNumber = number - off_days_number -1
    }
    

  let ordersDataCopy = JSON.parse(JSON.stringify(ordersData));
  let object_keys = Object.keys(ordersDataCopy)
  let num_of_current_col = ordersDataCopy[object_keys[0]].columnOrder.length + 1;

  for (let i = 0; i < object_keys.length; i++) {
    //loop the orders array
    for (let n = 0; n < newNumber; n++) {
      //push number of times

      let num = num_of_current_col + n;

      let column_num = "column-" + num;
      let week_number = "week-" + num;
      let column_number = "column-" + num;

      ordersDataCopy[object_keys[i]].columns[column_number] = {
        id: column_number,
        title: week_number,
        processId: []
      };

      ordersDataCopy[object_keys[i]].columnOrder.push(column_num);

      
    }
  }
  return ordersDataCopy;
};

// export default demo_orders_arr;
