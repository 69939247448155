export const refreshPageAfterAwake = () => {
const TIMEOUT = 60000;
let lastTime = (new Date()).getTime();

setInterval(function() {
  var currentTime = (new Date()).getTime();
  if (currentTime > (lastTime + TIMEOUT + 5000)) {
    document.location.reload()
    // Wake!
  }
  lastTime = currentTime;
}, TIMEOUT);

}