import {SERVICE_CALL_CATEGORY_ID, SERVICE_CALL_SUB_CATEGORY_ID, SERVICE_CALL_QUANTITY, SERVICE_CALL_TYPE_ALL_SYSTEMS, SERVICE_CALL_TYPE_HALF_YEAR, SYSTEM_LAYER_1} from '../../constants/customers-page'
import {addClient} from './popup'
import axios from "axios";
import { API } from "../../tools/keys/keys";
import { getCookie } from "../../tools/cookies/cookie";
import { polyfill } from "es6-promise";
import  * as momentBusinessDays  from 'moment-business-days';
import { generalGetRequest } from './general';
momentBusinessDays.updateLocale('us', {
  workingWeekdays: [0, 1, 2, 3, 4 ] 
});

export const createServiceCall = async (data) => {
    const {
      systems, ...rest
    } = data
  
    const body = {
      ... rest,
      order_category_id: SERVICE_CALL_CATEGORY_ID,
      sub_categoty_id: SERVICE_CALL_SUB_CATEGORY_ID,
      quantity: SERVICE_CALL_QUANTITY,
      started: true,
    }
     const typeOfCall = rest.type
    if(typeOfCall && typeOfCall !== SERVICE_CALL_TYPE_ALL_SYSTEMS && typeOfCall !==  SERVICE_CALL_TYPE_HALF_YEAR){
      const firstLayer = systems.find(i => i.layer === SYSTEM_LAYER_1)
      firstLayer && firstLayer.systems[0] && (firstLayer.systems[0].parent_system_id = null)
      body.systems = systems
    } 
  
    return await addClient(body)
  
  }



  export const deleteSubSystem = async (actual_sub_system_id , actual_system_id) =>  new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
  
  
    const headers = {
        'access-token': token_from_cookie
        }
  
    axios.get(`${API}/system/customer-page/delete-sub-system?actual_sub_system_id=${actual_sub_system_id}&actual_system_id=${actual_system_id}`, {headers}).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
  
        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })



  export const updateSubSystemGeneralSection = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/update-sub-system-general-section`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };

  export const uploadFile = async(body, customerId) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
    // axios(`${API}/system/order-systems/upload-file`, {headers} )
  
    const headers = {
        'access-token': token_from_cookie
        }
  
        const url = `${API}/system/customer-page/upload-file?customer_id=${customerId}`    
  
        const res = axios({
          method: "post",
          url,
          headers,
          data:body,
        }).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
  
        if(ok){
            resolve(output)
        }else{
            resolve(false)
        }
    }).catch(err => {
        resolve({ ok: false })
    })
  })
  

  export const deleteAttachment = async (customerId, fileId) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/delete-file?customer_id=${customerId}&file_id=${fileId}`;
    try {
      const res = await axios({
        method: "get",
        url,
        headers
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };

  export const getCustomerOrders = async (customerId) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/get-customer-orders-list?customer_id=${customerId}`;
    try {
      const res = await axios({
        method: "get",
        url,
        headers
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  }

  export const createQuote = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/create-quote`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };

  export const createProduct = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/create-product`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };

  export const updateQuoteInfo = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/update-quote-info`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };


  export const getOrderTypesForActionOrder = async () => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/get-order-types-for-service-actions`;
    try {
      const res = await axios({
        method: "get",
        url,
        headers
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  }

  export const deleteActualSystem = async (id) => {
    const responce = await generalGetRequest(`/system/customer-page/delete-actual-system?actual_system_id=${id}`)
    return responce?.result
  }

  export const updateGeneralSection = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/edit-general-section`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };


  export const getChildSystems = async (parentSystemId) => {
    const responce = await generalGetRequest(`/system/customer-page/get-systems?parent_system_id=${parentSystemId}`)
    return responce?.result
  }

  export const getFirstLayerSystems = async(customerId) => {
    const responce = await generalGetRequest(`/system/customer-page/get-first-layer-systems?customer_id=${customerId}`)
    return responce?.result
  }

  export const uploadAvatar = async(body, customerId) => new Promise(resolve => {
    const token_from_cookie = getCookie("login_cookie")
    // axios(`${API}/system/order-systems/upload-file`, {headers} )
  
    const headers = {
        'access-token': token_from_cookie
        }
  
        const url = `${API}/system/customer-page/upload-avatar?customer_id=${customerId}`    
  
        const res = axios({
          method: "post",
          url,
          headers,
          data:body,
        }).then(res => {
        const {
            ok,
            result
        } = res.data
          
        if(ok){
            resolve(result)
        }else{
            resolve(undefined)
        }
    }).catch(err => {
        resolve(undefined)
    })
  })


  export const cloneOrder= async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/clone-order`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body,
      });
      return res?.data?.result;
    } catch (err) {
      console.log("Error has been occured in POST request", err);
      if (err) return undefined;
    }
  };

  export const deleteSystems = async (_id) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/delete-system?_id=${_id}`;
    try {
      const res = await axios({
        method: "delete",
        url,
        headers,
      });
      return res.data
    } catch (err) {
      return
    }
  };

  export const duplicateSystems = async (body) => {
    const token_from_cookie = getCookie("login_cookie");
    const headers = {
      "access-token": token_from_cookie,
    };
    const url = `${API}/system/customer-page/duplicate-system`;
    try {
      const res = await axios({
        method: "post",
        url,
        headers,
        data: body
      });
      return res.data
    } catch (err) {
      return
    }
  };
export const getSearchAutoCompleteCustomer = (page, keyword, limit) =>
  new Promise((resolve) => {
    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };

    let url = `${API}/system/customer-page/search-auto-complete?page=${page}&keyword=${keyword}&limit=${limit}`;

    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          resolve(output);
        } else {
          console.log("error");
          resolve(false);
        }
      })
      .catch((err) => {
        resolve({ ok: false });
      });
});


export const getAllSystemsById = async (customer_id, layer, parent_system_id) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/customer-page/get-all-systems?customer_id=${customer_id}&layer=${layer}&parent_system_id=${parent_system_id}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data
  } catch (err) {
    return
  }
};

export const updateSystems = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/customer-page/update-systems`;
  try {
    const res = await axios({
      method: "put",
      url,
      headers,
      data: body
    });
    return res.data
  } catch (err) {
    return
  }
};

export const checkOrderNumberFree= async (order_number) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order/chek-orderNumber-free?order_number=${order_number}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res?.data?.result;
  } catch (err) {
    console.log("Error has been occured in checkOrderNumberFre", err);
    if (err) return undefined;
  }
};