import { useState, useEffect } from "react";
import { generalGetRequest } from "../functions/api/general";
import moment from "moment";
import {QUOTE_STATUS_OPTIONS} from '../constants/translations/customersPage'

export const useQuote = (quoteId) => {
  const { SENT_TO_CLIENT } = QUOTE_STATUS_OPTIONS
  const [locations, setLocations] = useState([]);
  const [quoteNumber, setQuoteNumber] = useState("");
  const [clientName, setClientName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [quoteStatus, setQuoteStatus] = useState('');
  const [quoteDate, setQuoteDate] = useState("");
  const [readOnly, setReadOnly] = useState(false)
  const [remarks, setRemarks] = useState('')
  const [projectType, setProjectType] = useState('')
  const [productDiscount, setProductDiscount] = useState(0)
  let [extraCharges, setExtraCharges] = useState([]);

  const getQuote = async () => {
    const { ok, result } = await generalGetRequest(
      `/system/customer-page/get-quote?quote_id=${quoteId}`
    );
    if (result) {
      const {quote, products} = result
      const {
        client_phone,client_name,address: quoteAddress,
         order_number,   due_date, status, quantity,value,customer_id,
          remarks: quoteRemarks, project_type, order_discount, extra_charges
        } = quote
      setRemarks(quoteRemarks || '')
      setProjectType(project_type || '')
      setLocations(products || [])
      setQuoteDate(moment(due_date).format("YYYY-MM-DD") || '')
      setQuoteNumber(order_number || '')
      setClientName(client_name || '')
      setAddress(quoteAddress || '')
      setPhoneNumber(client_phone || '')
      setQuoteStatus(status || '')
      setProductDiscount(order_discount || 0)
      setExtraCharges(Array.isArray(extra_charges) && extra_charges.length  ? extra_charges : [])
    }
  };

  useEffect(() => {
    if (quoteId) {
        getQuote();
    }
  }, [quoteId]);

  useEffect(() => {
    if (quoteStatus) setReadOnly(quoteStatus === SENT_TO_CLIENT)
  }, [quoteStatus]);

  return {
    locations,
    setLocations,
    quoteNumber,
    setQuoteNumber,
    clientName,
    setClientName,
    address,
    setAddress,
    phoneNumber,
    setPhoneNumber,
    quoteStatus,
    setQuoteStatus,
    quoteDate,
    setQuoteDate,
    readOnly,
    remarks, setRemarks,
    projectType, setProjectType,
    productDiscount, setProductDiscount,
    extraCharges, setExtraCharges
  };
};
