import { Rifm } from "rifm"

export const replaceProcessInState = (newProcess, state) => {
    let stateCopy = JSON.parse(JSON.stringify(state))
    
    let newestState = []
    stateCopy.map(day => {
        let newState = []
        day.dayData.map((userData) => {
            if(userData !== undefined) {
                let newVal = []
                if(userData.value && userData.value.length > 0) {
                    userData.value.map((p) => {
                        if(p.proccess._id === newProcess._id || p.proccess.original === newProcess._id){
                            if(p.proccess.original){
                                newVal.push({...p, original: p.proccess.original , proccess: newProcess})
                            } else {
                                newVal.push({...p, proccess: newProcess})
                            }
                        } else {
                            newVal.push(p)
                        }
                    })
                }
                newState.push({ key: userData.key, value: newVal, heightWouldBe: userData.heightWouldBe })
            }
        })
        newestState.push({
            ...day,
            dayData: newState
        })
    })

    // let newState = stateCopy.map(day => {
    //     return {
    //         ...day ,
    //         dayData: day.dayData.map(order => {
                
    //             if(order.proccess._id === newProcess._id || order.proccess.original === newProcess._id){
    //                 if(order.proccess.original){
    //                     return {...order, original: order.proccess.original , proccess: newProcess}
    //                 } else {
    //                     return {...order, proccess: newProcess}
    //                 }
                    
    //             } else {
    //                 return {...order}
    //             }
    //         })
    //     } 
    // })
    return newestState
}



export const removeFractionsOnBacklogDrag = (process, oldState) => {
    let newState = JSON.parse(JSON.stringify(oldState))
    let idToRemove = process.original ? process.original:process._id
    
    let newestState = []
    newState.map(day => {
        let newArr = []
        day.dayData.map((userData) => {
            if(userData !== undefined) {
                let newVal = []
                if(userData.value && userData.value.length > 0) {
                    userData.value.map((p) => {
                        if(p.proccess.original || p.proccess._id === idToRemove) {
                            if(p.proccess.backlog){
                                newVal.push(p)
                                // console.log("if 2")
                            } else {
                                newVal.push(p)
                            }
                            // console.log("if")
                        } else {
                            // console.log("else")
                            newVal.push(p)
                        }
                    })
                }
                newArr.push({ key: userData.key, value: newVal, heightWouldBe: userData.heightWouldBe })
            }
        })
        newestState.push({
            ...day,
            dayData: newArr
        })
    })

    // newState = newState.map(day => {
    //     return {
    //         ...day,
    //         dayData: day.dayData.filter((userData) => {
    //             if(userData !== undefined) {
    //                 if(userData.value && userData.value > 0) {
    //                     return userData.value.filter((p) => {
    //                         if(p.proccess.original || p.proccess._id === idToRemove) {
    //                             if(p.proccess.backlog){
    //                                 return true
    //                             } else {
    //                                 return false
    //                             }
    //                         } else {
    //                             return true
    //                         }
    //                     })
    //                 }
    //             }
    //             // if(order.proccess.original || order.proccess._id === idToRemove){
    //             //     if(order.proccess.backlog){
    //             //         return true
    //             //     } else {
    //             //         return false
    //             //     }
    //             // } else {
    //             //     return true
    //             // }
    //         })
    //     }

    // })
    newState = [...newestState]
    // console.log("newState: ", newState);
    return newState
}



export const removeBacklogDuplications = (process, oldState) => {
    let newState = JSON.parse(JSON.stringify(oldState))
    
    let newestState = []
    newState.map(day => {
        let newArr = []
        day.dayData.map((userData) => {
            if(userData !== undefined) {
                let newVal = []
                if(userData.value && userData.value.length > 0) {
                    userData.value.map((p) => {
                        if(!p.proccess.original && p.proccess.original !== p._id ) {
                            newVal.push(p)
                        }
                    })
                }
                newArr.push({ key: userData.key, value: newVal, heightWouldBe: userData.heightWouldBe })
            }
        })
        newestState.push({
            ...day,
            dayData: newArr
        })
    })

    // newState = newState.map(day => {
    //     return {
    //         ...day,
    //         dayData: day.dayData.filter(order => {
      
    //             if(order.proccess.original && order.proccess.original === process._id ){
    //                 return false
    //             } else {
    //                 return true
    //             }
    //         })
    //     }
    // })
    newState = [...newestState]
    // console.log("newState: ", newState);
    return newState
}



export const sleep = (milliseconds) => {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}
  