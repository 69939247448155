import moment from "moment";



// export const findDiffranceBetweenWeeks = function( bigger , smaller){
//   let a = moment(bigger); //the later date
//   let b = moment(smaller);
//   let diffrance_num =  a.diff(b, 'weeks') 
//   return diffrance_num
// }

//find start and end date from weeks arr

export const findRangOfDatesFromWeeksArr = function(dates_arr){
  let last_index = dates_arr.length - 1;
  let end_week_date = dates_arr[last_index].start_week
  let start_week_date = dates_arr[0].start_week
  let range_of_dates = {start: start_week_date, end: end_week_date}
  return range_of_dates 
}

 
//find last date from orders array
export const findOnlyLastOrderDateFromOrdersArr = function(ordersData) {
  let get_new_orders_data_copy = JSON.parse(JSON.stringify(ordersData))

  let last_index = get_new_orders_data_copy.length - 1;
  let last_order = get_new_orders_data_copy[last_index];

  let end_time = 0;

  for (let i in last_order.processes) {
    let process_date = last_order.processes[i].process_date;
    if (end_time === 0) {
      end_time = process_date;
    } else {
      if (process_date > end_time) {
        end_time = process_date;
      }
    }
  }

  return end_time
};



// find the orders dates that will be on the dashboard  --> not for scroll!


export const findOrderDates = function(ordersData) {

  let ordersDataCopy = ordersData.map(item => {
    return { ...item };
  });
  let last_index = ordersDataCopy.length - 1;
  let last_order = ordersDataCopy[last_index];

  let start_time = moment(new Date()).subtract(2, "weeks");
  let end_time = 0;

  for (let i in last_order.processes) {
    let process_date_2 = last_order.processes[i].process_date;
    if (end_time === 0) {
      end_time = process_date_2;
    } else {
      if (process_date_2 > end_time) {
        end_time = process_date_2;
      }
    }
  }

  return weeksGenerator(start_time._d, end_time);
};



//generate the number of week for the weeks bar 

export const weeksGenerator = function(startDate, endDate) {
  let start = new Date(startDate);
  let end = new Date(endDate);
  
  let arr = new Array(),
    dt = new Date(start);
  while (dt <= end) {
    let start_day = moment(dt).startOf("week");
    let end_day = moment(dt).endOf("week");

    let year = moment(dt).year();
    start_day = moment(start_day._d).format();
    let start_day_formated = moment(start_day).format("l");

    let final_start_day = start_day_formated.slice(0, -5);

    end_day = moment(end_day._d).format();
    let end_day_formated = moment(end_day).format("l");
    let final_end_day = end_day_formated.slice(0, -5);
    let week_dates = final_start_day + "-" + final_end_day;

    arr.push({
    // week: moment(dt).isoWeek(),
      week: moment(dt).format('w'),
      holiday: false,
      week_dates: week_dates,
      start_week: start_day,
      end_week: end_day,
      year,
      start_day_formated,


    }); //need to add holidays
    dt.setDate(dt.getDate() + 7);
  }
  return arr;
};



export const daysGenerator = function(startDate, endDate, days_off = []) {
    let finalArray = []
    let hebrewDaysOfWeek = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
    let start = new Date(startDate)
    let end = new Date(endDate)
    let diff =  Math.floor(( Date.parse(end.toDateString()) - Date.parse(start.toDateString()) ) / 86400000) + 2

    for(let i = 0; i < diff; i++){
        let currentDayInCount = moment(start).add(i, 'days')
        if(!days_off.includes(currentDayInCount.day()))
        finalArray.push({
            week: hebrewDaysOfWeek[moment(currentDayInCount).day()],
            holiday: false,
            week_dates: moment(currentDayInCount).format('YYYY/MM/DD'),
            start_week: start,
            end_week: start,
            year: moment(currentDayInCount).year(),
            start_day_formated: moment(currentDayInCount).format('YYYY/MM/DD'),         
        })
    }
    
    return finalArray
}



//add number of months when scrolling left:

export const addMonthsToWeeksArr = function(number , weeks_arr, isDaysView = false, days_off = []) {
  let weeks_arr_copy = weeks_arr.map(week => {
    return { ...week };
  });
   let last_index = weeks_arr_copy.length-1
   let last_week_in_the_array= weeks_arr_copy[last_index]


   let last_week_date
   if(isDaysView){
    last_week_date = new Date(last_week_in_the_array.week_dates)
   } else {
    last_week_date = last_week_in_the_array.start_week
   }
   


  let new_start_time
  if(isDaysView) {
        new_start_time = moment(last_week_date)
  } else {
        new_start_time = moment(last_week_date).add(1, "weeks");
  }

    
  let new_start_time_correct = moment(new_start_time).add(1, "days");

  let new_end_time
  if(isDaysView) {
     new_end_time =  moment(new_start_time_correct).add(number, "days");
} else {
     new_end_time =  moment(new_start_time_correct).add(number, "weeks");
}
  


  let add_months
  if(isDaysView) {
    add_months = daysGenerator(new_start_time_correct,new_end_time, days_off)
  } else {
    add_months = weeksGenerator(new_start_time_correct,new_end_time)
  }
   
  // console.log(add_months)
  
   
  for (let week of add_months ){
    weeks_arr_copy.push(week)

  }

  let new_weeks_arr = weeks_arr_copy
  return new_weeks_arr

}
