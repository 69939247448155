import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import "../../sass/login/login.scss"
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { setCookie } from "../../tools/cookies/cookie";
import { getUserDetails } from "../../functions/api/login";
import { polyfill } from 'es6-promise';

import { CSVLink } from "react-csv";

import Loader from "../LoaderNew/Loader";


import Bg from './images/plantika_pop.png'
import login_back from "./images/plantika_back.png"
import Logo from './images/plantika_logo.svg'
import { mobileMaxWidth } from "../../constants/responsive-pop-up";
import { CenterFocusStrong, LocalConvenienceStoreOutlined } from "@material-ui/icons";
import Axios from "axios";
import { createHeaders } from "../../constants/api-urls";
import { API } from "../../tools/keys/keys";


polyfill()

class Login extends Component {
  constructor() {
    super();
    this.state = {
      user: "",
      password: "",
      redirect: false,
      correct_user: true,
      load_button: false,
      script_load_button: false,
      script_status: 'הפעל סקריפט'
      // headers: [     
      //   { label: ' שבוע  מספר', key: 'y' },
      //   { label: 'ראשון', key: 'details.a' },
      //   { label: 'שני', key: 'details.b' },
      //   { label: 'שלישי', key: 'details.c' },
      //   { label: 'רביעי', key: 'details.d' },
      //   { label: 'חמישי', key: 'details.e' },
      // ],
      // data: [   
      //   { details: { a:  ["הדר",  " \n30", " \n2"] } , y: 'ייצור'},
      //   { details: { a:  ["רובי",  " \n30", " \n2"] } , y: 'ייצור'},
      //   { details: { a:  ["דניס",  " \n30", " \n2"] }, y: 'מסירה'},
      //   { details: { c:  ["לירז",  " \n30", " \n2"] }, y: 'התקנה'},
      //   { details: { a:  " "}, y: '   '},
      //   { details: { a:  "ראשון",b:  "שני", c:  "שלישי", d:  "רביעי", e:  "חמישי"}, y: 'שבוע מספר'},
      //   { details: { a:  ["הדר",  " \n30", " \n2"] } , y: 'ייצור'},
      //   { details: { a:  ["רובי",  " \n30", " \n2"] } , y: 'ייצור'},
      // ]
    };
  }



  submit = async e => {
    this.mobileView();
    e.preventDefault();
    const { user, password } = this.state;
    this.setState({
      load_button: true
    }, async () =>{
        
      const body = { email: user, password: password };
      const login_api = await getUserDetails(body);
    
      //api call true
      if (login_api && login_api !=null && login_api.token !=null) {
        setCookie("login_cookie", login_api.token);
        this.props.setUserData(login_api)
        // this.props.setUserToken(login_api.token)
        this.props.updateLogin(true)
        this.setState({
          redirect: true,
          correct_user: true,
          load_button: false
        });
      }else{ 
        this.setState({
          correct_user: false,
          load_button: false
        })
      }
    })
  
  };

  mobileView(){
    let isMobile = window.innerWidth <= mobileMaxWidth;
    if(isMobile){
        const rootView = document.getElementById("root")
        rootView.scrollIntoView();
    }
}

  updateState = e => {

    this.setState({
      [e.target.name]: e.target.value,
      correct_user: true
    });
  };


  addItem = (item) =>{
    this.props.addItems(item)
  }

  render() {
    const {correct_user, load_button, script_status, script_load_button} = this.state
    let dynamicFactoryName;
    if((typeof this.props?.login?.user !== 'undefined') && this.props?.login?.user.hasOwnProperty('factory_name')) {
      dynamicFactoryName = this.props.login.user.factory_name
    }
    //dynamic url factory name
    // const dynamicFactoryName = this.props.login.user.factory_name
    let script_btn_style = {
      width: '20px',
      height: '20px',
      border: '2px solid #9e9e9e',
      borderRadius:'50%',
      opacity: '0.041',
      cursor: 'pointer'
    }

    return (
      <div className="login__container">
       <div className="img__background" style={{backgroundImage:"url("+login_back+")"}}></div>
        {this.state.redirect ? (
          
          <Redirect to={"/" + dynamicFactoryName} />
          
        ) : (

          <div className="login__box__container">

            <form onSubmit={e => this.submit(e)}>

                <section className="login__box__container__form-control">
                    <h1>שם משתמש</h1>
                    <input
                    onChange={e => this.updateState(e)}
                    type="text"
                    name="user"
                    placeholder="הכנס שם משתמש"
                    ></input>
                </section>

                <section className="login__box__container__form-control">
                    <h1>סיסמה</h1>
                    <input
                    onChange={e => this.updateState(e)}
                    type="password"
                    name="password"
                    placeholder="הכנס סיסמה"
                    ></input>
                </section>
               
               
                <button type="submit">{ load_button ? <Loader /> : "כניסה"}</button> 
                {correct_user ? null :  <div className="login__validation">שם משתמש או סיסמה לא תקין</div> }
               
                {/* <section style={{margin: 'auto'}} id="scriptRunBtn">
                  <div style={{
                    width: '277px',
                    height: '34px',
                    border: 'none',
                    borderRadius: '20px',
                    background: '#56dded',
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: '500',
                    textAlign: 'center',
                    paddingTop: '9px',
                    cursor: 'pointer',
                    letterSpacing: '1px',
                  }}
                  
                  onMouseEnter={() => {
                    const btn = document.getElementById("scriptRunBtn");
                    btn.style.transition = 'transform .2s';
                    btn.style.transform =  "scale(1.1)"
                  }}

                  onMouseOut={() => {
                    const btn = document.getElementById("scriptRunBtn");
                    btn.style.transition = '';
                    btn.style.transform =  ""
                  }}
                  >
                    {script_load_button ? <Loader /> : script_status || 'התסריט בוצע'}
                  </div>
                </section> */}
            </form>

            <figure>
                <img src={Bg} alt=""/>
                <div>
                   <img src={Logo} alt=""/>
                </div>
            </figure>

          </div>

        )}
       
      </div>
    );
  }
}

function mapStateToProps({ router, login}) {
  return { router, login };
}
export default connect(mapStateToProps, actions)(Login);
