import { SET_SHOW_SIDEBAR_CALENDER } from "../actions/types";


const initialState = {
    showCalendarSidebar: true,
};

export default function generalReducer(state = initialState, action){
    switch (action.type) {
        case SET_SHOW_SIDEBAR_CALENDER:
            return { ...state, showCalendarSidebar : action.payload};
        default:
            return state;
            break;
    }
}