import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import he from "date-fns/locale/he";
import "moment/locale/he";
import moment from "moment";
import {
  getAllFactory,
  getAllSubDepartment,
  getAllProcesses,
  getProcess,
  setProcessDetais,
  getAllOrders,
  getOrderProcesses,
  setOrderProcessDetais,
  getOrderProcess,
} from "../../../actions/actions";
import {
  TextField,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  },
  formSelect: {
    margin: theme.spacing(1),
    minWidth: 223,
    maxWidth:223
  },
  formMultiSelect: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
    padding: theme.spacing(1),
  },
  formCheck: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  firstRow: {   
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: 223,
    },
  },
}));

export const ActualGanttDetails = (props) => {
  const dispatch = useDispatch();
  const procDetails = useSelector(
    (state) => state.ganttActual.orderProcessesDetails
  );
  const factoryList = useSelector((state) => state.factories.listFactories);

  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const subDepartmentsList = useSelector(
    (state) => state.subDepartments.listSubDepartments
  );

  const processeslist = useSelector(
    (state) => state.ganttTemplate.listProcesses
  );

  const orderlist = useSelector((state) => state.adminOrders.listOrders);

  useEffect(() => {
    if (props.id) {
      dispatch(getOrderProcess(props.id));
    }
    return function cleanup() {
      dispatch(setOrderProcessDetais(null));
    };
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllSubDepartment());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllProcesses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <Grid container spacing={0}>
      <>
        {props.id !== undefined && procDetails && (
          <ActualGanttForm
            id={props.id}
            procDetails={procDetails}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            subDepartmentsList={subDepartmentsList}
            processeslist={processeslist}
            orderlist={orderlist}
          />
        )}
        {props.id === undefined && (
          <ActualGanttForm
            id={props.id}
            procDetails={procDetails}
            onSubmit={props.onSubmit}
            factoryList={factoryList}
            subDepartmentsList={subDepartmentsList}
            processeslist={processeslist}
            filterFactory={filterFactory}
            orderlist={orderlist}
          />
        )}
      </>
    </Grid>
  );
};

function ActualGanttForm(props) {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const {
    processeslist,
    id,
    factoryList,
    subDepartmentsList,
    filterFactory,
    procDetails,
    orderlist,
  } = props;

  const getSubDepartmentList = () => {
    if (values.factory_id) {
      let depts = [];
      depts = subDepartmentsList.filter((element) => {
        return element.factory_id === values.factory_id;
      });
      return depts;
    } else {
      return subDepartmentsList;
    }
  };

  const getProcessesList = () => {
    if (values.factory_id) {
      let process = [];
      process = processeslist
        ? processeslist.filter((element) => {
            return element.factory_id === values.factory_id;
          })
        : [];
      return process;
    } else {      
      return [];
    }
  };

  const getOrderList = () => {
    if (values.factory_id) {
      let orders = [];
      orders = orderlist
        ? orderlist.filter((element) => {
            return element.factory_id === values.factory_id;
          })
        : [];
      return orders;
    } else {   
      return [];
    }
  };

  const [values, setValues] = useState({   
    factory_id: procDetails ? procDetails.factory_id : null,
    sub_department_id: procDetails ? procDetails.sub_department_id : null,
    order_id: procDetails ? procDetails.order_id : null,
    process_id: procDetails ? procDetails.process_id : null,
    previous_process_id: procDetails ? procDetails.previous_process_id : null,
    backlog: procDetails ? procDetails.backlog : null,
    cancelled: procDetails ? procDetails.cancelled : null,
    done: procDetails ? procDetails.done : null,
    finished: procDetails ? procDetails.finished : null,
    fraction: procDetails ? procDetails.fraction : null,
    is_detached: procDetails ? procDetails.is_detached : null,
    order_index: procDetails ? procDetails.order_index : null,
    original_process_date: procDetails && procDetails.original_process_date
      ? procDetails.original_process_date.toString().substring(0, 19)
      : null,
    process_date: procDetails && procDetails.process_date ? procDetails.process_date.toString().substring(0, 19) : null,
    sent_to_backlogs: procDetails && procDetails.sent_to_backlogs? procDetails.sent_to_backlogs.toString().substring(0, 19) : null,
    quantity: procDetails ? procDetails.quantity : null,
    color: procDetails ? procDetails.color : null,
    // labour_cost: procDetails?.labour_cost || '',
  });

  const getFactoryId = () => {
    let res = procDetails
      ? procDetails.factory_id
      : filterFactory
      ? filterFactory
      : null;
    setValues({ ...values, factory_id: res });
  };

  const initFactoryId = useMemo(() => getFactoryId(), [filterFactory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const {
     
      sub_department_id,
      factory_id,
      order_id,
      process_id,
      previous_process_id,
      backlog,
      cancelled,
      done,
      finished,
      fraction,
      is_detached,
      order_index,
      original_process_date,
      process_date,
      sent_to_backlogs,
      quantity,
      color,
      // labour_cost,
    } = values;
    let model;

    if (id) {
      model = {
        _id: id,
        sub_department_id,
        factory_id,
        order_id,
        process_id,
        previous_process_id,
        backlog,
        cancelled,
        done,
        finished,
        fraction,
        is_detached,
        order_index,
        original_process_date,
        process_date,
        sent_to_backlogs,
        quantity,
        color,
        // labour_cost,
      };
    } else {
      model = {
        sub_department_id,
        factory_id,
        order_id,
        process_id,
        previous_process_id,
        backlog,
        cancelled,
        done,
        finished,
        fraction,
        is_detached,
        order_index,
        original_process_date,
        process_date,
        sent_to_backlogs,
        quantity,
        color,
        // labour_cost,
      };
    }
    props.onSubmit(id, model);
  };

  return (
    <div>
      <form id="edit-gantt-actual" onSubmit={onSubmit} className={classes.form}>
        <Grid container direction="column">
          <Grid item className={classes.firstRow}>
            <TextField
              name="id"
              value={id ? id : "new"}
              disabled
              InputProps={{
                perUser: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="quantity"
              variant="outlined"
              label="quantity"
              type="number"
              value={values.quantity}
              onChange={handleInputChange}
            />
            <TextField
              name="finished"
              variant="outlined"
              label="finished"
              type="number"
              value={values.finished}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <TextField
              name="order_index"
              variant="outlined"
              label="order index"
              type="number"
              value={values.order_index}
              onChange={handleInputChange}
            />
           
            <TextField
              name="original_process_date"
              variant="outlined"
              label="original process date"
              value={values.original_process_date}
              type="datetime-local"
              className={classes.formSelect}
              InputLabelProps={{
                shrink: true,
              }}
               onChange={handleInputChange}  
            />
          </Grid>
          <Grid item>
           
            <TextField
              name="process_date"
              variant="outlined"
              label="process date"
              value={values.process_date}
              type="datetime-local"
              className={classes.formSelect}
              InputLabelProps={{
                shrink: true,
              }}
               onChange={handleInputChange}  
            />

            <TextField
              name="sent_to_backlogs"
              variant="outlined"
              label="sent to backlogs"
              value={values.sent_to_backlogs}
              type="datetime-local"
              className={classes.formSelect}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChange}  
            />
          </Grid>

          <Grid item>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="factory_label">factory</InputLabel>
              <Select
                labelId="factory_label"
                id="factory_id"
                value={values.factory_id}
                onChange={handleInputChange}
                label="factory"
                name="factory_id"
              >
                {factoryList.map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="sub_department_label">sub department</InputLabel>
              <Select
                labelId="sub_department_label"
                id="sub_department_id"
                value={values.sub_department_id}
                onChange={handleInputChange}
                label="sub department"
                name="sub_department_id"
              >
               
                {getSubDepartmentList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="process_id_label">process</InputLabel>
              <Select
                labelId="process_id_label"
                id="process_id_id"
                value={values.process_id}
                onChange={handleInputChange}
                label="process"
                name="process_id"
              >
                {getProcessesList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formSelect}>
              <InputLabel id="order_id_label">order</InputLabel>
              <Select
                labelId="order_id_label"
                id="order_id"
                value={values.order_id}
                onChange={handleInputChange}
                label="order"
                name="order_id"
              >
                {getOrderList().map((element) => {
                  return (
                    <MenuItem key={element._id} value={element._id}>
                      {element.order_number}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item>
            <TextField
              name="labour_cost"
              variant="outlined"
              label="labour cost"
              type="number"
              value={values.labour_cost}
              onChange={handleInputChange}
            />
          </Grid> */}

          <Grid item>
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="backlog"
                  checked={values.backlog}
                  onChange={handleCheckboxChange}
                />
              }
              label="backlog"
            />
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="cancelled"
                  checked={values.cancelled}
                  onChange={handleCheckboxChange}
                />
              }
              label="cancelled"
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="constant"
                  checked={values.constant}
                  onChange={handleCheckboxChange}
                />
              }
              label="constant"
            />
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="done"
                  checked={values.done}
                  onChange={handleCheckboxChange}
                />
              }
              label="done"
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="fraction"
                  checked={values.fraction}
                  onChange={handleCheckboxChange}
                />
              }
              label="fraction"
            />
            <FormControlLabel
              className={classes.formCheck}
              control={
                <Checkbox
                  name="is_detached"
                  checked={values.is_detached}
                  onChange={handleCheckboxChange}
                />
              }
              label="is_detached"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
