import React from 'react';
import Box from '@material-ui/core/Box';
import PopUpButtons from "../../../CustomersPage/reused_components/PopUpButtons";
import DialogPopup from "../../../../components/reused-components/DialogPopup/dialogPopup";
import { Typography } from "@material-ui/core";

const DeleteQuoteCnfPopup = (props) => {
    const {okCallback, cancelCallback, isOpen } = props

    return (
        <DialogPopup
            width={'auto'}
            height={'auto'}
            isOpen={isOpen}
            actions={<PopUpButtons
            handleClose={cancelCallback}
            handleCloseAndSave={okCallback}
            ok={'כן'}
            cancell={'לא'}
        />}
            contentStyle={{overflow: 'hidden', minHeight: '0px', paddingTop: '10px'}}
            content={<Box component="div" overflow="hidden" p={3}>
                <Typography variant="h6" align="center" gutterBottom>
                  {'האם כדאי לי לשמור?'}
                </Typography>
            </Box>}
        />
      )
}

export default DeleteQuoteCnfPopup