import { useState, useCallback, useRef } from 'react'
import { generalGetRequest } from '../functions/api/general'
import uuid from 'uuid'
import { TEST_FLOOR_CHECK_LIST_DATA } from '../constants/offline-mode'

export const useTestsPerFloor = (actualSystemId) => {
  const [testsPerFloor, setTestsPerFloor] = useState('')
  const selectedRowIdx = useRef('')
  const rowTitle = useRef('')

  const createRow = (tests) => {
    const { table_head, data } = tests
    const values = table_head?.map(i => {
      const { high_limit, low_limit, test_conclusion, input_type } = i
      const newCell = {
        value: "",
        high_limit,
        low_limit,
        test_conclusion,
        input_type
      }

      return newCell

    })
    const newData = {
      _id: uuid(),
      values
    }

    return newData

  }

  const createFirstRow = (tests) => {
    if (tests?.data?.length) {
      return
    } else {
      tests.data = [createRow(tests)]
    }
  }

  const addRow = () => {
    const tests = { ...testsPerFloor }
    const newRow = createRow(tests)
    tests.data.push(newRow)
    setTestsPerFloor(tests)
  }

  const deleteRow = (rowId) => {
    const tests = { ...testsPerFloor }
    const testIndex = tests.data.findIndex(i => i._id === rowId)
    tests.data.splice(testIndex, 1)
    setTestsPerFloor(tests)
  }

  const getTestsPerFloor = useCallback(async (test, orderId, rowIdx) => {
    // test = {
    //   icon: "/static/media/icon_Measurement of currents.a7f120e5.svg"
    //   order: 1
    //   row_title: "test_per_floor_id"
    //   show_stopper: ""
    //   test_per_floor_id: "61ae198bb4cf631adb7acbbd"
    //   values: (7)[{ … }, { … }, { … }, { … }, { … }, { … }, { … }]
    //   _id: "61aa1a5f61246a34d3afd06b"
    // }
    const { test_per_floor_id, _id: testId, row_title} = test
    const createUrl = () => `/system/order-systems/tests-per-floor?test_per_floor_id=${test_per_floor_id}&test_id=${testId}&order_id=${orderId}&actual_system_id=${actualSystemId}`
    const local_data_url = `${test_per_floor_id}${testId}${orderId}${actualSystemId}`
    if (test_per_floor_id && testId && orderId) {
      selectedRowIdx.current = rowIdx
      rowTitle.current = row_title
      const url = createUrl()
      // const responce = await generalGetRequest(url)
      const responce = JSON.parse(localStorage.getItem(TEST_FLOOR_CHECK_LIST_DATA))[local_data_url];
      const result = responce || ''
      createFirstRow(result)
      setTestsPerFloor(result)
    } else {
      selectedRowIdx.current = ''
      rowTitle.current = ''
      setTestsPerFloor('')
    }
  }, [setTestsPerFloor])

  return ({
    testsPerFloor,
    setTestsPerFloor,
    getTestsPerFloor,
    addRow,
    deleteRow,
    selectedRowIdx: selectedRowIdx.current,
    selectedRowTitle: rowTitle.current
  })
}