/* eslint-disable react-hooks/exhaustive-deps */

import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Input from '../../Customer/InputFields/Input'
import SelectInput from '../../Customer/InputFields/SelectInput/SelectInput'
import Button from '../../Customer/Buttons/Button'
import CheckButton from '../../Customer/Buttons/CheckButton/CheckButton';
import "./OrderDetails.scss"
import { CUSTOMERS_PAGE } from '../../../constants/translations/customersPage'
import { getOrderSystems } from '../../../functions/api/orders'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setOrderDataForPopup } from '../../../actions/mainPopup_actions'
import { generalGetRequest } from '../../../functions/api/general'
import { inputs, proposalCheckBtn, cancelReservationCheckBtn, add, cancel } from './OrderDetailsStatic'

function formateDate(date){
    let newDate = moment(date).format("YYYY-MM-DD")
    return newDate
}




function getProjectMangerOfProject(projectManagersList, employeesList=[]){
    const projectManagersMap = {};
    const employeesMap = {};
    for (let pm = 0; pm < projectManagersList.length; pm++){
        if (!projectManagersMap[projectManagersList[pm]._id]){
            projectManagersMap[projectManagersList[pm]._id] = projectManagersList[pm]
        }
    }
    for (let e = 0; e < employeesList.length; e++) {
        if (!employeesMap[employeesList[e]._id]) {
            employeesMap[employeesList[e]._id] = employeesList[e]
        }
    }

    let projectManager; 
    for (let id in employeesMap){
        if (projectManagersMap[id]){
            projectManager = projectManagersMap[id]
        }else{
            projectManager = {}
        }
    }

    return projectManager
}

// doAction needs to be called
export default function OrderDetails(props) {
    const { handleShowPopUp, showPopUp, categoryOptions, typeOptions, updateFormData, updateFormDataCategory, 
        handleAddOrder, errors, projectManagers, handleClosePopup, orderId, updateFormDataCheckBox 
    } = props;
    const [orderDetailsOfForm, setOrderDetailsOfForm] = useState({})
    const [currentCustomer, setCurrentCustomer] = useState({customer_number: ''})

    const projectManager = getProjectMangerOfProject(projectManagers?.employees || [], orderDetailsOfForm.employees)

    const dispatch = useDispatch();

   async function getFormData(){
        const formDataRes = await getOrderSystems(orderId)
       dispatch(setOrderDataForPopup(formDataRes.result))
       setOrderDetailsOfForm(formDataRes.result)
    }

    
    useEffect(() => {
        getFormData()
    }, [orderId])

    async function getCustomersList(currentFactory_id){
     const {result} = await generalGetRequest(`/system/order-systems/get-customers-list?factory_id=${currentFactory_id}`)
         // or we can match by  email, in some the cases name and number both are same
        const customer = result ? result.find((_customer) => _customer._id === orderDetailsOfForm.customer_id) : {}
        if (customer) setCurrentCustomer(customer);
    }
    useEffect(() => {
        getCustomersList(orderDetailsOfForm.factory_id)
     }, [orderDetailsOfForm.factory_id])

    return (
        <Grid className='orderDetails__main'>
            <Grid xs={12} className='orderDetails__checkBtnContainer'>
                <CheckButton 
                data={{ 
                    ...cancelReservationCheckBtn, 
                    isChecked: orderDetailsOfForm.cancelled,
                    updateFormDataCheckBox: updateFormDataCheckBox 
                    }} />
                &nbsp;
                &nbsp;
                <CheckButton 
                data={{ 
                    ...proposalCheckBtn, 
                    isChecked: !orderDetailsOfForm.started,
                    updateFormDataCheckBox: updateFormDataCheckBox 
                    }} />
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.ORDER_DETAILS}</span></Grid>
      
                <Grid className='orderDetails__inputRow__inputs'>
                    {
                        inputs.orderDetails?.map((input, index) => {
                            if (input.type === 'select') {
                                if (input.field === 'Category') {
                                    return <Grid xs={2} key={index} style={{ marginLeft: '4px' }}>
                                    <SelectInput 
                                    data={{
                                        ...input, 
                                    value: (categoryOptions.length && orderDetailsOfForm.order_category_id) ? categoryOptions?.find((_category) => _category._id === orderDetailsOfForm?.order_category_id) : '',
                                    selectOptions: categoryOptions, 
                                    handleChange: updateFormData, 
                                    error: !!(errors?.category && errors.category.length), 
                                    errorText: errors?.category 
                                    }} 
                                    />
                                    </Grid>
                                } else if (input.field === 'Order type') {
                                    
                                    return <Grid xs={2} key={index} style={{marginLeft: '4px'}}>
                                        <SelectInput
                                            data={{
                                                ...input,
                                                value: orderDetailsOfForm.type ? orderDetailsOfForm.type : '',
                                                // placeholder: orderDetailsOfForm.type?.name,
                                                selectOptions: typeOptions,
                                                handleChange: updateFormData,
                                            }}
                                        />
                                    </Grid>
                                }
                                return <Grid xs={2} key={index}><SelectInput data={ input } /></Grid>
                            }else{
                                if (input.field === 'Order Number'){
                                    return <Grid xs={2} key={index}>
                                        <Input
                                            data={{
                                                ...input,
                                                value: orderDetailsOfForm.order_number,
                                                onChange: updateFormData,
                                                // disabled : true,
                                                error: !!(errors[input.name] && errors[input.name].length),
                                                errorText: errors[input.name],
                                            }}
                                        /></Grid>
                                } else if (input.field === 'Delivery date') {
                                    return <Grid xs={2} key={index}>
                                        <Input
                                            data={{
                                                ...input,
                                                value: formateDate(orderDetailsOfForm.due_date),
                                                onChange: updateFormData
                                            }} /></Grid>
                                } else if (input.field === '# of developers'){
                                    return <Input
                                        key={index}
                                        data={{
                                            ...input,
                                            value: orderDetailsOfForm?.quantity, 
                                            error: !!(errors[input.name] && errors[input.name].length),
                                            errorText: errors[input.name],
                                            onChange: updateFormData
                                        }}
                                    />
                                } else if (input.field === 'Customer Number') {
                                    return <Input
                                        key={index}
                                        data={{
                                            ...input,
                                            value: currentCustomer.customer_number ? currentCustomer.customer_number : '',
                                            error: !!(errors[input.name] && errors[input.name].length),
                                            errorText: errors[input.name],
                                            onChange: updateFormData
                                        }}
                                    />
                                }
                                else{
                                    return <Grid xs={2} key={index}><Input data={input} /></Grid>
                                }
                            }
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.CONTACT_AND_ADDRESS}</span></Grid>
                <Grid className='orderDetails__inputRow__inputs'>
                    {
                        inputs.contactsAddress.map((input, index) => {
                            if (input.field === 'Customer Name'){
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, 
                                        value: orderDetailsOfForm.client_name, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'City') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input,
                                        value: orderDetailsOfForm.city,
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'Phone number') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, 
                                        value: orderDetailsOfForm.client_phone, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'Email') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, value: orderDetailsOfForm.client_email, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'Address') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            }
                            return <Input key={index} data={{ ...input, error: !!(errors[input.name] && errors[input.name].length), errorText: errors[input.name], handleChange: updateFormData  }} />
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.CUSTOMER_DETAILS}</span></Grid>
                <Grid className='orderDetails__inputRow__inputs'>
                    {
                        inputs.customerDetails.map((input, index) => {
                            if (input.type === 'select') {
                                if (input.field === 'Project manager') {
                                    return <Grid xs={2} key={index} style={{ marginLeft: '4px' }}>
                                        <SelectInput
                                            data={{
                                                ...input,
                                                // value: projectManager? projectManager : '',
                                                selectOptions: projectManagers?.employees || [],
                                                handleChange: updateFormData,
                                                error: !!(errors[input.name] && errors[input.name].length),
                                                errorText: errors[input.name],
                                            }}
                                        />
                                    </Grid>
                                }
                                return <Grid xs={2} key={index} style={{ marginLeft: '4px' }}>
                                <SelectInput 
                                        data={{
                                            ...input,
                                            selectOptions: [{ _id: 1, name: 'sales-representative-1' }],
                                            handleChange: updateFormData,
                                        }} />
                                </Grid>
                            }
                            return <Grid xs={2} key={index}>
                                <Input
                                    data={{
                                        ...input,
                                        onChange: updateFormData,
                                        error: false
                                    }} />
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.OTHER_DETAILS}</span></Grid>
                <Grid className='orderDetails__inputRow__inputs' style={{ display: 'flex', justifyContent: 'right' }}>
                    {
                        inputs.otherDetails.map((input, index) => {
                            if (input.type === 'select') {
                                return <Grid xs={4} key={index} style={{ marginLeft: '4px' }}>
                                <SelectInput 
                                data={{
                                    ...input,
                                    selectOptions: [{_id: 1, name: 'amendment-1'}],
                                    handleChange: updateFormData,
                                    }} 
                                />
                                </Grid>
                            }else {
                                if (input.field === 'Transaction value'){
                                    return <Grid xs={3} key={index}>
                                    <Input 
                                    data={{ 
                                        ...input, 
                                        value: orderDetailsOfForm.value, 
                                        error: !!(errors[input.name] && errors[input.name].length), 
                                        errorText: errors[input.name], 
                                        onChange: updateFormData 
                                        }} />
                                    </Grid>
                                }else{
                                    return <Grid xs={3} key={index}>
                                    <Input 
                                    data={{
                                        ...input,
                                        onChange: updateFormData,
                                        error: false
                                    }} /></Grid>
                                }
                            }
                           
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__btn__Container addOrderFooter'>
                <Button data={add} handleClick={()=>handleAddOrder()} />
                &nbsp;
                &nbsp;
                &nbsp;
                <Button data={cancel} handleClick={() => handleClosePopup()} />
            </Grid>
        </Grid>
    )
}
