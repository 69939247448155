import * as types from "../actions/types";
import { QUOTE } from "../constants/translations/customersPage";
import moment from "moment";
const { DRAFT } = QUOTE;

const initialState = {
  number: "",
  name: "",
  address: "",
  phone: "",
  dueDate: moment().format("YYYY-MM-DD"),
  products: [],
  activeLocation: "",
  totalAmount: "0",
  quantityOfProducts: "0",
  readOnly:false,
  printActive:false,
  quoteInfo:'',
  wasQuoteInfoChanged: false,
  currentBidsPageName: 'prevQuotes'
};

export default function quotationReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CUSTOMER_INFO:
      return {
        ...state,
        name: action.payload.name,
        address: action.payload.address,
        phone: action.payload.phone,
      };
    case types.SET_ACTIVE_LOCATION:
      return {
        ...state,
        activeLocation: action.payload.currentLocationIndex,
        products: action.payload.locations,
      };
    case types.CREATE_PRODUCT:
      return {
        ...state,
        products: action.payload,
        activeLocation: "",
      };
    case types.SET_QUOTE_DATA:
      return {
        ...state,
       quoteInfo: action.payload
      };
    case types.CLEAR_QUOTE_DATA:
      return {
        ...state,
        number: "",
        name: "",
        address: "",
        phone: "",
        dueDate: moment().format("YYYY-MM-DD"),
        status: DRAFT,
        products: [],
        activeLocation: "",
        quoteInfo:''
      };
    case types.SET_TOTAL_AMOUNT:
      return {
        ...state,
        totalAmount: action.payload,
      };
    case types.SET_PRODUCTS_QUANTITY:
      return {
        ...state,
        quantityOfProducts: action.payload,
      };
    case types.SET_EDITING_STATUS:
      return {
        ...state,
        readOnly: action.payload
      }
    case types.SET_PRINT_ACTIVE:
      return{
        ...state,
        printActive: action.payload

      }
    case types.SET_QUOTE_INFO_WAS_CHANGED:
        return{
          ...state,
          wasQuoteInfoChanged: action.payload
  
        }
    case types.SET_CURRENT_BIDS_PAGE_NAME:
      return {
        ...state,
        currentBidsPageName: action.payload
      }
    default:
      return state;
  }
}
