import { TABLET_VIEW_TRANSLATION } from "../../../../constants/translations/customer/tablet/tabletView"
import { CANCEL, SAVE } from "../../../../constants/translations/review-popup"

export const taskTabBtns = {
    task: {
        name: TABLET_VIEW_TRANSLATION.TASK,
        width: '30%',
        height: '70px',
        color: '#fff',
        backgroundColor: "#186315",
        fontSize: '24px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        iconContainerWidth: '32px',
        border: false,
        borderRadius: '90px',
        buttonName: 'AddIcon'
    },
    startTimeCount: {
        name: `${TABLET_VIEW_TRANSLATION.STARTED_AT}`, //'Started at 9 am',
        width: '40%',
        height: '70px',
        color: '#186315',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        iconContainerWidth: '32px',
        border: true,
        borderRadius: '90px',
        buttonName: 'play'
    },
    save: {
        name: SAVE,
        width: '30%',
        height: '70px',
        color: '#fff',
        backgroundColor: "#0091FF",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        borderRadius: '90px',
    },
    cancel: {
        name: CANCEL,
        width: '30%',
        height: '70px',
        color: '#0091FF',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        borderRadius: '90px',
    }
}


export const taskTabBtnsDesktop = {
    ...taskTabBtns,
    task: {
        ...taskTabBtns.task,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        iconContainerWidth: '26px',
        width: '40%',
    },
    save: {
        ...taskTabBtns.save,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        width: '50%',
    },
    cancel: {
        ...taskTabBtns.cancel,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        width: '50%',
    }
}

export const taskTabBtnsTablet = {
    startTimeCount: {
        ...taskTabBtns.startTimeCount,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        iconContainerWidht: '26px',
        width: '50%',
    },
    task: {
        ...taskTabBtns.task,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        iconContainerWidht: '26px',
        width: '40%',
    },
    save: {
        ...taskTabBtnsDesktop.save,
        with: '35%'
    },
    cancel: {
        ...taskTabBtnsDesktop.cancel,
        with: '35%'
    },
}

