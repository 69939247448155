import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core";
import { updateProcess, deleteProcess,updateProcessWithOrders } from "../../../actions/actions";
import { changeCurrentPage, changeRowsPerPage, deleteProcessWithOrders  } from "../../../actions/gantt_template_actions";
import TablePagination from '@material-ui/core/TablePagination';
import { GanttTemplateDetails } from "./GanttTemplateDetails";
import DetailsDialog from "../DetailsDialog";

const headTableConfig = [
  {
    id: "factory_id",
    label: "id factory",
    type: "string",
  },
  {
    id: "factory_name",
    label: "name factory",
    type: "string",
  },
  {
    id: "sub_department_id",
    label: "id sub department",
    type: "string",
  },
  {
    id: "sub_department_name",
    label: "name sub department",
    type: "string",
  },

  {
    id: "future_parallel_process_id",
    label: "future parallel process_id",
    type: "array",
    funcHandler: () => {},
  },
  //  TO DO
  // {
  //   id: "future_parallel_process_name",
  //   label: "future parallel process name",
  //   type: "array",
  //   funcHandler: () => {},
  // },
  {
    id: "gap_from_previous",
    label: "gap from previous",
    type: "numeric",
  },
  {
    id: "planning",
    label: "planning",
    type: "boolean",
  },
  {
    id: "mps",
    label: "mps",
    type: "numeric",
  },
  {
    id: "work_cost_percentage",
    label: "work cost percentage",
    type: "numeric",
  },
  {
    id: "duration",
    label: "duration",
    type: "numeric",
  },
  {
    id: "quantity",
    label: "quantity",
    type: "numeric",
  },
  {
    id: "constant",
    label: "constant",
    type: "boolean",
  },

  {
    id: "color",
    label: "color",
    type: "color",
  },
  {
    id: "is_parallel_process",
    label: "is parallel process",
    type: "boolean",
  },
  {
    id: "previous_process_id",
    label: "previous process id",
    type: "string",
  },
  //  TO DO
  // {
  //   id: "previous_process_name",
  //   label: "previous process name",
  //   type: "string",
  // },
  {
    id: "constraints_process_id",
    label: "constraints process id",
    type: "array",
    funcHandler: () => {},
  },
  //  TO DO
  // {
  //   id: "constraints_process_name",
  //   label: "constraints process name",
  //   type: "array",
  //   funcHandler: () => {},
  // },

  {
    id: "order",
    label: "order",
    type: "string",
  },
  {
    id: "last_process",
    label: "last process",
    type: "boolean",
  },
  {
    id: "type",
    label: "type",
    type: "numeric",
  },
  {
    id: "_id",
    label: "id",
    type: "id",
  },
  {
    id: "name",
    label: "name",
    type: "string",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table:{
    direction: "rtl"
  },
  container: {
    maxHeight: "calc(100vh - 160px)",
    direction: "ltr"
  },
  pagination: {
    direction: "ltr",
  }, 
  button: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const StyledHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243748",
    color: theme.palette.common.white,
    left: 'unset'
  },
  body: {
    fontSize: 14,
    fontFamily: "Rubik",
  },
}))(TableCell);

function TableHeadOrg(props) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headTableConfig.map((headCell) => (
          <StyledHeadCell
            component="th"
            scope="row"
            key={headCell.id}
            align={headCell.align}
          >
            <div className={classes.headCell}>{headCell.label}</div>
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableGanttTemplate(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { processeslist, countGantt } = props;
  const filterFactory = useSelector((state) => state.factories.filterFactory);

  const [isOpen, setIsOpen] = React.useState(false);
  const [idCurrent, setIdCurrent] = React.useState(undefined);
  const [isAddToOrder, setIsAddToOrder] = React.useState(false)
  const page = useSelector(state => state.ganttTemplate.currentPage);
  const rowsPerPage = useSelector(state => state.ganttTemplate.rowsPerPage);

  const handleEdit = (id) => {
    setIdCurrent(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onDelete = (id) => {
    setIsOpen(false);   
    if (isAddToOrder){
      dispatch(deleteProcessWithOrders({ _id: id }, filterFactory));
    }
    else {
      dispatch(deleteProcess({ _id: id }, filterFactory));
    }
  };

  const onSubmit = (id, model, isAddToOrder) => {
    setIsOpen(false);
    if (isAddToOrder){
      dispatch(updateProcessWithOrders(model, filterFactory));
    }
    else {
      dispatch(updateProcess(model, filterFactory));
    }
   
  };

  const handleChangePage = (event, newPage) => {
    dispatch(changeCurrentPage(newPage));
};

const handleChangeRowsPerPage = event => {  
    dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
};

const getIsAddToOrder = value => { 
  setIsAddToOrder({isAddToOrder: value })
};
  return (
    <div className={classes.root}>
      <DetailsDialog
        isOpen={isOpen}
        onClose={handleClose}
        submitLabel="Update"
        deleteLabel="Delete"
        form="edit-gantt-template"
        onDelete={onDelete}
        title="Edit Gantt Template"
        id={idCurrent}
      >
        <GanttTemplateDetails
          id={idCurrent}
          onSubmit={onSubmit}
          getIsAddToOrder = {getIsAddToOrder}
        />
      </DetailsDialog>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={classes.container} >
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeadOrg />
            <TableBody>
              {processeslist &&
                processeslist.map((element) => {
                  return (
                    <TableRow
                      style={{ height: 33 }}
                      hover
                      tabIndex={-1}
                      key={element._id}
                      onClick={() => handleEdit(element._id)}
                    >
                      {headTableConfig.map((col) => {
                        let value = element[col.id];

                        switch (col.id) {
                          case "constraints_process_id":
                          case "constraints_process_name":
                          case "future_parallel_process_id":
                          case "future_parallel_process_name":
                            if (element[col.id])
                              value = element[col.id].length
                                ? element[col.id].join(",")
                                : element[col.id];
                            break;
                          case "planning":
                          case "constant":
                          case "is_parallel_process":
                          case "last_process":
                            value =
                              String(element[col.id]) === "undefined"
                                ? ""
                                : String(element[col.id]);
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell component="th" scope="row" key={col.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          colSpan={3}
          count={countGantt}
          rowsPerPage={rowsPerPage}
          page={page} SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
