export const MOBILE_LANDING_PAGE = {
    INSTALLATION: 'הַתקָנָה',
    ZAIN_BATOR: "ג'יין כמו",
    RESOURCE_NAME: 'שם המשאב',
    QUANTITY_ORDERED: 'כמות שהוזמנה',
    AMOUNT_REPORTED: 'כמות שדווחה',
    CANCEL_TERMINATION: 'בטל תחרות',
    REPORT_OF_COMPLETION: 'דווח על סיום',
    SUBMIT_FOR_COMPLETION: 'שלח להשלמות',
    PARALLEL: 'מַקְבִּיל',
    CANCEL: 'לְבַטֵל',
    FILE: 'קוֹבֶץ',
    TASK: 'מְשִׁימָה',
    SYSTEM: 'מערכת',

}