import {useState, useEffect} from 'react'
import { generalGetRequest } from "../functions/api/general";


export const useSelectProductData = (name, type, productId, setSeriesSelectedType) =>{
    const [productTypes, setProductTypes] = useState([])
    const [productModels, setProductModels] = useState([])
    const [products, setProducts] = useState([])
    const [series, setSeries] = useState([])

    useEffect(()=>{
        const getData = async() => {
            if(name && type){
                const {result} = await generalGetRequest(`/system/product/get-products?name=${name}&type=${type}`)
                setProducts(result)
            }else if(name){
                const {result} = await generalGetRequest(`/system/product/get-products?name=${name}`)
                setProductModels(result)
                setProducts([])
            }else{
                const {result} = await generalGetRequest(`/system/product/get-products`)
                setProductTypes(result)
                setProducts([])
                setProductModels([])
            }
        }
        getData()
    },[name, type])

    useEffect(()=>{
        const getData = async() => {
            if(productId){
                setSeriesSelectedType('')
                const {result} = await generalGetRequest(`/system/product/get-series?product_id=${productId}`)
                if(result) setSeries(result)
            }else{
                setSeries([])
                setSeriesSelectedType('')
            }
        }
        getData()
    },[productId])

    return [productTypes, productModels, products, series]
}