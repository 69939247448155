import { Grid } from '@material-ui/core'
import React, {useRef} from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import "./UploadFileButton.scss";
import UplaodFileIconSvg from '../../assets/UplaoadFile.svg'; 
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';


export default function UploadFileButton(props) {
  const {handleFileUpload, view} = props;
  const hiddenInputRef = useRef(null);
  const handleClick = event => {
    hiddenInputRef.current.click();
  };
  const handleInputChange =(e) =>{
    const uploadedFile = e.target.files[0];
    handleFileUpload(uploadedFile)
  }
  return (
    <>
      <Grid container spacing={0} className={view === 'tablet' ? 'uploadFileBtn__main--tablet' : view === 'desktop' ? 'uploadFileBtn__main--desktop' : 'uploadFileBtn__main'}>
        <input type='file' style={{ display: 'none' }}
          ref={hiddenInputRef}
          onChange={handleInputChange}
        />
        <button className={view === 'tablet' ? 'uploadFileBtn__btn--tablet' : 'uploadFileBtn__btn'} onClick={handleClick}>
          <span className={view === 'tablet' ? 'uploadFileBtn__btn-title--tablet' : 'uploadFileBtn__btn-title'}>
            {view === 'desktop' ? DESKTOP_VIEW_TRANSLATION.CHOOSE_FILE_TO_UPLOAD : POP_UPS_TRANSLATION.UPLOAD_FILE}
          </span>
        
          {
            view !== 'desktop' && <>  &nbsp;
              &nbsp;  <img src={UplaodFileIconSvg} alt='icon' className={view === 'tablet' ? 'uploadFileBtn__icon--tablet' : 'uploadFileBtn__icon'} /></> 
          }
          {/* <span className={view === 'tablet' ? 'uploadFileBtn__icon__container--tablet' : 'uploadFileBtn__icon__container'}>
          <CloudUploadIcon className={view === 'tablet' ? 'uploadFileBtn__icon--tablet' : 'uploadFileBtn__icon'}/>
          </span> */}
        </button>
      </Grid>
    </>
   
  )
}
