import React, { useRef, useState } from 'react';
import { Grid, TextField, Typography, makeStyles } from "@material-ui/core"
import "./CustomerInfoEditSection.scss";
import EditIcon from '@material-ui/icons/Edit';
import { DESKTOP_VIEW_TRANSLATION } from '../../../constants/translations/customer/desktop/desktopView';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerData } from '../../../actions/customers_table_actions';
import { uploadAvatar } from '../../../functions/api/customer-page';
import { API } from '../../../tools/keys/keys';
import EditCustomerPopUp from '../../CustomersPage/Popups/createCustomerPopUp';


const useStyles = makeStyles(() => ({
    customerInfo__main: {
        height: '58px',
        border: '1px solid #CACBCC',
        borderRadius: '10px',
        backgroundColor: '#F7F7F7',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'between'
    },
    customerInfo__info: {
        display: "flex",
        alignItems: 'center',
        margin: '10px'
    },
    customerInfo__info__icon: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        padding: '3px',
        cursor: 'pointer'
    },
    customerInfo__info__text: {
        fontSize: '20px',
        fontWeight: '500',
        fontFamily: 'Mplus 1p',
        margin: '10px',
        color: '#3C93D6'
    },
    customerInfo__info__edit: {
        fontSize: '12px',
        fontWeight: '800',
        fontFamily: 'Mplus 1p',
        margin: '10px',
        color: '#3C93D6'
    },
    customerInfo__defaultAltIcon : {
        width: '100%', 
        height: '100%', 
        borderRadius: '50%', 
        background: '#bdbdbd', 
        color: '#fafafa' ,
        fontSize: '20px',
        fontWeight: 800,
        padding: '8px 10px',
    }
}));

export default function CustomerInfo() {
    const classes = useStyles()
 
    const [open, setOpen] = useState(false)

    const hiddenInputRef = useRef(null);


    const customer = useSelector((state) => state.customersPage.customerData)
    const customerName = customer?.name;
    const customerId = customer._id
    const avatar = customer?.avatar

    const dispatch = useDispatch()

    const handleClick = event => {
        hiddenInputRef.current.click();
    };

    const addAvatarHandler = async (element) => {
        const formData = new FormData()
        formData.append('avatar', element.files[0])
        const avatar = await uploadAvatar(formData, customerId)
        const newCustomerData = { ...customer, avatar }
        dispatch(setCustomerData(newCustomerData))
    }
    

    const closeHandler = () => {
        setOpen(false)
    }

    const openHandler = () => {
        setOpen(true)
    }


    return (
        <>
        {

                open ? <>
                <div className='popUp__background'>
                    <EditCustomerPopUp
                        isOpen={open}
                        handleClose={closeHandler}
                        customer={customer}
                        updateOpenCustomer={true}
                        mode="edit"
                    />
                </div>
                   
                </>  : null

        }
            <Grid className={classes.customerInfo__main}>
                <Grid xs={8} className={classes.customerInfo__info}>
                    <Typography className={classes.customerInfo__info__icon}>
                        <input
                            ref={hiddenInputRef}
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => addAvatarHandler(e.target)}
                        />
                        {
                            avatar ? <img
                                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                                src={API + avatar}
                                alt='avatar'
                                onClick={handleClick}
                            /> : 
                                <span
                                    className={classes.customerInfo__defaultAltIcon}
                                    onClick={handleClick}
                                >
                                    A
                                </span>
                        }
                    </Typography>
                    <Typography className={classes.customerInfo__info__text} >{customerName}</Typography>
                </Grid>
                <Grid xs={2} >
                </Grid>
                <Grid xs={2}
                    style={{ display: 'flex', justifyContent: 'end' }}
                    className={classes.customerInfo__info}
                    onClick={openHandler}
                >
                    <Typography className={classes.customerInfo__info__text}>{DESKTOP_VIEW_TRANSLATION.EDIT}</Typography>
                    <Typography className={classes.customerInfo__info__icon} style={{ background: '#3C93D6', color: '#fff', fontSize: '12px' }}><EditIcon /></Typography>
                </Grid>
            </Grid>
           
        </>
      
    )
}
