import { GET_QUOTES_LIST } from "../../constants/customer/quoteConstants";
import { desktopQuoteList } from "../../constants/staticeData/QuotesList";


const initialState = {
    quotes: desktopQuoteList,
};


export default function quoteReducer(state=initialState, action){
    switch (action.type) {
        case GET_QUOTES_LIST:
            return {...state, quotes: state.quotes}
            break;
    
        default:
            return state
    }
}