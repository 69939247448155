import * as types from "../actions/types";

const initialState = {
  loading: false,
  listUsers: [],
  userDetails: null, 
  error: null,
  countUsers:0,
  currentPage: 0,
  rowsPerPage: 10,
};

export default function orderСategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case types.USERS_CHANGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }; 
    case types.USERS_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,        
        rowsPerPage: action.payload
      };

      case types.ADMIN_GET_FILTER_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      }; 
    case types.ADMIN_GET_FILTER_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listUsers: action.payload.result,
        countUsers:action.payload.count
      };
    case types.ADMIN_GET_FILTER_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listUsers: action.payload,
      };
    case types.GET_ALL_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    case types.USERS_GET_BY_FACTORY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USERS_GET_BY_FACTORY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listUsers: action.payload,
      };
    case types.USERS_GET_BY_FACTORY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    case types.GET_BY_ID_USER_SUCCESS:
      return { 
        ...state,
        isLoading: false,
        error: null,
        userDetails: action.payload,
      };
    case types.SET_USER:
      return {
        ...state,
        userDetails: action.payload,
      };
    default:
      return state;
  }
}

