import {
    SET_ROUTE_LOCATION,

  } from '../actions/types'
  
  
  const initialState = {
    route_location: "",

  }
  
  export default function (state = initialState, action) {
  
    switch (action.type) {
      
      case SET_ROUTE_LOCATION:
        return {
          ...state,
          route_location:action.payload
        }    
      default:
        return state
    }
  }
  