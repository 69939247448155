import React from 'react';
import "./Header.scss";
import { Grid } from '@material-ui/core';
import plantika_new_logo from './../../assets/plantika_new_logo.png';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';


export default function Header() {
  return (
    <Grid container spacing={0} className='header__main'>
      <Grid xs={4} className='header__col heder__brand'>
        <img src={plantika_new_logo} alt='logo' />
      </Grid>
      <Grid xs={4} className='header__col header__search'>
        <div className='header__iconContainer'>
          <AddIcon style={{ color: '#fff' }} />
        </div>
        &nbsp;
        &nbsp;

        <div className='header__searchContainer'>
          <input placeholder='...Search' />
          <SearchIcon />
        </div>
      </Grid>
      <Grid xs={4} className='header__col header__account'>
        <div className='header__account__icons'>
          <div className='header__account__iconsContainer'><SmsFailedIcon style={{
            width: '24px', height: '24px', color: '#1861A3'
          }} /></div>
          <div className='header__account__iconsContainer' style={{position: 'relative'}}>
          <span className='header__notification'>5</span>
          <NotificationsIcon style={{
            width: '24px', height: '24px', color: '#1861A3'
          }} /></div>
          <div className='header__account__iconsContainer' style={{ background: "#1861A3"}}><PersonOutlinedIcon style={{
            width: '24px', height: '24px', color: '#fff'
          }} /></div>
        </div>

        <div className='header_verticalLine'></div>

        <div className='header__account__logo'>
          <span>CHOCAV</span>
          &nbsp;
          <span><StarOutlineIcon/></span>
        </div>
      </Grid>
    </Grid>
  )
}
