import React, { useEffect, useState } from 'react';
import Table from '../../Table/Table2';
import Button from '../../Buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActionCreate } from '../../../../actions/customer/quoteActions';
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';
import DigitalSignaturePopUp from '../../PopUps/DigitalSignaturePopUp/DigitalSignaturePopUp';
import { getComparator, stableSort } from '../../../CustomersPage/QuotationTable/tools';
import { generalGetRequest } from '../../../../functions/api/general';
import { useCreateUrlWithCustomerId } from '../../../../hooks/useCreateUrlWithCustomerId';
import moment from 'moment';
import { useHistory } from 'react-router-dom'
import { QUOTE_STATUS_OPTIONS } from '../../../../constants/translations/customersPage';
import Loader from '../../../LoaderNew/Loader';
import { createQuote } from '../../../../functions/api/customer-page';
import { setCustomerInfo } from '../../../../actions/quotation-actions';
import { setNavigationUrl } from '../../../../hooks/helper';
import { desktopQuoteList as quotesTableData } from '../../../../constants/staticeData/QuotesList';
import { setCustomerPageQuotes } from '../../../../actions/customers_table_actions';




const quoteBtn = {
    name: DESKTOP_VIEW_TRANSLATION.QUOTE,
    width: '15%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#186315",
    fontSize: '16px',
    fontWeight: 800,
    icon: true,
    iconContainer: true,
    border: false,
    buttonName: 'AddIcon'

}

function formateQuotesDataIntoTableFormate(quotesList) {
    const rows = [];
    for (let row of quotesList) {
        let _row = {
            id: row['_id'], // row['customer_id']
            quoteNumber: row['order_number'] ? row['order_number'] : "-",
            customerName: row['client_name'] ? row['client_name'] : "-",
            coordinationDate: row['due_date'] ? moment(row['due_date']).format('DD/MM/YYYY') : "-",
            contactName: row['contact_name'] ? row['contact_name'] : "-",
            contactPhoneNumber: row['client_phone'] ? row['client_phone'] : "-"
        };
        rows.push(_row)
    }

    return rows
}

export default function QuoteTab(props) {
    const { showPopUp, handleShowPopUp, customer, selectedTab } = props;

    const customerId = customer._id;
    const GET_QUOTES_URL = `/system/customer-page/get-quotes`;
    const url = useCreateUrlWithCustomerId(GET_QUOTES_URL, customerId)

    const [currentPopup, setCurrentPopup] = useState(null);

    const [quotesList, setQuotesList] = useState([]) 
    // const [order, setOrder] = useState('asc');
    // const [orderBy, setOrderBy] = useState('calories');

    // for the redirection to add quote
    const history = useHistory()
    const { DRAFT } = QUOTE_STATUS_OPTIONS;
    const factoryName = useSelector((state) => state.login.user.factory_name);
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();
    // const quotesTableData = useSelector((state) => state.quotes.quotes);
    
    // const quoteList =  stableSort(data, getComparator(order, orderBy));
    quotesTableData.rows = quotesList;

    const popupClickHandlers = {
        // 'Customer report': () => {
        //     setCurrentPopup('Customer report');
        //     handleShowPopUp();
        // },
        // 'Internal report': () => {
        //     setCurrentPopup('Internal report');
        //     handleShowPopUp();
        // },
        'Signature': () => {
            setCurrentPopup('signature');
            handleShowPopUp();
        },
    }

    // const handleRequestSort = (event, property) => {
    //     const isAsc = orderBy === property && order === 'asc';
    //     setOrder(isAsc ? 'desc' : 'asc');
    //     setOrderBy(property);
    // };


  

    const createQuoteHandler = async () => {
        setLoader(true);
        const { _id, address, phone, name } = customer;
        const data = await createQuote({
            quote_info: {
                client_name: name,
                due_date: new Date(),
                started: false,
                address: address,
                status: DRAFT,
                customer_id: _id,
                quantity: 0,
                value: 0,
                client_phone: phone,
                // ??????????  //?????????
                order_category_id: "5f6e0ee658bf6041b4953c95",
                sub_category_id: "5e3a77fd5ee4ad2e1dff7bec",
                type: 0,
            },
            products: []
        });

        const quoteId = data?.result
        dispatch(setCustomerInfo({ address, phone, name }));
        setLoader(false);
        setNavigationUrl(`${history.location.pathname}`);
        history.push(
            `/${factoryName}/customers-page/create-quote/${_id}/${quoteId}`
        );
    };


    useEffect(() => {
        if (selectedTab === 1){
            (async function () {
                const { ok, result } = await generalGetRequest(url);
                if (result) {
                    const formattedQuotesTableDataRows = formateQuotesDataIntoTableFormate(result);
                    setQuotesList(formattedQuotesTableDataRows)
                    dispatch(setCustomerPageQuotes(result))
                }
            })()
        }
        return () => {
        }
    }, [url, customer, selectedTab])
    return (
        <>
            <Table data={quotesTableData} popupClickHandlers={popupClickHandlers} />
            {loader ? <Loader /> : null}
            <div className='desktopTab__footer'>
                <div className='desktopTab__footer__btnContainer'>
                    <div onClick={createQuoteHandler} style={{width: '100%', display: 'flex', justifyContent: 'left'}}>
                        <Button data={quoteBtn} />
                    </div>
                </div>
            </div>
        </>
    )
}
