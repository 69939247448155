export const PRESS_TO_START = 'התחל'
export const STARTED_AT = (time) =>`התחלה ${time}`
export const PRESS_TO_END = 'סיים'
export const SYSTEMS = 'מערכות' 
export const TASKS = 'משימות' 
export const FILES = 'קבצים'
export const REVIEW_POPUP_TITLE = 'בדיקות'
export const REPORT_DONE = 'שמור'
export const CLOSE = 'ביטול'
// export const ADD_SYSTEM = 'מערכות'
// export const ADD_FILE = 'קבצים'
// export const ADD_TASK = 'משימות'
export const TECHNICIANS_TITLE = 'טכנאים'
export const UPLOAD_FILES_TITLE = 'העלאת קבצים'
export const CANCEL = 'בטל'
export const SAVE = 'שמור'
// export const SAVE_REVIEW_POP_UP = 'ביטול'
export const SYSTEM_DETAILS = 'פרטים'
export const DELETE = 'מחק'
export const VISUAL_INSPECTION = 'מפרט בדיקות'
export const ALL_RIGHT = "הכל תקין"
export const LOCATION = `מיקום`
export const FLOOR = `קומה`
export const ADD = `הוסף`
export const SIGNATURE = 'Signature'
export const REOPEN_ORDER ='פתח מחדש'
export const SAVE_AND_CLOSE_REVIEW_POP_UP = 'שמור'
export const TERMINATION_REPORTED = 'דווח סיום'
export const NON_CRIRTICAL_FAULT_SHOW_STOPPER = 'תקלה לא קריטית: לתקן בהקדם'
export const PROPER = 'תקין'