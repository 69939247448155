import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, TextField, withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  createDepartment,
  getAllFactory,
  setFactoryFilterValue,
  getDepartments,
  createFactory,
  createEmployee,
  createRecipient,
  createSubDepartment,
  setDepartmentFilterValue,
  createOrderType,
  createProcess,
  createOrderProcess,
  createProcessWithOrders,
  createOrderCategory,
  createUser,
  createOrderCollectionStage,
  createSystem,
  // setAdditionalTestsPopUpActive
} from "../../actions/actions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../../sass/adminPanel/_admin.scss";
import { FactoryDetails } from "./factoryies/FactoryDetails";
import { CustomButton } from "../generals/CustomButton";
import { DepartmentDetails } from "./department/DepartmentDetails";
import { ResourceDetails } from "./resource/ResourceDetails";
import { RecipientDetails } from "./recipient/RecipientDetais";
import { SubDepartmentDetails } from "./subDepartment/SubDepartmentDetails";
import { OrderTypeDetails } from "./orderType/OrderTypeDetails";
import { GanttTemplateDetails } from "./ganttTemplate/GanttTemplateDetails";
import { ActualGanttDetails } from "./actualGantt/ActualGanttDetails";
import DetailsDialog from "./DetailsDialog";
import { OrderCategoryDetails } from "./categories/OrderCategoryDetails";
import { UserDetails } from "./users/UserDetails";
import { OrderCollectionStagesDetails } from "./orderCollectionStages/OrderCollectionStagesDetails";
import SystemDetailsWrapper from "./Systems/SystemDetails/SystemDetailsWrapper";

const FilterTextField = withStyles({
  root: {
    height: "inherit",
    "& label": {
      transform: "translate(14px, 10px) scale(1)",
      fontFamily: "Rubik",
      opacity: 0.5,
      fontSize: "14px",
      fontWeight: 400,
    },

    "& label.Mui-focused": {
      color: "#0091ff",
    },

    "& label:hover": {
      color: "#40acff",
    },

    "& .MuiOutlinedInput-root": {
      height: "inherit",
      borderRadius: "40px",
      border: "none",
      paddingTop: "0",
      paddingBottom: "0",
      "& input": {
        padding: "0",
      },
      "& fieldset": {
        border: "1px solid #ddd",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0091ff",
      },
      "&:hover fieldset": {
        border: "solid 2px #0091ff",
      },
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: "10px",
    height: "inherit",
    width: 240,
    "& .MuiAutocomplete-input:first-child": {
      padding: 0,
    },
  },
}));

export const ControlPanel = () => {
  const classes = useStyles();

  const [currentEntity, setCurrentEntity] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const factoryList = useSelector((state) => state.factories.listFactories);
  const filterFactory = useSelector((state) => state.factories.filterFactory);
  const deparmentList = useSelector(
    (state) => state.departments.listDepartments
  );
  const filterDepartment = useSelector(
    (state) => state.departments.filterDepartment
  );
  
  useEffect(() => {
    dispatch(getAllFactory());
  }, [dispatch, filterFactory]);

  useEffect(() => {
    dispatch(getDepartments(filterFactory));
  }, [dispatch, filterFactory]);

 
  const getCurrentEntity = () => {
    switch (location.pathname) {
      case "/admin/gantt_template":
        return {
          name: "gantt_template",
          funcEntity: createProcess,
          funcEntityAddToOrder: createProcessWithOrders,
          component: GanttTemplateDetails,
          title: `New Gantt Template`,
          form: "edit-gantt-template",
          btnText: "add gantt template",
        };

      case "/admin/factories":
        return {
          name: "factory",
          btnText: "add factory",
          funcEntity: createFactory,
          component: FactoryDetails,
          form: "edit-factory",
          title: `New Factory`,
        };
      case "/admin/department":
        return {
          name: "department",
          funcEntity: createDepartment,
          component: DepartmentDetails,
          title: `New Department`,
          form: "edit-department",
          btnText: "add department",
        };

      // case "/admin/resourses":
      //   return {
      //     name: "resource",
      //     funcEntity: createEmployee,
      //     component: ResourceDetails,
      //     title: `New Resourse`,
      //     form: "edit-resource",
      //     btnText: "add resource",
      //   };

      case "/admin/recipients":
        return {
          name: "recipient",
          funcEntity: createRecipient,
          component: RecipientDetails,
          title: `New Recipient`,
          form: "edit-recipient",
          btnText: "add recipient",
        };

      case "/admin/sub_department":
        return {
          name: "sub_dept",
          funcEntity: createSubDepartment,
          component: SubDepartmentDetails,
          title: `New Sub Department`,
          form: "edit-sub-department",
          btnText: "add sub dept",
        };
      case "/admin/order_types":
        return {
          name: "order_type",
          funcEntity: createOrderType,
          component: OrderTypeDetails,
          title: `New Order Type`,
          form: "edit-order-type",
          btnText: "add order type",
        };

      case "/admin/gantt_actual":
        return {
          name: "gantt_actual",
          funcEntity: createOrderProcess,
          component: ActualGanttDetails,
          title: `New Actual Gantt`,
          form: "edit-gantt-actual",
          btnText: "add actual gantt",
        };

      case "/admin/order_categories":
        return {
          name: "order_categories",
          funcEntity: createOrderCategory,
          component: OrderCategoryDetails,
          title: `New Order Category`,
          form: "edit-order-category",
          btnText: "add order category",
        };
      case "/admin/users":
        return {
          name: "users",
          funcEntity: createUser,
          component: UserDetails,
          title: `New User`,
          form: "edit-user",
          btnText: "add user",
        };
      case "/admin/order_collection_stages":
        return {
          name: "order_collection_stages",
          funcEntity: createOrderCollectionStage,
          component: OrderCollectionStagesDetails,
          title: `New Order Collection Stage`,
          form: "edit-order-collection-stages",
          btnText: "add collection stages",
        };

      case "/admin/systems":
        return {
          name: "systems",
          btnText: "add system",
          funcEntity: createSystem,
          component: SystemDetailsWrapper,
          form: "edit-system",
          title: `New system`,
        };
          
      default:
        return {
        name: "factory",
        btnText: "add factory",
        funcEntity: createFactory,
        component: FactoryDetails,
        form: "edit-factory",
        title: `New Factory`,
        };
      }
  };

  useMemo(() => {
    let entity = () => getCurrentEntity();
    return setCurrentEntity(entity);
  }, [location]);

  const handleFilterFactory = (e, option) => {
    if (option) {
      dispatch(setDepartmentFilterValue(null));
      dispatch(setFactoryFilterValue(option._id || null));
    } else {
      dispatch(setFactoryFilterValue(null));
      dispatch(setDepartmentFilterValue(null));
    }
  };

  const handleFilterDepartment = (e, option) => {
    dispatch(setDepartmentFilterValue(option._id || null));
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleEdit = () => {
    setIsOpen(true);
  };

  const onSubmit = (id, model, isAddToOrder = false) => {
    setIsOpen(false);
    if (currentEntity && currentEntity.name === "gantt_template") {
      if (isAddToOrder) {
        dispatch(currentEntity.funcEntityAddToOrder(model, filterFactory));
      } else {
        dispatch(currentEntity.funcEntity(model, filterFactory));
      }
    } else {
      dispatch(currentEntity.funcEntity(model, filterFactory));
    }
  };

  // const openAdditionalTestsHandler = () => {
  //   dispatch(setAdditionalTestsPopUpActive(true))
  // }

  return (
    <div className="admin__control__panel">
      {currentEntity && (
        <DetailsDialog
          isOpen={isOpen}
          onClose={handleClose}
          submitLabel="Add"
          form={currentEntity.form}
          title={currentEntity.title}
        >
          <currentEntity.component onSubmit={onSubmit} />
        </DetailsDialog>
      )}
      {currentEntity && currentEntity.name === "sub_dept" && (
        <Autocomplete
          className={classes.filter}
          options={deparmentList ? deparmentList : []}
          getOptionLabel={(option) => option && option.name}
          onChange={(e, option) => handleFilterDepartment(e, option)}
          value={
            (deparmentList &&
              deparmentList.find((element) => {
                return element._id === filterDepartment;
              })) ||
            null
          }
          renderInput={(params) => (
            <FilterTextField
              {...params}
              label="filter department"
              variant="outlined"
            />
          )}
        />
      )}
      <Autocomplete
        className={classes.filter}
        options={factoryList}
        getOptionLabel={(option) => option.name}
        onChange={(e, option) => handleFilterFactory(e, option)}
        value={
          factoryList.find((element) => {
            return element._id === filterFactory;
          }) || null
        }
        renderInput={(params) => (
          <FilterTextField
            {...params}
            label="filter factory"
            variant="outlined"
          />
        )}
      />

      {/* {location.pathname === "/admin/systems" ? <div className="button__wrapper">
        <CustomButton
          variant="contained"
          color="primary"
          // onClick={() => handlerAddEntity(null)}
          onClick={openAdditionalTestsHandler}
        >
          Conditional Tests
        </CustomButton>
      </div> : null} */}
      <div className="button__wrapper">
        <CustomButton
          variant="contained"
          color="primary"
          // onClick={() => handlerAddEntity(null)}
          onClick={handleEdit}
        >
          {currentEntity && currentEntity.btnText}
        </CustomButton>
      </div>

    </div>
  );
};
