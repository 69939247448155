import activationTest from '../../../../images/workTypes/icon_Activation test.svg'
import airFlowMeasurement from '../../../../images/workTypes/icon_Air flow measurement.svg'
import measurementOfCurrents from '../../../../images/workTypes/icon_Measurement of currents.svg'
import visualInspectionAndAudio from '../../../../images/workTypes/icon_Visual inspection and audio.svg'
import visualTest from '../../../../images/workTypes/icon_visual test.svg'
import measuringAirflowVelocity from '../../../../images/workTypes/icon_measuring_airflow_velocity.svg'
import pressureMeasurement from '../../../../images/workTypes/icon_pressure_measurement.svg'

export const icons = [
    {
        name:'activation test',
        icon: activationTest   
    },{
        name:'air flow measurement',
        icon: airFlowMeasurement   
    },{
        name:'measurement of currents',
        icon: measurementOfCurrents   
    },{
        name:'visual inspecion and audio',
        icon: visualInspectionAndAudio   
    },{
        name:'visual test',
        icon: visualTest   
    },{
        name: 'pressure measurement',
        icon: pressureMeasurement
    },{
        name: 'measurement airflow velocity',
        icon: measuringAirflowVelocity
    }]
    // [activationTest, airFlowMeasurement, measurementOfCurrents, visualInspectionAndAudio, visualTest]