import React, { useEffect, useState } from 'react';
import Table from '../../Table/Table2';
import Button from '../../Buttons/Button';
import ReviewPopUp from '../../PopUps/ReviewPopUp/ReviewPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { orderActionCreator } from '../../../../actions/customer/orderActions';
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';
import DigitalSignaturePopUp from '../../PopUps/DigitalSignaturePopUp/DigitalSignaturePopUp';
import { getCustomerOrders } from '../../../../functions/api/customer-page';
import { setCustomerPageOrders } from '../../../../actions/customers_table_actions';
import moment from 'moment';
import OrderCreatedInfoDialog from '../../../CustomersPage/Popups/OrderCreatedInfoDialog';
import { ORDER_ADDED_SUCCESS } from '../../../../constants/customers-page';
import CreateServiceCallWithSelectedSystemsPopUp from '../../../CustomersPage/Popups/CreateServiceCallWithSelectedSystemsPopUp';
import { SERVICE } from '../../../../constants';
import { desktopOrdersList as ordersTableData } from '../../../../constants/staticeData/ordersList';


const orderTabBtn = {
    order: {
        name: DESKTOP_VIEW_TRANSLATION.ORDER, 
        width: '12%',
        height: '52px',
        color: '#fff',
        backgroundColor: "#186315",
        fontSize: '16px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        border: false,
        isBoxShadow: true,
        buttonName: 'AddIcon'
    },
    criticism: {
        name: DESKTOP_VIEW_TRANSLATION.CRITICISM, 
        width: '15%',
        height: '52px',
        color: '#fff',
        backgroundColor: "#0091FF",
        fontSize: '16px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        isBoxShadow: true,
        buttonName: 'AddIcon'
    },
    actions: {
        name: DESKTOP_VIEW_TRANSLATION.ACTIONS, 
        width: '12%',
        height: '52px',
        color: '#fff',
        backgroundColor: "#455768",
        fontSize: '16px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        isBoxShadow: true,
        buttonName: 'AddIcon'
    },
    semiAnnualAudit: {
        name: DESKTOP_VIEW_TRANSLATION.SEMI_ANNUAL_AUDIT, 
        width: '20%',
        height: '52px',
        backgroundColor: '#fff',
        color: "#455768",
        fontSize: '16px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        isBoxShadow: true,
        buttonName: 'AddIcon'
    }
}


function formateOrderDataIntoTableFormate(ordersList){
    const rows = [];
    for (let row of ordersList) {
        let _row = {
            id: row['_id'],
            orderNumber: row['order_number'] ? row['order_number'] : "-",
            customerName: row['client_name'] ? row['client_name'] : "-",
            coordinationDate: row['due_date'] ? moment(row['due_date']).format('DD/MM/YYYY') : "-",
            contactName: row['contact_name'] ? row['contact_name'] : "-",
            contactPhoneNumber: row['client_phone'] ? row['client_phone'] : "-"
        };
        rows.push(_row)
    }

    return rows
}


export default function OrderTab(props) {
    const { showPopUp, handleShowPopUp, customer, customerId, lastCreatedOrderId, selectedTab } = props;
   
    const dispatch = useDispatch();
    // const ordersTableData = useSelector((state) => state.customerOrders.orders)
    const view = "desktop";
    const [action, setAction] = useState('');
    const [currentPopup, setCurrentPopup] = useState(null);
    const [orderCreatedPopup, openOrderCreatedPopup] = useState(false)
    const [openPopup, setOpenPopup] = useState(false);
    const [defectionMessage, setDefectionMessage] = useState('');
    const [ordersList, setOrdersList] = useState([]);

    const factoryType = useSelector(state => state?.login?.user?.type_of_factory);

    ordersTableData.rows = ordersList;

    // do not need to show the action pop-up if we the account is not metalpress
    ordersTableData.popUpData = factoryType !== SERVICE && [];
 
    const openHandler = () => {
        setOpenPopup(true)
        handleShowPopUp()
    }


    const closeHandler = () => {
        setOpenPopup(false)
        handleShowPopUp()
    }

    function handleButtonAction(action) {
        if (action === 'semiAnnualAudit') {
            setAction('semiAnnualAudit');
        }
        if (action === 'review') {
            setAction('review');
        }
        if( action === 'action'){
            setAction('action');
        }
        handleShowPopUp()
        openHandler();
    };

    const popupClickHandlers = {
        // 'Customer report': () => {
        //     setCurrentPopup('Customer report');
        //     handleShowPopUp();
        // },
        // 'Internal report': () => {
        //     setCurrentPopup('Internal report');
        //     handleShowPopUp();
        // },
        'Signature': () => {
            setCurrentPopup('signature');
            handleShowPopUp();
        },
    }
    
    // useEffect(() => {
    //     dispatch(orderActionCreator.getOrdersList());
    // }, [])

    useEffect(() => {
        if (selectedTab === 0) {
            fetchOrders()
        }
        async function fetchOrders() {
            const { result } = await getCustomerOrders(customerId)
            if (result) {
                const formattedOrdersTableDataRows = formateOrderDataIntoTableFormate(result);
                setOrdersList(formattedOrdersTableDataRows)
                dispatch(setCustomerPageOrders(result))
            }
        }
    }, [customerId, lastCreatedOrderId, customer, selectedTab])
    return (
        <>
            {
                ordersTableData && ordersTableData ? <Table data={ordersTableData} popupClickHandlers={factoryType !== SERVICE  && popupClickHandlers} /> : <p>Loading...</p>
            }
            <div className={showPopUp ? 'popUp__background' : ""}>
                {
                    showPopUp && action === 'semiAnnualAudit' ?
                        <ReviewPopUp
                            showPopUp={showPopUp}
                            handleShowPopUp={handleShowPopUp}
                            popUpName={DESKTOP_VIEW_TRANSLATION.SEMI_ANNUAL_AUDIT}
                            view={view}
                            customer={customer}
                            isSpecialCall={true} /** points to a speciall systems, that marked from admin panel  */
                            setIsOrderCreatedMessage={openOrderCreatedPopup}
                            setDefectionMessage={setDefectionMessage}
                            // closeServiceCallPopUpHandler={closeHandler}
                        /> : null
                }
                {
                    showPopUp && action === 'review' ?
                        <ReviewPopUp
                            showPopUp={showPopUp}
                            handleShowPopUp={handleShowPopUp}
                            view={view}
                            customer={customer}
                            // isSpecialCall={true} /** points to a speciall systems, that marked from admin panel  */
                            setIsOrderCreatedMessage={openOrderCreatedPopup}
                            setDefectionMessage={setDefectionMessage}
                            // closeServiceCallPopUpHandler={closeHandler}
                        /> : null
                }
                {
                    showPopUp && action === 'action' ? 
                    <CreateServiceCallWithSelectedSystemsPopUp
                        isOpen={openPopup}
                        setIsOrderCreatedMessage={openOrderCreatedPopup}
                        closeServiceCallPopUpHandler={closeHandler}
                        customer={customer}
                        isSpecialCall={true} /** points to a speciall systems, that marked from admin panel  */
                        defectionMessage={setDefectionMessage}
                    /> : null
                }
                {
                    showPopUp && currentPopup === 'signature' ? <>
                        <div style={{ visibility: currentPopup === 'signature' ? 'visible' : 'hidden' }}>
                            <DigitalSignaturePopUp showPopUp={showPopUp} handleShowPopUp={handleShowPopUp} />
                        </div>
                    </>: null
                }
                {
                    orderCreatedPopup ? <OrderCreatedInfoDialog
                        isOpen={orderCreatedPopup}
                        closeHandler={() => openOrderCreatedPopup(false)}
                        message={defectionMessage ? defectionMessage : ORDER_ADDED_SUCCESS}
                    /> : null
                }
            </div>

            {
                factoryType === SERVICE ? <div className='desktopView desktopTab__footer'>
                    <div className='desktopTab__footer__btnContainer'>
                        <Button data={orderTabBtn.semiAnnualAudit} handleClick={() => handleButtonAction('semiAnnualAudit')} />
                        &nbsp;
                        &nbsp;
                        <Button data={orderTabBtn.actions} handleClick={() => handleButtonAction('action')} />
                        &nbsp;
                        &nbsp;
                        <Button data={orderTabBtn.criticism} handleClick={() => handleButtonAction('review')} />
                        &nbsp;
                        &nbsp;
                        <Button data={orderTabBtn.order} />
                    </div>
                </div> : null
            }
           
        </>
    )
}
