import React, { useState } from 'react';
import "./BlackStrap.scss";
import { Grid } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import TodayIcon from '@material-ui/icons/Today';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Button from '../../Buttons/Button';
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';

const backToToday = {
    name: DESKTOP_VIEW_TRANSLATION.BACK_TO_TODAY, 
    width: '100%',
    height: '20px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '10px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    padding: '2px 5px'
}

export default function BlackStrap(props) {
    const [isViewInfo, setIsViewInfo] = useState(props.data);
    return (
        <Grid container spacing={0} className='blackStrap__main'>
        {
                isViewInfo ? <>
                    <Grid className='blackStrap__countDown'>
                        <span className='blackStrap__iconContainer'><QueryBuilderIcon className='blackStrap__icon' /></span>
                        &nbsp;
                        &nbsp;
                        <label>17:06:19</label>
                    </Grid>
                    <Grid className='blackStrap__dateSelector'>
                        <span className='blackStrap__iconContainer__calendar'><TodayIcon style={{ fontSize: '16px' }} /></span>
                        <span className='blackStrap__dateSelector__date'>
                            <span className='blackStrap__iconContainer'> <ArrowLeftIcon style={{ fontSize: '12px !important' }} /></span>
                            <label>August 2021/10</label>
                            <span className='blackStrap__iconContainer'><ArrowRightIcon style={{ fontSize: '12px !important' }} /></span>
                        </span>
                    </Grid>
                    <Grid className='blackStrap__backBtn'>
                        <Button data={backToToday} />
                    </Grid>
                </>
                : null
        }
       
        </Grid>
    )
}
