import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import {useStyles} from '../style'
import {ADD_TEST, ADD_ADDITIONAL_TEST} from '../../../../constants/translations/admin-systems'
import {CustomButton} from '../../../generals/CustomButton'


const EditCheckListButtons = (props) => {
    const {createTestHandler, createAdditionalTestHandler} = props
    const classes = useStyles()
    return <Grid className = {classes.addIconWrapper}>
      <CustomButton onClick={createTestHandler}>{ADD_TEST}</CustomButton>
      <Typography>Edit check list</Typography>
        <CustomButton onClick={createAdditionalTestHandler}>{ADD_ADDITIONAL_TEST}</CustomButton>
    </Grid>
  }

export default EditCheckListButtons