import axios from 'axios'
import {API} from "../../tools/keys/keys"
import { polyfill } from 'es6-promise';
import { LOGIN_DATA } from '../../constants/offline-mode'
polyfill()

export const getUserDetails = (body) => new Promise(resolve => {
    axios.post(`${API}/auth/login`, body).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok){
            const data = JSON.stringify(output)
            localStorage.setItem(LOGIN_DATA,data)
            resolve(output.result)
        }else{
            resolve(false)
        }
      
    }).catch(err => {
        const data = JSON.parse(localStorage.getItem(LOGIN_DATA))
        resolve(data)
        // resolve({ ok: false })
    })
})


export const getUserByToken = (body,token) => new Promise(resolve => {
    const headers = {
        'access-token': token
        }
    axios.post(`${API}/auth/login`,body, {headers} ).then(res => {
        const {
            ok,
            result
        } = res.data
        const output = {
            ok,
            result
        }
        if(ok){
            const data = JSON.stringify(output)
            localStorage.setItem(LOGIN_DATA,data)
            resolve(output)
        }else{
            resolve(false)
        }
      
    }).catch(err => {
        const data = JSON.parse(localStorage.getItem(LOGIN_DATA))
        resolve(data)
        // resolve({ ok: false })
    })
})