import React, { useEffect, useState } from "react";
import {Grid, Paper} from "@material-ui/core";
import { generalGetRequest } from '../../../../functions/api/general'
import DetailsDialog from '../../DetailsDialog'
import { updateInput } from '../../../../functions/api/admin'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStyles } from '../style'
import InputOptions from './InputOptions'
import InputsSelect from './InputsSelect'
import {InputType} from '../templates'
import { INPUT_NAME_PROPERTY, REG_EXP_OBJECT_ID, MAX_SINGLE_INPUT } from "../../../../constants/admin-systems";
import EditInputName from './EditInputName'
import EditInputTitle from './EditInputTitle'
import {useMinAndMaxInputNumbers} from '../../../../hooks/useMinAndMaxInputNumbers'

const EditInputPopUp = (props) => {
  const classes = useStyles()
  const { inputTypes, setInputTypes, value, changeCheckListValueHandler, setEditInputPopupActive, editInputPopupActive, showStoppers } = props
  const [inputType, setInputType] = useState(value)
  const [input, setInput] = useState('')

  const {singleNumber, multipleNumber} = useMinAndMaxInputNumbers(inputTypes)
    
  const getInputs = async() => {
    const responce = await generalGetRequest(`/admin/systems/get-system-inputs`)
    if(responce.result){
      setInputTypes(responce.result)
    }
  }

  useEffect(() => {
    const getInputByNumber = async (input) => {
      const responce = await generalGetRequest(`/admin/systems/find-input-by-number?number=${input}`)
      responce?.result && setInput(responce.result)
    }
    inputType && (!parseInt(inputType) || (singleNumber && multipleNumber && inputType >= singleNumber && inputType <= multipleNumber)) && getInputByNumber(inputType)
  }, [inputType, singleNumber, multipleNumber])

  const changeInputOptionsHandler = (idx, property) => (value) => {
    const inputCopy = {...input}
    inputCopy.options[idx][property] = value
    setInput(inputCopy)
  }

  const deleteInputOptionHandler = (idx) => {
    const inputCopy = {...input}
    inputCopy.options.splice(idx,1)
    setInput(inputCopy)
  }

  const addOptionHandler = () => {
    const inputCopy = {...input}
    const newOption = {
      option: '',
      action: ''
    }
    inputCopy.options.push(newOption)
    setInput(inputCopy)
  }

  const submitForm = async () => {
    const inputCopy = {...input}
    if(!inputCopy._id.match(REG_EXP_OBJECT_ID)){
      delete inputCopy._id
    }
    const body = {
      ...inputCopy
    }
    changeCheckListValueHandler(inputType)
    const result = await updateInput(body)
    setEditInputPopupActive(false)

  }

  const createInputHandler = () => {
    const inputNumber = multipleNumber + 1 + ''
    const inputType = new InputType(inputNumber)
    const newInputTypes = [ inputType, ...inputTypes]
    setInputTypes(newInputTypes)
    setInputType(inputNumber)
    setInput(inputType)
  }

  const editMultipleHandler = (value) => {
    const inputCopy = {...input}
    const {input_number} = inputCopy
    const inputTypesCopy = [...inputTypes]
    const inputType = inputTypesCopy.find(i => i.input_number === input_number)
    if(value){
      inputType.input_number = multipleNumber + 1 + ''
      inputCopy.input_number = multipleNumber + 1 + ''
    }else{
      inputCopy.input_number = singleNumber - 1 + ''
      inputType.input_number = singleNumber - 1 + ''
    }
    setInputTypes(inputTypesCopy)
    setInputType(inputCopy.input_number)
    setInput(inputCopy)
  }

  const changeNameHandler = (value) => {
    const inputCopy = {...input}
    inputCopy[INPUT_NAME_PROPERTY] = value
    setInput(inputCopy)
  }

  const deleteInputHandler = async() => {
    if(input?._id.match(REG_EXP_OBJECT_ID)){
      await generalGetRequest(`/admin/systems/delete-input?input_id=${input?._id}`)
    }
    await getInputs()
    const newType = inputTypes?.[0]?.input_number
    setInputType(newType)
  }

  return (inputTypes ? <DetailsDialog
    isOpen={editInputPopupActive}
    onClose={() => setEditInputPopupActive(false)}
    submitLabel="Update"
    maxWidth='md'
    title={<EditInputTitle
      addHandler = {createInputHandler}
      deleteHandler = {deleteInputHandler}
    />}
    submitHandler = {submitForm}
  >
    <Paper className={classes.editInputWrapper}>
      <InputsSelect
        inputTypes={inputTypes}
        setInputType={setInputType}
        // defaultValue={defaultValueIndex}
        defaultValue={inputType}
      />
      {parseInt(inputType) ? <React.Fragment>
                                <EditInputName
                                  input = {input}
                                  changeNameHandler = {changeNameHandler} 
                                  setMultiple = {editMultipleHandler} 
                                  multiple = {input.input_number > MAX_SINGLE_INPUT}
                                  />
                                  <InputOptions
                                    options={input?.options || []}
                                    changeInputOptionsHandler={changeInputOptionsHandler}
                                    deleteInputOptionHandler={deleteInputOptionHandler}
                                    showStoppers = {showStoppers}
                                  />
                                  <Grid className={classes.container} >
                                    <AddCircleIcon className={`${classes.icons} ${classes.addIcon}`} onClick={addOptionHandler} />
                                  </Grid> 
                              </React.Fragment> : null}
                            </Paper>
                          </DetailsDialog> : null)
}

export default EditInputPopUp
