import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { URL_NAVIGATION_HISTORY } from '../../constants/offline-mode';
import { goBack, reSetNavigationUrl } from '../../hooks/helper';
import '../../sass/customersPage/_back-navigation.scss'

const BackNavigation = (props) => {
    const { onClickCB } = props;
    const history = useHistory();
    
    history.listen(({pathname}) => {
        let url_history = JSON.parse(localStorage.getItem(URL_NAVIGATION_HISTORY)) || []
        url_history = [...url_history]
        let h = url_history?.map((item, index) => {
            if(item === pathname) {
                let p = url_history?.splice(index, 1)
                p && p.length && reSetNavigationUrl(url_history)
            }
        })
    })

    const cb = () => !onClickCB ? goBack(history) : onClickCB(goBack);
    
    return(
        <button className='back-navigation' onClick={cb}>
            חזור
        </button>
    )
}


export default BackNavigation